/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { Upload, Modal, Typography, Row, Col, Divider, Button } from 'antd';
import {
  InboxOutlined,
  InfoCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { useLocalStore, observer } from 'mobx-react';
import { useMutation } from '@apollo/react-hooks';
import Sortable from 'sortablejs';
import styled from 'styled-components';
import { toJS } from 'mobx';

import { MULTI_FILE_UPLOAD, SINGLE_FILE_REMOVE } from '@shared/queries';

import { formatFilename } from '@utils/common';
import { checkExif } from '@utils/preUpload';
import theme from '@utils/styled-theme';

const { Dragger } = Upload;
const { Paragraph, Text } = Typography;

const Wrapper = styled.div`
  .ant-upload-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .ant-upload-list-item {
    &:first-child {
      border: 2px dashed ${(props) => props.theme.primaryColor};
    }
  }
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }

  .multi-remove-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    img {
      cursor: pointer;
      width: 50px;
      height: 50px;
      object-fit: cover;
      border: 2px solid #fff;
    }
    .seleted {
      border: 2px solid ${(props) => props.theme.errColor};
    }
  }
  .multi-remove-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
`;

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const Dropzone = observer((props) => {
  const state = useLocalStore(() => ({
    previewVisible: false,
    previewImage: '',
    uploading: false,
    uploadFileCnt: 0,
    fileList: [],
    removeList: [],
  }));

  const [upload] = useMutation(MULTI_FILE_UPLOAD);
  const [remove] = useMutation(SINGLE_FILE_REMOVE);

  const handleCancel = useCallback(() => {
    state.previewVisible = false;
  }, []);
  const handlePreview = useCallback(async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    state.previewImage = file.url || file.preview;
    state.previewVisible = true;
  }, []);

  const onRemove = useCallback(
    async (file) => {
      if (file) {
        window.ask({
          title: '해당 이미지를 삭제하시겠습니까?',
          content:
            '이미지는 저장여부와 관계 없이 진행시 바로 삭제되어 복구할 수 없습니다.',
          async onOk() {
            state.uploading = true;
            const fileList = toJS(state.fileList);
            const idx = fileList.findIndex((item) => item.name === file.name);

            if (file.status !== 'done') {
              state.uploadFileCnt -= 1;
            } else {
              try {
                await remove({
                  variables: {
                    Key: state.fileList[idx].name,
                  },
                });
              } catch (err) {
                if (err && err.message) {
                  window.alert({ title: err.message });
                }
              }
            }
            fileList.splice(idx, 1);
            state.fileList = fileList;

            if (props.setParentImages) {
              props.setParentImages(fileList, props.lang);
            }

            if (props.handleSaveData) {
              await props.handleSaveData(
                fileList.filter((item) => item.status === 'done'),
                props.lang,
              );
            }

            state.uploading = false;
          },
        });
      }
    },
    [state.fileList, props],
  );

  const handleRemoveMulti = useCallback(
    async (isAll) => {
      if (isAll) {
        window.ask({
          title: '전체 이미지를 삭제하시겠습니까?',
          content:
            '이미지는 저장여부와 관계 없이 진행시 바로 삭제되어 복구할 수 없습니다.',
          async onOk() {
            state.uploading = true;
            const fileList = toJS(state.fileList);

            for (const file of fileList) {
              try {
                await remove({
                  variables: {
                    Key: file.name,
                  },
                });
              } catch (err) {}
            }

            state.uploadFileCnt = 0;
            state.fileList = [];
            if (props.setParentImages) {
              props.setParentImages([], props.lang);
            }
            if (props.handleSaveData) {
              await props.handleSaveData([], props.lang);
            }
            state.uploading = false;
          },
        });
      } else {
        window.ask({
          title: '선택된 이미지를 삭제하시겠습니까?',
          content:
            '이미지는 저장여부와 관계 없이 진행시 바로 삭제되어 복구할 수 없습니다.',
          async onOk() {
            state.uploading = true;
            const fileList = toJS(state.fileList);
            const removeList = toJS(state.removeList);

            for (const file of removeList) {
              const idx = fileList.findIndex((item) => item.name === file.name);
              if (file.status !== 'done') {
                state.uploadFileCnt -= 1;
              } else {
                try {
                  await remove({
                    variables: {
                      Key: fileList[idx].name,
                    },
                  });
                } catch (err) {}
              }
              fileList.splice(idx, 1);
            }

            state.fileList = fileList;
            if (props.setParentImages) {
              props.setParentImages(fileList, props.lang);
            }

            if (props.handleSaveData) {
              await props.handleSaveData(
                fileList.filter((item) => item.status === 'done'),
                props.lang,
              );
            }
            state.uploading = false;
          },
        });
      }
    },
    [props],
  );

  const handleSelectRemove = useCallback(
    async (file) => {
      const removeList = toJS(state.removeList);
      const fIdx = removeList.findIndex((item) => item.name === file.name);
      if (fIdx !== -1) {
        removeList.splice(fIdx, 1);
      } else {
        removeList.push(file);
      }
      state.removeList = removeList;
    },
    [props],
  );

  const beforeUpload = useCallback(
    async (file) => {
      if (props.limit && state.uploadFileCnt >= props.limit) {
        return window.info({
          title: `${props.limit}개 까지만 업로드 가능합니다.`,
        });
      }

      window.maskOn();
      try {
        // const resizeFile = await checkExif(file, props.width, props.height);
        let resizeFile;
        if (/\.gif$/i.test(file.name)) {
          resizeFile = file;
        } else {
          resizeFile = await checkExif(file, 100000, 100000);
          resizeFile.uid = file.uid;
          resizeFile.name = file.name;
        }
        resizeFile.status = 'load';
        state.uploadFileCnt += 1;
        const fileList = toJS(state.fileList);
        fileList.push(resizeFile);
        state.fileList = fileList;
        if (props.setParentImages) {
          props.setParentImages(fileList, props.lang);
        }
      } catch (e) {
        if (e && e.message) {
          window.maskOff();
          window.alert({ title: e.message });
        }
      }

      setTimeout(() => {
        window.maskOff();
      }, 100);
      return false;
    },
    [props.setParentImages, props.limit],
  );

  const handleUpload = useCallback(() => {
    return new Promise(async (resolve) => {
      state.uploading = true;
      const uploadFiles = [];
      const uploadPath = props.uploadPath;
      const fileList = toJS(state.fileList);
      const images = [];
      for (let i = 0; i < fileList.length; i++) {
        delete fileList[i].__typename;

        if (fileList[i].status !== 'done') {
          const filename = formatFilename(fileList[i].type, uploadPath);
          fileList[i].fileName = filename;
          state.uploadFileCnt -= 1;
          uploadFiles.push({
            file: fileList[i],
            filename,
          });
        }
        images.push(fileList[i]);
      }

      if (uploadFiles.length) {
        try {
          const uploadRes = await upload({
            variables: {
              datas: uploadFiles,
            },
          });

          if (uploadRes.data && uploadRes.data.multiFileUpload) {
            for (const uploaded of uploadRes.data.multiFileUpload) {
              const fIdx = images.findIndex(
                (item) => item.fileName === uploaded.name,
              );
              if (fIdx !== -1) {
                images[fIdx] = {
                  ...images[fIdx],
                  ...uploaded,
                };
              }
            }
          }
        } catch (err) {
          return window.alert({ title: err.message });
        }
      }
      resolve(images);
      state.uploading = false;
    });
  }, [props]);

  useEffect(() => {
    const el = document.querySelector(
      `${props.id ? '#' + props.id + ' ' : ''}.ant-upload-list`,
    );
    if (el) {
      Sortable.create(el, {
        onEnd: (evt) => {
          const fileList = toJS(state.fileList);
          state.fileList = [];
          const newData = fileList[evt.oldIndex];
          fileList.splice(evt.oldIndex, 1);
          fileList.splice(evt.newIndex, 0, newData);
          state.fileList = fileList;
          if (props.setParentImages) {
            props.setParentImages(fileList, props.lang);
          }
        },
      });
    }
  }, [props.setParentImages, props.lang, props.id]);

  useEffect(() => {
    if (!state.fileList.length) {
      let uploadFileCnt = 0;
      const fileList = [];
      for (const img of props.images) {
        if (img.uid && img.url) {
          uploadFileCnt++;
          fileList.push(img);
        }
      }
      state.uploadFileCnt = uploadFileCnt;
      state.fileList = fileList;
    }
  }, [props.images]);

  useEffect(() => {
    props.setHandleUpload(handleUpload, props.lang);
  }, [props.lang]);

  return (
    <div className="clearfix">
      <Wrapper id={props.id}>
        <Dragger
          multiple={props.multiple}
          accept="image/*"
          listType="picture-card"
          fileList={state.fileList}
          onPreview={handlePreview}
          onRemove={onRemove}
          beforeUpload={beforeUpload}
          customRequest={() => {
            return;
          }}
          disabled={props.disabled}
        >
          <p className="ant-upload-drag-icon">
            {props.disabled ? (
              <CloseCircleOutlined style={{ color: '#e74c3c' }} />
            ) : (
              <InboxOutlined style={{ color: theme.primaryColor }} />
            )}
          </p>
          {props.disabled ? (
            <p className="ant-upload-text">사용할 수 없는 상태입니다.</p>
          ) : (
            <>
              <p className="ant-upload-text">
                클릭 or 이미지를 여기로 드래그 하세요.
              </p>
              {!props.hideDescription && (
                <p className="ant-upload-hint">
                  아래에 로딩된 이미지들을 드래그하여 순서를 바꿀 수 있습니다.
                </p>
              )}
            </>
          )}
        </Dragger>

        {state.fileList && state.fileList.length ? (
          <Row>
            <Col xs={24}>
              {props.descriptions &&
                props.descriptions.map((text, idx) => (
                  <Paragraph key={`dropzone_description_${idx}`}>
                    <InfoCircleOutlined style={{ color: theme.primaryColor }} />
                    &nbsp;
                    <Text strong={true}>{text}</Text>
                  </Paragraph>
                ))}
            </Col>
            <Col xs={24}>
              <Paragraph>
                <InfoCircleOutlined style={{ color: theme.primaryColor }} />
                &nbsp;
                <Text strong={true}>
                  아래에서 여러 이미지를 한번에 삭제할 수 있습니다.
                </Text>
              </Paragraph>
            </Col>
            <Col xs={24}>
              <div className="multi-remove-wrapper">
                {toJS(state.fileList).map((item, idx) => (
                  <img
                    key={`${props.id}_multi_remove_${idx}`}
                    className={
                      toJS(state.removeList).find((r) => r.name === item.name)
                        ? 'seleted'
                        : ''
                    }
                    src={item.url}
                    onClick={() => handleSelectRemove(item)}
                  />
                ))}
              </div>
            </Col>
            <Col xs={24} style={{ marginTop: 15 }}>
              <div className="multi-remove-btn">
                <Button
                  danger
                  disabled={!state.removeList.length}
                  onClick={() => handleRemoveMulti()}
                >
                  선택삭제
                </Button>
                <Button
                  type="primary"
                  danger
                  onClick={() => handleRemoveMulti(true)}
                >
                  전체삭제
                </Button>
              </div>
            </Col>
          </Row>
        ) : null}

        <Divider />

        <Modal
          visible={state.previewVisible}
          footer={null}
          onCancel={handleCancel}
          centered={true}
          bodyStyle={{ paddingTop: '50px' }}
        >
          <img alt="" style={{ width: '100%' }} src={state.previewImage} />
        </Modal>
      </Wrapper>
    </div>
  );
});

export default Dropzone;
