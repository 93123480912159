import gql from 'graphql-tag';
import { postFragment } from '@shared/fragments';

export const POSTS = gql`
  ${postFragment}
  query Posts($page: Int, $limit: Int, $findQuery: JSON) {
    posts(page: $page, limit: $limit, findQuery: $findQuery) {
      docs {
        ...PostFragment
      }
      totalDocs
      limit
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      pagingCounter
    }
  }
`;

export const POST = gql`
  ${postFragment}
  query Post($id: ID) {
    post(id: $id) {
      ...PostFragment
    }
  }
`;

export const POST_LIST = gql`
  ${postFragment}
  query PostList($findQuery: JSON) {
    postList(findQuery: $findQuery) {
      ...PostFragment
    }
  }
`;

export const CHECK_NEW_POST = gql`
  query CheckNewPost($boardType: String) {
    checkNewPost(boardType: $boardType)
  }
`;

export const ADD_POST = gql`
  ${postFragment}
  mutation AddPost($data: InputPost) {
    addPost(data: $data) {
      ...PostFragment
    }
  }
`;

export const UPDATE_POST = gql`
  ${postFragment}
  mutation UpdatePost($id: ID!, $data: InputPost) {
    updatePost(id: $id, data: $data) {
      ...PostFragment
    }
  }
`;

export const REMOVE_POST = gql`
  mutation RemovePost($id: ID!) {
    removePost(id: $id)
  }
`;

export const PREV_NEXT_POST = gql`
  query PrevNextPost($id: ID, $board: String) {
    prevNextPost(id: $id, board: $board)
  }
`;

export const MY_POST_CNT = gql`
  query MyPostCnt($board: String, $created: ID) {
    myPostCnt(board: $board, created: $created)
  }
`;
