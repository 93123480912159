/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import {
  Row,
  Col,
  InputNumber,
  Space,
  Card,
  Radio,
  Divider,
  Button,
} from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import uniqid from 'uniqid';

import { numberWithCommas } from '@utils/common';

const Wrapper = styled.div`
  width: 100%;
`;

const ProductConfigK = observer(({ productValue, setProductValue }) => {
  const state = useLocalStore(() => ({}));

  const handleChangeNumber = useCallback(
    (type) => (value) => {
      productValue[type] = value;
      setProductValue(productValue);
    },
    [productValue],
  );

  const handleChangeNumberArr = useCallback(
    (type, key, uid) => (value) => {
      if (!productValue[type]) {
        productValue[type] = [];
      }
      const fIdx = productValue[type].findIndex((item) => item.uid === uid);
      if (fIdx !== -1) {
        productValue[type][fIdx][key] = value;
      }
      setProductValue(productValue);
    },
    [productValue],
  );

  const handleAddPriceData = useCallback(
    (type) => (e) => {
      if (!productValue[type]) {
        productValue[type] = [];
      }
      productValue[type].push({
        uid: uniqid.time(),
      });
      setProductValue(productValue);
    },
    [productValue],
  );

  const handleRemovePriceData = useCallback(
    (type, uid) => (e) => {
      if (!productValue[type]) {
        productValue[type] = [];
      }
      const fIdx = productValue[type].findIndex((item) => item.uid === uid);
      if (fIdx !== -1) {
        productValue[type].splice(fIdx, 1);
      }
      setProductValue(productValue);
    },
    [productValue],
  );

  return (
    <Wrapper>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={8}>
          <Card title="K좋아요" size="small">
            <h3>[건금액]</h3>
            <Space>
              <InputNumber
                value={productValue.kLikePrice}
                onChange={handleChangeNumber('kLikePrice')}
                formatter={(value) => numberWithCommas(value)}
                parser={(value) => value.replace(/[^0-9]/g, '')}
                style={{ width: 140 }}
              />
              <span>원</span>
            </Space>

            <Divider />

            <h3>[월금액]</h3>
            <Row gutter={[10, 10]}>
              <Col xs={24}>
                <Space>
                  <InputNumber
                    value={productValue.kLikeMonthDays}
                    onChange={handleChangeNumber('kLikeMonthDays')}
                    style={{ width: 80 }}
                  />
                  <span>일</span>
                </Space>
              </Col>
              {productValue.kLikeMonthPrice &&
                productValue.kLikeMonthPrice.map((item) => (
                  <Col xs={24} key={item.uid}>
                    <Space size={30}>
                      <Space>
                        <InputNumber
                          value={item.cnt}
                          onChange={handleChangeNumberArr(
                            'kLikeMonthPrice',
                            'cnt',
                            item.uid,
                          )}
                          style={{ width: 80 }}
                        />
                        <span>개</span>
                      </Space>
                      <Space>
                        <InputNumber
                          value={item.price}
                          onChange={handleChangeNumberArr(
                            'kLikeMonthPrice',
                            'price',
                            item.uid,
                          )}
                          formatter={(value) => numberWithCommas(value)}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: 140 }}
                        />
                        <span>원</span>
                      </Space>
                      <Button
                        shape="circle"
                        icon={<MinusOutlined />}
                        size="small"
                        danger
                        onClick={handleRemovePriceData(
                          'kLikeMonthPrice',
                          item.uid,
                        )}
                      />
                    </Space>
                  </Col>
                ))}
            </Row>
            <Button
              icon={<PlusOutlined />}
              block
              onClick={handleAddPriceData('kLikeMonthPrice')}
            >
              월금액 추가
            </Button>
          </Card>
        </Col>
        <Col xs={24} lg={8}>
          <Card title="K팔로워" size="small">
            <h3>[건금액]</h3>
            <Space>
              <InputNumber
                value={productValue.kFollowPrice}
                onChange={handleChangeNumber('kFollowPrice')}
                formatter={(value) => numberWithCommas(value)}
                parser={(value) => value.replace(/[^0-9]/g, '')}
                style={{ width: 140 }}
              />
              <span>원</span>
            </Space>
          </Card>
        </Col>
        <Col xs={24} lg={8}>
          <Card title="K댓글" size="small">
            <h3>[건금액]</h3>
            <Row gutter={[10, 10]}>
              <Col xs={24}>
                <h4>랜덤댓글</h4>
                <Space>
                  <InputNumber
                    value={productValue.kRandomCommentPrice}
                    onChange={handleChangeNumber('kRandomCommentPrice')}
                    formatter={(value) => numberWithCommas(value)}
                    parser={(value) => value.replace(/[^0-9]/g, '')}
                    style={{ width: 140 }}
                  />
                  <span>원</span>
                </Space>
              </Col>
              <Col xs={24}>
                <h4>직접입력 댓글</h4>
                <Space>
                  <InputNumber
                    value={productValue.kDirectCommentPrice}
                    onChange={handleChangeNumber('kDirectCommentPrice')}
                    formatter={(value) => numberWithCommas(value)}
                    parser={(value) => value.replace(/[^0-9]/g, '')}
                    style={{ width: 140 }}
                  />
                  <span>원</span>
                </Space>
              </Col>
            </Row>

            <Divider />

            <h3>[월금액]</h3>
            <Row gutter={[10, 10]}>
              <Col xs={24}>
                <Space>
                  <InputNumber
                    value={productValue.kCommentMonthDays}
                    onChange={handleChangeNumber('kCommentMonthDays')}
                    style={{ width: 80 }}
                  />
                  <span>일</span>
                </Space>
              </Col>
              <Col xs={24}>
                <h4>랜덤댓글</h4>
                <Row gutter={[10, 10]}>
                  {productValue.kRandomCommentMonthPrice &&
                    productValue.kRandomCommentMonthPrice.map((item) => (
                      <Col xs={24} key={item.uid}>
                        <Space size={30}>
                          <Space>
                            <InputNumber
                              value={item.cnt}
                              onChange={handleChangeNumberArr(
                                'kRandomCommentMonthPrice',
                                'cnt',
                                item.uid,
                              )}
                              style={{ width: 80 }}
                            />
                            <span>개</span>
                          </Space>
                          <Space>
                            <InputNumber
                              value={item.price}
                              onChange={handleChangeNumberArr(
                                'kRandomCommentMonthPrice',
                                'price',
                                item.uid,
                              )}
                              formatter={(value) => numberWithCommas(value)}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: 140 }}
                            />
                            <span>원</span>
                          </Space>
                          <Button
                            shape="circle"
                            icon={<MinusOutlined />}
                            size="small"
                            danger
                            onClick={handleRemovePriceData(
                              'kRandomCommentMonthPrice',
                              item.uid,
                            )}
                          />
                        </Space>
                      </Col>
                    ))}
                </Row>
                <Button
                  icon={<PlusOutlined />}
                  block
                  onClick={handleAddPriceData('kRandomCommentMonthPrice')}
                >
                  랜덤댓글 추가
                </Button>
              </Col>
              <Col xs={24}>
                <h4>직접입력 댓글</h4>
                <Row gutter={[10, 10]}>
                  {productValue.kDirectCommentMonthPrice &&
                    productValue.kDirectCommentMonthPrice.map((item) => (
                      <Col xs={24} key={item.uid}>
                        <Space size={30}>
                          <Space>
                            <InputNumber
                              value={item.cnt}
                              onChange={handleChangeNumberArr(
                                'kDirectCommentMonthPrice',
                                'cnt',
                                item.uid,
                              )}
                              style={{ width: 80 }}
                            />
                            <span>개</span>
                          </Space>
                          <Space>
                            <InputNumber
                              value={item.price}
                              onChange={handleChangeNumberArr(
                                'kDirectCommentMonthPrice',
                                'price',
                                item.uid,
                              )}
                              formatter={(value) => numberWithCommas(value)}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: 140 }}
                            />
                            <span>원</span>
                          </Space>
                          <Button
                            shape="circle"
                            icon={<MinusOutlined />}
                            size="small"
                            danger
                            onClick={handleRemovePriceData(
                              'kDirectCommentMonthPrice',
                              item.uid,
                            )}
                          />
                        </Space>
                      </Col>
                    ))}
                </Row>
                <Button
                  icon={<PlusOutlined />}
                  block
                  onClick={handleAddPriceData('kDirectCommentMonthPrice')}
                >
                  직접입력 댓글 추가
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Wrapper>
  );
});

export default ProductConfigK;
