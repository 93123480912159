/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback, useMemo } from 'react';
import {
  Table,
  Row,
  Col,
  Radio,
  Space,
  DatePicker,
  Button,
  Input,
  Select,
  Grid,
  Descriptions,
} from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import moment from 'moment';
import { useApolloClient } from '@apollo/react-hooks';
import { toJS } from 'mobx';

import useStore from '@stores/useStore';
import { POINT_LOGS } from '@shared/queries/PointLogQueries';
import { numberWithCommas } from '@utils/common';

import CustomModal from '@Common/CustomModal';

import SaleDetail from './SaleDetail';

const { Search } = Input;
const { Option } = Select;
const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  width: 100%;

  .error {
    color: ${(props) => (props.error ? '#e74c3c' : props.theme.primaryColor)};
  }
`;

const SaleList = observer(({ listType, user }) => {
  const screen = useBreakpoint();
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    list: [],
    paginate: {
      docs: [],
      totalDocs: 0,
      limit: 10,
      page: 1,
      totalPages: 1,
    },
    selectedData: {},
    detailOpen: false,

    searchType: null,
    searchText: '',
    startDate: null,
    endDate: null,
    payMethod: null,
    searchStatus: null,

    now: moment(),
  }));

  const client = useApolloClient();

  const initDateFetch = useCallback(async () => {
    const now = await commonStore.getServerNow();
    state.now = now;

    let query = [];
    switch (listType) {
      case 'all':
        break;
      case 'today':
        state.startDate = moment(
          now.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          }),
        );
        state.endDate = moment(
          now.set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999,
          }),
        );
        query = [
          {
            paymentDate: {
              $gte: state.startDate.toDate(),
            },
          },
          {
            paymentDate: {
              $lte: state.endDate.toDate(),
            },
          },
        ];
        break;
      case 'week':
        state.startDate = moment(now.startOf('isoWeek'));
        state.endDate = moment(now.endOf('isoWeek'));
        query = [
          {
            paymentDate: {
              $gte: state.startDate.toDate(),
            },
          },
          {
            paymentDate: {
              $lte: state.endDate.toDate(),
            },
          },
        ];
        break;
      case 'month':
        state.startDate = moment(now.startOf('month'));
        state.endDate = moment(now.endOf('month'));
        query = [
          {
            paymentDate: {
              $gte: state.startDate.toDate(),
            },
          },
          {
            paymentDate: {
              $lte: state.endDate.toDate(),
            },
          },
        ];
        break;
      default:
    }

    fetchData({ $and: query });
  }, [listType, user]);

  const fetchData = useCallback(
    async (findQuery = {}) => {
      window.maskOn();

      let query = [
        {
          pointType: '포인트충전',
          $or: [
            { status: '결제완료' },
            { status: '결제취소' },
            { status: '환불완료' },
          ],
        },
      ];

      if (user) {
        query.push({ user: user.id });
      }

      if (findQuery['$and']) {
        findQuery['$and'] = findQuery['$and'].concat(query);
      } else {
        findQuery['$and'] = query;
      }

      const result = await client.query({
        query: POINT_LOGS,
        variables: {
          page: state.paginate.page,
          limit: state.paginate.limit,
          findQuery,
        },
      });

      if (result.data && result.data.pointLogs) {
        state.paginate = result.data.pointLogs;
        state.list = result.data.pointLogs.docs;
      }
      window.maskOff();
    },
    [listType, user],
  );

  const handleDetail = useCallback(
    (modalOpen, data) => () => {
      if (modalOpen && data) {
        state.selectedData = data;
      } else {
        state.selectedData = {};
      }
      state.detailOpen = modalOpen;
    },
    [],
  );

  const handleChangePage = useCallback(
    (page) => {
      if (state.paginate.page !== page) {
        state.paginate.page = page;
        handleSearch()();
      }
    },
    [user],
  );

  const handleChangeInput = useCallback(
    (type) => (e) => {
      state[type] = e.target.value;
    },
    [],
  );
  const handleChangeSearch = useCallback(
    (type) => (value) => {
      state[type] = value;
    },
    [],
  );
  const handleChangeDate = useCallback(
    (type) => (momentVal) => {
      state[type] = momentVal;
    },
    [],
  );

  const handleSearch = useCallback(
    (type) => (value) => {
      const findQuery = {};
      findQuery['$and'] = [];

      const searchType = type || state.searchType;
      state.searchType = searchType;

      if (state.searchText && state.searchText.length) {
        findQuery.user = {
          $or: [
            { username: { $regex: state.searchText, $options: 'ig' } },
            { name: { $regex: state.searchText, $options: 'ig' } },
          ],
        };
      }

      if (searchType === 'pay_method' && state.payMethod) {
        findQuery['$and'].push({ pay_method: state.payMethod });
      }
      if (searchType === 'status' && state.searchStatus) {
        findQuery['$and'].push({ status: state.searchStatus });
      }

      if (state.startDate) {
        findQuery['$and'].push({
          paymentDate: {
            $gte: state.startDate.set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            }),
          },
        });
      }
      if (state.endDate) {
        findQuery['$and'].push({
          paymentDate: {
            $lte: state.endDate.set({
              hour: 23,
              minute: 59,
              second: 59,
              millisecond: 999,
            }),
          },
        });
      }
      if (!findQuery['$and'].length) {
        delete findQuery['$and'];
      }

      fetchData(findQuery);
    },
    [user],
  );

  useEffect(() => {
    initDateFetch();
  }, [listType, user]);

  const columns = useMemo(
    () => [
      {
        title: '날짜',
        dataIndex: 'paymentDate',
        key: 'paymentDate',
        render: (_, row) =>
          row.paymentDate
            ? moment(row.paymentDate).format('YYYY-MM-DD HH:mm:ss')
            : '',
        align: 'center',
      },
      {
        title: '아이디',
        dataIndex: 'username',
        key: 'username',
        render: (_, row) => row.user.username,
        align: 'center',
      },
      {
        title: '성명',
        dataIndex: 'created',
        key: 'created',
        render: (_, row) => row.user.name,
        align: 'center',
      },
      {
        title: '내용',
        dataIndex: 'description',
        key: 'description',
        align: 'center',
      },
      {
        title: '결제금액',
        dataIndex: 'amount',
        key: 'amount',
        render: (_, row) =>
          row.status !== '결제취소' ? (
            <span className={row.status === '환불완료' ? 'red' : ''}>
              {numberWithCommas(row.point)}
            </span>
          ) : (
            0
          ),
        align: 'center',
      },
      {
        title: '세액',
        dataIndex: 'tax',
        key: 'tax',
        render: (_, row) =>
          row.status !== '결제취소' ? numberWithCommas(row.tax) : 0,
        align: 'center',
      },
      {
        title: '지급포인트',
        dataIndex: 'point',
        key: 'point',
        render: (_, row) =>
          row.status !== '결제취소'
            ? numberWithCommas(row.point - (row.tax || 0))
            : 0,
        align: 'center',
      },
      {
        title: '결제방식',
        dataIndex: 'pay_method',
        key: 'pay_method',
        render: (pay_method) =>
          pay_method === 'card'
            ? '카드'
            : pay_method === 'trans'
            ? '계좌이체'
            : pay_method === 'phone'
            ? '소액결제'
            : pay_method === 'bank'
            ? '무통장'
            : '',
        align: 'center',
      },
      {
        title: '상태',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (_, row) =>
          row.status === '환불완료' ? (
            <Button type="primary" onClick={handleDetail(true, row)}>
              상세
            </Button>
          ) : (
            <Button
              type="primary"
              danger
              onClick={handleDetail(true, row)}
              disabled={
                moment(row.paymentDate).add(5, 'days') < moment(state.now) ||
                row.point - (row.tax || 0) !== row.usePoint
              }
            >
              취소
            </Button>
          ),
        align: 'center',
      },
    ],
    [state.now],
  );

  const mobileColumns = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'idx',
        key: 'idx',
        render: (_, row, idx) => {
          return (
            <Descriptions size="small" bordered column={1}>
              <Descriptions.Item label="날짜">
                {row.paymentDate
                  ? moment(row.paymentDate).format('YYYY-MM-DD HH:mm:ss')
                  : ''}
              </Descriptions.Item>
              <Descriptions.Item label="아이디">
                {row.user.username}
              </Descriptions.Item>
              <Descriptions.Item label="성명">
                {row.user.name}
              </Descriptions.Item>
              <Descriptions.Item label="내용">
                {row.description}
              </Descriptions.Item>
              <Descriptions.Item label="결제금액">
                {row.status !== '결제취소' ? (
                  <span className={row.status === '환불완료' ? 'red' : ''}>
                    {numberWithCommas(row.point)}
                  </span>
                ) : (
                  0
                )}
              </Descriptions.Item>
              <Descriptions.Item label="세액">
                {row.status !== '결제취소' ? numberWithCommas(row.tax) : 0}
              </Descriptions.Item>
              <Descriptions.Item label="지급포인트">
                {row.status !== '결제취소'
                  ? numberWithCommas(row.point - (row.tax || 0))
                  : 0}
              </Descriptions.Item>
              <Descriptions.Item label="결제방식">
                {row.pay_method === 'card'
                  ? '카드'
                  : row.pay_method === 'trans'
                  ? '계좌이체'
                  : row.pay_method === 'phone'
                  ? '소액결제'
                  : row.pay_method === 'bank'
                  ? '무통장'
                  : ''}
              </Descriptions.Item>
              <Descriptions.Item label="결제취소">
                {row.status}
              </Descriptions.Item>
              <Descriptions.Item label="">
                {row.status === '환불완료' ? (
                  <Button type="primary" onClick={handleDetail(true, row)}>
                    상세
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    danger
                    onClick={handleDetail(true, row)}
                    disabled={
                      moment(row.paymentDate).add(5, 'days') <
                        moment(state.now) ||
                      row.point - (row.tax || 0) !== row.usePoint
                    }
                  >
                    취소
                  </Button>
                )}
              </Descriptions.Item>
            </Descriptions>
          );
        },
      },
    ],
    [state.now],
  );

  return (
    <Wrapper>
      <Row gutter={[32, 16]}>
        <Col xs={24} lg={6}>
          <h4>기간별 검색</h4>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={16}>
              <Space>
                <DatePicker
                  placeholder="시작일"
                  value={state.startDate}
                  onChange={handleChangeDate('startDate')}
                />
                <DatePicker
                  placeholder="종료일"
                  value={state.endDate}
                  onChange={handleChangeDate('endDate')}
                />
              </Space>
            </Col>
            <Col xs={24} lg={6}>
              <Button type="primary" block onClick={handleSearch('date')}>
                검색
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={6}>
          <h4>결제방식</h4>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={18}>
              <Select
                value={state.payMethod}
                placeholder="결제방식 선택"
                onChange={handleChangeSearch('payMethod')}
                style={{ width: '100%' }}
              >
                <Option value={null}>전체</Option>
                <Option value={'vbank'}>가상계좌</Option>
                <Option value={'trans'}>계좌이체</Option>
                <Option value={'phone'}>소액결제</Option>
                <Option value={'bank'}>무통장</Option>
              </Select>
            </Col>
            <Col xs={24} lg={6}>
              <Button type="primary" block onClick={handleSearch('pay_method')}>
                검색
              </Button>
            </Col>
          </Row>
        </Col>
        {!user && (
          <Col xs={24} lg={4}>
            <h4>ID검색</h4>
            <Search
              placeholder="이름 | 아이디"
              onSearch={handleSearch('name')}
              onChange={handleChangeInput('searchText')}
              enterButton
            />
          </Col>
        )}
        <Col xs={24} lg={8}>
          <h4>상태검색</h4>
          <Space>
            <Select
              value={state.searchStatus}
              onChange={handleChangeSearch('searchStatus')}
              style={{ width: 120 }}
            >
              <Option value={null}>전체</Option>
              <Option value={'결제완료'}>결제완료</Option>
              <Option value={'환불완료'}>환불완료</Option>
            </Select>
            <Button type="primary" block onClick={handleSearch('status')}>
              검색
            </Button>
          </Space>
        </Col>
      </Row>

      <Table
        dataSource={toJS(state.list)}
        columns={screen.lg ? columns : mobileColumns}
        // scroll={{ x: 992 }}
        pagination={{
          current: state.paginate.page,
          total: state.paginate.totalDocs,
          showSizeChanger: false,
          onChange: handleChangePage,
        }}
        rowKey={(row) => row.id}
        summary={(pageData) => {
          let pagePrice = 0;
          pageData.map((row) =>
            row.status !== '결제취소' ? (pagePrice += row.point) : 0,
          );
          return screen.lg ? (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={4}>총 결제금액</Table.Summary.Cell>
              <Table.Summary.Cell>
                {numberWithCommas(pagePrice, true)}
              </Table.Summary.Cell>
              <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
            </Table.Summary.Row>
          ) : (
            <Table.Summary.Row>
              <Table.Summary.Cell>
                <Descriptions size="small" bordered column={1}>
                  <Descriptions.Item label="총 결제금액">
                    {numberWithCommas(pagePrice)}
                  </Descriptions.Item>
                </Descriptions>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />

      <CustomModal
        visible={state.detailOpen}
        onCancel={handleDetail(false)}
        forceRender={true}
        footer={null}
        minwidth={800}
      >
        {state.selectedData && state.selectedData.id && (
          <SaleDetail
            handleClose={handleDetail}
            propData={toJS(state.selectedData)}
            refetch={initDateFetch}
          />
        )}
      </CustomModal>
    </Wrapper>
  );
});

export default SaleList;
