/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback } from 'react';
import { Descriptions, Input, Button } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import moment from 'moment';
import axios from 'axios';
import { useApolloClient, useMutation } from '@apollo/react-hooks';

import useStore from '@stores/useStore';
import { numberWithCommas } from '@utils/common';
import { UPDATE_POINT_LOG } from '@shared/queries/PointLogQueries';
import { UPDATE_USER, USER_ONE } from '@shared/queries/UserQueries';

const RefundDetail = observer(({ propData = {}, refetch, handleClose }) => {
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    refundReason: '',
    memo: '',
  }));

  const [update] = useMutation(UPDATE_POINT_LOG);
  const [updateUser] = useMutation(UPDATE_USER);
  const client = useApolloClient();

  const handleChangeInput = useCallback(
    (type) => (e) => {
      state[type] = e.target.value;
    },
    [],
  );

  const handleSubmitRefund = useCallback(() => {
    return window.ask({
      title: '계속 진행하시겠습니까?',
      content: `해당 내역을 환불처리 하시겠습니까?`,
      async onOk() {
        window.maskOn();
        if (propData.pay_method === 'bank') {
          await update({
            variables: {
              id: propData.id,
              data: {
                status: '환불완료',
                refundReason: state.refundReason,
                memo: state.memo,
                refundDate: moment().format('YYYY-MM-DD'),
              },
            },
          });

          const userRes = await client.query({
            query: USER_ONE,
            variables: {
              findQuery: {
                id: propData.user.id,
              },
            },
          });

          if (userRes.data && userRes.data.userOne) {
            let point = userRes.data.userOne.point;

            point -= propData.point - (propData.tax || 0);

            await updateUser({
              variables: {
                _id: propData.user.id,
                data: {
                  point,
                },
              },
            });
          }

          if (refetch) {
            await refetch();
          }
          window.maskOff();
          state.refundReason = '';
          state.memo = '';

          window.alert({ title: '환불처리가 완료되었습니다.' });
          handleClose(false)();
        } else {
          const data = {
            orderId: propData.orderId,
            imp_uid: propData.imp_uid,
            // amount: propData.created.isTest ? 100 : propData.itemPrice,
            amount: propData.point,
            reason: state.refundReason, // 환불사유
            memo: state.memo,
            manager: commonStore.user.id,
          };
          if (propData.refundAccount) {
            data.isRefundAccount = true;
            data.refund_holder = propData.refundAccount.refund_holder;
            data.refund_bank = propData.refundAccount.refund_bank;
            data.refund_account = propData.refundAccount.refund_account;
          }

          const res = await axios({
            url: `${commonStore.baseIamportUrl}/payments/cancel`,
            method: 'post',
            data,
          });

          window.maskOff();

          state.refundReason = '';
          state.memo = '';

          if (res.data.status === 'success') {
            if (refetch) {
              await refetch();
            }
            window.alert({ title: '환불처리가 완료되었습니다.' });
            handleClose(false)();
          } else {
            window.alert({
              title: '환불처리에 실패하였습니다.',
              content: res.data.message,
            });
          }
        }
      },
    });
  }, [propData, refetch, handleClose]);

  return (
    <>
      <Descriptions
        title="결제상세"
        bordered
        size="small"
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      >
        <Descriptions.Item label="결제일">
          {moment(propData.paymentDate).format('YYYY-MM-DD')}
        </Descriptions.Item>
        <Descriptions.Item label="이름">{propData.user.name}</Descriptions.Item>
        <Descriptions.Item label="아이디">
          {propData.user.username}
        </Descriptions.Item>
        <Descriptions.Item label="결제금액">
          {numberWithCommas(propData.point)}
        </Descriptions.Item>
        <Descriptions.Item label="세액">
          {numberWithCommas(propData.tax)}
        </Descriptions.Item>
        <Descriptions.Item label="지급포인트">
          {numberWithCommas(propData.point - (propData.tax || 0))}
        </Descriptions.Item>
        <Descriptions.Item label="결제방식">
          {propData.pay_method === 'card'
            ? '카드'
            : propData.pay_method === 'trans'
            ? '계좌이체'
            : propData.pay_method === 'phone'
            ? '소액결제'
            : propData.pay_method === 'bank'
            ? '무통장'
            : ''}
        </Descriptions.Item>
        <Descriptions.Item label="상태">{propData.status}</Descriptions.Item>
        {propData.status === '환불완료' ? (
          <>
            <Descriptions.Item label="환불일">
              {propData.refundDate ? propData.refundDate : ''}
            </Descriptions.Item>
            <Descriptions.Item label="취소사유">
              {propData.refundReason ? <pre>{propData.refundReason}</pre> : ''}
            </Descriptions.Item>
            <Descriptions.Item label="상담내용">
              {propData.memo ? <pre>{propData.memo}</pre> : ''}
            </Descriptions.Item>
            <Descriptions.Item label="환불진행자">
              {propData.manager ? propData.manager.name : ''}
            </Descriptions.Item>
          </>
        ) : (
          <>
            <Descriptions.Item label="취소사유">
              <Input.TextArea
                rows={4}
                value={state.refundReason}
                onChange={handleChangeInput('refundReason')}
              />
            </Descriptions.Item>
            <Descriptions.Item label="상담내용">
              <Input.TextArea
                rows={4}
                value={state.memo}
                onChange={handleChangeInput('memo')}
              />
            </Descriptions.Item>
            <Descriptions.Item label="">
              <Button type="primary" onClick={handleSubmitRefund}>
                환불처리
              </Button>
            </Descriptions.Item>
          </>
        )}
      </Descriptions>
    </>
  );
});

export default RefundDetail;
