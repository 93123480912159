/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { Row, Col, InputNumber, Space, Card } from 'antd';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { numberWithCommas } from '@utils/common';

const Wrapper = styled.div`
  width: 100%;
`;

const ProductConfigOT = observer(({ productValue, setProductValue }) => {
  const handleChangeNumber = useCallback(
    (type) => (value) => {
      productValue[type] = value;
      setProductValue(productValue);
    },
    [productValue],
  );

  return (
    <Wrapper>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Card title="설정" size="small">
            <h4>최적화 테스트 포인트</h4>
            <Space>
              <InputNumber
                value={productValue.price}
                onChange={handleChangeNumber('price')}
                formatter={(value) => numberWithCommas(value)}
                parser={(value) => value.replace(/[^0-9]/g, '')}
                style={{ width: 140 }}
              />
              <span>원</span>
            </Space>
          </Card>
        </Col>
      </Row>
    </Wrapper>
  );
});

export default ProductConfigOT;
