/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Divider,
  Input,
  Space,
  InputNumber,
  Radio,
} from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { toJS } from 'mobx';

import useStore from '@stores/useStore';

import Description from '@Common/Description';

import ModifyPPCaseByInstaPost from './ModifyPPCaseByInstaPost';

const Wrapper = styled.div`
  width: 100%;
`;

const ModifyPPCaseBy = observer(
  ({ propData = {}, handleSubmit, handleClose, isAdmin }) => {
    const { commonStore } = useStore();
    const state = useLocalStore(() => ({
      casePriceData: [],
      monthPriceData: [],
      customerMonthPriceData: [],
      randomInstaPostData: null,
      isInstaPostRandom: false,

      handleUpload: {},
      setHandleUpload(uploadFunc, lang) {
        if (this.isInstaPostRandom) {
          this.handleUpload = uploadFunc;
        } else {
          const handleUpload = toJS(this.handleUpload);
          handleUpload[`${lang.itemUid}_${lang.idx}`] = uploadFunc;
          this.handleUpload = handleUpload;
        }
      },
      setParentImages(images, lang) {
        if (this.isInstaPostRandom) {
          this.randomInstaPostData.images = images;
        } else {
          const priceData = toJS(this[lang.priceType]);
          const fIdx = priceData.findIndex((item) => item.uid === lang.itemUid);
          if (fIdx !== -1) {
            priceData[fIdx].instaPostData[lang.idx].images = images;
          }
          this[lang.priceType] = priceData;
        }
      },

      // DB 데이터
      mainHashtag: '',
      comment: '1.',
      buyDirectCommentCnt: 0,
      isBuyDirectComment: false,
      instaId: '',
      instaPassword: '',

      testLikeCnt: undefined,
      testTrafficCnt: undefined,
      testArrivalCnt: undefined,
      testPublishTime: [],
      testSaveCnt: undefined,
      testCommentCnt: undefined,
      testKeepTrafficCnt: undefined,
      testKeepLikeCnt: undefined,
      testKeepSaveCnt: undefined,
      testKeepFollowCnt: undefined,
      testKeepCommentCnt: undefined,
    }));

    const handleChangeRadio = useCallback(
      (type) => (e) => {
        state[type] = e.target.value;

        if (type === 'isBuyDirectComment') {
          if (e.target.value) {
            state.buyDirectCommentCnt = 1;
            state.comment = '1.';
          } else {
            state.buyDirectCommentCnt = 0;
            state.comment = '';
          }
        }
      },
      [],
    );

    const checkPostVisible = useCallback(() => {
      const data =
        propData.paymentType === '월구매'
          ? toJS(state.monthPriceData)
          : propData.paymentType === '건구매'
          ? toJS(state.casePriceData)
          : propData.paymentType === '고객월건바이'
          ? toJS(state.customerMonthPriceData)
          : [];

      if (!data.length) {
        return false;
      }
      return true;
    }, [propData]);

    const handleChangeInput = useCallback(
      (type) => (e) => {
        state[type] = e.target.value;
      },
      [],
    );

    const handleChangeInputNumber = useCallback(
      (type) => (value) => {
        state[type] = value;
      },
      [],
    );

    const handleCheckTextAreaRow = useCallback(
      (type) => (e) => {
        if (type === 'comment') {
          const lines = state.comment.split('\n');
          let values = [];
          for (let i = 0; i < state.buyDirectCommentCnt; i++) {
            if (lines[i] && lines[i].length) {
              if (!/^[0-9]+\./.test(lines[i])) {
                values.push(`${i + 1}.${lines[i]}`);
              } else {
                let dotIdx = lines[i].indexOf('.');
                values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
              }
            }
          }
          state.comment = values.join('\n');
        }
      },
      [],
    );

    const handlePressEnter = useCallback(
      (type) => (e) => {
        e.persist();
        setTimeout(() => {
          const lines = state[type].split('\n');
          let values = [];
          for (let i = 0; i < lines.length; i++) {
            if (!/^[0-9]+\./.test(lines[i])) {
              values.push(`${i + 1}.${lines[i]}`);
            } else {
              let dotIdx = lines[i].indexOf('.');
              values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
            }
          }
          state[type] = values.join('\n');
        }, 10);
      },
      [],
    );

    const handleClickSubmit = useCallback(async () => {
      let imageCheck = true;
      let imageErrMsg = '';

      if (state.isInstaPostRandom) {
        if (
          (!state.randomInstaPostData.videos ||
            !state.randomInstaPostData.videos.length) &&
          (!state.randomInstaPostData.images ||
            state.randomInstaPostData.images.length < 10)
        ) {
          imageCheck = false;
          imageErrMsg = `게시물 이미지 최소 10장 이상 업로드해 주세요.`;
        }
      } else {
        let priceData = [];
        if (state.paymentType === '월구매') {
          priceData = toJS(state.monthPriceData);
        } else if (state.paymentType === '건구매') {
          priceData = toJS(state.casePriceData);
        } else if (state.paymentType === '고객월건바이') {
          priceData = toJS(state.customerMonthPriceData);
        }

        let postCnt = 1;
        for (const item of priceData) {
          if (item.checked) {
            for (const [idx, data] of item.instaPostData.entries()) {
              if (
                (!data.videos || !data.videos.length) &&
                (!data.images || !data.images.length)
              ) {
                imageCheck = false;
                imageErrMsg = `${item.rank1}~${item.rank2}위 옵션 ${
                  idx + 1
                }게시물의 이미지를 업로드해 주세요.`;
                break;
              }
              postCnt++;
            }
          }

          if (!imageCheck) {
            break;
          }
        }
      }

      if (!imageCheck) {
        return window.alert({ title: imageErrMsg });
      }

      setTimeout(() => {
        const data = {
          paymentType: propData.paymentType,
          comment: state.comment,
          buyDirectCommentCnt: state.buyDirectCommentCnt,
          isBuyDirectComment: state.isBuyDirectComment,
          isInstaPostRandom: state.isInstaPostRandom,
          randomInstaPostData: toJS(state.randomInstaPostData),
        };

        let priceData = [];
        let priceType = null;
        if (propData.paymentType === '건구매') {
          priceData = toJS(state.casePriceData);
          priceType = 'buyCaseData';
        } else if (propData.paymentType === '월구매') {
          priceData = toJS(state.monthPriceData);
          priceType = 'buyMonthData';
        } else if (propData.paymentType === '고객월건바이') {
          priceData = toJS(state.customerMonthPriceData);
          priceType = 'buyCustomerMonthData';
          data.instaId = state.instaId;
          data.instaPassword = state.instaPassword;
        }

        data[priceType] = priceData;

        handleSubmit(propData.id, data, null, {
          uploadPath: `work/pp_cb/${propData.company.id}`,
          fileType: 'image',
          handleUpload: toJS(state.handleUpload),
        });
      }, 10);
    }, [propData, handleSubmit]);

    useEffect(() => {
      if (propData.buyCaseData) {
        state.casePriceData = propData.buyCaseData;
      }
      if (propData.buyMonthData) {
        state.monthPriceData = propData.buyMonthData;
      }
      if (propData.buyCustomerMonthData) {
        state.customerMonthPriceData = propData.buyCustomerMonthData;
      }
      if (propData.randomInstaPostData) {
        state.randomInstaPostData = propData.randomInstaPostData;
      }
      if (propData.buyDirectCommentCnt) {
        state.buyDirectCommentCnt = propData.buyDirectCommentCnt;
      }
      if (propData.mainHashtag) {
        state.mainHashtag = propData.mainHashtag;
      }
      if (propData.comment) {
        state.comment = propData.comment;
      }
      if (propData.instaId) {
        state.instaId = propData.instaId;
      }
      if (propData.instaPassword) {
        state.instaPassword = propData.instaPassword;
      }

      state.isInstaPostRandom = Boolean(propData.isInstaPostRandom);
      state.isBuyDirectComment = Boolean(propData.isBuyDirectComment);
    }, [propData]);

    return (
      <Wrapper>
        <h3>프로그램 추가옵션</h3>
        <Row gutter={[10, 10]}>
          <Col xs={24}>
            <Radio.Group
              value={state.isBuyDirectComment}
              onChange={handleChangeRadio('isBuyDirectComment')}
            >
              <Radio value={true} disabled={true}>
                직접입력댓글 사용
              </Radio>
              <Radio value={false}>사용안함</Radio>
            </Radio.Group>
          </Col>
          {state.isBuyDirectComment && (
            <Col xs={24}>
              <h4>직접입력댓글</h4>
              <Space>
                <InputNumber
                  min={1}
                  value={state.buyDirectCommentCnt}
                  onChange={handleChangeInputNumber('buyDirectCommentCnt')}
                />
                <span>개</span>
              </Space>
            </Col>
          )}
        </Row>

        <Divider />

        <Row gutter={[10, 10]}>
          <Col xs={24}>
            <Row gutter={[10, 10]}>
              {propData.paymentType === '고객월건바이' && (
                <Col xs={24}>
                  <Space>
                    <div>
                      <h4>인스타 아이디</h4>
                      <Input
                        value={state.instaId}
                        onChange={handleChangeInput('instaId')}
                      />
                    </div>
                    <div>
                      <h4>인스타 비밀번호</h4>
                      <Input
                        value={state.instaPassword}
                        onChange={handleChangeInput('instaPassword')}
                      />
                    </div>
                  </Space>
                </Col>
              )}

              <ModifyPPCaseByInstaPost
                localStore={state}
                propData={propData}
                isAdmin={commonStore.user.isTest ? true : isAdmin}
                isContentModify={/(test1)|(test2)|(test3)|(test4)|(eluen)/.test(
                  commonStore.user.username,
                )}
              />

              {state.isBuyDirectComment && (
                <Col xs={24}>
                  <h4>인스타 댓글 입력 (추가옵션)</h4>
                  <Description text="구매 개수 만큼 일괄입력(한줄에 하나씩)" />
                  <Input.TextArea
                    rows={5}
                    value={state.comment}
                    onChange={handleChangeInput('comment')}
                    onBlur={handleCheckTextAreaRow('comment')}
                    onPressEnter={handlePressEnter('comment')}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>

        <Divider />

        <Row gutter={[10, 10]} justify="end">
          <Col>
            <Button onClick={handleClose} style={{ width: 120 }}>
              닫기
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={handleClickSubmit}
              style={{ width: 120 }}
            >
              저장
            </Button>
          </Col>
        </Row>
      </Wrapper>
    );
  },
);

export default ModifyPPCaseBy;
