import gql from 'graphql-tag';
import { autocompleteTopFragment } from '@shared/fragments';

export const AUTOCOMPLETE_TOPS = gql`
  ${autocompleteTopFragment}
  query AutocompleteTops($page: Int, $limit: Int, $findQuery: JSON) {
    autocompleteTops(page: $page, limit: $limit, findQuery: $findQuery) {
      docs {
        ...AutocompleteTopFragment
      }
      totalDocs
      limit
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      pagingCounter
    }
  }
`;

export const AUTOCOMPLETE_TOP = gql`
  ${autocompleteTopFragment}
  query AutocompleteTop($findQuery: JSON) {
    autocompleteTop(findQuery: $findQuery) {
      ...AutocompleteTopFragment
    }
  }
`;

export const AUTOCOMPLETE_TOP_LIST = gql`
  ${autocompleteTopFragment}
  query AutocompleteTopList($findQuery: JSON) {
    autocompleteTopList(findQuery: $findQuery) {
      ...AutocompleteTopFragment
    }
  }
`;

export const ADD_AUTOCOMPLETE_TOP = gql`
  ${autocompleteTopFragment}
  mutation AddAutocompleteTop($data: InputAutocompleteTop) {
    addAutocompleteTop(data: $data) {
      ...AutocompleteTopFragment
    }
  }
`;

export const UPDATE_AUTOCOMPLETE_TOP = gql`
  ${autocompleteTopFragment}
  mutation UpdateAutocompleteTop($id: ID!, $data: InputAutocompleteTop) {
    updateAutocompleteTop(id: $id, data: $data) {
      ...AutocompleteTopFragment
    }
  }
`;

export const REMOVE_AUTOCOMPLETE_TOP = gql`
  mutation RemoveAutocompleteTop($id: ID!) {
    removeAutocompleteTop(id: $id)
  }
`;
