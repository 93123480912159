/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Row, Col, Space, Divider } from 'antd';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { useMutation, useApolloClient } from '@apollo/react-hooks';

import { LOGIN } from '@shared/queries';
import useStore from '@stores/useStore';

import FindId from './FindId';
import FindPassword from './FindPassword';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .form-wrapper {
    width: 100%;
  }

  .sub-title {
    text-align: center;
    font-size: 16px;
    color: ${(props) => props.theme.greyColor};
  }
`;

const Login = observer(({ isAdmin }) => {
  const { authStore } = useStore();

  const [login] = useMutation(LOGIN);
  const client = useApolloClient();

  const onFinish = useCallback(
    async (values) => {
      if (values.remember) {
        Cookies.set('remember_id', values.username);
      }

      const res = await login({
        variables: {
          data: {
            username: values.username,
            password: values.password,
            isAdmin,
          },
        },
      });
      if (res.data && res.data.login) {
        if (res.data.login.error) {
          return window.alert({ title: res.data.login.error });
        } else {
          Cookies.set('addpick-instagram-jwt', res.data.login.token);
          window.localStorage.setItem(
            'addpick-instagram-jwt',
            res.data.login.token,
          );
          const user = await authStore.loginCheck(client);
          if (user) {
            const url = Cookies.get('redirect-url');
            if (url) {
              Cookies.remove('redirect-url');
              window.location.href = url;
            } else {
              window.location.href = '/';
            }
          }
        }
      }
    },
    [isAdmin],
  );

  return (
    <Wrapper>
      <div className="form-wrapper">
        {/* <Row justify="center" gutter={[16, 32]}>
          <Col xs={24} lg={5}>
            <SiteTitle />
            {isAdmin && <div className="sub-title">어드민 관리자 페이지</div>}
          </Col>
        </Row> */}
        <Row justify="center" align="middle" gutter={[16, 0]}>
          <Col xs={20} lg={5}>
            <Form
              name="login"
              layout="vertical"
              initialValues={{
                username: '',
                password: '',
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                label="아이디(핸드폰 번호)"
                name="username"
                rules={[
                  {
                    required: true,
                    message: isAdmin
                      ? '아이디를 입력해 주세요!'
                      : '핸드폰 번호를 입력해 주세요!',
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder={
                    isAdmin ? '아이디를 입력하세요' : '핸드폰 번호를 입력하세요'
                  }
                />
              </Form.Item>

              <Form.Item
                label="비밀번호"
                name="password"
                rules={[
                  { required: true, message: '비밀번호를 입력해 주세요!' },
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="비밀번호를 입력하세요"
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" size="large" block>
                  로그인
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row justify="center" gutter={[16, 16]}>
          <Col xs={20} lg={5}>
            <Space
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
              }}
            >
              <Link to="/">홈</Link>
              <Divider type="vertical" />
              <FindId />
              <Divider type="vertical" />
              <FindPassword />
              <Divider type="vertical" />
              <Link to="/signup">회원가입</Link>
            </Space>
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
});

export default Login;
