import gql from 'graphql-tag';

export const fileFragment = gql`
  fragment FileFragment on File {
    uid
    status
    name
    url
    filename
    size
    link
  }
`;

export const userFragment = gql`
  fragment UserFragment on User {
    id
    username
    email
    name
    userGroup
    isAdmin
    isBlock
    isRemove
    isTest

    phone
    passwordAsk
    passwordAnswer
    companyName
    companyTel
    owner
    businessNum
    status
    point
    role
    defPostType

    taxData
    cashData

    productValue

    createdAt
    updatedAt

    accessToken
    loginTime
    lastLoginTime
  }
`;

export const companyFragment = gql`
  ${userFragment}
  fragment CompanyFragment on Company {
    id
    name
    phone
    memo
    user {
      ...UserFragment
    }

    created {
      ...UserFragment
    }
    updated {
      ...UserFragment
    }
    createdAt
    updatedAt

    works
  }
`;

export const postFragment = gql`
  ${userFragment}
  ${fileFragment}
  fragment PostFragment on Post {
    id
    board
    title
    content
    views
    isNotice
    postDate
    images {
      ...FileFragment
    }
    files {
      ...FileFragment
    }
    created {
      ...UserFragment
    }
    updated {
      ...UserFragment
    }
    createdAt
    updatedAt
    author
    password

    commentCnt
  }
`;

export const commentFragment = gql`
  ${userFragment}
  fragment CommentFragment on Comment {
    id
    board
    content
    parent
    post
    created {
      ...UserFragment
    }
    updated {
      ...UserFragment
    }
    createdAt
    updatedAt

    children
  }
`;

export const workFragment = gql`
  fragment WorkFragment on Work {
    id
    workType
    productName
    paymentType
    instaId
    instaPassword
    instaContents
    instaContentText
    url
    commentType
    comment
    commentStorage
    isMonth
    buyCnt
    isBuyDirectComment
    buyDirectCommentCnt
    buyAmount
    otTime
    buyCaseData
    buyMonthData
    buyCustomerMonthData
    randomInstaPostData
    isInstaPostRandom
    programType
    buyOaIdData
    phone
    postType

    mainHashtag
    hashtags
    hashtagStorage
    maxCounting
    counting
    hashtagRank
    postRank
    followerRank
    followingRank
    progressRate
    status
    isActivated

    fileType
    zipFile
    images
    videos

    rankInterval
    postCnt
    trafficCnt
    likeCnt
    commentCnt
    saveCnt
    followCnt

    testLikeCnt
    testTrafficCnt
    testArrivalCnt
    testPublishTime
    testSaveCnt
    testCommentCnt
    testKeepTrafficCnt
    testKeepLikeCnt
    testKeepSaveCnt
    testKeepFollowCnt
    testKeepCommentCnt

    user
    company
    createdAt
    updatedAt

    rank
    ranks
    currentRank
  }
`;

export const pointLogFragment = gql`
  ${userFragment}
  fragment PointLogFragment on PointLog {
    id
    pointType
    description
    point
    tax
    usePoint
    memo
    cancelReason

    imp_uid
    merchant_uid
    next_merchant_uid
    customer_uid
    receipt_url
    pay_method

    vbank_date
    vbank_holder
    vbank_name
    vbank_num
    refundAccount
    refundReason

    orderId
    orderDate
    status
    paymentDate
    refundDate

    user {
      ...UserFragment
    }
    manager {
      ...UserFragment
    }
    company
    work
    created {
      ...UserFragment
    }
    updated {
      ...UserFragment
    }

    createdAt
    updatedAt
  }
`;

export const accountFragment = gql`
  fragment AccountFragment on Account {
    id
    accountType
    userId
    instaId
    companyName

    createdAt
    updatedAt
  }
`;

export const accountErrorFragment = gql`
  fragment AccountErrorFragment on AccountError {
    id
    userId
    instaId
    companyName
    status
    reason

    createdAt
    updatedAt
  }
`;

export const rankFragment = gql`
  fragment RankFragment on Rank {
    id
    hashtagRank
    postRank
    followerRank
    followingRank
    caseDataRank
    monthDataRank

    instaId
    url
    trafficCnt
    likeCnt
    commentCnt

    createdAt
    updatedAt

    work
  }
`;

export const workScheduleFragment = gql`
  ${userFragment}
  fragment WorkScheduleFragment on WorkSchedule {
    id
    nextPaymentDate

    user {
      ...UserFragment
    }
    work

    createdAt
    updatedAt
  }
`;

export const invoiceFragment = gql`
  ${userFragment}
  fragment InvoiceFragment on Invoice {
    id
    userId
    userName
    invoiceType
    cashType
    phone
    companyName
    businessNum
    owner
    address
    email
    amount
    tax
    status

    businessImage

    user {
      ...UserFragment
    }
    manager {
      ...UserFragment
    }

    createdAt
    updatedAt
  }
`;

export const usePPFragment = gql`
  fragment UsePPFragment on UsePP {
    id
    userId
    userName
    slots

    likeCnt
    commentCnt
    followCnt
    saveCnt
    trafficCnt
    slotCnt

    rankAllCnt
    rank10Cnt
    rank20Cnt
    rankOmissionCnt

    user

    createdAt
    updatedAt
  }
`;

export const autocompleteTopFragment = gql`
  fragment AutocompleteTopFragment on AutocompleteTop {
    id
    userId
    userName
    slots

    likeCnt
    commentCnt
    followCnt
    saveCnt
    trafficCnt
    slotCnt

    rankAllCnt
    rank10Cnt
    rank20Cnt
    rankOmissionCnt

    user

    createdAt
    updatedAt
  }
`;
