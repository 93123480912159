/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Space, Button, Divider } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import styled from 'styled-components';
import uniqid from 'uniqid';

import useStore from '@stores/useStore';
import { formatFilename } from '@utils/common';
import { META } from '@shared/queries';
import { USER_ONE } from '@shared/queries/UserQueries';
import { ADD_WORK } from '@shared/queries/WorkQueries';
import {
  DEFAULT_ZIP_UPLOAD,
  UPLOAD_PROGRESS,
  SINGLE_FILE_REMOVE,
  SINGLE_FILE_UPLOAD,
} from '@shared/queries';
import { UPDATE_USER } from '@shared/queries/UserQueries';
import { ADD_POINT_LOG } from '@shared/queries/PointLogQueries';
import { ADD_WORK_SCHEDULE } from '@shared/queries/WorkScheduleQueries';

import CustomModal from '@Common/CustomModal';
import PointCharging from '@Member/Mypage/Point/PointCharging';
import WorkList from '@Member/Work/WorkDetail/WorkList';

import BuyK from './BuyK';
import BuyPP from './BuyPP';
import BuyPPCaseBy from './BuyPPCaseBy';
import BuyOA from './BuyOA';
import BuyOT from './BuyOT';
import { toJS } from 'mobx';

const Wrapper = styled.div`
  width: 100%;

  .amount {
    font-size: 1.2rem;
    font-weight: bold;
    color: ${(props) => props.theme.blueColor};
  }
`;

const BuyWork = observer(
  ({
    company,
    user,
    metaKey,
    modalTitle,
    modalOpen,
    handleModal,
    setRefetchWorkList,
  }) => {
    const router = useHistory();
    const { commonStore } = useStore();
    const state = useLocalStore(() => ({
      data: null,
      pointChargingOpen: false,
      otListOpen: false,
    }));

    const [add] = useMutation(ADD_WORK);
    const [addWorkSchedule] = useMutation(ADD_WORK_SCHEDULE);
    const [addPointLog] = useMutation(ADD_POINT_LOG);
    const [zipFileUpload] = useMutation(DEFAULT_ZIP_UPLOAD);
    const [fileUpload] = useMutation(SINGLE_FILE_UPLOAD);
    const [fileRemove] = useMutation(SINGLE_FILE_REMOVE);
    const [updateUser] = useMutation(UPDATE_USER);
    const client = useApolloClient();

    const fetchData = useCallback(async () => {
      if (
        commonStore.user &&
        commonStore.user.productValue &&
        commonStore.user.productValue[metaKey]
      ) {
        state.data = toJS(commonStore.user.productValue[metaKey]);
      } else {
        const res = await client.query({
          query: META,
          variables: {
            key: metaKey,
          },
        });
        if (res.data && res.data.meta) {
          state.data = res.data.meta.value;
        }
      }
    }, [metaKey]);

    const handleClose = useCallback(() => {
      state.data = null;
      if (handleModal) {
        handleModal(false)();
      }
    }, [handleModal]);

    const handleClosePointCharging = useCallback(() => {
      state.pointChargingOpen = false;
    }, []);

    const saveZipFile = useCallback((resizeFile, path) => {
      return new Promise(async (resolve) => {
        const progressUid = uniqid();
        const data = {
          file: resizeFile,
          uploadPath: path,
          progressUid,
        };

        state.zipUploadProgress = 0;
        const timer = setInterval(() => {
          state.zipUploadProgress = Math.floor(window.uploadProgress);
          if (window.uploadProgress >= 100) {
            clearInterval(timer);
          }
          window.maskOn();
        }, 10);

        client
          .subscribe({
            query: UPLOAD_PROGRESS,
            variables: { uploadPath: path, progressUid },
          })
          .subscribe({
            next({ data }) {
              state.progress = Math.floor(data.uploadProgress);
              if (data.uploadProgress < 100) {
                window.maskOn();
              }
            },
          });

        const res = await zipFileUpload({
          variables: { data },
        });
        if (res.data && res.data.defaultZipUpload) {
          if (res.data.defaultZipUpload.errMsg) {
            return resolve({
              ok: false,
              errMsg: res.data.defaultZipUpload.errMsg,
            });
          }

          const filename = resizeFile.name || resizeFile.filename;
          return resolve({
            images: res.data.defaultZipUpload.images,
            filename,
            path,
            ok: true,
          });
        }
        resolve({
          ok: false,
          errMsg: '업로드 데이터 처리 중 오류가 발생하였습니다.',
        });
      });
    }, []);

    const saveFile = useCallback((resizeFile, uploadUrl) => {
      return new Promise(async (resolve) => {
        const filename = formatFilename(resizeFile.type, uploadUrl);
        const data = {
          filename,
          file: resizeFile,
        };

        const res = await fileUpload({
          variables: { data },
        });
        let uid, name, url, err;
        if (res.errors && res.errors.length) {
          err = res.errors[0].message;
        } else {
          uid = res.data.singleFileUpload.uid;
          url = res.data.singleFileUpload.url;
          name = filename;
        }

        resolve({
          data: {
            uid,
            name,
            url,
            status: 'done',
          },
          err,
        });
      });
    }, []);

    const handleSubmit = useCallback(
      async (data, upload, ppCaseByUpload) => {
        if (!user && !commonStore.user) {
          return router.push('/login');
        }

        window.maskOn(300000);
        if (metaKey !== 'product_ot' && !company) {
          window.maskOff();
          return window.alert({ title: '연동할 업체를 찾을 수 없습니다.' });
        }

        if (user) {
          data.user = user.id;
        } else {
          data.user = commonStore.user.id;
        }

        const userRes = await client.query({
          query: USER_ONE,
          variables: {
            findQuery: {
              _id: data.user,
            },
          },
        });

        let userPoint = 0;
        if (userRes.data && userRes.data.userOne) {
          userPoint = userRes.data.userOne.point || 0;
        }

        if (data.buyAmount > userPoint) {
          window.maskOff();
          return window.alert({ title: '포인트 잔액이 부족합니다.' });
        }

        userPoint -= data.buyAmount;
        if (metaKey !== 'product_ot') {
          data.company = company.id;
        }

        let isErr = false;
        try {
          if (upload) {
            data.fileType = upload.fileType;
            if (upload.fileType === 'image') {
              const images = await upload.handleUpload();
              data.images = images;
            } else if (upload.zipFile && upload.zipFile.status === 'load') {
              const res = await saveZipFile(upload.zipFile, upload.uploadPath);
              if (res.ok) {
                data.images = res.images;
                data.zipFile = {
                  filename: res.filename,
                  path: res.path,
                };
              }
            }

            if (upload.videos && upload.videos.length) {
              data.videos = [];
              for (const v of upload.videos) {
                if (v.status === 'load') {
                  const res = await saveFile(v, upload.uploadPath);
                  if (!res.err) {
                    data.videos.push(res.data);
                  }
                }
              }
            }
          }

          if (ppCaseByUpload) {
            if (ppCaseByUpload.handleUpload) {
              data.fileType = 'image';
              if (data.isInstaPostRandom) {
                const images = await ppCaseByUpload.handleUpload();
                data.randomInstaPostData.images = images;
              } else {
                let dataType = 'buyCaseData';
                if (data.paymentType === '월구매') {
                  dataType = 'buyMonthData';
                } else if (data.paymentType === '고객월건바이') {
                  dataType = 'buyCustomerMonthData';
                }

                for (const [idx, item] of data[dataType].entries()) {
                  for (const [
                    postIdx,
                    postData,
                  ] of item.instaPostData.entries()) {
                    const images = await ppCaseByUpload.handleUpload[
                      `${item.uid}_${postIdx}`
                    ]();
                    data[dataType][idx].instaPostData[postIdx].images = images;
                  }
                }
              }
            }

            //video upload
            if (data.isInstaPostRandom) {
              if (
                data.randomInstaPostData &&
                data.randomInstaPostData.videos &&
                data.randomInstaPostData.videos.length
              ) {
                const videos = [];
                for (const v of data.randomInstaPostData.videos) {
                  if (v.status === 'load') {
                    const res = await saveFile(v, ppCaseByUpload.uploadPath);
                    if (!res.err) {
                      videos.push(res.data);
                    }
                  }
                }
                data.randomInstaPostData.videos = videos;
              }
            } else {
              let dataType = 'buyCaseData';
              if (data.paymentType === '월구매') {
                dataType = 'buyMonthData';
              } else if (data.paymentType === '고객월건바이') {
                dataType = 'buyCustomerMonthData';
              }

              for (const [idx, item] of data[dataType].entries()) {
                for (const [
                  postIdx,
                  postData,
                ] of item.instaPostData.entries()) {
                  if (postData.videos && postData.videos.length) {
                    const videos = [];
                    for (const v of postData.videos) {
                      if (v.status === 'load') {
                        const res = await saveFile(
                          v,
                          ppCaseByUpload.uploadPath,
                        );
                        if (!res.err) {
                          videos.push(res.data);
                        }
                      }
                    }
                    data[dataType][idx].instaPostData[postIdx].videos = videos;
                  }
                }
              }
            }
          }
        } catch (error) {
          isErr = true;
        }

        if (isErr) {
          window.alert({ title: '등록 중 오류가 발생하였습니다.' });
          return;
        }

        if (!data.hashtags || !data.hashtags[0] || !data.hashtags[0].length) {
          data.hashtags = [];
        }
        if (!data.instaContents || !data.instaContents[0]) {
          data.instaContents = [];
        }

        const resAdd = await add({
          variables: {
            data,
          },
        });

        const resUpdateUser = await updateUser({
          variables: {
            id: data.user,
            data: {
              point: userPoint,
            },
          },
        });

        if (
          resAdd.data &&
          resAdd.data.addWork &&
          resUpdateUser.data &&
          resUpdateUser.data.updateUser
        ) {
          if (data.paymentType === '월구매') {
            await addWorkSchedule({
              variables: {
                data: {
                  user: data.user,
                  work: resAdd.data.addWork.id,
                },
              },
            });
          }

          const pointLogData = {
            pointType: '포인트사용',
            user: data.user,
            created: data.user,
            point: data.buyAmount,
            status: '포인트사용',
            work: resAdd.data.addWork.id,
          };
          pointLogData.description = `${data.workType}`;
          if (metaKey !== 'product_ot') {
            pointLogData.description += `(${company.name})`;
            pointLogData.company = company.id;
          }
          await addPointLog({
            variables: {
              data: pointLogData,
            },
          });
        }

        window.maskOff();

        if (setRefetchWorkList) {
          setRefetchWorkList(true);
        }

        if (handleModal) {
          handleClose();
          window.success({ title: '구매가 완료되었습니다.' });
        } else {
          state.data = null;
          router.push('/');
        }
      },
      [company, user, metaKey, setRefetchWorkList, handleModal],
    );

    useEffect(() => {
      if (modalOpen || metaKey === 'product_ot') {
        fetchData();
      }
    }, [metaKey, modalOpen]);

    return (
      <>
        {metaKey === 'product_ot' ? (
          <Wrapper>
            <Row gutter={[10, 10]} justify="space-between" align="bottom">
              <Col xs={24} style={{ fontSize: '1.2rem' }}>
                최적화 테스트
              </Col>
              {user || commonStore.user ? (
                <>
                  <Col>
                    잔액 : {user ? user.point : commonStore.user.point} 포인트
                  </Col>
                  <Col>
                    <Space>
                      <Button onClick={() => (state.pointChargingOpen = true)}>
                        충전하기
                      </Button>
                      <Button onClick={() => (state.otListOpen = true)}>
                        최적화내역
                      </Button>
                    </Space>
                  </Col>
                </>
              ) : null}
            </Row>

            <Divider />

            {metaKey === 'product_ot' && state.data && (
              <BuyOT
                data={toJS(state.data)}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
              />
            )}
          </Wrapper>
        ) : (
          <CustomModal
            title={
              <Row gutter={[10, 10]} justify="space-between" align="bottom">
                <Col xs={24} style={{ fontSize: '1.2rem' }}>
                  {modalTitle}
                </Col>
                {user || commonStore.user ? (
                  <>
                    <Col>
                      잔액 : {user ? user.point : commonStore.user.point} 포인트
                    </Col>
                    <Col>
                      <Space>
                        <Button
                          onClick={() => (state.pointChargingOpen = true)}
                        >
                          충전하기
                        </Button>
                        {metaKey === 'product_ot' && (
                          <Button onClick={() => (state.otListOpen = true)}>
                            최적화내역
                          </Button>
                        )}
                      </Space>
                    </Col>
                  </>
                ) : null}
              </Row>
            }
            visible={modalOpen}
            onCancel={handleClose}
            forceRender={true}
            minwidth={800}
            footer={null}
          >
            <Wrapper>
              {metaKey === 'product_k' && state.data && (
                <BuyK
                  data={toJS(state.data)}
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                />
              )}
              {metaKey === 'product_pp' && state.data && (
                <BuyPP
                  data={toJS(state.data)}
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  company={company}
                />
              )}
              {metaKey === 'product_pp_caseBy' && state.data && (
                <BuyPPCaseBy
                  data={toJS(state.data)}
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  company={company}
                />
              )}
              {metaKey === 'product_oa' && state.data && (
                <BuyOA
                  data={toJS(state.data)}
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  company={company}
                  workType="최적화계정육성"
                />
              )}
              {/* {metaKey === 'product_influencer' && state.data && (
            <BuyOA
              data={toJS(state.data)}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              company={company}
              workType="인플루언서"
            />
          )} */}
              {metaKey === 'product_ot' && state.data && (
                <BuyOT
                  data={toJS(state.data)}
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  company={company}
                />
              )}
            </Wrapper>
          </CustomModal>
        )}

        <CustomModal
          title=""
          visible={state.pointChargingOpen}
          onCancel={handleClosePointCharging}
          forceRender={true}
          minwidth={800}
          footer={null}
        >
          <PointCharging handleClose={handleClosePointCharging} />
        </CustomModal>

        {commonStore.user && (
          <CustomModal
            title="최적화테스트 내역"
            visible={state.otListOpen}
            onCancel={(e) => (state.otListOpen = false)}
            forceRender={true}
            minwidth={1200}
            footer={null}
          >
            <WorkList workType="최적화테스트" />
          </CustomModal>
        )}
      </>
    );
  },
);

export default BuyWork;
