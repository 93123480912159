/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Row, Col, Form, Input, Button, Pagination, Divider } from 'antd';
import styled from 'styled-components';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { toJS } from 'mobx';

import useStore from '@stores/useStore';
import { COMMENTS, ADD_COMMENT } from '@shared/queries/CommentQueries';
import { validateMessages } from '@utils/customFormValidator';

import CommentList from './CommentList';

const { TextArea } = Input;

const Wrapper = styled.div`
  width: 100%;
`;

const Comment = observer(({ board, postId, isAdmin }) => {
  const [form] = Form.useForm();
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    list: [],
    paginate: {
      docs: [],
      limit: 100,
      page: 1,
      totalPages: 1,
      totalDocs: 0,
    },
    get listVal() {
      return toJS(this.list);
    },
  }));

  const [add] = useMutation(ADD_COMMENT);
  const client = useApolloClient();

  const fetchData = useCallback(async () => {
    window.maskOn();
    const findQuery = { post: postId };

    const result = await client.query({
      query: COMMENTS,
      variables: {
        page: state.paginate.page,
        limit: state.paginate.limit,
        findQuery,
      },
    });

    if (result.data && result.data.comments) {
      state.paginate = result.data.comments;
      state.list = result.data.comments.docs;
    }
    window.maskOff();
  }, [postId, state.paginate]);

  const handleChangePage = useCallback((page) => {
    if (state.paginate.page !== page) {
      state.paginate.page = page;
      process.nextTick(() => {
        fetchData();
      });
    }
  }, []);

  const handleSubmit = useCallback(
    async (values) => {
      const data = {
        ...values,
        board,
        post: postId,
        created: commonStore.user.id,
      };
      await add({ variables: { data } });
      window.success({ title: '댓글 등록이 완료되었습니다.' });
      form.resetFields();
      fetchData();
    },
    [form, board],
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Wrapper>
      {isAdmin && (
        <Form
          form={form}
          name="comment_form"
          preserve={false}
          initialValues={{
            content: '',
          }}
          validateMessages={validateMessages}
          onFinish={handleSubmit}
          scrollToFirstError
          autoComplete="off"
        >
          <Row>
            <Col xs={24} lg={20}>
              <Form.Item name="content" rules={[{ required: true }]} noStyle>
                <TextArea
                  placeholder="댓글을 입력해 주세요"
                  rows={3}
                  autoSize={{ minRows: 3, maxRows: 3 }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={4}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!Boolean(commonStore.user)}
                block
                style={{ height: '100%' }}
              >
                등록
              </Button>
            </Col>
          </Row>
        </Form>
      )}

      {state.listVal.length ? (
        <>
          <CommentList list={state.listVal} fetchData={fetchData} />
          <Divider />
        </>
      ) : null}

      {/* <Divider /> */}
      {/* <Row justify="center">
        <Col>
          <Pagination
            current={state.paginate.page}
            total={state.paginate.totalDocs}
            onChange={handleChangePage}
            showSizeChanger={false}
          />
        </Col>
      </Row> */}
    </Wrapper>
  );
});

export default Comment;
