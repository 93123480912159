/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import {
  Row,
  Col,
  Tabs,
  Space,
  DatePicker,
  Button,
  Select,
  Input,
  Divider,
} from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { toJS } from 'mobx';

import PageTitle from '@Common/PageTitle';

import SlotList from './SlotList';

const { TabPane } = Tabs;
const { Option } = Select;
const { Search } = Input;

const Wrapper = styled.div`
  width: 100%;
`;

const Slot = observer(({ rootTab }) => {
  const state = useLocalStore(() => ({
    tab: '1',

    searchType: null,
    startDate: null,
    endDate: null,
    findQuery: undefined,

    isRefetchWorkList: false,
    setRefetchWorkList(value) {
      this.isRefetchWorkList = value;
    },
  }));

  const handleChangeTab = useCallback((key) => {
    state.tab = key;
  }, []);

  const handleChangeSearch = useCallback(
    (type) => (value) => {
      state[type] = value;
    },
    [],
  );
  const handleChangeDate = useCallback(
    (type) => (momentVal) => {
      state[type] = momentVal;
    },
    [],
  );

  const handleSearch = useCallback((value) => {
    const findQuery = {};

    if (value && value.length) {
      if (state.searchType) {
        findQuery[state.searchType] = { $regex: value, $options: 'ig' };
      } else {
        findQuery.all = {
          instaId: { $regex: value, $options: 'ig' },
        };
      }
    }

    findQuery['$and'] = [];
    if (state.startDate) {
      findQuery['$and'].push({
        updatedAt: {
          $gte: state.startDate.startOf('date').toDate(),
        },
      });
    }
    if (state.endDate) {
      findQuery['$and'].push({
        updatedAt: {
          $lte: state.endDate.endOf('date').toDate(),
        },
      });
    }
    if (!findQuery['$and'].length) {
      delete findQuery['$and'];
    }

    state.findQuery = findQuery;
  }, []);

  useEffect(() => {
    if (rootTab === '1') {
      handleSearch();
    }
  }, [rootTab]);

  return (
    <Wrapper>
      <Row>
        <Col xs={24}>
          <PageTitle>도배형 슬롯</PageTitle>
        </Col>
      </Row>

      <Row gutter={[32, 16]} align="bottom">
        <Col xs={24} lg={6}>
          <h4>기간별 검색</h4>
          <Row gutter={[16, 0]}>
            <Col xs={24} lg={16}>
              <Space>
                <DatePicker
                  placeholder="시작일"
                  value={state.startDate}
                  onChange={handleChangeDate('startDate')}
                />
                <DatePicker
                  placeholder="종료일"
                  value={state.endDate}
                  onChange={handleChangeDate('endDate')}
                />
              </Space>
            </Col>
            <Col xs={24} lg={6}>
              <Button type="primary" block onClick={handleSearch}>
                검색
              </Button>
            </Col>
          </Row>
        </Col>

        <Col xs={24} lg={18}>
          <h4>전체검색</h4>
          <Space>
            <Select
              value={state.searchType}
              onChange={handleChangeSearch('searchType')}
              style={{ width: 120 }}
            >
              <Option value={null}>전체</Option>
              <Option value="instaId">인스타아이디</Option>
            </Select>
            <Search onSearch={handleSearch} enterButton />
          </Space>
        </Col>
      </Row>

      <Divider />

      <Tabs activeKey={state.tab} onChange={handleChangeTab}>
        <TabPane tab="인기게시물 슬롯" key="1">
          <SlotList
            workType="도배형인기게시물"
            findQuery={toJS(state.findQuery)}
            isRefetchWorkList={state.isRefetchWorkList}
            setRefetchWorkList={state.setRefetchWorkList}
          />
        </TabPane>
      </Tabs>
    </Wrapper>
  );
});

export default Slot;
