/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback, useMemo } from 'react';
import {
  Descriptions,
  Grid,
  Space,
  Tag,
  Row,
  Col,
  Card,
  Button,
  Image,
} from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/react-hooks';
import moment from 'moment';
import { toJS } from 'mobx';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

import useStore from '@stores/useStore';
import { excelResizeWidth } from '@utils/common';

import CustomModal from '@Common/CustomModal';

const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  width: 100%;

  .ant-descriptions-item-label {
    width: 140px;
  }

  .list-img {
    width: 50px;
    height: 50px;
  }

  .insta-post {
    margin-right: 15px;
    margin-bottom: 20px;
  }
`;

const RankComp = ({ ranks, type }) => {
  return ranks && ranks.length ? (
    <Space size={20} style={{ display: 'flex', flexWrap: 'wrap' }}>
      {' '}
      {ranks.map((row, idx) => {
        return (
          <Tag key={`rank_detail_${idx}`}>
            <Space>
              {row[type] ? (
                row[type].hasOwnProperty('interval') ? (
                  row[type].interval === 0 ? (
                    <div>
                      {row[type].rank}
                      <span>(-)</span>
                    </div>
                  ) : row[type].interval < 0 ? (
                    <div>
                      {row[type].rank}
                      <span className="red">
                        (▲{Math.abs(row[type].interval)})
                      </span>
                    </div>
                  ) : (
                    <div>
                      {row[type].rank}
                      <span className="blue">
                        (▼{Math.abs(row[type].interval)})
                      </span>
                    </div>
                  )
                ) : row[type].rank ? (
                  <span>{row[type].rank}(-)</span>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
            </Space>
          </Tag>
        );
      })}
    </Space>
  ) : null;
};

const Detail = observer(
  ({
    propData = {},
    modalOpen,
    handleModal,
    company,
    getMonthDataRank,
    isAdmin,
  }) => {
    const screen = useBreakpoint();
    const { commonStore } = useStore();

    const state = useLocalStore(() => ({
      images: [],
      imageModalOpen: false,
      dataType: 'buyCaseData',
    }));

    const client = useApolloClient();

    const handleClose = useCallback(() => {
      handleModal(false)();
    }, []);

    const handleImageModal = useCallback(
      (modalOpen, data) => (e) => {
        if (modalOpen && data) {
          state.images = data.images;
        } else {
          state.images = [];
        }
        state.imageModalOpen = modalOpen;
      },
      [],
    );

    const handleExcelDown = useCallback(async () => {
      window.maskOn();

      const workbook = new Excel.Workbook();

      let worksheet = workbook.addWorksheet('작업내역');

      // worksheet.addRow([
      //   '아이디',
      //   '이름',
      //   '서버구분',
      //   '중분류',
      //   '소분류',
      //   '업체명 키워드',
      //   '노출키워드',
      //   // '사업자등록번호',
      //   '업체주소',
      //   '기간',
      //   '횟수',
      // ]);
      // worksheet.addRow([
      //   server.user.username,
      //   server.user.name,
      //   server.product.label,
      //   server.middleCategory ? server.middleCategory.name : '',
      //   server.lastCategory ? server.lastCategory.name : '',
      //   server.memberKeyword,
      //   server.topKeyword,
      //   server.address,
      //   server.counter,
      //   server.rollingCnt,
      // ]);
      // worksheet.addRow([]);

      if (propData.workType === '인기게시물건바이') {
        let header = [
          '날짜',
          '아이디',
          'URL',
          '댓글',
          '해시태그',
          '부해시태그',
        ];

        if (propData.isInstaPostRandom) {
          if (Array.isArray(propData.randomInstaPostData.instaContent)) {
            for (
              let i = 0;
              i < propData.randomInstaPostData.instaContent.length;
              i++
            ) {
              header.push(`내용${i + 1}`);
            }
          } else {
            header.push('내용');
          }

          if (propData.paymentType === '월구매') {
            header = header.concat(['카운팅']);
          }
          if (isAdmin) {
            header = header.concat(['좋아요', '댓글', '트래픽횟수']);
          }

          worksheet.addRow(header);

          let value = [moment(propData.createdAt).format('YYYY-MM-DD')];

          value = value.concat([
            propData.instaId || '',
            propData.randomInstaPostData.url || '',
            propData.comment || '',
            propData.randomInstaPostData.mainHashtag || '',
            propData.randomInstaPostData.hashtags
              ? propData.randomInstaPostData.hashtags
              : '',
          ]);

          if (Array.isArray(propData.randomInstaPostData.instaContent)) {
            for (
              let i = 0;
              i < propData.randomInstaPostData.instaContent.length;
              i++
            ) {
              value.push(propData.randomInstaPostData.instaContent[i] || '');
            }
          } else {
            value.push(propData.randomInstaPostData.instaContent || '');
          }

          if (propData.paymentType === '월구매') {
            value.push(`${propData.counting}/${propData.maxCounting}일`);
          }

          if (isAdmin) {
            value = value.concat([
              propData.randomInstaPostData.likeCnt || 0,
              propData.randomInstaPostData.commentCnt || 0,
              propData.randomInstaPostData.trafficCnt || 0,
            ]);
          }

          worksheet.addRow(value);
        } else {
          let rankType = 'caseDataRank';
          if (propData.paymentType === '월구매') {
            header = header.concat(['카운팅', '순위']);
            rankType = 'monthDataRank';
          } else {
            header.push('순위');
            rankType = 'caseDataRank';
          }
          if (isAdmin) {
            header = header.concat(['좋아요', '댓글', '트래픽횟수']);
          }

          worksheet.addRow(header);

          for (const item of propData[state.dataType]) {
            if (item.instaPostData) {
              for (const pData of item.instaPostData) {
                let value = [moment(propData.createdAt).format('YYYY-MM-DD')];

                value = value.concat([
                  propData.instaId || '',
                  pData.url || '',
                  pData.instaContent || '',
                  propData.comment || '',
                  pData.mainHashtag || '',
                  pData.hashtags ? pData.hashtags : '',
                ]);

                if (propData.paymentType === '월구매') {
                  value.push(`${propData.counting}/${propData.maxCounting}일`);
                }

                // if (propData.rank && propData.rank[rankType]) {
                //   const f = propData.rank[rankType].find(
                //     (r) => r.uid === pData.uid,
                //   );
                //   if (f && f.rank) {
                //     value.push(f.rank);
                //   }
                // }
                value.push(pData.currentRank);

                if (isAdmin) {
                  value = value.concat([
                    pData.likeCnt || 0,
                    pData.commentCnt || 0,
                    pData.trafficCnt || 0,
                  ]);
                }

                worksheet.addRow(value);
              }
            }
          }
        }
      }

      worksheet = await excelResizeWidth(worksheet);
      const xls64 = await workbook.xlsx.writeBuffer({ base64: true });
      let title = '';
      if (propData.company) {
        title += propData.company.name;
      }

      title += `-${propData.workType} 작업내역`;

      saveAs(
        new Blob([xls64], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `[${moment(propData.createdAt).format('YYYY-MM-DD')}]_${title}`,
      );

      window.maskOff();
    }, [propData, isAdmin]);

    useEffect(() => {
      if (propData.id) {
        if (propData.paymentType === '월구매') {
          state.dataType = 'buyMonthData';
        } else if (propData.paymentType === '건구매') {
          state.dataType = 'buyCaseData';
        } else if (propData.paymentType === '고객월건바이') {
          state.dataType = 'buyCustomerMonthData';
        }
      }
    }, [propData]);

    return (
      <CustomModal
        title={`${company ? company.name + ' ' : ''}${propData.workType} ${
          propData.paymentType
        } 작업내역`}
        visible={modalOpen}
        onCancel={handleClose}
        footer={null}
        minwidth={800}
        forceRender={true}
      >
        <Wrapper>
          <Row justify="end" style={{ marginBottom: 15 }}>
            <Col>
              <Button type="primary" block onClick={() => handleExcelDown()}>
                엑셀다운
              </Button>
            </Col>
          </Row>

          <Descriptions
            title=""
            bordered
            size="small"
            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          >
            <Descriptions.Item label="날짜">
              {moment(propData.createdAt).format('YYYY-MM-DD')}
            </Descriptions.Item>

            {propData.paymentType === '월구매' ||
              (propData.paymentType === '고객월건바이' && (
                <Descriptions.Item label="인스타아이디">
                  {propData.instaId}
                </Descriptions.Item>
              ))}

            {propData.isInstaPostRandom ? (
              <Descriptions.Item label="게시물">
                <Card style={{ marginBottom: 10 }}>
                  <Space
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'flex-start',
                    }}
                  >
                    <div className="insta-post">
                      <h4>이미지</h4>
                      <img
                        className="list-img click"
                        src={
                          propData.randomInstaPostData.images &&
                          propData.randomInstaPostData.images[0]
                            ? propData.randomInstaPostData.images[0].url
                            : '/images/no-image.png'
                        }
                        onClick={handleImageModal(
                          true,
                          propData.randomInstaPostData,
                        )}
                      />
                    </div>
                    <div className="insta-post">
                      <h4>URL</h4>
                      {propData.randomInstaPostData.url
                        ? propData.randomInstaPostData.url
                        : ''}
                    </div>

                    {Array.isArray(
                      propData.randomInstaPostData.instaContent,
                    ) ? (
                      propData.randomInstaPostData.instaContent.map(
                        (item, idx) => (
                          <div
                            className="insta-post"
                            key={`${propData.id}_randomInstaPostData_instaContent_${idx}`}
                          >
                            <h4>내용 {idx + 1}</h4>
                            <pre>{item}</pre>
                          </div>
                        ),
                      )
                    ) : (
                      <div className="insta-post">
                        <h4>내용</h4>
                        <pre>
                          {propData.randomInstaPostData.instaContent
                            ? propData.randomInstaPostData.instaContent
                            : ''}
                        </pre>
                      </div>
                    )}

                    <div className="insta-post">
                      <h4>해시태그</h4>
                      {propData.randomInstaPostData.mainHashtag
                        ? propData.randomInstaPostData.mainHashtag
                        : ''}
                    </div>
                    <div className="insta-post">
                      <h4>부해시태그</h4>
                      <pre>
                        {propData.randomInstaPostData.hashtags
                          ? propData.randomInstaPostData.hashtags
                          : ''}
                      </pre>
                    </div>
                  </Space>
                </Card>
              </Descriptions.Item>
            ) : (
              <Descriptions.Item label="게시물">
                {propData[state.dataType].map((item) => (
                  <Row
                    gutter={[10, 10]}
                    key={`${propData.id}_mobile_${item.uid}`}
                  >
                    {item.instaPostData
                      ? item.instaPostData.map((pData, idx) => (
                          <Col
                            xs={24}
                            key={`${propData.id}_mobile_${item.uid}_${idx}`}
                          >
                            <Card style={{ marginBottom: 10 }}>
                              <Space
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  alignItems: 'flex-start',
                                }}
                              >
                                <div className="insta-post">
                                  <h4>이미지</h4>
                                  <img
                                    className="list-img click"
                                    src={
                                      pData.images && pData.images[0]
                                        ? pData.images[0].url
                                        : '/images/no-image.png'
                                    }
                                    onClick={handleImageModal(true, pData)}
                                  />
                                </div>
                                <div className="insta-post">
                                  <h4>URL</h4>
                                  {pData.url ? pData.url : ''}
                                </div>
                                <div className="insta-post">
                                  <h4>내용</h4>
                                  {pData.instaContent ? pData.instaContent : ''}
                                </div>
                                <div className="insta-post">
                                  <h4>해시태그</h4>
                                  {pData.mainHashtag ? pData.mainHashtag : ''}
                                </div>
                                <div className="insta-post">
                                  <h4>부해시태그</h4>
                                  <pre>
                                    {pData.hashtags ? pData.hashtags : ''}
                                  </pre>
                                </div>
                                <div className="insta-post">
                                  <h4>순위</h4>
                                  {getMonthDataRank(propData, pData)}
                                </div>
                                {isAdmin && (
                                  <div className="insta-post">
                                    <h4>좋아요</h4>
                                    {pData.likeCnt ? pData.likeCnt : ''}
                                  </div>
                                )}
                                {isAdmin && (
                                  <div className="insta-post">
                                    <h4>댓글</h4>
                                    {pData.commentCnt ? pData.commentCnt : ''}
                                  </div>
                                )}
                                {isAdmin && (
                                  <div className="insta-post">
                                    <h4>트래픽횟수</h4>
                                    {pData.trafficCnt ? pData.trafficCnt : ''}
                                  </div>
                                )}
                              </Space>
                            </Card>
                          </Col>
                        ))
                      : null}
                  </Row>
                ))}
              </Descriptions.Item>
            )}

            {propData.paymentType === '월구매' && (
              <Descriptions.Item label="카운팅">
                <span>
                  {propData.counting}/{propData.maxCounting}일
                </span>
              </Descriptions.Item>
            )}

            {propData.isBuyDirectComment && (
              <Descriptions.Item label="댓글">
                <pre>{propData.comment}</pre>
              </Descriptions.Item>
            )}

            {/* <Descriptions.Item label="순위">
              {propData.ranks && propData.ranks.length ? (
                <RankComp ranks={propData.ranks} type="caseDataRank" />
              ) : null}
            </Descriptions.Item> */}
          </Descriptions>

          <CustomModal
            visible={state.imageModalOpen}
            onCancel={handleImageModal(false)}
            footer={null}
            minwidth={800}
            forceRender={true}
          >
            <Row
              gutter={[20, 20]}
              style={{ display: 'flex', flexWrap: 'wrap' }}
            >
              {toJS(state.images).map((item) => (
                <Col key={item.name}>
                  <Image
                    width={100}
                    height={100}
                    src={item.url ? item.url : '/images/no-image.png'}
                  />
                </Col>
              ))}
            </Row>
          </CustomModal>
        </Wrapper>
      </CustomModal>
    );
  },
);

export default Detail;
