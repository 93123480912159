/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Divider,
  Input,
  InputNumber,
  Space,
  Radio,
  Card,
} from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { toJS } from 'mobx';

import useStore from '@stores/useStore';

import Description from '@Common/Description';

const Wrapper = styled.div`
  width: 100%;

  margin-top: 20px;
`;

const SlotModify = observer(
  ({ workType, propData = {}, handleClose, handleSubmit }) => {
    const { commonStore } = useStore();
    const state = useLocalStore(() => ({
      // DB 데이터
      instaId: '',
      instaContents: [],
      hashtags: '',
      rankInterval: [],
      postCnt: null,
      trafficCnt: null,
      likeCnt: null,
      commentCnt: null,
      url: '',
      saveCnt: null,
      followCnt: null,
      maxCounting: null,
    }));

    const handleChangeInput = useCallback(
      (type, idx) => (e) => {
        if (type === 'instaContents') {
          const instaContents = toJS(state.instaContents);
          if (!instaContents[idx] || typeof instaContents[idx] !== 'object') {
            instaContents[idx] = { isUse: true, content: e.target.value };
          } else {
            instaContents[idx].content = e.target.value;
          }
          state.instaContents = instaContents;
        } else {
          state[type] = e.target.value;
        }
      },
      [],
    );

    const handleChangeInputNumber = useCallback(
      (type, idx) => (value) => {
        if (idx !== undefined && !isNaN(idx)) {
          state[type][idx] = value;
        } else {
          state[type] = value;
        }
      },
      [],
    );

    const handleChangeRadio = useCallback(
      (type) => (e) => {
        if (type === 'fileType') {
          state.images = [];
          state.zipFile = null;
        }

        state[type] = e.target.value;
      },
      [],
    );

    const handleAddInstaContents = useCallback((e) => {
      const instaContents = toJS(state.instaContents);
      instaContents.push({ isUse: true, content: '' });
      state.instaContents = instaContents;
    }, []);

    const handleRemoveInstaContents = useCallback(
      (idx) => (e) => {
        const instaContents = toJS(state.instaContents);
        instaContents.splice(idx, 1);
        state.instaContents = instaContents;
      },
      [],
    );
    const handleUseInstaContents = useCallback(
      (idx) => (e) => {
        const instaContents = toJS(state.instaContents);
        instaContents[idx].isUse = e.target.value;
        state.instaContents = instaContents;
      },
      [],
    );

    const handleCheckTextAreaRow = useCallback(
      (type) => (e) => {
        if (type === 'hashtags') {
          const lines = state.hashtags.split('\n');
          let values = [];
          for (let i = 0; i < 20; i++) {
            if (lines[i]) {
              values.push(lines[i]);
            }
          }
          state.hashtags = values.join('\n');
        }
      },
      [propData],
    );

    const handleClickSubmit = useCallback(async () => {
      setTimeout(() => {
        const data = {
          workType,
          instaId: state.instaId,
          instaContents: toJS(state.instaContents),
          hashtags: toJS(state.hashtags).split('\n'),
          rankInterval: [],
          postCnt: state.postCnt,
          trafficCnt: state.trafficCnt,
          likeCnt: state.likeCnt,
          commentCnt: state.commentCnt,
          url: state.url,
          saveCnt: state.saveCnt,
          followCnt: state.followCnt,
          maxCounting: state.maxCounting,
        };

        handleSubmit(propData && propData.id ? propData.id : null, data);
      }, 10);
    }, [propData, handleSubmit, workType]);

    useEffect(() => {
      if (propData) {
        if (propData.instaId) {
          state.instaId = propData.instaId;
        }
        if (propData.url) {
          state.url = propData.url;
        }
        if (propData.hashtags && propData.hashtags.length) {
          state.hashtags = propData.hashtags.join('\n');
        }
        if (propData.rankInterval) {
          state.rankInterval = propData.rankInterval;
        }
        if (propData.postCnt) {
          state.postCnt = propData.postCnt;
        }
        if (propData.trafficCnt) {
          state.trafficCnt = propData.trafficCnt;
        }
        if (propData.likeCnt) {
          state.likeCnt = propData.likeCnt;
        }
        if (propData.commentCnt) {
          state.commentCnt = propData.commentCnt;
        }
        if (propData.saveCnt) {
          state.saveCnt = propData.saveCnt;
        }
        if (propData.followCnt) {
          state.followCnt = propData.followCnt;
        }
        if (propData.maxCounting) {
          state.maxCounting = propData.maxCounting;
        }
        if (propData.instaContents) {
          state.instaContents = propData.instaContents;
        }
      }
    }, [propData]);

    return (
      <Wrapper>
        <Row gutter={[10, 20]}>
          <Col xs={24}>
            <Row gutter={[10, 10]}>
              <Col xs={12} lg={6}>
                <h4>인스타 아이디</h4>
                <Input
                  value={state.instaId}
                  onChange={handleChangeInput('instaId')}
                />
              </Col>
              <Col xs={12} lg={4}>
                <h4>순위</h4>
                <Space>
                  <InputNumber
                    value={toJS(state.rankInterval)[0]}
                    onChange={handleChangeInputNumber('rankInterval', 0)}
                  />
                  <span>~</span>
                  <InputNumber
                    value={toJS(state.rankInterval)[1]}
                    onChange={handleChangeInputNumber('rankInterval', 1)}
                  />
                </Space>
              </Col>
              <Col xs={12} lg={24}>
                <h4>인스타 URL</h4>
                <Input value={state.url} onChange={handleChangeInput('url')} />
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col xs={12} lg={4}>
                <h4>게시물개수</h4>
                <InputNumber
                  value={state.postCnt}
                  onChange={handleChangeInputNumber('postCnt')}
                />
              </Col>
              <Col xs={12} lg={4}>
                <h4>트래픽횟수</h4>
                <InputNumber
                  value={state.trafficCnt}
                  onChange={handleChangeInputNumber('trafficCnt')}
                />
              </Col>
              <Col xs={12} lg={4}>
                <h4>좋아요개수</h4>
                <InputNumber
                  value={state.likeCnt}
                  onChange={handleChangeInputNumber('likeCnt')}
                />
              </Col>
              <Col xs={12} lg={4}>
                <h4>저장개수</h4>
                <InputNumber
                  value={state.saveCnt}
                  onChange={handleChangeInputNumber('saveCnt')}
                />
              </Col>
              <Col xs={12} lg={4}>
                <h4>팔로우개수</h4>
                <InputNumber
                  value={state.followCnt}
                  onChange={handleChangeInputNumber('followCnt')}
                />
              </Col>
              <Col xs={12} lg={4}>
                <h4>댓글개수</h4>
                <InputNumber
                  value={state.commentCnt}
                  onChange={handleChangeInputNumber('commentCnt')}
                />
              </Col>
              <Col xs={12} lg={4}>
                <h4>진행할 일수</h4>
                <InputNumber
                  value={state.maxCounting}
                  onChange={handleChangeInputNumber('maxCounting')}
                />
              </Col>
            </Row>
          </Col>

          <Col xs={24} lg={12}>
            <Card size="small" title="해시태그">
              <Input.TextArea
                rows={5}
                value={state.hashtags}
                onChange={handleChangeInput('hashtags')}
                onBlur={handleCheckTextAreaRow('hashtags')}
              />
            </Card>
          </Col>

          <Col xs={24}>
            <h4>인스타 게시물 내용 입력</h4>
            <Row gutter={[10, 10]} justify="center" align="middle">
              {toJS(state.instaContents).map((item, idx) => (
                <Col xs={24} key={`instaContents_${idx}`}>
                  <Card
                    size="small"
                    title={
                      <Radio.Group
                        value={item.isUse}
                        onChange={handleUseInstaContents(idx)}
                      >
                        <Radio value={true}>사용</Radio>
                        <Radio value={false}>미사용</Radio>
                      </Radio.Group>
                    }
                    extra={
                      <Button
                        icon={<MinusOutlined />}
                        shape="circle"
                        onClick={handleRemoveInstaContents(idx)}
                      />
                    }
                  >
                    <Input
                      value={item.content}
                      onChange={handleChangeInput('instaContents', idx)}
                    />
                  </Card>
                </Col>
              ))}

              <Col>
                <Button
                  icon={<PlusOutlined />}
                  shape="circle"
                  onClick={handleAddInstaContents}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider />

        <Row gutter={[10, 10]} justify="end">
          <Col>
            <Button onClick={handleClose} style={{ width: 120 }}>
              닫기
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={handleClickSubmit}
              style={{ width: 120 }}
            >
              저장
            </Button>
          </Col>
        </Row>
      </Wrapper>
    );
  },
);

export default SlotModify;
