/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';

import SaleList from '../../../SaleStatus/SaleList/SaleList';
import Point from '../../../SaleStatus/Point';
import GivePoint from '../../../Point/GivePoint';

const { TabPane } = Tabs;

const Wrapper = styled.div`
  width: 100%;
`;

const PaymentInfo = observer(({ user }) => {
  const router = useHistory();
  const state = useLocalStore(() => ({
    tab: '1',
  }));

  const handleChangeTab = useCallback((key) => {
    state.tab = key;
  }, []);

  return (
    <Wrapper>
      <Tabs activeKey={state.tab} onChange={handleChangeTab}>
        <TabPane tab="포인트충전" key="1">
          <SaleList listType="all" user={user} />
        </TabPane>
        <TabPane tab="포인트사용" key="3">
          <Point user={user} />
        </TabPane>
        <TabPane tab="포인트지급" key="4">
          <GivePoint user={user} />
        </TabPane>
      </Tabs>
    </Wrapper>
  );
});

export default PaymentInfo;
