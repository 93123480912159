/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import Cookies from 'js-cookie';

import useStore from '@stores/useStore';

import CustomModal from '@Common/CustomModal';

const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  width: 100%;
`;

const ProductDetail = observer(({ modalOpen, handleModal, content }) => {
  const router = useHistory();
  const screen = useBreakpoint();
  const { commonStore } = useStore();

  const handleClose = useCallback(() => {
    handleModal(false)();
  }, []);

  const handleBuy = useCallback(() => {
    if (commonStore.user) {
      window.info({
        title: '업체추가 후 상품을 선택할 수 있습니다.',
        onOk() {
          handleClose();
          setTimeout(() => {
            if (screen.lg) {
              window.location.href = '#work';
            } else {
              router.push('/work');
            }
          }, 10);
        },
      });
    } else {
      Cookies.set('redirect-url', '/');
      router.push('/login');
    }
  }, [commonStore.user, screen]);

  return (
    <CustomModal
      title=""
      visible={modalOpen}
      onCancel={handleClose}
      forceRender={true}
      minwidth={800}
      okText="구매"
      cancelText="닫기"
      onOk={handleBuy}
    >
      <Wrapper dangerouslySetInnerHTML={{ __html: content }} />
    </CustomModal>
  );
});

export default ProductDetail;
