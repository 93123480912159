/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import {
  Row,
  Col,
  Radio,
  Button,
  Divider,
  Input,
  InputNumber,
  Space,
  Select,
} from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/react-hooks';
import moment from 'moment';

import useStore from '@stores/useStore';
import { numberWithCommas } from '@utils/common';
import { WORK_LIST } from '@shared/queries/WorkQueries';

import Description from '@Common/Description';

const { Option } = Select;

const Wrapper = styled.div`
  width: 100%;
`;

const BuyK = observer(({ data, handleClose, handleSubmit }) => {
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    daysKey: 'kLikeMonthDays',
    priceKey: 'kLikePrice',
    selectedMonthPriceUid: null,

    // DB 데이터
    workType: 'K좋아요',
    paymentType: '건구매',
    url: '',
    instaId: '',
    commentType: '랜덤댓글',
    comment: '',
    buyCnt: 1,
    buyAmount: 0,
    isMonth: false,
  }));

  const client = useApolloClient();

  const calcPrice = useCallback(() => {
    let value = 0;
    if (state.isMonth) {
      let f;
      if (state.selectedMonthPriceUid && Array.isArray(data[state.priceKey])) {
        f = data[state.priceKey].find(
          (item) => item.uid === state.selectedMonthPriceUid,
        );
      }

      if (f) {
        value = f.price;
        state.buyCnt = f.cnt;
      }
    } else if (data[state.priceKey]) {
      value = data[state.priceKey];
      value = value * state.buyCnt;
    }
    state.buyAmount = value;
  }, [data]);

  const handleChangeRadio = useCallback(
    (type) => (e) => {
      state.selectedMonthPriceUid = null;
      state[type] = e.target.value;

      setTimeout(() => {
        if (state.workType === 'K좋아요') {
          if (state.paymentType === '건구매') {
            state.isMonth = false;
            state.priceKey = 'kLikePrice';
          } else if (state.paymentType === '월구매') {
            state.isMonth = true;
            state.priceKey = 'kLikeMonthPrice';
            state.daysKey = 'kLikeMonthDays';
          }
        } else if (state.workType === 'K팔로워') {
          state.isMonth = false;
          state.priceKey = 'kFollowPrice';
          state.paymentType = '건구매';
        } else if (state.workType === 'K댓글') {
          if (state.paymentType === '건구매') {
            state.isMonth = false;
            if (state.commentType === '직접입력댓글') {
              state.priceKey = 'kDirectCommentPrice';
              state.comment = '1.';
            } else if (state.commentType === '랜덤댓글') {
              state.priceKey = 'kRandomCommentPrice';
              state.comment = '';
            }
          } else if (state.paymentType === '월구매') {
            state.isMonth = true;
            state.daysKey = 'kCommentMonthDays';
            if (state.commentType === '직접입력댓글') {
              state.priceKey = 'kDirectCommentMonthPrice';
              state.comment = '1.';
            } else if (state.commentType === '랜덤댓글') {
              state.priceKey = 'kRandomCommentMonthPrice';
              state.comment = '';
            }
          }
        }

        setTimeout(() => {
          calcPrice();
        }, 10);
      }, 10);
    },
    [],
  );

  const handleChangeSelect = useCallback(
    (type) => (value) => {
      state[type] = value;

      setTimeout(() => {
        calcPrice();
      }, 10);
    },
    [],
  );

  const handleChangeInputNumber = useCallback(
    (type) => (value) => {
      state[type] = value;
      if (type === 'buyCnt') {
        setTimeout(() => {
          calcPrice();
        }, 10);
      }
    },
    [],
  );

  const handleChangeInput = useCallback(
    (type) => (e) => {
      state[type] = e.target.value;
    },
    [],
  );

  const handleCheckTextAreaRow = useCallback(
    (type) => (e) => {
      if (type === 'comment') {
        const lines = state.comment.split('\n');
        let values = [];
        for (let i = 0; i < state.buyCnt; i++) {
          if (lines[i] && lines[i].length) {
            if (!/^[0-9]+\./.test(lines[i])) {
              values.push(`${i + 1}.${lines[i]}`);
            } else {
              let dotIdx = lines[i].indexOf('.');
              values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
            }
          }
        }
        state.comment = values.join('\n');
      }
    },
    [],
  );

  const handlePressEnter = useCallback(
    (type) => (e) => {
      e.persist();
      setTimeout(() => {
        const lines = state[type].split('\n');
        let values = [];
        for (let i = 0; i < lines.length; i++) {
          if (!/^[0-9]+\./.test(lines[i])) {
            values.push(`${i + 1}.${lines[i]}`);
          } else {
            let dotIdx = lines[i].indexOf('.');
            values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
          }
        }
        state[type] = values.join('\n');
      }, 10);
    },
    [],
  );

  const handleClickSubmit = useCallback(async () => {
    // if (!state.instaId || !state.instaId.length) {
    //   return window.alert({ title: '인스타 아이디를 입력해 주세요.' });
    // }

    if (state.paymentType === '건구매') {
      window.maskOn();
      const now = await commonStore.getServerNow();
      const findQuery = {
        user: commonStore.user.id,
        workType: state.workType,
        paymentType: state.paymentType,
      };

      if (
        !Boolean(commonStore.user.isTest) &&
        (state.workType === 'K좋아요' || state.workType === 'K팔로워')
      ) {
        switch (state.workType) {
          case 'K좋아요':
            // findQuery['$and'] = [];
            // findQuery['$and'].push({
            //   createdAt: { $gte: moment(now).startOf('date') },
            // });
            // findQuery['$and'].push({
            //   createdAt: { $lte: moment(now).endOf('date') },
            // });
            findQuery.url = { $regex: state.url, $options: 'ig' };
            break;
          case 'K팔로워':
            findQuery['$and'] = [];
            findQuery['$and'].push({
              createdAt: { $gte: moment(now).startOf('year') },
            });
            findQuery['$and'].push({
              createdAt: { $lte: moment(now).endOf('date') },
            });
            break;
          default:
            break;
        }
        const res = await client.query({
          query: WORK_LIST,
          variables: {
            findQuery,
          },
        });
        if (res.data && res.data.workList) {
          const list = res.data.workList;
          let cnt = 0;
          for (const item of list) {
            cnt += item.buyCnt;
          }
          cnt += state.buyCnt;
          let isErr = false;
          let errMsg = '';
          switch (state.workType) {
            case 'K좋아요':
              if (cnt >= 3000) {
                isErr = true;
                errMsg = 'URL당 3000개까지만 구매 가능합니다.';
              }
              break;
            case 'K팔로워':
              if (cnt >= 10000) {
                isErr = true;
                errMsg = '1년 10000개까지만 구매 가능합니다.';
              }
              break;
            default:
              break;
          }
          if (isErr) {
            window.maskOff();
            return window.alert({ title: errMsg });
          }
        }
      }

      window.maskOff();
    }

    setTimeout(() => {
      const workData = {
        workType: state.workType,
        paymentType: state.paymentType,
        status: '진행중',
        isMonth: state.isMonth,
        buyCnt: state.buyCnt,
        buyAmount: state.buyAmount,
        instaId: state.instaId,
      };

      if (state.workType !== 'K팔로워') {
        workData.url = state.url;
        if (state.workType === 'K댓글') {
          workData.commentType = state.commentType;
          workData.comment = state.comment;
        }
      }

      handleSubmit(workData);
    }, 10);
  }, [data]);

  useEffect(() => {
    if (data) {
      setTimeout(() => {
        calcPrice();
      }, 10);
    }
  }, [data]);

  return (
    <Wrapper>
      <h3>프로그램</h3>
      <Row gutter={[15, 15]}>
        <Col xs={24}>
          <Radio.Group
            buttonStyle="solid"
            value={state.workType}
            onChange={handleChangeRadio('workType')}
          >
            <Radio.Button value="K좋아요">K좋아요</Radio.Button>
            <Radio.Button value="K팔로워">K팔로워</Radio.Button>
            <Radio.Button value="K댓글">K댓글</Radio.Button>
          </Radio.Group>
        </Col>
        {state.workType !== 'K팔로워' && (
          <Col xs={24}>
            <Radio.Group
              buttonStyle="solid"
              value={state.paymentType}
              onChange={handleChangeRadio('paymentType')}
            >
              <Radio.Button value="건구매">건구매</Radio.Button>
              <Radio.Button value="월구매">월구매</Radio.Button>
            </Radio.Group>
          </Col>
        )}
      </Row>

      <Divider />

      <h3>수량</h3>
      <Row gutter={[10, 10]}>
        {state.workType === 'K댓글' && (
          <Col xs={24}>
            <Radio.Group
              value={state.commentType}
              onChange={handleChangeRadio('commentType')}
            >
              <Radio value="랜덤댓글">랜덤댓글 사용</Radio>
              <Radio value="직접입력댓글">직접입력댓글 사용</Radio>
            </Radio.Group>
          </Col>
        )}

        {state.paymentType === '건구매' && (
          <>
            <Col xs={24}>
              {state.workType === 'K좋아요' ? (
                <Description text="URL당 3000개 가능" />
              ) : state.workType === 'K팔로워' ? (
                <Description text="1년 10000개 가능" />
              ) : state.workType === 'K댓글' ? (
                <Description text="1일 제한없음" />
              ) : (
                ''
              )}
            </Col>
            <Col xs={24}>
              <Space>
                <InputNumber
                  value={state.buyCnt}
                  onChange={handleChangeInputNumber('buyCnt')}
                  min={1}
                />
                <span>개</span>
                <span>({numberWithCommas(state.buyAmount, true)} 포인트)</span>
              </Space>
            </Col>
          </>
        )}

        {state.paymentType === '월구매' &&
          data[state.priceKey] &&
          Array.isArray(data[state.priceKey]) && (
            <Col xs={24}>
              <Space>
                <Select
                  value={state.selectedMonthPriceUid}
                  onChange={handleChangeSelect('selectedMonthPriceUid')}
                  style={{ width: 220 }}
                >
                  {data[state.priceKey].map((item) => (
                    <Option value={item.uid} key={item.uid}>
                      +{item.cnt}개씩 {numberWithCommas(item.price, true)}포인트
                      ({data[state.daysKey]}일)
                    </Option>
                  ))}
                </Select>
                <span>({numberWithCommas(state.buyAmount, true)} 포인트)</span>
              </Space>
            </Col>
          )}
      </Row>

      <Divider />

      <Row gutter={[10, 10]}>
        {state.workType === 'K팔로워' ||
        (state.workType === 'K좋아요' && state.isMonth) ||
        (state.workType === 'K댓글' && state.isMonth) ? (
          <Col xs={24}>
            <h4>인스타 아이디</h4>
            <Input
              value={state.instaId}
              onChange={handleChangeInput('instaId')}
            />
          </Col>
        ) : null}

        {(state.workType === 'K좋아요' || state.workType === 'K댓글') &&
          !state.isMonth && (
            <Col xs={24}>
              <h4>인스타 게시물 URL</h4>
              <Input value={state.url} onChange={handleChangeInput('url')} />
            </Col>
          )}

        {state.workType === 'K댓글' && state.commentType === '직접입력댓글' && (
          <Col xs={24}>
            <h4>인스타 댓글 입력</h4>
            <Description text="구매 개수 만큼 일괄입력(한줄에 하나씩)" />
            <Input.TextArea
              rows={5}
              value={state.comment}
              onChange={handleChangeInput('comment')}
              onBlur={handleCheckTextAreaRow('comment')}
              onPressEnter={handlePressEnter('comment')}
            />
          </Col>
        )}
      </Row>

      <Divider />

      <Row gutter={[10, 10]} justify="end">
        <Col>
          <Space>
            <span>총 결제 포인트</span>
            <span className="amount">
              {numberWithCommas(state.buyAmount, true)}
            </span>
            <span>포인트</span>
          </Space>
        </Col>
      </Row>

      <Row gutter={[10, 10]} justify="end">
        <Col>
          <Button onClick={handleClose} style={{ width: 120 }}>
            취소
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={handleClickSubmit}
            style={{ width: 120 }}
          >
            구매
          </Button>
        </Col>
      </Row>
    </Wrapper>
  );
});

export default BuyK;
