/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import {
  Form,
  Input,
  Switch,
  Row,
  Col,
  Select,
  Tag,
  InputNumber,
  DatePicker,
  Space,
  Button,
} from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import { toJS } from 'mobx';
import styled from 'styled-components';

import useStore from '@stores/useStore';
import { ADD_POST, UPDATE_POST } from '@shared/queries/PostQueries';
import { SINGLE_FILE_UPLOAD, SINGLE_FILE_REMOVE } from '@shared/queries';
import { checkExif } from '@utils/preUpload';
import { formatFilename } from '@utils/common';
import { validateMessages } from '@utils/customFormValidator';

import CustomModal from '@Common/CustomModal';
import Editor from '@Common/Editor';

const { Option } = Select;

const Wrapper = styled.div`
  width: 100%;

  .ant-input-group-addon {
    padding: 0;
  }
`;

const PostModify = observer(
  ({ modifyOpen, refetch, propData = {}, handleModify, board, isAdmin }) => {
    const [form] = Form.useForm();

    const { commonStore } = useStore();
    const state = useLocalStore(() => ({
      preSave: null,
      setPreSave(preSave) {
        state.preSave = preSave;
      },

      files: [],
      get filesVal() {
        return toJS(this.files);
      },

      phone: '',
      isAuth: false,
    }));

    const [add] = useMutation(ADD_POST);
    const [update] = useMutation(UPDATE_POST);
    const [singleFileUpload] = useMutation(SINGLE_FILE_UPLOAD);
    const [singleFileRemove] = useMutation(SINGLE_FILE_REMOVE);

    const handleAuth = useCallback(() => {
      window.IMP.certification(
        {
          phone: state.phone,
        },
        (rsp) => {
          // callback
          if (rsp.success) {
            // 인증 성공 시 로직
            state.isAuth = true;
          } else {
            // 인증 실패 시 로직
            state.isAuth = false;
          }
        },
      );
    }, []);

    const handleClose = useCallback(() => {
      state.files = [];
      form.resetFields();
      handleModify(false)();
    }, []);

    const handleChangeFile = useCallback(
      (idx) => async (e) => {
        if (e.target.files && e.target.files[0]) {
          const stateFile = toJS(state.files);
          const file = e.target.files[0];
          e.target.value = '';
          if (stateFile[idx] && stateFile[idx].status === 'done') {
            window.ask({
              title: '계속 진행하시겠습니까?',
              content: `파일${
                idx + 1
              } 항목을 변경하시면 기존 파일은 삭제됩니다.`,
              async onOk() {
                await singleFileRemove({
                  variables: { Key: stateFile[idx].name },
                });
                try {
                  // const resizeFile = await checkExif(file, 256, 256);
                  file.status = 'load';
                  stateFile[idx] = file;
                  state.files = stateFile;
                } catch (e) {
                  if (e && e.message) {
                    window.alert({ title: e.message });
                  }
                }
              },
            });
          } else {
            try {
              // const resizeFile = await checkExif(file, 256, 256);
              // resizeFile.status = 'load';
              file.status = 'load';
              stateFile[idx] = file;
              state.files = stateFile;
            } catch (e) {
              if (e && e.message) {
                window.alert({ title: e.message });
              }
            }
          }
        }
      },
      [],
    );

    const saveFile = useCallback(
      (resizeFile) => {
        return new Promise(async (resolve) => {
          const filename = formatFilename(
            resizeFile.type,
            `post/${board}/files/${moment().format('YYYY-MM')}`,
          );
          const data = {
            filename,
            file: resizeFile,
          };

          const res = await singleFileUpload({
            variables: { data },
          });
          let uid, name, url, err;
          if (res.errors && res.errors.length) {
            err = res.errors[0].message;
          } else {
            uid = res.data.singleFileUpload.uid;
            url = res.data.singleFileUpload.url;
            name = filename;
          }

          resolve({
            data: {
              uid,
              name,
              url,
              status: 'done',
            },
            err,
          });
        });
      },
      [commonStore.user],
    );

    const handleValidate = useCallback(async () => {
      const value = await state.preSave(true);
      const val = value.replace(/(<p>)|(<br>)|(<\/p>)|(<br\/>)|\s/g, '');
      const content = val.length ? value : '';
      form.setFieldsValue({
        content,
      });
      return form
        .validateFields()
        .then((values) => {
          handleSubmit(values);
        })
        .catch((errorInfo) => {
          if (errorInfo.errorFields[0]) {
            form.scrollToField(errorInfo.errorFields[0].name);
          }
        });
    }, [propData, state.preSave]);

    const handleSubmit = useCallback(
      async (values) => {
        // if (!isAdmin && !propData.id && !state.isAuth) {
        //   return window.alert({ title: '본인인증을 완료해 주세요' });
        // }

        window.maskOn();

        let files = toJS(state.files);
        for (let i = 0; i < files.length; i++) {
          if (files[i] && files[i].status === 'load') {
            const result = await saveFile(files[i]);
            if (result.err) {
              return window.alert({
                title: '파일 업로드 중 오류가 발생하였습니다.',
              });
            }
            files[i] = {
              ...result.data,
              filename: files[i].name || files[i].filename,
              size: files[i].size,
            };
          }
        }

        const [content, images] = await state.preSave();

        const data = {
          ...values,
          content,
          images,
          board,
          files,
        };

        let title = '';

        let result;
        if (propData.id) {
          if (commonStore.user) {
            data.updated = commonStore.user.id;
          }
          result = await update({
            variables: { id: propData.id, data },
          });
          if (result && result.data && result.data.updatePost) {
          }
          title = `${result.data.updatePost.title} 게시글이 수정되었습니다.`;
        } else {
          if (commonStore.user) {
            data.created = commonStore.user.id;
          }
          result = await add({ variables: { data } });
          if (result && result.data && result.data.addPost) {
            title = `${result.data.addPost.title} 게시글이 추가되었습니다.`;
          }
        }

        await refetch();
        form.resetFields();
        window.maskOff();
        window.success({ title });
        handleClose();
      },
      [propData, state.preSave, board, isAdmin],
    );

    useEffect(() => {
      if (propData.files && propData.files.length) {
        state.files = propData.files;
      }
      if (
        propData.author &&
        propData.author.phone &&
        propData.author.phone.length
      ) {
        state.phone = propData.author.phone;
      }
      if (form) {
        form.resetFields();
      }
    }, [propData, form]);

    return (
      <CustomModal
        title={`${board} ${propData.id ? '수정' : '등록'}`}
        visible={modifyOpen}
        onOk={handleValidate}
        onCancel={handleClose}
        forceRender={true}
        minwidth={800}
      >
        <Wrapper>
          <Form
            form={form}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="post_modify"
            initialValues={{
              title: propData.title || '',
              content: propData.content || '',
              isNotice: propData.isNotice != null ? propData.isNotice : false,
              views: propData.views || 0,
              postDate: propData.postDate ? moment(propData.postDate) : null,
              author: propData.author || null,
              password: propData.password || '',
            }}
            validateMessages={validateMessages}
            scrollToFirstError
            onValuesChange={(changedValues) => {
              if (
                changedValues.phone &&
                /^010[0-9]{4}[0-9]{4}$/.test(changedValues.phone)
              ) {
                state.phone = changedValues.phone;
              }
            }}
          >
            {isAdmin && (
              <Form.Item
                name="isNotice"
                label="공지"
                valuePropName="checked"
                labelAlign="left"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 22 }}
                colon={false}
              >
                <Switch />
              </Form.Item>
            )}

            {(isAdmin || !commonStore.user) && (
              <Row gutter={16}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name={['author', 'name']}
                    label="작성자(비회원)"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item shouldUpdate noStyle>
                    {() => (
                      <Form.Item
                        name={['author', 'phone']}
                        label="연락처"
                        rules={[
                          { required: true, pattern: /^010[0-9]{4}[0-9]{4}$/ },
                        ]}
                      >
                        <Input
                          placeholder="핸드폰번호를 - 없이 입력하세요"
                          // addonAfter={
                          //   Boolean(propData && propData.id) ? null : (
                          //     <Button
                          //       size="small"
                          //       type="primary"
                          //       htmlType="button"
                          //       block
                          //       disabled={
                          //         !/^010[0-9]{4}[0-9]{4}$/.test(
                          //           form.getFieldValue(['author', 'phone']),
                          //         ) || state.isAuth
                          //       }
                          //       onClick={handleAuth}
                          //       style={{ height: 30 }}
                          //     >
                          //       본인인증
                          //     </Button>
                          //   )
                          // }
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row gutter={16}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="password"
                  label="게시글 비밀번호"
                  rules={[{ required: true }]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="title"
              label="게시글 제목"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            {isAdmin && (
              <Space size={10}>
                <Form.Item name="postDate" label="날짜">
                  <DatePicker />
                </Form.Item>
                <Form.Item name="views" label="조회수">
                  <InputNumber />
                </Form.Item>
              </Space>
            )}

            <Form.Item shouldUpdate noStyle>
              {() => (
                <Form.Item
                  name="content"
                  label="게시글 내용"
                  rules={[{ required: true }]}
                >
                  <Editor
                    setPreSave={state.setPreSave}
                    preSave={state.preSave}
                    uploadUrl={`post/${board}/content/${moment().format(
                      'YYYY-MM',
                    )}`}
                    content={propData.content}
                    images={propData.images ? propData.images : []}
                  />
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item label="파일1">
              <input
                id={`file1`}
                type="file"
                onChange={handleChangeFile(0)}
                style={{ display: 'none' }}
              />
              <Row align="middle" gutter={5}>
                <Col xs={24} lg={4}>
                  <Button
                    htmlType="button"
                    onClick={() => document.querySelector(`#file1`).click()}
                  >
                    파일선택
                  </Button>
                </Col>
                <Col xs={24} lg={20}>
                  <Input
                    value={
                      state.filesVal[0]
                        ? state.filesVal[0].filename
                          ? state.filesVal[0].filename
                          : state.filesVal[0].name
                        : null
                    }
                    disabled
                  />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item label="파일2">
              <input
                id={`file2`}
                type="file"
                onChange={handleChangeFile(1)}
                style={{ display: 'none' }}
              />
              <Row align="middle" gutter={5}>
                <Col xs={24} lg={4}>
                  <Button
                    htmlType="button"
                    onClick={() => document.querySelector(`#file2`).click()}
                  >
                    파일선택
                  </Button>
                </Col>
                <Col xs={24} lg={20}>
                  <Input
                    value={
                      state.filesVal[1]
                        ? state.filesVal[1].filename
                          ? state.filesVal[1].filename
                          : state.filesVal[1].name
                        : null
                    }
                    disabled
                  />
                </Col>
              </Row>
            </Form.Item>
          </Form>
          {propData.id && commonStore.user && commonStore.user.isAdmin ? (
            <>
              <Row justify="end">
                <Col>
                  입력자:{' '}
                  <Tag>
                    {propData.created
                      ? propData.created.name
                      : propData.author
                      ? propData.author.name
                      : ''}
                  </Tag>
                  입력일시:{' '}
                  <Tag>
                    {moment(propData.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                  </Tag>
                </Col>
              </Row>
              <Row justify="end">
                <Col>
                  수정자:{' '}
                  <Tag>{propData.updated ? propData.updated.name : ''}</Tag>
                  수정일시:{' '}
                  <Tag>
                    {moment(propData.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
                  </Tag>
                </Col>
              </Row>
            </>
          ) : null}
        </Wrapper>
      </CustomModal>
    );
  },
);

export default PostModify;
