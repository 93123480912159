/* eslint-disable react-hooks/exhaustive-deps*/
import React from 'react';
import { Descriptions } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';

const PointVbank = observer(({ propData }) => {
  return (
    <Descriptions
      title="입금 계좌 정보"
      bordered
      size="small"
      column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
    >
      <Descriptions.Item label="입금 기한">
        {moment(propData.vbank_date, 'YYYYMMDDHHmm').format(
          'YYYY-MM-DD HH:mm:ss',
        )}
      </Descriptions.Item>
      <Descriptions.Item label="예금주">
        {propData.vbank_holder}
      </Descriptions.Item>
      <Descriptions.Item label="은행명">
        {propData.vbank_name}
      </Descriptions.Item>
      <Descriptions.Item label="계좌번호">
        {propData.vbank_num}
      </Descriptions.Item>
    </Descriptions>
  );
});

export default PointVbank;
