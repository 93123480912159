/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import {
  Row,
  Col,
  Input,
  InputNumber,
  Checkbox,
  Select,
  Button,
  Divider,
  Form,
  Grid,
  Space,
  Affix,
} from 'antd';
import styled from 'styled-components';
import { observer, useLocalStore } from 'mobx-react';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { toJS } from 'mobx';

import { METAS_REGEXP, SAVE_META } from '@shared/queries';
import { USERS } from '@shared/queries/UserQueries';
import { validateMessages } from '@utils/customFormValidator';

import Description from '@Common/Description';

const { Option } = Select;
const { TextArea } = Input;
const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  width: 100%;

  .ant-form-item-label {
    font-weight: bold;
  }
`;

const DefaultConfig = observer(() => {
  const [form] = Form.useForm();
  const screens = useBreakpoint();

  const state = useLocalStore(() => ({
    users: [],
    get usersVal() {
      return toJS(this.users);
    },
  }));

  const client = useApolloClient();
  const usersRes = useQuery(USERS, {
    variables: { findQuery: { isAdmin: true } },
  });
  const [save] = useMutation(SAVE_META);

  const fetchData = useCallback(async () => {
    const result = await client.query({
      query: METAS_REGEXP,
      variables: { key: '^df_' },
    });

    if (result.data && result.data.metasRegexp) {
      for (const meta of result.data.metasRegexp) {
        const key = meta.key.replace('df_', '');
        form.setFieldsValue({
          [key]: meta.value,
        });
      }
    }
  }, []);

  const handleSubmit = useCallback(async (values) => {
    window.maskOn();

    for (const key in values) {
      await save({
        variables: {
          key: `df_${key}`,
          data: {
            key: `df_${key}`,
            value: values[key],
          },
        },
      });
    }

    await fetchData();
    window.maskOff();
    window.success({ title: '기본환경 저장이 완료되었습니다. ' });
  }, []);

  useEffect(() => {
    if (usersRes.data && usersRes.data.users) {
      state.users = usersRes.data.users;
    }
  }, [usersRes.data]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Wrapper>
      <Form
        form={form}
        name="df_config_form"
        colon={false}
        labelAlign="left"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 10 }}
        layout={screens.lg ? 'horizontal' : 'vertical'}
        initialValues={{
          site_title: '',
          admin: null,
          admin_email: '',
          admin_email_name: '',
          // use_point: true,
          point_date: 0,
          cur_visitor_time: 10,
          ban_ip: '',
          meta_tag: '',
        }}
        validateMessages={validateMessages}
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={[32, 16]}>
          <Col xs={24} lg={20}>
            <Form.Item label="홈페이지 제목" name="site_title">
              <Input />
            </Form.Item>

            <Divider />

            <Form.Item label="최고관리자" name="admin">
              <Select>
                {state.usersVal.map((item) => (
                  <Option value={item.id} key={item.username}>
                    {item.username}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Divider />

            <Description text="관리자가 보내고 받는 용도로 사용하는 메일 주소를 입력합니다.(회원가입, 인증메일, 회원메일발송 등에서 사용)" />
            <Form.Item
              label="관리자 메일 주소"
              name="admin_email"
              rules={[
                {
                  type: 'email',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Divider />

            <Description text="관리자가 보내고 받는 용도로 사용하는 메일의 발송이름을 입력합니다.(회원가입, 인증메일, 회원메일발송 등에서 사용)" />
            <Form.Item label="관리자 메일 발송이름" name="admin_email_name">
              <Input />
            </Form.Item>

            <Divider />

            {/* <Form.Item
              label="포인트 사용"
              name="use_point"
              valuePropName="checked"
            >
              <Checkbox>사용</Checkbox>
            </Form.Item>

            <Divider /> */}

            <Description text="기간을 0으로 설정 시 포인트 유효기간이 적용되지 않습니다." />
            <Form.Item label="포인트 유효기간">
              <Space size={10} align="baseline">
                <Form.Item name="point_date">
                  <InputNumber />
                </Form.Item>
                <span>일</span>
              </Space>
            </Form.Item>

            <Divider />

            <Description text="설정값 이내의 접속자를 현재 접속자로 인정" />
            <Form.Item label="현재 접속자">
              <Space size={12} align="baseline">
                <Form.Item name="cur_visitor_time">
                  <InputNumber />
                </Form.Item>
                <span>분</span>
              </Space>
            </Form.Item>

            <Divider />

            <Description text="입력된 IP는 접근할 수 없음. (, 로 구분)" />
            <Form.Item label="접근차단 IP" name="ban_ip">
              <TextArea rows={5} />
            </Form.Item>

            <Divider />

            <Form.Item label="메타태그" name="meta_tag">
              <TextArea rows={5} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={4}>
            <Affix offsetTop={80}>
              <Form.Item noStyle>
                <Button type="primary" size="large" htmlType="submit" block>
                  저장
                </Button>
              </Form.Item>
            </Affix>
          </Col>
        </Row>
      </Form>
    </Wrapper>
  );
}, []);

export default DefaultConfig;
