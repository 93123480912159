import CommonStore from './CommonStore';
import AuthStore from './AuthStore';
import MetaStore from './MetaStore';

export class RootStore {
  commonStore;
  authStore;
  smsStore;
  metaStore;
  constructor() {
    this.commonStore = new CommonStore();
    this.authStore = new AuthStore(this);
    this.metaStore = new MetaStore();
  }
}

export default function initializeStore() {
  return new RootStore();
}
