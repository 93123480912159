/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Layout,
  Row,
  Col,
  Drawer,
  Space,
  Menu,
  Grid,
  Button,
  Affix,
} from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { observer, useLocalStore } from 'mobx-react';
import { toJS } from 'mobx';
import styled from 'styled-components';
import { lighten } from 'polished';

import useStore from '@stores/useStore';
import { numberWithCommas } from '@utils/common';

import SiteTitle from '@Styled/SiteTitle';
import Menus from '@Common/Menus';
import RightBanner from '@Member/Home/RightBanner';

import Footer from './Footer';

const { Header, Content } = Layout;
const { useBreakpoint } = Grid;

const headerKeys = ['home', 'notice', 'contact', 'mypage'];

const Wrapper = styled(Layout)`
  .content-layout {
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }
  .header {
    position: fixed;
    width: 100%;
    z-index: 2;
    padding: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background: #fff;
    height: 66px;

    .header-left,
    .header-right {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 24px 16px 0;
    width: 100%;
  }
  .content-children {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    min-height: 500px;

    ${(props) =>
      !props.ishome &&
      `
      background-color: #fff;
      padding: 20px;
    `};
  }

  .profile {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0.5rem;
    border: 1px dashed #e0e0e0;
    padding: 0.5rem;

    div {
      width: 100%;
      text-align: center;
    }
  }
  .trigger {
    font-size: 18px;
  }

  ${(props) => props.theme.breakpoint('xs', 'lg')`
    .header-right {
      display: none !important;
    }
  `}
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`;

const SubHeader = styled(Header)`
  background: ${(props) => lighten(0.1, props.theme.primaryColor)};
  color: #fff;
  margin-top: 66px;
`;

const MobileMenu = styled.div`
  width: 100%;
  height: 55px;
  display: none;
  border-bottom: 1px solid #f0f0f0;

  .ant-row {
    height: 100%;
  }
  .ant-col {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      border-right: 1px solid #f0f0f0;
    }
  }

  ${(props) => props.theme.breakpoint('xs', 'lg')`
    display: block;
  `}
`;

const MemberDesktopLayout = observer(
  ({
    children,
    headerCurrent,
    collapsed,
    routes,
    toggle,
    handleClickHeaderMenu,
  }) => {
    const router = useLocation();
    const screen = useBreakpoint();

    const { commonStore } = useStore();

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          backgroundColor: '#fff',
        }}
      >
        <Wrapper
          collapsed={collapsed ? 1 : 0}
          ishome={router.pathname === '/' ? 1 : 0}
        >
          <StyledDrawer
            // title={<SiteTitle size="24px" />}
            title=" "
            placement="left"
            closable={false}
            onClose={toggle}
            visible={collapsed}
          >
            <MobileMenu>
              <Row>
                <Col xs={12}>
                  <Link to="/login">로그인</Link>
                </Col>
                <Col xs={12}>
                  <Link to="/signup">회원가입</Link>
                </Col>
              </Row>
            </MobileMenu>
            <Menus />
          </StyledDrawer>

          <Layout className="content-layout">
            <Header className="header">
              <div className="header-left">
                <Space size={16}>
                  {React.createElement(
                    collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                    {
                      className: 'trigger',
                      onClick: toggle,
                    },
                  )}
                  <Link to="/" style={{ color: '#000' }}>
                    <SiteTitle size="24px" />
                  </Link>
                </Space>
              </div>
              <div className="header-right">
                <Menu
                  onClick={handleClickHeaderMenu}
                  selectedKeys={[headerCurrent]}
                  mode="horizontal"
                >
                  <Menu.Item key="home">
                    <Link to="/">HOME</Link>
                  </Menu.Item>
                  <Menu.Item key="notice">
                    <Link to="/notice">공지사항</Link>
                  </Menu.Item>
                  <Menu.Item key="contact">
                    <Link to="/contact">고객문의</Link>
                  </Menu.Item>
                  <Menu.Item key="qna">
                    <Link to="/qna">사용방법</Link>
                  </Menu.Item>
                  <Menu.Item key="pointCharging">
                    <Link
                      to={{
                        pathname: commonStore.user ? `/mypage` : '/login',
                        query: { tab: '3' },
                      }}
                    >
                      포인트충전
                    </Link>
                  </Menu.Item>
                  {commonStore.user && (
                    <Menu.Item key="mypage">
                      <Link to="/mypage">마이페이지</Link>
                    </Menu.Item>
                  )}
                  <Menu.Item key="logout">
                    {commonStore.user ? (
                      <span onClick={commonStore.logout}>로그아웃</span>
                    ) : (
                      <Link to="/login">로그인</Link>
                    )}
                  </Menu.Item>
                </Menu>
              </div>
            </Header>
            <SubHeader>
              <Space
                size={20}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                {commonStore.user ? (
                  <Link to="/mypage">{commonStore.user.name}</Link>
                ) : (
                  <Link to="/login">비회원님</Link>
                )}

                {commonStore.user && (
                  <Space>
                    <span>
                      포인트 : {numberWithCommas(commonStore.user.point)}
                    </span>
                    {/* <Link
                    to={{ pathname: `/mypage`, query: { tab: 3 } }}
                    style={{ color: '#fff', textDecorationLine: 'underline' }}
                  >
                    충전
                  </Link> */}
                  </Space>
                )}
              </Space>
            </SubHeader>

            <Row gutter={[10, 10]}>
              <Col xs={24} lg={22}>
                <Content className="content">
                  <div className="content-children">{children}</div>
                </Content>
              </Col>
              {screen.lg && (
                <Col xs={24} lg={2}>
                  <RightBanner />
                  {/* <Affix offsetTop={60}>
                    <RightBanner />
                  </Affix> */}
                </Col>
              )}
            </Row>
          </Layout>
        </Wrapper>
        <Footer />
      </div>
    );
  },
);

export default MemberDesktopLayout;
