/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { Row, Col, InputNumber, Space, Card, Radio } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';

import { numberWithCommas } from '@utils/common';

const Wrapper = styled.div`
  width: 100%;
`;

const ProductConfigOA = observer(({ productValue, setProductValue }) => {
  const state = useLocalStore(() => ({}));

  const handleChangeNumber = useCallback(
    (type) => (value) => {
      productValue[type] = value;
      setProductValue(productValue);
    },
    [productValue],
  );

  const handleChangeRadio = useCallback(
    (type) => (e) => {
      productValue[type] = e.target.value;
      setProductValue(productValue);
    },
    [productValue],
  );

  return (
    <Wrapper>
      <Card title="가격설정" size="small">
        <Row gutter={[10, 10]}>
          <Col xs={24} lg={8}>
            <h3>[월금액]</h3>
            <Space size={30}>
              <Space>
                <InputNumber
                  value={productValue.monthDays}
                  onChange={handleChangeNumber('monthDays')}
                  style={{ width: 80 }}
                />
                <span>일</span>
              </Space>
              <Space>
                <InputNumber
                  value={productValue.monthPrice}
                  onChange={handleChangeNumber('monthPrice')}
                  formatter={(value) => numberWithCommas(value)}
                  parser={(value) => value.replace(/[^0-9]/g, '')}
                  style={{ width: 140 }}
                />
                <span>원</span>
              </Space>
            </Space>
          </Col>

          <Col xs={24} lg={8}>
            <h3>[프로그램 추가옵션]</h3>
            <Space>
              <span>직접입력댓글</span>
              <InputNumber
                value={productValue.directCommentPrice}
                onChange={handleChangeNumber('directCommentPrice')}
                formatter={(value) => numberWithCommas(value)}
                parser={(value) => value.replace(/[^0-9]/g, '')}
                style={{ width: 140 }}
              />
              <span>원</span>
            </Space>
          </Col>
        </Row>
      </Card>
      <Card title="프로그램 환경설정" size="small" style={{ marginTop: 20 }}>
        <Space size={40} style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div>
            <h4>작업일수</h4>
            <InputNumber
              value={productValue.workDayCnt}
              onChange={handleChangeNumber('workDayCnt')}
              style={{ width: 80 }}
            />
          </div>
          {/* <div>
            <h4>피드수</h4>
            <InputNumber
              value={productValue.feedCnt}
              onChange={handleChangeNumber('feedCnt')}
              style={{ width: 80 }}
            />
          </div>
          <div>
            <h4>스토리수</h4>
            <InputNumber
              value={productValue.storyCnt}
              onChange={handleChangeNumber('storyCnt')}
              style={{ width: 80 }}
            />
          </div> */}
          <div>
            <h4>게시물수</h4>
            <InputNumber
              value={productValue.postCnt}
              onChange={handleChangeNumber('postCnt')}
              style={{ width: 80 }}
            />
          </div>
          <div>
            <h4>좋아요</h4>
            <InputNumber
              value={productValue.likeCnt}
              onChange={handleChangeNumber('likeCnt')}
              style={{ width: 80 }}
            />
          </div>
          <div>
            <h4>선팔</h4>
            <InputNumber
              value={productValue.firstFollowCnt}
              onChange={handleChangeNumber('firstFollowCnt')}
              style={{ width: 80 }}
            />
          </div>
          <div>
            <h4>맞팔</h4>
            <Radio.Group
              onChange={handleChangeRadio('isEachOtherFollow')}
              value={productValue.isEachOtherFollow}
              optionType="button"
              buttonStyle="solid"
            >
              <Radio.Button value={true}>Auto</Radio.Button>
              <Radio.Button value={false}>Off</Radio.Button>
            </Radio.Group>
          </div>
        </Space>
      </Card>
    </Wrapper>
  );
});

export default ProductConfigOA;
