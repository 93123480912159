/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect } from 'react';
import { maskOn, maskOff } from '@utils/mask';
import styled from 'styled-components';

const Dummy = styled.div`
  width: 100vw;
  height: 100vh;
  background: #fff;
`;

export default ({ time }) => {
  useEffect(() => {
    maskOn(time);
    return () => {
      maskOff();
    };
  }, []);
  return <Dummy>&nbsp;</Dummy>;
};
