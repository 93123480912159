/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { Form, Input, Button, Space, Row, Col } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { observer, useLocalStore } from 'mobx-react';
import { useMutation } from '@apollo/react-hooks';
import styled from 'styled-components';

import useStore from '@stores/useStore';
import {
  ADD_COMPANY,
  UPDATE_COMPANY,
  REMOVE_COMPANY,
} from '@shared/queries/CompanyQueries';
import { validateMessages } from '@utils/customFormValidator';

import CustomModal from '@Common/CustomModal';

const Container = styled.div`
  width: 100%;

  .company-name,
  .company-name span {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .add-company-btn {
    animation: btn-ani 1s infinite;
    text-decoration: none !important;
  }

  @keyframes btn-ani {
    0% {
      background-color: #fff;
      color: #1890ff;
    }
    20% {
      background-color: #fff;
      color: #1890ff;
    }
    40% {
      background-color: #1890ff;
      color: #fff;
    }
    60% {
      background-color: #1890ff;
      color: #fff;
    }
    80% {
      background-color: #fff;
      color: #1890ff;
    }
    100% {
      background-color: #fff;
      color: #1890ff;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;

  .ant-input-group-addon {
    padding: 0;
  }
`;

const CompanyModify = observer(({ refetch, propData = {}, user }) => {
  const [form] = Form.useForm();

  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    visible: false,
    isAddBtnHover: false,
  }));

  const [add] = useMutation(ADD_COMPANY);
  const [update] = useMutation(UPDATE_COMPANY);
  const [remove] = useMutation(REMOVE_COMPANY);

  const handleModal = useCallback(
    (modalOpen) => () => {
      state.visible = modalOpen;
    },
    [],
  );

  const handleValidate = useCallback(async () => {
    return form
      .validateFields()
      .then((values) => {
        handleSubmit(values);
      })
      .catch((errorInfo) => {
        if (errorInfo.errorFields[0]) {
          form.scrollToField(errorInfo.errorFields[0].name);
        }
      });
  }, [propData, refetch, user]);

  const handleSubmit = useCallback(
    async (values) => {
      window.maskOn();

      const data = {
        ...values,
      };

      if (user) {
        data.user = user.id;
      } else {
        data.user = commonStore.user.id;
      }

      let title = '';

      let result;
      if (propData.id) {
        if (commonStore.user) {
          data.updated = commonStore.user.id;
        }
        result = await update({
          variables: { id: propData.id, data },
        });
        title = `업체가 수정되었습니다.`;
      } else {
        if (commonStore.user) {
          data.created = commonStore.user.id;
        }
        result = await add({ variables: { data } });
        title = `업체가 추가되었습니다.`;
      }

      if (refetch) {
        await refetch();
      }
      form.resetFields();
      window.maskOff();
      window.success({ title });
      handleModal(false)();
    },
    [propData, refetch, user],
  );

  const handleRemove = useCallback(() => {
    window.ask({
      title: '계속 진행하시겠습니까?',
      content: '업체 삭제시 진행중이던 프로그램 삭제 및 복구가 불가 합니다.',
      async onOk() {
        window.maskOn();
        await remove({
          variables: {
            id: propData.id,
          },
        });
        if (refetch) {
          await refetch();
        }
        window.maskOff();
        window.success({ title: '업체 삭제가 완료되었습니다.' });
        handleModal(false)();
      },
    });
  }, [propData, refetch]);

  useEffect(() => {
    if (form) {
      form.resetFields();
    }
  }, [propData, form]);

  return (
    <Container>
      {!propData || !propData.id ? (
        <Button
          className="add-company-btn"
          type="primary"
          icon={<PlusCircleOutlined />}
          // ghost={!state.isAddBtnHover}
          block
          onClick={handleModal(true)}
          onMouseEnter={() => (state.isAddBtnHover = true)}
          onMouseLeave={() => (state.isAddBtnHover = false)}
        >
          내 업체 등록
        </Button>
      ) : (
        <Space align="center">
          <div className="company-name">{propData.name}</div>
          <Button type="primary" size="large" onClick={handleModal(true)} ghost>
            정보수정
          </Button>
        </Space>
      )}
      <CustomModal
        title={
          <Space align="center">
            <div>업체 {propData.id ? '수정' : '등록'}</div>
            <Button type="primary" danger onClick={handleRemove}>
              업체삭제
            </Button>
          </Space>
        }
        visible={state.visible}
        onOk={handleValidate}
        onCancel={handleModal(false)}
        forceRender={true}
        minwidth={800}
      >
        <Wrapper>
          <Form
            form={form}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name={`company_modify${propData.id ? propData.id : ''}`}
            initialValues={{
              name: propData.name || '',
              phone: propData.phone || '',
              memo: propData.memo || '',
            }}
            validateMessages={validateMessages}
            scrollToFirstError
            autoComplete="off"
          >
            <Form.Item name="name" label="업체명" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="phone"
              label="전화번호"
              rules={[
                { required: true, pattern: /^[0-9]{2,3}[0-9]{3,4}[0-9]{4}$/ },
              ]}
            >
              <Input placeholder="- 없이 입력하세요" />
            </Form.Item>

            <Form.Item name="memo" label="상담메모">
              <Input.TextArea rows={5} />
            </Form.Item>
          </Form>
        </Wrapper>
      </CustomModal>
    </Container>
  );
});

export default CompanyModify;
