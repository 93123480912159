import gql from 'graphql-tag';
import { rankFragment } from '@shared/fragments';

export const RANK = gql`
  ${rankFragment}
  query Rank($id: ID) {
    rank(id: $id) {
      ...RankFragment
    }
  }
`;

export const RANKS = gql`
  ${rankFragment}
  query Ranks($page: Int, $limit: Int, $findQuery: JSON) {
    ranks(page: $page, limit: $limit, findQuery: $findQuery) {
      docs {
        ...RankFragment
      }
      totalDocs
      limit
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      pagingCounter
    }
  }
`;

export const RANK_LIST = gql`
  ${rankFragment}
  query RankList($findQuery: JSON) {
    rankList(findQuery: $findQuery) {
      ...RankFragment
    }
  }
`;

export const ADD_RANK = gql`
  ${rankFragment}
  mutation AddRank($data: InputRank) {
    addRank(data: $data) {
      ...RankFragment
    }
  }
`;

export const UPDATE_RANK = gql`
  ${rankFragment}
  mutation UpdateRank($id: ID!, $data: InputRank) {
    updateRank(id: $id, data: $data) {
      ...RankFragment
    }
  }
`;

export const REMOVE_RANK = gql`
  mutation RemoveRank($id: ID!) {
    removeRank(id: $id)
  }
`;
