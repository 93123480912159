/* eslint-disable react-hooks/exhaustive-deps*/
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Layout from '@Common/Layout/MemberLayout';

import Login from '@Common/Login';
import Signup from '@Member/Signup';

import Post from '@Common/Post';
import PostDetail from '@Common/Post/PostDetail';

import Home from './Home';

import QnA from './QnA';
import Mypage from './Mypage';
import Work from './Work';
import BuyWork from './Work/WorkDetail/BuyWork';

const LayoutWrapper = ({ props, Comp }) => (
  <Layout>
    <Comp {...props} />
  </Layout>
);

const Member = (props) => {
  return (
    <Switch>
      <Route exact path={'/signup'} component={Signup} />
      <Route exact path={'/login'} component={Login} />

      <Route
        exact
        path="/notice/:id"
        component={(props) => (
          <LayoutWrapper
            Comp={() => (
              <PostDetail {...props} board="공지사항" title="공지사항" />
            )}
          />
        )}
      />
      <Route
        exact
        path="/notice"
        component={(props) => (
          <LayoutWrapper
            props={props}
            Comp={() => <Post board="공지사항" title="공지사항" />}
          />
        )}
      />

      <Route
        exact
        path="/contact/:id"
        component={(props) => (
          <LayoutWrapper
            Comp={() => (
              <PostDetail {...props} board="고객문의" title="고객문의" />
            )}
          />
        )}
      />
      <Route
        exact
        path="/contact"
        component={(props) => (
          <LayoutWrapper
            props={props}
            Comp={() => <Post board="고객문의" title="고객문의" />}
          />
        )}
      />

      <Route
        exact
        path="/qna"
        component={(props) => <LayoutWrapper props={props} Comp={QnA} />}
      />

      <Route
        exact
        path="/mypage/:id"
        component={(props) => (
          <LayoutWrapper
            props={props}
            Comp={() => <PostDetail board="고객문의" title="고객문의" />}
          />
        )}
      />
      <Route
        exact
        path="/mypage"
        component={(props) => <LayoutWrapper props={props} Comp={Mypage} />}
      />
      <Route
        exact
        path="/work"
        component={(props) => <LayoutWrapper props={props} Comp={Work} />}
      />
      <Route
        exact
        path="/buyot"
        component={(props) => (
          <LayoutWrapper
            props={props}
            Comp={() => <BuyWork metaKey={'product_ot'} />}
          />
        )}
      />
      <Route
        exact
        path="/"
        component={(props) => <LayoutWrapper props={props} Comp={Home} />}
      />

      <Redirect from={'*'} to={'/'} />
    </Switch>
  );
};

export default Member;
