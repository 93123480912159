import gql from 'graphql-tag';
import { companyFragment } from '@shared/fragments';

export const COMPANY = gql`
  ${companyFragment}
  query Company($id: ID!) {
    company(id: $id) {
      ...CompanyFragment
    }
  }
`;

export const COMPANIES = gql`
  ${companyFragment}
  query Companies($findQuery: JSON, $sort: JSON) {
    companies(findQuery: $findQuery, sort: $sort) {
      ...CompanyFragment
    }
  }
`;

export const ADD_COMPANY = gql`
  ${companyFragment}
  mutation AddCompany($data: InputCompany) {
    addCompany(data: $data) {
      ...CompanyFragment
    }
  }
`;

export const UPDATE_COMPANY = gql`
  ${companyFragment}
  mutation UpdateCompany($id: ID!, $data: InputCompany) {
    updateCompany(id: $id, data: $data) {
      ...CompanyFragment
    }
  }
`;

export const REMOVE_COMPANY = gql`
  mutation RemoveCompany($id: ID!) {
    removeCompany(id: $id)
  }
`;
