/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { Form, Input, Row, Col, Select, Tag } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import { toJS } from 'mobx';
import styled from 'styled-components';

import useStore from '@stores/useStore';
import { ADD_POST, UPDATE_POST } from '@shared/queries/PostQueries';
import { validateMessages } from '@utils/customFormValidator';

import CustomModal from '@Common/CustomModal';
import Editor from '@Common/Editor';

const { Option } = Select;

const Wrapper = styled.div`
  width: 100%;

  .ant-input-group-addon {
    padding: 0;
  }
`;

const QnAModify = observer(
  ({ modifyOpen, refetch, propData = {}, handleModify }) => {
    const [form] = Form.useForm();

    const { commonStore } = useStore();
    const state = useLocalStore(() => ({
      preSave: null,
      setPreSave(preSave) {
        state.preSave = preSave;
      },
    }));

    const [add] = useMutation(ADD_POST);
    const [update] = useMutation(UPDATE_POST);

    const handleClose = useCallback(() => {
      form.resetFields();
      handleModify(false)();
    }, []);

    const handleValidate = useCallback(async () => {
      const value = await state.preSave(true);
      const val = value.replace(/(<p>)|(<br>)|(<\/p>)|(<br\/>)|\s/g, '');
      const content = val.length ? value : '';
      form.setFieldsValue({
        content,
      });
      return form
        .validateFields()
        .then((values) => {
          handleSubmit(values);
        })
        .catch((errorInfo) => {
          if (errorInfo.errorFields[0]) {
            form.scrollToField(errorInfo.errorFields[0].name);
          }
        });
    }, [propData, state.preSave]);

    const handleSubmit = useCallback(
      async (values) => {
        window.maskOn();

        const [content, images] = await state.preSave();

        const data = {
          ...values,
          content,
          images,
          board: 'qna',
        };

        let title = '';

        let result;
        if (propData.id) {
          if (commonStore.user) {
            data.updated = commonStore.user.id;
          }
          result = await update({
            variables: { id: propData.id, data },
          });
          title = `${result.data.updatePost.title} 사용방법이 수정되었습니다.`;
        } else {
          if (commonStore.user) {
            data.created = commonStore.user.id;
          }
          result = await add({ variables: { data } });
          title = `${result.data.addPost.title} 사용방법이 추가되었습니다.`;
        }

        await refetch();
        form.resetFields();
        window.maskOff();
        window.success({ title });
        handleClose();
      },
      [propData],
    );

    useEffect(() => {
      if (form) {
        form.resetFields();
      }
    }, [propData, form]);

    return (
      <CustomModal
        title={`사용방법 ${propData.id ? '수정' : '등록'}`}
        visible={modifyOpen}
        onOk={handleValidate}
        onCancel={handleClose}
        forceRender={true}
        minwidth={800}
      >
        <Wrapper>
          <Form
            form={form}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="post_modify"
            initialValues={{
              title: propData.title || '',
              content: propData.content || '',
            }}
            validateMessages={validateMessages}
            scrollToFirstError
          >
            <Form.Item name="title" label="제목" rules={[{ required: true }]}>
              <Input />
            </Form.Item>

            <Form.Item shouldUpdate noStyle>
              {() => (
                <Form.Item
                  name="content"
                  label="답변"
                  rules={[{ required: true }]}
                >
                  <Editor
                    setPreSave={state.setPreSave}
                    preSave={state.preSave}
                    uploadUrl={`post/qna/content/${moment().format('YYYY-MM')}`}
                    content={propData.content}
                    images={propData.images ? propData.images : []}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Form>
          {propData.id && commonStore.user && commonStore.user.isAdmin ? (
            <>
              <Row justify="end">
                <Col>
                  입력자:{' '}
                  <Tag>
                    {propData.created
                      ? propData.created.name
                      : propData.author
                      ? propData.author.name
                      : ''}
                  </Tag>
                  입력일시:{' '}
                  <Tag>
                    {moment(propData.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                  </Tag>
                </Col>
              </Row>
              <Row justify="end">
                <Col>
                  수정자:{' '}
                  <Tag>{propData.updated ? propData.updated.name : ''}</Tag>
                  수정일시:{' '}
                  <Tag>
                    {moment(propData.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
                  </Tag>
                </Col>
              </Row>
            </>
          ) : null}
        </Wrapper>
      </CustomModal>
    );
  },
);

export default QnAModify;
