/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout, Drawer, Grid } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import { toJS } from 'mobx';
import Cookies from 'js-cookie';
import { useSubscription } from '@apollo/react-hooks';

import useStore from '@stores/useStore';
import { NEW_USER } from '@shared/queries/UserQueries';
import memberMenuData from '@Member/memberMenuData';

import MemberDesktopLayout from './MemberDesktopLayout';
import MemberMobileLayout from './MemberMobileLayout';

const headerKeys = ['home', 'notice', 'contact', 'mypage'];

const { useBreakpoint } = Grid;

const DrawerLayout = observer((props) => {
  const router = useLocation();
  const screen = useBreakpoint();

  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    headerCurrent: 'home',
    collapsed: false,
    routes: [],
    get routesVal() {
      return toJS(state.routes);
    },
  }));

  const userSubscription = useSubscription(NEW_USER, {
    variables: { id: commonStore.user ? commonStore.user.id : 'null-user' },
  });

  const toggle = useCallback(() => {
    state.collapsed = !state.collapsed;
  }, []);

  const handleClickHeaderMenu = useCallback((e) => {
    state.headerCurrent = e.key;
    commonStore.menuOpenKeys = [e.key];
  }, []);

  useEffect(() => {
    const menus = toJS(memberMenuData);
    const pathnameArr = router.pathname.split('/');

    if (pathnameArr[1]) {
      const routes = [];
      const fIdx = menus.findIndex((item) => item.key === pathnameArr[1]);
      if (fIdx !== -1) {
        if (menus[fIdx].children) {
          routes.push({ label: menus[fIdx].label, key: menus[fIdx].key });

          const f = menus[fIdx].children.find((r) => {
            const regExp = new RegExp(`${r.link}$`, 'i');
            return regExp.test(router.pathname);
          });
          routes.push(f);

          const menuOpenKeys = toJS(commonStore.menuOpenKeys);
          const fKey = menuOpenKeys.find((key) => key === pathnameArr[1]);
          if (!fKey) {
            menuOpenKeys.push(pathnameArr[1]);
          }
          commonStore.menuSelectedKeys = [router.pathname];
          commonStore.menuOpenKeys = menuOpenKeys;

          const fHeaderKey = headerKeys.find((item) => {
            const regExp = new RegExp(item, 'i');
            return regExp.test(router.pathname);
          });
          state.headerCurrent = fHeaderKey || menus[0].key;
        } else {
          routes.push({ label: menus[fIdx].label, link: menus[fIdx].link });
          commonStore.menuSelectedKeys = [menus[fIdx].key];
          const fKey = headerKeys.find((item) => item === menus[fIdx].key);
          state.headerCurrent = fKey || menus[0].key;
        }

        routes.unshift({
          label: menus[0].label,
          link: menus[0].link,
        });

        state.routes = routes;
      }
    } else {
      state.routes = [{ label: menus[0].label, link: menus[0].link }];
      commonStore.menuSelectedKeys = [menus[0].key];
      state.headerCurrent = menus[0].key;
    }
  }, [router]);

  useEffect(() => {
    if (userSubscription.data && userSubscription.data.newUser) {
      commonStore.user.point = userSubscription.data.newUser.point;
    }
  }, [userSubscription.data]);

  return (
    <>
      {(!Cookies.get('deviceMode') && screen.lg) ||
      (Cookies.get('deviceMode') && Cookies.get('deviceMode') !== 'mobile') ? (
        <MemberDesktopLayout
          headerCurrent={state.headerCurrent}
          collapsed={state.collapsed}
          routes={state.routesVal}
          toggle={toggle}
          handleClickHeaderMenu={handleClickHeaderMenu}
        >
          {props.children}
        </MemberDesktopLayout>
      ) : (
        <MemberMobileLayout
          headerCurrent={state.headerCurrent}
          collapsed={state.collapsed}
          routes={state.routesVal}
          toggle={toggle}
          handleClickHeaderMenu={handleClickHeaderMenu}
        >
          {props.children}
        </MemberMobileLayout>
      )}
    </>
  );
});

export default DrawerLayout;
