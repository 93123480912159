/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { toJS } from 'mobx';
import moment from 'moment';

import { NOTICE_DASHBOARD } from '@shared/queries/DashboardQueries';

const Wrapper = styled.div`
  width: 100%;
`;

const NoticeDashboard = observer(() => {
  const state = useLocalStore(() => ({
    data: [],
    get dataVal() {
      return toJS(this.data);
    },
  }));
  const queryRes = useQuery(NOTICE_DASHBOARD);

  useEffect(() => {
    if (queryRes.data && queryRes.data.noticeDashboard) {
      state.data = queryRes.data.noticeDashboard;
    }
  }, [queryRes.data]);

  return (
    <Wrapper>
      <Card
        title={<Link to={{ pathname: `/notice` }}>공지사항 관리</Link>}
        extra={<Link to={{ pathname: `/notice` }}>자세히보기 {'>'}</Link>}
      >
        {state.dataVal.map((item) => (
          <Link to={{ pathname: `/notice/${item.id}` }} key={item.id}>
            <Row gutter={40} style={{ marginBottom: 10 }}>
              <Col>{moment(item.postDate).format('YYYY-MM-DD')}</Col>
              <Col>{item.title}</Col>
            </Row>
          </Link>
        ))}
      </Card>
    </Wrapper>
  );
});

export default NoticeDashboard;
