/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { Row, Col, Button, Affix, Input, Card } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { toJS } from 'mobx';

import { META, SAVE_META } from '@shared/queries';

const Wrapper = styled.div`
  width: 100%;
`;

const BanWords = observer(() => {
  const state = useLocalStore(() => ({
    banwordsData: {
      description: '프로그램 전체 금기어',
    },
    isActivated: true,
  }));

  const client = useApolloClient();
  const [save] = useMutation(SAVE_META);

  const fetchData = useCallback(async () => {
    const result = await client.query({
      query: META,
      variables: { key: 'program_banwords' },
    });
    if (result.data && result.data.meta) {
      state.banwordsData = result.data.meta.value;
    }
  }, []);

  const handleChangeInput = useCallback((e) => {
    const banwordsData = toJS(state.banwordsData);
    banwordsData.banwords = e.target.value;
    state.banwordsData = banwordsData;
  }, []);

  const handleSubmit = useCallback(async () => {
    window.maskOn();
    const value = toJS(state.banwordsData);
    value.isActivated = state.isActivated;

    await save({
      variables: {
        key: 'program_banwords',
        data: {
          key: 'program_banwords',
          value,
        },
      },
    });

    await fetchData();
    window.maskOff();
    window.success({ title: `프로그램 금기어 설정 저장이 완료되었습니다.` });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Wrapper>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={20}>
          <Card title="설정" size="small">
            <h4>금기어 필터링</h4>
            <Input.TextArea
              placeholder="해시태그,댓글,태그,게시물"
              value={state.banwordsData.banwords}
              rows={10}
              onChange={handleChangeInput}
            />
          </Card>
        </Col>
        <Col xs={24} lg={4}>
          <Affix offsetTop={80}>
            <Button type="primary" size="large" block onClick={handleSubmit}>
              저장
            </Button>
          </Affix>
        </Col>
      </Row>
    </Wrapper>
  );
});

export default BanWords;
