/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { Collapse, Row, Col, Space } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { observer, useLocalStore } from 'mobx-react';
import { toJS } from 'mobx';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/react-hooks';

import useStore from '@stores/useStore';
import { POST_LIST } from '@shared/queries/PostQueries';

const { Panel } = Collapse;

const Wrapper = styled.div`
  width: 100%;
`;

const QnA = observer(() => {
  const { commonStore } = useStore();

  const state = useLocalStore(() => ({
    list: [],
  }));

  const client = useApolloClient();

  const fetchData = useCallback(async (findQuery = {}) => {
    window.maskOn();

    const result = await client.query({
      query: POST_LIST,
      variables: {
        findQuery: {
          board: 'qna',
        },
      },
    });

    if (result.data && result.data.postList) {
      state.list = result.data.postList;
    }
    window.maskOff();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Wrapper>
      <Row gutter={[20, 20]} justify="center">
        <Col>
          <h2>사용방법</h2>
        </Col>
      </Row>

      <Collapse defaultActiveKey={[]} expandIconPosition="right">
        {toJS(state.list).map((item, idx) => (
          <Panel
            header={
              <Space>
                <QuestionCircleOutlined />
                <span>{item.title}</span>
              </Space>
            }
            key={`${idx}`}
          >
            <div dangerouslySetInnerHTML={{ __html: item.content }} />
          </Panel>
        ))}
      </Collapse>
    </Wrapper>
  );
});

export default QnA;
