/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback } from 'react';
import { Tabs } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';

import Slot from './Slot';
import UsePP from './UsePP';
import AutocompleteTop from './AutocompleteTop';

const { TabPane } = Tabs;

const Wrapper = styled.div`
  width: 100%;
`;

const AdminSetting = observer(() => {
  const state = useLocalStore(() => ({
    tab: '1',
  }));

  const handleChangeTab = useCallback((key) => {
    state.tab = key;
  }, []);

  return (
    <Wrapper>
      <Tabs activeKey={state.tab} onChange={handleChangeTab} type="card">
        <TabPane tab="도배형 슬롯" key="1">
          <Slot rootTab={state.tab} />
        </TabPane>
        <TabPane tab="인기게시물 사용" key="2">
          <UsePP rootTab={state.tab} />
        </TabPane>
        <TabPane tab="자동완성 상위노출" key="3">
          <AutocompleteTop rootTab={state.tab} />
        </TabPane>
      </Tabs>
    </Wrapper>
  );
});

export default AdminSetting;
