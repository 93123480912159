/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import {
  Row,
  Col,
  Input,
  InputNumber,
  Space,
  Card,
  Radio,
  Divider,
  Button,
} from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import uniqid from 'uniqid';

import { numberWithCommas } from '@utils/common';

const Wrapper = styled.div`
  width: 100%;
`;

const ProductConfigPP = observer(({ productValue, setProductValue }) => {
  const state = useLocalStore(() => ({}));

  const handleChangeInput = useCallback(
    (type, key, uid) => (e) => {
      if (uid) {
        if (!productValue[type] || !Array.isArray(productValue[type])) {
          productValue[type] = [];
        }
        const fIdx = productValue[type].findIndex((item) => item.uid === uid);
        if (fIdx !== -1) {
          productValue[type][fIdx][key] = e.target.value;
        }
      } else {
        productValue[type] = e.target.value;
      }
      setProductValue(productValue);
    },
    [productValue],
  );

  const handleChangeNumber = useCallback(
    (type, key) => (value) => {
      if (type === 'oaIdDiscount') {
        if (!productValue[type]) {
          productValue[type] = {};
        }
        productValue[type][key] = value;
      } else {
        productValue[type] = value;
      }
      setProductValue(productValue);
    },
    [productValue],
  );

  const handleChangeNumberArray = useCallback(
    (type, idx) => (value) => {
      if (!productValue[type]) {
        productValue[type] = [];
      }
      productValue[type][idx] = value;
      setProductValue(productValue);
    },
    [productValue],
  );

  const handleChangeRadio = useCallback(
    (type, key) => (e) => {
      if (type === 'oaIdDiscount') {
        if (!productValue[type]) {
          productValue[type] = {};
        }
        productValue[type][key] = e.target.value;
      } else {
        productValue[type] = e.target.value;
      }
      setProductValue(productValue);
    },
    [productValue],
  );

  const handleChangeNumberArr = useCallback(
    (type, key, uid) => (value) => {
      if (!productValue[type] || !Array.isArray(productValue[type])) {
        productValue[type] = [];
      }
      const fIdx = productValue[type].findIndex((item) => item.uid === uid);
      if (fIdx !== -1) {
        productValue[type][fIdx][key] = value;
      }
      setProductValue(productValue);
    },
    [productValue],
  );

  const handleAddPriceData = useCallback(
    (type) => (e) => {
      if (!productValue[type] || !Array.isArray(productValue[type])) {
        productValue[type] = [];
      }
      productValue[type].push({
        uid: uniqid.time(),
      });
      setProductValue(productValue);
    },
    [productValue],
  );

  const handleRemovePriceData = useCallback(
    (type, uid) => (e) => {
      if (!productValue[type]) {
        productValue[type] = [];
      }
      const fIdx = productValue[type].findIndex((item) => item.uid === uid);
      if (fIdx !== -1) {
        productValue[type].splice(fIdx, 1);
      }
      setProductValue(productValue);
    },
    [productValue],
  );

  return (
    <Wrapper>
      <Card title="가격설정" size="small">
        <Row gutter={[20, 20]}>
          <Col xs={24} lg={10}>
            <h3>[건금액]</h3>
            <Space size={30}>
              {/* <Input
                defaultValue={productValue.caseName}
                onBlur={handleChangeInput('caseName')}
              /> */}
              <Space>
                <InputNumber
                  value={productValue.caseCnt}
                  onChange={handleChangeNumber('caseCnt')}
                  style={{ width: 80 }}
                />
                <span>회</span>
              </Space>
              <Space>
                <InputNumber
                  value={productValue.casePrice}
                  onChange={handleChangeNumber('casePrice')}
                  formatter={(value) => numberWithCommas(value)}
                  parser={(value) => value.replace(/[^0-9]/g, '')}
                  style={{ width: 140 }}
                />
                <span>원</span>
              </Space>
            </Space>
          </Col>
          <Col xs={24} lg={10}>
            <h3>[월금액]</h3>
            <Row gutter={[10, 10]}>
              <Col xs={24}>
                <Space>
                  <InputNumber
                    value={productValue.monthDays}
                    onChange={handleChangeNumber('monthDays')}
                    style={{ width: 80 }}
                  />
                  <span>일</span>
                </Space>
              </Col>
              {Array.isArray(productValue.ppMonthPrice) &&
                productValue.ppMonthPrice.map((item) => (
                  <Col xs={24} key={item.uid}>
                    <Space size={30}>
                      {/* <Input
                        defaultValue={item.name}
                        onBlur={handleChangeInput(
                          'ppMonthPrice',
                          'name',
                          item.uid,
                        )}
                      /> */}
                      <Space>
                        <InputNumber
                          value={item.cnt}
                          onChange={handleChangeNumberArr(
                            'ppMonthPrice',
                            'cnt',
                            item.uid,
                          )}
                          style={{ width: 80 }}
                        />
                        <span>회</span>
                      </Space>
                      <Space>
                        <InputNumber
                          value={item.price}
                          onChange={handleChangeNumberArr(
                            'ppMonthPrice',
                            'price',
                            item.uid,
                          )}
                          formatter={(value) => numberWithCommas(value)}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: 140 }}
                        />
                        <span>원</span>
                      </Space>
                      <Button
                        shape="circle"
                        icon={<MinusOutlined />}
                        size="small"
                        danger
                        onClick={handleRemovePriceData(
                          'ppMonthPrice',
                          item.uid,
                        )}
                      />
                    </Space>
                  </Col>
                ))}
            </Row>
            <Button
              icon={<PlusOutlined />}
              block
              onClick={handleAddPriceData('ppMonthPrice')}
            >
              월금액 추가
            </Button>
          </Col>
          <Col xs={24}>
            <h3>[최적화 아이디사용]</h3>
            <Row gutter={[20, 20]}>
              <Col xs={24} lg={8}>
                <h4>순위설정</h4>
                <Row gutter={[10, 10]}>
                  {productValue.oaIdRanks &&
                    productValue.oaIdRanks.map((item) => (
                      <Col xs={24} key={item.uid}>
                        <Space size={15}>
                          <Space>
                            <InputNumber
                              value={item.rank1}
                              onChange={handleChangeNumberArr(
                                'oaIdRanks',
                                'rank1',
                                item.uid,
                              )}
                              style={{ width: 70 }}
                            />
                            <span>위</span>
                          </Space>
                          <span>~</span>
                          <Space>
                            <InputNumber
                              value={item.rank2}
                              onChange={handleChangeNumberArr(
                                'oaIdRanks',
                                'rank2',
                                item.uid,
                              )}
                              style={{ width: 70 }}
                            />
                            <span>위</span>
                          </Space>

                          <Button
                            shape="circle"
                            icon={<MinusOutlined />}
                            size="small"
                            danger
                            onClick={handleRemovePriceData(
                              'oaIdRanks',
                              item.uid,
                            )}
                          />
                        </Space>
                      </Col>
                    ))}
                </Row>
                <Button
                  icon={<PlusOutlined />}
                  block
                  onClick={handleAddPriceData('oaIdRanks')}
                >
                  순위 추가
                </Button>
              </Col>
              <Col xs={24} lg={10}>
                <h4>금액설정</h4>
                <Row gutter={[10, 10]}>
                  {productValue.oaIdPrice &&
                    productValue.oaIdPrice.map((item) => (
                      <Col xs={24} key={item.uid}>
                        <Space size={15}>
                          <Space>
                            <InputNumber
                              value={item.viewCnt1}
                              onChange={handleChangeNumberArr(
                                'oaIdPrice',
                                'viewCnt1',
                                item.uid,
                              )}
                              style={{ width: 100 }}
                            />
                            <span>건</span>
                          </Space>
                          <span>~</span>
                          <Space>
                            <InputNumber
                              value={item.viewCnt2}
                              onChange={handleChangeNumberArr(
                                'oaIdPrice',
                                'viewCnt2',
                                item.uid,
                              )}
                              style={{ width: 100 }}
                            />
                            <span>건</span>
                          </Space>
                          <Space>
                            <InputNumber
                              value={item.price}
                              onChange={handleChangeNumberArr(
                                'oaIdPrice',
                                'price',
                                item.uid,
                              )}
                              formatter={(value) => numberWithCommas(value)}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                              style={{ width: 140 }}
                            />
                            <span>원</span>
                          </Space>

                          <Button
                            shape="circle"
                            icon={<MinusOutlined />}
                            size="small"
                            danger
                            onClick={handleRemovePriceData(
                              'oaIdPrice',
                              item.uid,
                            )}
                          />
                        </Space>
                      </Col>
                    ))}
                </Row>
                <Button
                  icon={<PlusOutlined />}
                  block
                  onClick={handleAddPriceData('oaIdPrice')}
                >
                  최적화 아이디사용 금액 추가
                </Button>
              </Col>
              <Col xs={24} lg={6}>
                <h4>최대 노출 개수</h4>
                <Space>
                  <InputNumber
                    value={productValue.oaIdVisibleCnt}
                    onChange={handleChangeNumber('oaIdVisibleCnt')}
                    style={{ width: 70 }}
                  />
                  <div>개</div>
                </Space>
                <Divider />
                {productValue.oaIdDiscount && (
                  <>
                    <h4>할인설정</h4>
                    <Space direction="vertical">
                      <Space>
                        <div>상위노출 개수 2개부터</div>
                        <InputNumber
                          value={productValue.oaIdDiscount.cnt}
                          onChange={handleChangeNumber('oaIdDiscount', 'cnt')}
                          style={{ width: 70 }}
                        />
                        <div>개당</div>
                      </Space>
                      <Space>
                        <Radio.Group
                          value={productValue.oaIdDiscount.discountType}
                          onChange={handleChangeRadio(
                            'oaIdDiscount',
                            'discountType',
                          )}
                        >
                          <Radio value="원">원</Radio>
                          <Radio value="%">%</Radio>
                        </Radio.Group>
                      </Space>
                      <Space>
                        <InputNumber
                          value={productValue.oaIdDiscount.value}
                          onChange={handleChangeNumber('oaIdDiscount', 'value')}
                          formatter={(value) => numberWithCommas(value)}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: 140 }}
                        />
                        <div>{productValue.oaIdDiscount.discountType}</div>
                        <div>할인</div>
                      </Space>
                    </Space>
                  </>
                )}
                <Divider />
                <h4>프로그램 추가옵션</h4>
                <div>직접입력댓글</div>
                <Space>
                  <InputNumber
                    value={productValue.directCommentPrice}
                    onChange={handleChangeNumber('directCommentPrice')}
                    formatter={(value) => numberWithCommas(value)}
                    parser={(value) => value.replace(/[^0-9]/g, '')}
                    style={{ width: 140 }}
                  />
                  <span>원</span>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Card title="프로그램 환경설정" size="small" style={{ marginTop: 20 }}>
        <Row gutter={[20, 10]} align="middle">
          <Col xs={24} lg={4}>
            <h3 style={{ margin: 0 }}>[기본환경]</h3>
          </Col>
          <Col xs={24} lg={20}>
            <Space size={40} style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div>
                <h4>순위 카운팅 일수</h4>
                <InputNumber
                  value={productValue.rateCountDayCnt}
                  onChange={handleChangeNumber('rateCountDayCnt')}
                  style={{ width: 80 }}
                />
              </div>
              <div>
                <h4>대표 해시태그 트래픽</h4>
                <InputNumber
                  value={productValue.mainHashTagTraffic}
                  onChange={handleChangeNumber('mainHashTagTraffic')}
                  style={{ width: 80 }}
                />
                <span> % </span>
              </div>
              <div>
                <h4>부 해시태그 트래픽</h4>
                <Space size={20}>
                  <div>
                    <InputNumber
                      value={
                        productValue.subHashTagTraffic &&
                        productValue.subHashTagTraffic[0]
                          ? productValue.subHashTagTraffic[0]
                          : ''
                      }
                      onChange={handleChangeNumberArray('subHashTagTraffic', 0)}
                      style={{ width: 80 }}
                    />
                    <span> % </span>
                  </div>
                  <div>
                    <InputNumber
                      value={
                        productValue.subHashTagTraffic &&
                        productValue.subHashTagTraffic[1]
                          ? productValue.subHashTagTraffic[1]
                          : ''
                      }
                      onChange={handleChangeNumberArray('subHashTagTraffic', 1)}
                      style={{ width: 80 }}
                    />
                    <span> % </span>
                  </div>
                  <div>
                    <InputNumber
                      value={
                        productValue.subHashTagTraffic &&
                        productValue.subHashTagTraffic[2]
                          ? productValue.subHashTagTraffic[2]
                          : ''
                      }
                      onChange={handleChangeNumberArray('subHashTagTraffic', 2)}
                      style={{ width: 80 }}
                    />
                    <span> % </span>
                  </div>
                  <div>
                    <InputNumber
                      value={
                        productValue.subHashTagTraffic &&
                        productValue.subHashTagTraffic[3]
                          ? productValue.subHashTagTraffic[3]
                          : ''
                      }
                      onChange={handleChangeNumberArray('subHashTagTraffic', 3)}
                      style={{ width: 80 }}
                    />
                    <span> % </span>
                  </div>
                </Space>
              </div>
              <div>
                <h4>내부체류시간</h4>
                <InputNumber
                  value={productValue.internalStayTime}
                  onChange={handleChangeNumber('internalStayTime')}
                  style={{ width: 80 }}
                />
              </div>
            </Space>
          </Col>
        </Row>

        <Divider />

        <Row gutter={[20, 10]} align="middle">
          <Col xs={24} lg={4}>
            <h3 style={{ margin: 0 }}>[1 DAYS]</h3>
          </Col>
          <Col xs={24} lg={20}>
            <Space size={40} style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div>
                <h4>트래픽 횟수</h4>
                <InputNumber
                  value={productValue.trafficCnt}
                  onChange={handleChangeNumber('trafficCnt')}
                  style={{ width: 80 }}
                />
              </div>
              <div>
                <h4>좋아요</h4>
                <InputNumber
                  value={productValue.likeCnt}
                  onChange={handleChangeNumber('likeCnt')}
                  style={{ width: 80 }}
                />
              </div>
              <div>
                <h4>댓글</h4>
                <InputNumber
                  value={productValue.commentCnt}
                  onChange={handleChangeNumber('commentCnt')}
                  style={{ width: 80 }}
                />
              </div>
              <div>
                <h4>팔로우</h4>
                <InputNumber
                  value={productValue.followCnt}
                  onChange={handleChangeNumber('followCnt')}
                  style={{ width: 80 }}
                />
              </div>
              <div>
                <h4>저장</h4>
                <InputNumber
                  value={productValue.saveCnt}
                  onChange={handleChangeNumber('saveCnt')}
                  style={{ width: 80 }}
                />
              </div>
              <div>
                <h4>공유</h4>
                <InputNumber
                  value={productValue.shareCnt}
                  onChange={handleChangeNumber('shareCnt')}
                  style={{ width: 80 }}
                />
              </div>
              <div>
                <h4>피드개수</h4>
                <InputNumber
                  value={productValue.feedCnt}
                  onChange={handleChangeNumber('feedCnt')}
                  style={{ width: 80 }}
                />
              </div>
            </Space>
          </Col>
        </Row>

        <Divider />

        <Row gutter={[20, 10]} align="middle">
          <Col xs={24} lg={4}>
            <h3 style={{ margin: 0 }}>[월]</h3>
          </Col>
          <Col xs={24} lg={20}>
            <Space size={60} style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div>
                <Space size={20}>
                  <div>
                    <h4>좋아요 시간</h4>
                    <InputNumber
                      value={productValue.monthLikeHours}
                      onChange={handleChangeNumber('monthLikeHours')}
                      style={{ width: 80 }}
                    />
                  </div>
                  <div>
                    <h4>개수</h4>
                    <InputNumber
                      value={productValue.monthLikeCnt}
                      onChange={handleChangeNumber('monthLikeCnt')}
                      style={{ width: 80 }}
                    />
                  </div>
                </Space>
              </div>
              <div>
                <Space size={20}>
                  <div>
                    <h4>댓글 시간</h4>
                    <InputNumber
                      value={productValue.monthCommentHours}
                      onChange={handleChangeNumber('monthCommentHours')}
                      style={{ width: 80 }}
                    />
                  </div>
                  <div>
                    <h4>개수</h4>
                    <InputNumber
                      value={productValue.monthCommentCnt}
                      onChange={handleChangeNumber('monthCommentCnt')}
                      style={{ width: 80 }}
                    />
                  </div>
                </Space>
              </div>
            </Space>
          </Col>
        </Row>
      </Card>
    </Wrapper>
  );
});

export default ProductConfigPP;
