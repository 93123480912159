/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import {
  Row,
  Col,
  Input,
  InputNumber,
  Select,
  Button,
  Divider,
  Form,
  Grid,
  Space,
  Affix,
} from 'antd';
import styled from 'styled-components';
import { observer, useLocalStore } from 'mobx-react';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { toJS } from 'mobx';

import { METAS_REGEXP, SAVE_META } from '@shared/queries';
import { ROLES } from '@shared/constants';
import { validateMessages } from '@utils/customFormValidator';

import Description from '@Common/Description';

const { Option } = Select;
const { TextArea } = Input;
const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  width: 100%;

  .ant-form-item-label {
    font-weight: bold;
  }
`;

const MemberConfig = observer(() => {
  const [form] = Form.useForm();
  const screens = useBreakpoint();

  const state = useLocalStore(() => ({}));

  const client = useApolloClient();
  const [save] = useMutation(SAVE_META);

  const fetchData = useCallback(async () => {
    const result = await client.query({
      query: METAS_REGEXP,
      variables: { key: '^member_' },
    });

    if (result.data && result.data.metasRegexp) {
      for (const meta of result.data.metasRegexp) {
        const key = meta.key.replace('member_', '');
        form.setFieldsValue({
          [key]: meta.value,
        });
      }
    }
  }, []);

  const handleSubmit = useCallback(async (values) => {
    window.maskOn();

    for (const key in values) {
      await save({
        variables: {
          key: `member_${key}`,
          data: {
            key: `member_${key}`,
            value: values[key],
          },
        },
      });
    }

    await fetchData();
    window.maskOff();
    window.success({ title: '회원가입 설정 저장이 완료되었습니다. ' });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Wrapper>
      <Form
        form={form}
        name="member_config_form"
        colon={false}
        labelAlign="left"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 10 }}
        layout={screens.lg ? 'horizontal' : 'vertical'}
        initialValues={{
          def_role: 1,
          leave_remove: 30,
          ban_word: '',
          terms: '',
          privacy: '',
        }}
        validateMessages={validateMessages}
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={[32, 16]}>
          <Col xs={24} lg={20}>
            <Form.Item label="회원가입 시 권한" name="def_role">
              <Select>
                {ROLES.map((item) => (
                  <Option value={item.value} key={`role_${item.value}`}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Divider />

            <Form.Item label="회원탈퇴 후 삭제일">
              <Space size={10} align="baseline">
                <Form.Item name="leave_remove">
                  <InputNumber />
                </Form.Item>
                <span>일 후 자동 삭제</span>
              </Space>
            </Form.Item>

            <Divider />

            <Description text="회원아이디, 이름으로 사용할 수 없는 단어를 입력합니다." />
            <Description text="단어와 단어 사이는 , 로 구분합니다." />
            <Form.Item label="아이디,닉네임 필터링" name="ban_word">
              <TextArea rows={5} />
            </Form.Item>

            <Divider />

            <Form.Item label="회원가입약관" name="terms">
              <TextArea rows={5} />
            </Form.Item>

            <Divider />

            <Form.Item label="개인정보처리방침" name="privacy">
              <TextArea rows={5} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={4}>
            <Affix offsetTop={80}>
              <Form.Item noStyle>
                <Button type="primary" size="large" htmlType="submit" block>
                  저장
                </Button>
              </Form.Item>
            </Affix>
          </Col>
        </Row>
      </Form>
    </Wrapper>
  );
}, []);

export default MemberConfig;
