/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { Form, Input, Row, Col, Button } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import moment from 'moment';
import { toJS } from 'mobx';
import styled from 'styled-components';

import { UPDATE_USER } from '@shared/queries/UserQueries';
import useStore from '@stores/useStore';
import { validateMessages } from '@utils/customFormValidator';

const Wrapper = styled.div`
  .ant-input-group-addon {
    padding: 0;
    margin: 0;
  }

  iframe {
    height: auto !important;
  }
`;

const CompanyInfo = observer(({ propData = {}, refetch }) => {
  const [form] = Form.useForm();
  const { commonStore } = useStore();

  const state = useLocalStore(() => ({}));

  const [update] = useMutation(UPDATE_USER);
  const client = useApolloClient();

  const handleSubmit = useCallback(
    async (values) => {
      window.maskOn();

      const data = {
        ...values,
      };

      data.updated = commonStore.user.id;
      await update({
        variables: { id: propData.id, data },
      });
      window.success({ title: `${propData.name} 업체정보가 수정되었습니다.` });
      if (refetch) {
        await refetch();
      }
      form.resetFields();
      window.maskOff();
    },
    [propData, refetch],
  );

  useEffect(() => {
    if (form) {
      form.resetFields();
    }
  }, [propData, form]);

  return (
    <Wrapper>
      <Form
        form={form}
        layout="vertical"
        name="company_info_form"
        initialValues={{
          companyName: propData.companyName,
          companyTel: propData.companyTel,
          owner: propData.owner,
          businessNum: propData.businessNum,
        }}
        validateMessages={validateMessages}
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Form.Item label="업체명" name="companyName">
              <Input size="large" />
            </Form.Item>
            <Form.Item label="업체연락처" name="companyTel">
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label="대표자명" name="owner">
              <Input size="large" />
            </Form.Item>
            <Form.Item label="사업자번호" name="businessNum">
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" size="large" htmlType="submit" block>
            저장
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  );
});

export default CompanyInfo;
