import gql from 'graphql-tag';
import { commentFragment } from '@shared/fragments';

export const COMMENTS = gql`
  ${commentFragment}
  query Comments($page: Int, $limit: Int, $findQuery: JSON) {
    comments(page: $page, limit: $limit, findQuery: $findQuery) {
      docs {
        ...CommentFragment
      }
      totalDocs
      limit
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      pagingCounter
    }
  }
`;

export const COMMENT = gql`
  ${commentFragment}
  query Comment($id: ID) {
    comment(id: $id) {
      ...CommentFragment
    }
  }
`;

export const ADD_COMMENT = gql`
  ${commentFragment}
  mutation AddComment($data: InputComment) {
    addComment(data: $data) {
      ...CommentFragment
    }
  }
`;

export const UPDATE_COMMENT = gql`
  ${commentFragment}
  mutation UpdateComment($id: ID!, $data: InputComment) {
    updateComment(id: $id, data: $data) {
      ...CommentFragment
    }
  }
`;

export const REMOVE_COMMENT = gql`
  mutation RemoveComment($id: ID!) {
    removeComment(id: $id)
  }
`;

export const MY_COMMENT_CNT = gql`
  query MyCommentCnt($created: ID) {
    myCommentCnt(created: $created)
  }
`;
