/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  Space,
  Button,
  Select,
  Input,
  Row,
  Col,
  Tag,
  notification,
  DatePicker,
  Pagination,
} from 'antd';
import { EditOutlined, MinusOutlined } from '@ant-design/icons';
import { toJS } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import moment from 'moment';
import styled from 'styled-components';

import useStore from '@stores/useStore';
import {
  POSTS,
  POST_LIST,
  ADD_POST,
  UPDATE_POST,
  REMOVE_POST,
} from '@shared/queries/PostQueries';

import CustomModal from '@Common/CustomModal';
import PageTitle from '@Common/PageTitle';

import PostModify from './PostModify';
import PostListTable from './PostListTable';

const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;

const Wrapper = styled.div`
  width: 100%;
  .add-btn {
    z-index: 1;
    float: right;
  }
`;

const DetailView = styled.div`
  width: 100%;
  overflow: auto;
  margin-top: 1rem;
`;

const PostList = observer(({ board, title, isAdmin, userId }) => {
  const { commonStore } = useStore();

  const state = useLocalStore(() => ({
    viewOpen: false,
    modifyOpen: false,
    selectedData: {},
    selectedDetail: null,

    searchType: 1,
    searchRange: [],
    get searchRangeVal() {
      return toJS(this.searchRange);
    },

    noticeCnt: 0,
    list: [],
    paginate: {
      docs: [],
      totalDocs: 0,
      limit: 20,
      page: 1,
      totalPages: 1,
    },

    selectedList: [],
    selectedRowKeys: [],

    actionType: '복사',
    actionOpen: false,
    actionBoard: null,
  }));

  const client = useApolloClient();
  const [add] = useMutation(ADD_POST);
  const [update] = useMutation(UPDATE_POST);
  const [remove] = useMutation(REMOVE_POST);

  const fetchData = useCallback(
    async (query = {}) => {
      let findQuery = { board };
      let notices = [];

      window.maskOn();

      if (!userId) {
        findQuery.isNotice = true;
        const notice = await client.query({
          query: POST_LIST,
          variables: {
            findQuery,
          },
        });
        if (notice.data && notice.data.postList) {
          notices = notice.data.postList;
          state.noticeCnt = notice.data.postList.length;
        }
      }

      findQuery = {
        ...findQuery,
        ...query,
        isNotice: false,
      };

      if (userId) {
        findQuery.created = { _id: userId };
      }

      const result = await client.query({
        query: POSTS,
        variables: {
          page: state.paginate.page,
          limit: state.paginate.limit,
          findQuery,
        },
      });

      if (result.data && result.data.posts) {
        const docs = [...result.data.posts.docs];
        state.paginate = result.data.posts;
        state.list = notices.concat(
          docs.map((item, idx) => {
            item.num = idx + 1;
            if (state.paginate.page > 1) {
              item.num += (state.paginate.page - 1) * state.paginate.limit;
            }
            return item;
          }),
        );
      }
      window.maskOff();
    },
    [board, userId],
  );

  const handleChangePage = useCallback(
    (page) => {
      if (state.paginate.page !== page) {
        state.paginate.page = page;
        handleSearch();
      }
    },
    [board, userId],
  );

  const handleChangeShowSize = useCallback(
    (current, pageSize) => {
      if (state.paginate.limit !== parseInt(pageSize)) {
        state.paginate.limit = pageSize;
        handleSearch();
      }
    },
    [board, userId],
  );

  const handleModify = useCallback(
    (modalOpen, data) => (e) => {
      if (e) {
        e.stopPropagation();
      }
      if (modalOpen && data) {
        state.selectedData = data;
      } else {
        state.selectedData = {};
      }
      state.modifyOpen = modalOpen;
    },
    [],
  );
  const handleClickView = useCallback(
    (row) => () => {
      state.selectedDetail = row;
      state.viewOpen = true;
    },
    [],
  );
  const handleCloseView = useCallback(() => {
    state.viewOpen = false;
  }, []);

  const handleChangeRange = useCallback((value) => {
    state.searchRange = value;
  }, []);
  const handleChangeSearch = useCallback((value) => {
    state.searchRange = [];
    state.searchType = value;
  }, []);
  const handleSearch = useCallback(
    (value) => {
      const findQuery = {};

      if (state.searchType === 1) {
        if (value && value.length) {
          findQuery.created = { name: { $regex: value, $options: 'ig' } };
          findQuery.author = {
            'author.name': { $regex: value, $options: 'ig' },
          };
        }
      } else if (state.searchType === 2) {
        findQuery.postDate = {
          $gte: state.searchRangeVal[0]
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toDate(),
          $lte: state.searchRangeVal[1]
            .set({
              hour: 23,
              minute: 59,
              second: 59,
              millisecond: 999,
            })
            .toDate(),
        };
      } else {
        if (value && value.length) {
          findQuery.created = { username: { $regex: value, $options: 'ig' } };
        }
      }

      fetchData(findQuery);
    },
    [board, userId],
  );

  const handleChangeBoard = useCallback((value) => {
    state.actionBoard = value;
  }, []);
  const handleAction = useCallback(
    (type) => () => {
      state.actionType = type;
      state.actionOpen = true;
    },
    [],
  );
  const handleCloseAction = useCallback(
    (modalOpen) => () => {
      state.actionOpen = modalOpen;
    },
    [],
  );
  const handleSubmitAction = useCallback(async () => {
    window.maskOn();
    const selectedList = toJS(state.selectedList);
    for (const item of selectedList) {
      if (state.actionType === '복사') {
        const data = {
          board: state.actionBoard,
          title: item.title,
          content: item.content,
          images: item.images,
          files: item.files,
          views: item.views,
          isNotice: item.isNotice,
          createdAt: item.createdAt,
          updatedAt: item.updatedAt,
        };
        if (item.created) {
          data.created = item.created.id;
        }
        if (item.updated) {
          data.updated = item.updated.id;
        }
        if (item.author) {
          data.author = item.author;
        }
        await add({ variables: { data } });
      } else if (state.actionType === '이동') {
        await update({
          variables: { id: item.id, data: { board: state.actionBoard } },
        });
      }
    }
    window.maskOff();
    window.success({ title: `${state.actionType} 작업이 완료되었습니다.` });
    state.actionBoard = board;
    state.selectedList = [];
    state.selectedRowKeys = [];
    fetchData();
    state.actionOpen = false;
  }, [state.actionType, state.actionBoard, state.selectedList, board]);

  const handleRemove = useCallback(
    (id, title, isMulti) => (e) => {
      if (e) {
        e.stopPropagation();
      }
      if (!isMulti) {
        window.ask({
          title: `계속 진행하시겠습니까?`,
          content: (
            <div>
              {title} 게시글이 삭제됩니다.
              <br />
              <strong>삭제하시면 복구하실 수 없습니다.</strong>
            </div>
          ),
          async onOk() {
            await remove({ variables: { id } });
            fetchData();
          },
        });
      } else {
        window.ask({
          title: `계속 진행하시겠습니까?`,
          content: (
            <div>
              선택하신 게시글들이 삭제됩니다.
              <br />
              <strong>삭제하시면 복구하실 수 없습니다.</strong>
            </div>
          ),
          async onOk() {
            const selectedList = toJS(state.selectedList);
            for (const item of selectedList) {
              await remove({ variables: { id: item.id } });
              notification.success({
                message: `${item.title} 게시글이 삭제되었습니다.`,
                duration: 7,
              });
            }
            state.selectedList = [];
            state.selectedRowKeys = [];
            fetchData();
          },
        });
      }
    },
    [state.selectedList],
  );

  useEffect(() => {
    state.actionBoard = board;
    fetchData();
  }, [board, userId]);

  return (
    <Wrapper>
      <PageTitle>{title}</PageTitle>
      <Row justify="space-between" gutter={16} style={{ marginTop: 40 }}>
        <Col xs={24} lg={12}>
          <Row gutter={[10, 10]}>
            <Col xs={24} lg={6}>
              <Select
                value={state.searchType}
                placeholder="검색구분"
                onChange={handleChangeSearch}
                style={{ width: '100%' }}
              >
                <Option value={1}>작성자</Option>
                <Option value={2}>등록일</Option>
                <Option value={3}>아이디</Option>
              </Select>
            </Col>
            {state.searchType === 2 ? (
              <Col xs={24} lg={12}>
                <Space size={5}>
                  <RangePicker
                    value={state.searchRangeVal}
                    onChange={handleChangeRange}
                  />
                  <Button
                    type="primary"
                    onClick={handleSearch}
                    disabled={
                      !Boolean(
                        state.searchRangeVal &&
                          state.searchRangeVal[0] &&
                          state.searchRangeVal[1],
                      )
                    }
                  >
                    검색
                  </Button>
                </Space>
              </Col>
            ) : (
              <Col xs={24} lg={6}>
                <Search
                  placeholder="검색어 입력"
                  onSearch={handleSearch}
                  enterButton
                />
              </Col>
            )}
          </Row>
        </Col>
        <Col>
          {isAdmin ? (
            <Space size={10}>
              <Button
                onClick={handleAction('복사')}
                disabled={!state.selectedList.length}
              >
                복사
              </Button>
              <Button
                onClick={handleAction('이동')}
                disabled={!state.selectedList.length}
              >
                이동
              </Button>
              <Button
                onClick={handleRemove(null, null, true)}
                disabled={!state.selectedList.length}
                danger
              >
                삭제
              </Button>
              <Button type="primary" onClick={handleModify(true, null)}>
                글쓰기
              </Button>
            </Space>
          ) : board === '고객문의' ? (
            <Button type="primary" onClick={handleModify(true, null)}>
              글쓰기
            </Button>
          ) : null}
        </Col>
      </Row>

      <PostListTable
        board={board}
        isAdmin={isAdmin}
        dataSource={toJS(state.list)}
        handleModify={handleModify}
        handleRemove={handleRemove}
        localStore={state}
      />

      <Row justify="center" style={{ marginTop: 20 }}>
        <Col>
          <Pagination
            current={state.paginate.page}
            defaultPageSize={20 + state.noticeCnt}
            total={state.paginate.totalDocs}
            pageSizeOptions={['20', '50', '100']}
            showSizeChanger={true}
            locale={{ items_per_page: '개씩' }}
            onChange={handleChangePage}
            onShowSizeChange={handleChangeShowSize}
          />
        </Col>
      </Row>

      <PostModify
        modifyOpen={state.modifyOpen}
        refetch={fetchData}
        propData={toJS(state.selectedData)}
        handleModify={handleModify}
        board={board}
        isAdmin={isAdmin}
      />

      {/* <CustomModal
        title={`${
          state.selectedDetail ? state.selectedDetail.title : ''
        } 게시글 내용`}
        visible={state.viewOpen}
        onCancel={handleCloseView}
        footer={null}
      >
        {state.selectedDetail && (
          <>
            <Row justify="end">
              <Col>
                입력자: <Tag>{state.selectedDetail.created.name}</Tag>
                입력일시:{' '}
                <Tag>
                  {moment(state.selectedDetail.createdAt).format(
                    'YYYY-MM-DD HH:mm:ss',
                  )}
                </Tag>
              </Col>
              <Col>
                수정자: <Tag>{state.selectedDetail.updated.name}</Tag>
                수정일시:{' '}
                <Tag>
                  {moment(state.selectedDetail.updatedAt).format(
                    'YYYY-MM-DD HH:mm:ss',
                  )}
                </Tag>
              </Col>
            </Row>
            <DetailView
              dangerouslySetInnerHTML={{ __html: state.selectedDetail.content }}
            />
          </>
        )}
      </CustomModal> */}

      <CustomModal
        title={`${state.actionType}`}
        visible={state.actionOpen}
        onCancel={handleCloseAction(false)}
        onOk={handleSubmitAction}
      >
        <Select
          value={state.actionBoard}
          onChange={handleChangeBoard}
          style={{ width: 200 }}
        >
          <Option value="공지사항">공지사항</Option>
          <Option value="고객문의">고객문의</Option>
        </Select>
      </CustomModal>
    </Wrapper>
  );
});

export default PostList;
