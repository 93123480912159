import gql from 'graphql-tag';
import { accountErrorFragment } from '@shared/fragments';

export const ACCOUNT_ERRORS = gql`
  ${accountErrorFragment}
  query AccountErrors($page: Int, $limit: Int, $findQuery: JSON) {
    accountErrors(page: $page, limit: $limit, findQuery: $findQuery) {
      docs {
        ...AccountErrorFragment
      }
      totalDocs
      limit
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      pagingCounter
    }
  }
`;

export const ACCOUNT_ERROR = gql`
  ${accountErrorFragment}
  query AccountError($findQuery: JSON) {
    accountError(findQuery: $findQuery) {
      ...AccountErrorFragment
    }
  }
`;

export const ACCOUNT_ERROR_LIST = gql`
  ${accountErrorFragment}
  query AccountErrorList($findQuery: JSON) {
    accountErrorList(findQuery: $findQuery) {
      ...AccountErrorFragment
    }
  }
`;

export const ADD_ACCOUNT_ERROR = gql`
  ${accountErrorFragment}
  mutation AddAccountError($data: InputAccountError) {
    addAccountError(data: $data) {
      ...AccountErrorFragment
    }
  }
`;

export const UPDATE_ACCOUNT_ERROR = gql`
  ${accountErrorFragment}
  mutation UpdateAccountError($id: ID!, $data: InputAccountError) {
    updateAccountError(id: $id, data: $data) {
      ...AccountErrorFragment
    }
  }
`;

export const REMOVE_ACCOUNT_ERROR = gql`
  mutation RemoveAccountError($id: ID!) {
    removeAccountError(id: $id)
  }
`;
