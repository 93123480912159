/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import { Row, Col, Card, Collapse, Divider } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { toJS } from 'mobx';
import moment from 'moment';

import { WORK_DASHBOARD } from '@shared/queries/DashboardQueries';

const { Panel } = Collapse;

const Wrapper = styled.div`
  width: 100%;
`;

const WorkList = ({ list }) => {
  return list && list.length
    ? list.map((item) => (
        <Row
          justify="space-between"
          align="middle"
          key={item.id}
          style={{ marginBottom: 10 }}
        >
          <Col xs={7}>
            {moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
          </Col>
          <Col xs={4}>{item.user.name}</Col>
          <Col xs={5}>{item.company ? item.company.name : ''}</Col>
          <Col xs={5}>{item.instaId ? item.instaId : ''}</Col>
          <Col xs={3}>{item.status}</Col>
        </Row>
      ))
    : null;
};

const WorkDashboard = observer(() => {
  const state = useLocalStore(() => ({
    data: [],
    get dataVal() {
      return toJS(this.data);
    },
  }));
  const queryRes = useQuery(WORK_DASHBOARD);

  useEffect(() => {
    if (queryRes.data && queryRes.data.workDashboard) {
      state.data = queryRes.data.workDashboard;
    }
  }, [queryRes.data]);

  return (
    <Wrapper>
      <Card
        title={<Link to={{ pathname: `/workStatus` }}>작업내역 관리</Link>}
        extra={<Link to={{ pathname: `/workStatus` }}>자세히보기 {'>'}</Link>}
      >
        <Collapse defaultActiveKey={['1', '2', '3', '4', '5']}>
          <Panel
            header="K좋아요K팔로워K댓글"
            extra={
              <Link to={{ pathname: `/workStatus`, query: { tab: '1' } }}>
                자세히보기 {'>'}
              </Link>
            }
            key="1"
          >
            <WorkList list={state.dataVal.product_k} />
          </Panel>
          <Panel
            header="인기게시물 상위작업"
            extra={
              <Link to={{ pathname: `/workStatus`, query: { tab: '2' } }}>
                자세히보기 {'>'}
              </Link>
            }
            key="2"
          >
            <WorkList list={state.dataVal.product_pp} />
          </Panel>
          <Panel
            header="인기게시물 건바이"
            extra={
              <Link to={{ pathname: `/workStatus`, query: { tab: '3' } }}>
                자세히보기 {'>'}
              </Link>
            }
            key="3"
          >
            <h4>월보장</h4>
            <WorkList list={state.dataVal.product_pp_caseBy_month} />
            <Divider />
            <h4>건바이</h4>
            <WorkList list={state.dataVal.product_pp_caseBy_case} />
          </Panel>
          <Panel
            header="최적화계정육성"
            extra={
              <Link to={{ pathname: `/workStatus`, query: { tab: '4' } }}>
                자세히보기 {'>'}
              </Link>
            }
            key="4"
          >
            <WorkList list={state.dataVal.product_oa} />
          </Panel>
          <Panel
            header="최적화테스트"
            extra={
              <Link to={{ pathname: `/workStatus`, query: { tab: '5' } }}>
                자세히보기 {'>'}
              </Link>
            }
            key="5"
          >
            <WorkList list={state.dataVal.product_ot} />
          </Panel>
        </Collapse>
      </Card>
    </Wrapper>
  );
});

export default WorkDashboard;
