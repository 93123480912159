/* eslint-disable react-hooks/exhaustive-deps*/
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import SaleDashboard from './SaleDashboard';
import MemberDashboard from './MemberDashboard';
import ContactDashboard from './ContactDashboard';
import NoticeDashboard from './NoticeDashboard';
import WorkDashboard from './WorkDashboard';

const Wrapper = styled.div`
  width: 100%;
`;

const Home = observer(() => {
  const router = useHistory();

  return (
    <Wrapper>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12}>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <SaleDashboard />
            </Col>
            <Col xs={24}>
              <NoticeDashboard />
            </Col>
            <Col xs={24}>
              <ContactDashboard />
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={12}>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <MemberDashboard />
            </Col>
            <Col xs={24}>
              <WorkDashboard />
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
});

export default Home;
