/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Layout,
  Row,
  Col,
  Drawer,
  Space,
  Menu,
  Grid,
  Button,
  Input,
  Divider,
} from 'antd';
import { MenuOutlined, UserOutlined, PhoneOutlined } from '@ant-design/icons';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import Cookies from 'js-cookie';

import { METAS } from '@shared/queries';
import useStore from '@stores/useStore';
import { numberWithCommas } from '@utils/common';

import CustomModal from '@Common/CustomModal';
import SiteTitle from '@Styled/SiteTitle';

import PointCharging from '@Member/Mypage/Point/PointCharging';

const { Header, Content, Footer } = Layout;
const { TextArea } = Input;
const { useBreakpoint } = Grid;

const Wrapper = styled(Layout)`
  background-color: #fff;
  display: block;

  .ant-layout-header {
    background-color: #fff;
  }
  .menu-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    .ant-menu {
      display: flex;
      justify-content: center;
      align-items: center;

      .ant-menu-item {
        margin-left: ${(props) => (props.islogin ? '1px' : '3px')};
        margin-right: ${(props) => (props.islogin ? '1px' : '3px')};

        .ant-divider {
          border-color: #d0d0d0;
        }
      }
    }
  }

  .site-header {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 120px;

    .trigger {
      font-size: 1.2rem;
    }
    .my-btn span {
      font-size: 1.2rem;
    }
  }

  .content-layout {
    background-color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    ${(props) => !props.ishome && `padding: 15px`};
  }
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .footer-menu {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    padding: 0;
  }
  .footer {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    color: #fff;
    background-color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & * {
      font-size: 11px;
    }

    .change-device-btn {
      text-decoration: underline;
    }
  }
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }

  .drawer-header {
    .name {
      font-size: 1.2rem;
      font-weight: bold;
    }
    .user-point {
      font-size: 1.2rem;
      color: #8e44ad;
    }
    a {
      color: #777;
    }
  }

  .ant-menu-item {
    background-color: #fff;

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon {
        color: #777;
      }
    }
  }
`;

const MemberMobileLayout = observer(
  ({
    children,
    headerCurrent,
    collapsed,
    routes,
    toggle,
    handleClickHeaderMenu,
  }) => {
    const router = useHistory();
    const screen = useBreakpoint();

    const { commonStore } = useStore();

    const state = useLocalStore(() => ({
      pointChargingOpen: false,

      modalVisible: false,
      modalTitle: '',
      modalText: '',
    }));

    const metaRes = useQuery(METAS, {
      variables: { keys: ['member_terms', 'member_privacy'] },
    });

    const handleModal = useCallback(
      (type) => () => {
        state.modalTitle = type;
        if (type === '이용약관') {
          state.modalText = state.termsText;
        } else {
          state.modalText = state.privacyText;
        }
        state.modalVisible = true;
      },
      [],
    );

    const handleClosePointCharging = useCallback(() => {
      state.pointChargingOpen = false;
    }, []);

    useEffect(() => {
      if (metaRes.data && metaRes.data.metas) {
        for (const item of metaRes.data.metas) {
          if (item.key === 'member_terms') {
            state.termsText = item.value;
          } else {
            state.privacyText = item.value;
          }
        }
      }
    }, [metaRes.data]);

    return (
      <Wrapper
        collapsed={collapsed ? 1 : 0}
        ishome={router.location.pathname === '/' ? 1 : 0}
        islogin={commonStore.user ? 1 : 0}
      >
        <Header className="menu-header">
          <Menu
            onClick={handleClickHeaderMenu}
            selectedKeys={[headerCurrent]}
            mode="horizontal"
          >
            {commonStore.user ? (
              <Menu.Item key="logout">
                <span onClick={commonStore.logout}>로그아웃</span>
              </Menu.Item>
            ) : (
              <Menu.Item key="login">
                <Link to="/login">로그인</Link>
              </Menu.Item>
            )}

            <Menu.Item key="divider-1">
              <Divider type="vertical" />
            </Menu.Item>

            {commonStore.user ? (
              <Menu.Item key="mypage">
                <Link to="/mypage">마이페이지</Link>
              </Menu.Item>
            ) : (
              <Menu.Item key="signin">
                <Link to="/signup">회원가입</Link>
              </Menu.Item>
            )}

            <Menu.Item key="divider-2">
              <Divider type="vertical" />
            </Menu.Item>

            <Menu.Item key="notice">
              <Link to="/notice">공지사항</Link>
            </Menu.Item>

            <Menu.Item key="divider-3">
              <Divider type="vertical" />
            </Menu.Item>

            <Menu.Item key="contact">
              <Link to="/contact">고객문의</Link>
            </Menu.Item>

            <Menu.Item key="divider-4">
              <Divider type="vertical" />
            </Menu.Item>

            <Menu.Item key="qna">
              <Link to="/qna">사용방법</Link>
            </Menu.Item>
          </Menu>
        </Header>

        <StyledDrawer
          // title={<SiteTitle size="24px" />}
          title=""
          placement="left"
          closable={true}
          onClose={toggle}
          visible={collapsed}
        >
          <Row
            className="drawer-header"
            gutter={[10, 10]}
            style={{ marginTop: 50, paddingLeft: 15, paddingRight: 15 }}
          >
            {commonStore.user ? (
              <>
                <Col xs={24} className="name">
                  {commonStore.user.name}님
                </Col>
                <Col xs={24}>
                  <Space style={{ display: 'flex', alignItems: 'baseline' }}>
                    <span className="user-point">
                      {numberWithCommas(commonStore.user.point)}
                    </span>
                    <span>point</span>
                  </Space>
                </Col>
                <Col xs={24}>
                  <Space size={15}>
                    <a href="#" onClick={commonStore.logout}>
                      로그아웃 {'>'}
                    </a>
                    <a
                      href="#"
                      onClick={() => (state.pointChargingOpen = true)}
                    >
                      충전하기 {'>'}
                    </a>
                  </Space>
                </Col>
              </>
            ) : (
              <>
                <Col xs={24} className="name">
                  로그인 해주세요
                </Col>
                <Col xs={24}>
                  <Space size={15}>
                    <Link to="/login">로그인 {'>'}</Link>
                    <Link to="/signup">회원가입 {'>'}</Link>
                  </Space>
                </Col>
              </>
            )}
          </Row>

          <Divider />

          <Menu
            onClick={handleClickHeaderMenu}
            selectedKeys={[headerCurrent]}
            mode="vertical"
          >
            <Menu.Item key="home">
              <Link to="/">
                <span>HOME</span>
                <span className="icon">{'>'}</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="notice">
              <Link to="/notice">
                <span>공지사항</span>
                <span className="icon">{'>'}</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="contact">
              <Link to="/contact">
                <span>고객문의</span>
                <span className="icon">{'>'}</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="qna">
              <Link to="/qna">
                <span>사용방법</span>
                <span className="icon">{'>'}</span>
              </Link>
            </Menu.Item>
          </Menu>
        </StyledDrawer>

        <Header className="site-header">
          <div>
            {React.createElement(MenuOutlined, {
              className: 'trigger',
              onClick: toggle,
            })}
          </div>
          <Link to="/" style={{ color: '#000' }}>
            <SiteTitle size="28px" />
          </Link>
          {/* {commonStore.user ? (
            <Button
              className="my-btn"
              type="text"
              icon={<UserOutlined />}
              onClick={() => router.push('/mypage')}
            />
          ) : (
            <Button
              icon={<img src="/images/icon/phone.gif" />}
              type="link"
              style={{ margin: 0, padding: 0, lineHeight: 0 }}
              href="tel:02-1661-9613"
            />
          )} */}
          <Button
            icon={<img src="/images/icon/phone.gif" />}
            type="link"
            style={{ margin: 0, padding: 0, lineHeight: 0 }}
            href="tel:02-1661-9613"
          />
        </Header>

        <Layout className="content-layout">
          <Content className="content">
            <div className="content-children">{children}</div>
          </Content>
        </Layout>

        <Footer className="footer-menu">
          <Button type="text" onClick={handleModal('이용약관')}>
            이용약관
          </Button>
          <Button type="text" onClick={handleModal('개인정보처리방침')}>
            개인정보처리방침
          </Button>
          <Button type="text" onClick={() => router.push('/contact')}>
            고객문의
          </Button>
        </Footer>

        <Footer className="footer">
          <div>Office. 1661-9613 | Fax. 02-2169-2777</div>
          <div>Email. addpicknetwork@naver.com</div>
          <div>서울 금천구 독산동 291-1 현대지식산업센터 b동 1104-1105호</div>
          <div>대표 : 유시청 | 사업자등록번호 : 336-88-00644</div>
          <div>© 2020. ADDPICKNETWORK Inc. ALL RIGHTS RESERVED.</div>
          <div
            className="change-device-btn click"
            onClick={() => {
              Cookies.set('deviceMode', 'pc');
              window.location.reload();
            }}
          >
            PC버전으로 보기
          </div>
        </Footer>

        <CustomModal
          title={state.modalTitle}
          visible={state.modalVisible}
          onCancel={(e) => (state.modalVisible = false)}
          footer={null}
        >
          <TextArea
            value={state.modalText}
            rows={15}
            disabled
            style={{ color: '#333' }}
          />
        </CustomModal>

        <CustomModal
          title=""
          visible={state.pointChargingOpen}
          onCancel={handleClosePointCharging}
          forceRender={true}
          footer={null}
        >
          <PointCharging handleClose={handleClosePointCharging} />
        </CustomModal>
      </Wrapper>
    );
  },
);

export default MemberMobileLayout;
