import gql from 'graphql-tag';
import { workScheduleFragment } from '@shared/fragments';

export const WORK_SCHEDULES = gql`
  ${workScheduleFragment}
  query WorkSchedules($page: Int, $limit: Int, $findQuery: JSON) {
    workSchedules(page: $page, limit: $limit, findQuery: $findQuery) {
      docs {
        ...WorkScheduleFragment
      }
      totalDocs
      limit
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      pagingCounter
    }
  }
`;

export const WORK_SCHEDULE = gql`
  ${workScheduleFragment}
  query WorkSchedule($findQuery: JSON) {
    workSchedule(findQuery: $findQuery) {
      ...WorkScheduleFragment
    }
  }
`;

export const WORK_SCHEDULE_LIST = gql`
  ${workScheduleFragment}
  query WorkScheduleList($findQuery: JSON) {
    workScheduleList(findQuery: $findQuery) {
      ...WorkScheduleFragment
    }
  }
`;

export const ADD_WORK_SCHEDULE = gql`
  ${workScheduleFragment}
  mutation AddWorkSchedule($data: InputWorkSchedule) {
    addWorkSchedule(data: $data) {
      ...WorkScheduleFragment
    }
  }
`;

export const UPDATE_WORK_SCHEDULE = gql`
  ${workScheduleFragment}
  mutation UpdateWorkSchedule($id: ID!, $data: InputWorkSchedule) {
    updateWorkSchedule(id: $id, data: $data) {
      ...WorkScheduleFragment
    }
  }
`;

export const REMOVE_WORK_SCHEDULE = gql`
  mutation RemoveWorkSchedule($id: ID!) {
    removeWorkSchedule(id: $id)
  }
`;
