/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Button } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/react-hooks';
import { toJS } from 'mobx';

import { MEMBER_DASHBOARD } from '@shared/queries/DashboardQueries';
import { numberWithCommas } from '@utils/common';

import CustomModal from '@Common/CustomModal';
import Work from '@Member/Work';

const Wrapper = styled.div`
  width: 100%;
`;

const MemberDashboard = observer(() => {
  const state = useLocalStore(() => ({
    detailOpen: false,
    selectedWorkData: {},

    data: [],
    get dataVal() {
      return toJS(this.data);
    },
  }));

  const client = useApolloClient();

  const fetchData = useCallback(async () => {
    const result = await client.query({
      query: MEMBER_DASHBOARD,
      variables: {},
    });

    if (result.data && result.data.memberDashboard) {
      state.data = result.data.memberDashboard;

      if (state.selectedWorkData && state.selectedWorkData.id) {
        const f = result.data.memberDashboard.find(
          (item) => item.id === state.selectedWorkData.id,
        );
        if (f) {
          state.selectedWorkData = f;
        }
      }
    }
  }, []);

  const handleWorkModal = useCallback(
    (modalOpen, data) => () => {
      if (modalOpen && data) {
        state.selectedWorkData = data;
      } else {
        state.selectedWorkData = {};
      }
      state.workOpen = modalOpen;
    },
    [],
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Wrapper>
      <Card
        title={<Link to={{ pathname: `/memberStatus` }}>회원 관리</Link>}
        extra={<Link to={{ pathname: `/memberStatus` }}>자세히보기 {'>'}</Link>}
      >
        {state.dataVal.map((item) => (
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: 10 }}
            key={item.id}
          >
            <Col>{item.name}</Col>
            <Col>{item.companyName ? item.companyName : '-'}</Col>
            <Col>
              <span className="highlight">
                {numberWithCommas(item.point, true)}
              </span>
              point
            </Col>
            <Col>
              <Button type="primary" onClick={handleWorkModal(true, item)}>
                서버설정
              </Button>
            </Col>
          </Row>
        ))}
      </Card>

      <CustomModal
        visible={state.workOpen}
        onCancel={handleWorkModal(false)}
        forceRender={true}
        footer={null}
        minwidth={1200}
      >
        {state.selectedWorkData && state.selectedWorkData.id && (
          <div style={{ marginTop: 30 }}>
            <Work user={toJS(state.selectedWorkData)} />
          </div>
        )}
      </CustomModal>
    </Wrapper>
  );
});

export default MemberDashboard;
