/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Divider,
  Input,
  Space,
  Radio,
  Select,
  Checkbox,
  InputNumber,
  Collapse,
  Card,
} from 'antd';
import {
  VideoCameraAddOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { toJS } from 'mobx';

import useStore from '@stores/useStore';
import { numberWithCommas } from '@utils/common';

import Description from '@Common/Description';
import Dropzone from '@Common/Dropzone';

const { Option } = Select;
const { Panel } = Collapse;

const Wrapper = styled.div`
  width: 100%;

  .video-wrapper {
    width: 100%;
    .ant-space-item {
      &:first-child {
        width: 100%;
      }
    }
  }
`;

const BuyPPCaseByInstaPost = observer(({ localStore, company }) => {
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    priceType: null,
    priceData: [],
    cntData: {},
  }));

  const handleAddVideo = (itemIdx, idx) => () => {
    if (localStore.isInstaPostRandom) {
      const videos = toJS(localStore.randomInstaPostData.videos);
      videos.push({});
      localStore.randomInstaPostData.videos = videos;
    } else {
      const priceData = toJS(localStore[state.priceType]);
      priceData[itemIdx].instaPostData[idx].videos.push({});
      localStore[state.priceType] = priceData;
    }
  };

  const handleRemoveVideo = (vIdx, itemIdx, idx) => () => {
    if (localStore.isInstaPostRandom) {
      const videos = toJS(localStore.randomInstaPostData.videos);
      videos.splice(vIdx, 1);
      localStore.randomInstaPostData.videos = videos;
    } else {
      const priceData = toJS(localStore[state.priceType]);
      priceData[itemIdx].instaPostData[idx].videos.splice(vIdx, 1);
      localStore[state.priceType] = priceData;
    }
  };

  const handleChangeVideoFile =
    (vIdx, itemIdx, idx, itemUid, postDataUid) => async (e) => {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        e.target.value = '';

        const videoUrl = window.URL.createObjectURL(file);
        let videoElem;
        if (localStore.isInstaPostRandom) {
          videoElem = document.querySelector('#random_insta_post_video');
        } else {
          videoElem = document.querySelector(
            `#${itemUid}_${postDataUid}_video`,
          );
        }
        videoElem.setAttribute('src', videoUrl);

        setTimeout(() => {
          if (!videoElem || !videoElem.duration) {
            return window.alert({ title: '동영상을 로드할 수 없습니다.' });
          }

          if (videoElem.duration > 60) {
            return window.alert({ title: '1분 이하로 업로드해 주세요.' });
          }
          if (file.size > 100000000) {
            return window.alert({ title: '100M 이하로 업로드해 주세요.' });
          }

          file.status = 'load';
          if (localStore.isInstaPostRandom) {
            const videos = toJS(localStore.randomInstaPostData.videos);
            videos[vIdx] = file;
            localStore.randomInstaPostData.videos = videos;
          } else {
            const priceData = toJS(localStore[state.priceType]);
            priceData[itemIdx].instaPostData[idx].videos[vIdx] = file;
            localStore[state.priceType] = priceData;
          }
        }, 500);
      }
    };

  const handleAddInstaContent = (itemIdx, idx) => (e) => {
    if (localStore.isInstaPostRandom) {
      localStore.randomInstaPostData.instaContent.push('');
    } else {
      const priceData = toJS(localStore[state.priceType]);
      priceData[itemIdx].instaPostData[idx].instaContent.push('');
      localStore[state.priceType] = priceData;
    }
  };

  const handleChangeInput = (itemIdx, idx, type) => (e) => {
    if (localStore.isInstaPostRandom) {
      if (type === 'instaContent') {
        localStore.randomInstaPostData[type][idx] = e.target.value;
      } else {
        localStore.randomInstaPostData[type] = e.target.value;
      }
    } else {
      const priceData = toJS(localStore[state.priceType]);
      priceData[itemIdx].instaPostData[idx][type] = e.target.value;
      localStore[state.priceType] = priceData;
    }
  };

  const handleCheckTextAreaRow = (itemIdx, idx, type) => (e) => {
    if (type === 'hashtags') {
      if (localStore.isInstaPostRandom) {
        const priceData = toJS(localStore.randomInstaPostData);
        priceData[type] = e.target.value;

        setTimeout(() => {
          let len = 20;
          const lines = priceData[type].split('\n');
          let values = [];
          for (let i = 0; i < len; i++) {
            if (lines[i] && lines[i].length) {
              if (!/^[0-9]+\./.test(lines[i])) {
                values.push(`${i + 1}.${lines[i]}`);
              } else {
                let dotIdx = lines[i].indexOf('.');
                values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
              }
            }
            priceData[type] = values.join('\n');
            localStore.randomInstaPostData = priceData;
          }
        }, 10);
      } else {
        const priceData = toJS(localStore[state.priceType]);
        priceData[itemIdx].instaPostData[idx][type] = e.target.value;

        setTimeout(() => {
          let len = 20;
          const lines = priceData[itemIdx].instaPostData[idx][type].split('\n');
          let values = [];
          for (let i = 0; i < len; i++) {
            if (lines[i] && lines[i].length) {
              if (!/^[0-9]+\./.test(lines[i])) {
                values.push(`${i + 1}.${lines[i]}`);
              } else {
                let dotIdx = lines[i].indexOf('.');
                values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
              }
            }
            priceData[itemIdx].instaPostData[idx][type] = values.join('\n');
            localStore[state.priceType] = priceData;
          }
        }, 10);
      }
    }
  };

  const handlePressEnter = (itemIdx, idx, type) => (e) => {
    e.persist();
    if (type === 'hashtags') {
      setTimeout(() => {
        if (localStore.isInstaPostRandom) {
          const priceData = toJS(localStore.randomInstaPostData);
          priceData[type] = e.target.value;
          const lines = priceData[type].split('\n');
          let values = [];
          for (let i = 0; i < lines.length; i++) {
            if (!/^[0-9]+\./.test(lines[i])) {
              values.push(`${i + 1}.${lines[i]}`);
            } else {
              let dotIdx = lines[i].indexOf('.');
              values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
            }
          }
          priceData[type] = values.join('\n');
          localStore.randomInstaPostData = priceData;
        } else {
          const priceData = toJS(localStore[state.priceType]);
          priceData[itemIdx].instaPostData[idx][type] = e.target.value;
          const lines = priceData[itemIdx].instaPostData[idx][type].split('\n');
          let values = [];
          for (let i = 0; i < lines.length; i++) {
            if (!/^[0-9]+\./.test(lines[i])) {
              values.push(`${i + 1}.${lines[i]}`);
            } else {
              let dotIdx = lines[i].indexOf('.');
              values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
            }
          }
          priceData[itemIdx].instaPostData[idx][type] = values.join('\n');
          localStore[state.priceType] = priceData;
        }
      }, 10);
    }
  };

  const InstaPostItem = (item, itemIdx) => {
    let value = [];
    for (let i = 0; i < item.instaPostData.length; i++) {
      if (item.instaPostData[i]) {
        value.push(
          <Card
            size="small"
            title={`${
              localStore.isInstaPostRandom
                ? state.cntData[`${item.uid}_${item.instaPostData[i].uid}`]
                : i + 1
            }번째 게시물`}
            style={{ marginBottom: 15 }}
            key={`${item.uid}_${item.instaPostData[i].uid}_card`}
          >
            <Row gutter={[10, 10]}>
              <Col xs={24}>
                <h4>인스타 게시물 이미지 업로드 (jpg파일)</h4>
                <Description text="이미지 1장 ~ 10장 업로드" />

                <Row gutter={[10, 10]} style={{ marginTop: 15 }}>
                  <Col xs={24}>
                    <Dropzone
                      id={`${item.uid}_${item.instaPostData[i].uid}_images`}
                      multiple={true}
                      uploadPath={
                        commonStore.user.isTest
                          ? 'test'
                          : `work/pp_cb/${company ? company.id : 'no-company'}`
                      }
                      setHandleUpload={localStore.setHandleUpload}
                      setParentImages={localStore.setParentImages}
                      images={item.instaPostData[i].images || []}
                      width={100000}
                      height={100000}
                      descriptions={[
                        '위 사진들을 드래그하여 보여지는 순서를 변경할 수 있습니다.',
                      ]}
                      hideDescription={true}
                      lang={{
                        itemUid: item.uid,
                        idx: i,
                        priceType: state.priceType,
                      }}
                    />
                  </Col>
                </Row>
              </Col>

              <Col xs={24}>
                <h4>동영상 업로드</h4>
                <video
                  id={`${item.uid}_${item.instaPostData[i].uid}_video`}
                  style={{ display: 'none' }}
                />

                <Row gutter={[10, 10]}>
                  {item.instaPostData[i].videos.map((v, vIdx) => (
                    <Col
                      xs={24}
                      key={`${item.uid}_${item.instaPostData[i].uid}_video_${vIdx}`}
                    >
                      <input
                        id={`${item.uid}_${item.instaPostData[i].uid}_video_file_${vIdx}`}
                        accept="video/*"
                        type="file"
                        onChange={handleChangeVideoFile(
                          vIdx,
                          itemIdx,
                          i,
                          item.uid,
                          item.instaPostData[i].uid,
                        )}
                        style={{ display: 'none' }}
                      />
                      <Space className="video-wrapper">
                        <Input
                          value={v ? (v.filename ? v.filename : v.name) : null}
                          disabled
                          addonAfter={
                            <Button
                              size="small"
                              type="text"
                              onClick={() =>
                                document
                                  .querySelector(
                                    `#${item.uid}_${item.instaPostData[i].uid}_video_file_${vIdx}`,
                                  )
                                  .click()
                              }
                              block
                            >
                              {v && v.size ? '수정' : '업로드'}
                            </Button>
                          }
                        />
                        <Button
                          icon={<DeleteOutlined />}
                          onClick={handleRemoveVideo(vIdx, itemIdx, i)}
                        />
                      </Space>
                    </Col>
                  ))}

                  <Col xs={24}>
                    <Button
                      type="primary"
                      icon={<VideoCameraAddOutlined />}
                      onClick={handleAddVideo(itemIdx, i)}
                    >
                      동영상 업로드 추가
                    </Button>
                  </Col>
                </Row>
              </Col>

              <Col xs={24}>
                <h4>인스타 게시물 내용 입력</h4>
                <Input.TextArea
                  rows={4}
                  // value={item.instaPostData[i].instaContent}
                  onBlur={handleChangeInput(itemIdx, i, 'instaContent')}
                />
              </Col>

              <Col xs={24}>
                <h4>대표 해시태그</h4>
                <Input
                  // value={item.instaPostData[i].mainHashtag}
                  onBlur={handleChangeInput(itemIdx, i, 'mainHashtag')}
                />
              </Col>

              {/* {localStore.paymentType !== '고객월건바이' && (
                <Col xs={24}>
                  <h4>부 해시태그</h4>
                  <Description text="해시태그 1~20개 입력(한줄에 하나씩)" />
                  <Input.TextArea
                    rows={4}
                    value={item.instaPostData[i].hashtags}
                    onChange={handleChangeInput(itemIdx, i, 'hashtags')}
                    onBlur={handleCheckTextAreaRow(itemIdx, i, 'hashtags')}
                    onPressEnter={handlePressEnter(itemIdx, i, 'hashtags')}
                  />
                </Col>
              )} */}
            </Row>
          </Card>,
        );
      }
    }
    return value;
  };

  useEffect(() => {
    const cntData = {};
    let priceData = [];
    if (localStore.paymentType === '건구매') {
      state.priceType = 'casePriceData';
      priceData = toJS(localStore.casePriceData);
    } else if (localStore.paymentType === '월구매') {
      state.priceType = 'monthPriceData';
      priceData = toJS(localStore.monthPriceData);
    } else if (localStore.paymentType === '고객월건바이') {
      state.priceType = 'customerMonthPriceData';
      priceData = toJS(localStore.customerMonthPriceData);
    }
    let cnt = 1;
    for (const item of priceData) {
      if (item.instaPostData) {
        for (const data of item.instaPostData) {
          cntData[`${item.uid}_${data.uid}`] = cnt;
          cnt++;
        }
      }
    }
    state.cntData = cntData;
    state.priceData = priceData;
  }, [
    localStore.casePriceData,
    localStore.monthPriceData,
    localStore.customerMonthPriceData,
    localStore.paymentType,
  ]);

  return state.priceType ? (
    <Wrapper>
      {!localStore.isInstaPostRandom ? (
        <Col xs={24}>
          <Collapse
            defaultActiveKey={toJS(localStore[state.priceType]).map(
              (item) => `insta_post_${item.uid}`,
            )}
          >
            {toJS(localStore[state.priceType]).map(
              (item, idx) =>
                item.checked && (
                  <Panel
                    header={`${item.rank1}~${item.rank2}위 옵션`}
                    key={`insta_post_${item.uid}`}
                  >
                    {InstaPostItem(item, idx)}
                  </Panel>
                ),
            )}
          </Collapse>
        </Col>
      ) : (
        <Col xs={24}>
          {localStore.randomInstaPostData && (
            <Card size="small" title={`랜덤 게시물 입력`}>
              <Row gutter={[10, 10]}>
                <Col xs={24}>
                  <h4>인스타 게시물 이미지 업로드 (jpg파일)</h4>
                  <Description text="이미지 최소 10장 이상 업로드" />

                  <Row gutter={[10, 10]} style={{ marginTop: 15 }}>
                    <Col xs={24}>
                      <Dropzone
                        id={`random_insta_post_images`}
                        multiple={true}
                        uploadPath={
                          commonStore.user.isTest
                            ? 'test'
                            : `work/pp_cb/${
                                company ? company.id : 'no-company'
                              }`
                        }
                        setHandleUpload={localStore.setHandleUpload}
                        setParentImages={localStore.setParentImages}
                        images={localStore.randomInstaPostData.images || []}
                        width={100000}
                        height={100000}
                        descriptions={[
                          '위 사진들을 드래그하여 보여지는 순서를 변경할 수 있습니다.',
                        ]}
                        hideDescription={true}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col xs={24}>
                  <h4>동영상 업로드</h4>
                  <video
                    id="random_insta_post_video"
                    style={{ display: 'none' }}
                  />

                  <Row gutter={[10, 10]}>
                    {toJS(localStore.randomInstaPostData.videos).map(
                      (v, vIdx) => (
                        <Col xs={24} key={`random_insta_post_video_${vIdx}`}>
                          <input
                            id={`random_insta_post_video_file_${vIdx}`}
                            accept="video/*"
                            type="file"
                            onChange={handleChangeVideoFile(vIdx)}
                            style={{ display: 'none' }}
                          />

                          <Space className="video-wrapper">
                            <Input
                              value={
                                v ? (v.filename ? v.filename : v.name) : null
                              }
                              disabled
                              addonAfter={
                                <Button
                                  size="small"
                                  type="text"
                                  onClick={() =>
                                    document
                                      .querySelector(
                                        `#random_insta_post_video_file_${vIdx}`,
                                      )
                                      .click()
                                  }
                                  block
                                >
                                  {v && v.size ? '수정' : '업로드'}
                                </Button>
                              }
                            />
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={handleRemoveVideo(vIdx)}
                            />
                          </Space>
                        </Col>
                      ),
                    )}
                    <Col xs={24}>
                      <Button
                        type="primary"
                        icon={<VideoCameraAddOutlined />}
                        onClick={handleAddVideo()}
                      >
                        동영상 업로드 추가
                      </Button>
                    </Col>
                  </Row>
                </Col>

                {localStore.randomInstaPostData.instaContent.map(
                  (item, idx) => (
                    <Col
                      xs={24}
                      key={`randomInstaPostData_instaContent_${idx}`}
                    >
                      <h4>인스타 게시물 내용 입력 {idx + 1}</h4>
                      <Input.TextArea
                        rows={4}
                        // value={localStore.randomInstaPostData.instaContent}
                        onChange={handleChangeInput(null, idx, 'instaContent')}
                      />
                    </Col>
                  ),
                )}
                <Col xs={24}>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={handleAddInstaContent()}
                  >
                    인스타 게시물 내용 추가
                  </Button>
                </Col>

                <Col xs={24}>
                  <h4>대표 해시태그</h4>
                  <Input
                    // value={item.instaPostData[i].mainHashtag}
                    onBlur={handleChangeInput(null, null, 'mainHashtag')}
                  />
                </Col>

                {/* {localStore.paymentType !== '고객월건바이' && (
                  <Col xs={24}>
                    <h4>부 해시태그</h4>
                    <Description text="해시태그 1~20개 입력(한줄에 하나씩)" />
                    <Input.TextArea
                      rows={4}
                      value={localStore.randomInstaPostData.hashtags}
                      onChange={handleChangeInput(null, null, 'hashtags')}
                      onBlur={handleCheckTextAreaRow(null, null, 'hashtags')}
                      onPressEnter={handlePressEnter(null, null, 'hashtags')}
                    />
                  </Col>
                )} */}
              </Row>
            </Card>
          )}
        </Col>
      )}
    </Wrapper>
  ) : null;
});

export default BuyPPCaseByInstaPost;
