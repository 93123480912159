/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { Tabs } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';

import useStore from '@stores/useStore';

import CustomModal from '@Common/CustomModal';

import UsePPModifyForm from '@Admin/AdminSetting/UsePP/UsePPModifyForm';

const { TabPane } = Tabs;

const Wrapper = styled.div`
  width: 100%;
`;

const UsePP = observer(({ propData = [], modalOpen, handleModal, refetch }) => {
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    tab: '',
  }));

  const handleChangeTab = useCallback((key) => {
    state.tab = key;
  }, []);

  useEffect(() => {
    if (propData && propData[0]) {
      state.tab = propData[0].id;
    }
  }, [propData]);

  return (
    <CustomModal
      title={`인기게시물 사용 수정`}
      visible={modalOpen}
      onCancel={handleModal(false)}
      minwidth={800}
      footer={null}
    >
      <Wrapper>
        <Tabs activeKey={state.tab} onChange={handleChangeTab}>
          {propData
            ? propData.map((item, idx) => (
                <TabPane tab={`인기게시물 사용${idx + 1}`} key={item.id}>
                  <UsePPModifyForm
                    propData={item}
                    modalOpen={modalOpen}
                    handleModal={handleModal}
                    refetch={refetch}
                  />
                </TabPane>
              ))
            : null}
        </Tabs>
      </Wrapper>
    </CustomModal>
  );
});

export default UsePP;
