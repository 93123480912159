/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col, Layout, Grid } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';

import useStore from '@stores/useStore';

import PageTitle from '@Common/PageTitle';
import CustomModal from '@Common/CustomModal';
import PointCharging from '@Member/Mypage/Point/PointCharging';
import Work from '@Member/Work';

import MainBanner from './MainBanner';

const { useBreakpoint } = Grid;
const { Header } = Layout;

const Wrapper = styled.div`
  width: 100%;

  .product-header {
    height: 50px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .product-item {
      font-weight: bold;
    }
  }

  .img-menu {
    padding: 15px;
    img {
      width: 100%;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
`;

const MobileMain = observer(
  ({
    handleProductDetailModal,
    product_k,
    product_pp,
    product_oa,
    product_pp_caseBy,
    handleModifyModal,
    usePPData,
    autocompleteTopData,
  }) => {
    const router = useHistory();
    const { commonStore } = useStore();

    const state = useLocalStore(() => ({
      pointChargingOpen: false,
    }));

    const handleClosePointCharging = useCallback(() => {
      state.pointChargingOpen = false;
    }, []);

    const handleChargingPoint = useCallback(() => {
      if (commonStore.user) {
        state.pointChargingOpen = true;
      } else {
        router.push('/login');
      }
    }, []);

    return (
      <Wrapper>
        <Header className="product-header">
          {product_k && (
            <>
              <div
                className="product-item"
                onClick={handleProductDetailModal(
                  true,
                  product_k.detail.content,
                )}
              >
                좋아요
              </div>
              <div
                className="product-item"
                onClick={handleProductDetailModal(
                  true,
                  product_k.detail.content,
                )}
              >
                팔로워
              </div>
              <div
                className="product-item"
                onClick={handleProductDetailModal(
                  true,
                  product_k.detail.content,
                )}
              >
                댓글
              </div>
            </>
          )}
          {product_oa && (
            <div
              className="product-item"
              onClick={handleProductDetailModal(
                true,
                product_oa.detail.content,
              )}
            >
              최적화계정육성
            </div>
          )}

          <div className="product-item" onClick={handleChargingPoint}>
            충전하기
          </div>
        </Header>

        <Row justify="center" gutter={[20, 20]}>
          <Col xs={24}>
            <MainBanner />
          </Col>
        </Row>

        <div className="img-menu">
          {usePPData && usePPData.length ? (
            <Button
              type="primary"
              size="large"
              style={{ borderRadius: 0, marginBottom: 5, height: 49 }}
              block
              onClick={handleModifyModal(true, 'usePPModifyOpen')}
            >
              인기게시물 사용
            </Button>
          ) : null}

          {autocompleteTopData && autocompleteTopData.length ? (
            <Button
              type="primary"
              size="large"
              style={{ borderRadius: 0, marginBottom: 5, height: 49 }}
              block
              onClick={handleModifyModal(true, 'autocompleteTopModifyOpen')}
            >
              자동완성 상위노출
            </Button>
          ) : null}

          <img
            src="/images/mobile-menu1.jpg"
            onClick={() => router.push('/qna')}
          />
          <a target="_blank" href="https://pf.kakao.com/_ieXMs">
            <img src="/images/mobile-kakao.jpg" />
          </a>
          {product_k && (
            <>
              <img
                src="/images/mobile-menu2.jpg"
                onClick={handleProductDetailModal(
                  true,
                  product_k.detail.content,
                )}
              />
              <img
                src="/images/mobile-menu3.jpg"
                onClick={handleProductDetailModal(
                  true,
                  product_k.detail.content,
                )}
              />
              <img
                src="/images/mobile-menu4.jpg"
                onClick={handleProductDetailModal(
                  true,
                  product_k.detail.content,
                )}
              />
            </>
          )}

          {product_pp && (
            <img
              src="/images/mobile-menu5.jpg"
              onClick={handleProductDetailModal(
                true,
                product_pp.detail.content,
              )}
            />
          )}

          {product_pp_caseBy && (
            <img
              src="/images/mobile-menu6.jpg"
              onClick={handleProductDetailModal(
                true,
                product_pp_caseBy.detail.content,
              )}
            />
          )}

          {product_oa && (
            <img
              src="/images/mobile-menu7.jpg"
              onClick={handleProductDetailModal(
                true,
                product_oa.detail.content,
              )}
            />
          )}

          <img
            src="/images/mobile-menu8.jpg"
            onClick={handleProductDetailModal(false, null, true)}
          />

          <img src="/images/mobile-menu9.jpg" onClick={handleChargingPoint} />
        </div>

        {/* {commonStore.user && (
          <>
            <PageTitle id="work">작업관리</PageTitle>
            <Card>
              <Work />
            </Card>
          </>
        )} */}

        <CustomModal
          title=""
          visible={state.pointChargingOpen}
          onCancel={handleClosePointCharging}
          forceRender={true}
          footer={null}
        >
          <PointCharging handleClose={handleClosePointCharging} />
        </CustomModal>
      </Wrapper>
    );
  },
);

export default MobileMain;
