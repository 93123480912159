/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { Card, Row, Col, Button, Grid, Space } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';

import PageTitle from '@Common/PageTitle';
import Work from '@Member/Work';

import MainBanner from './MainBanner';

const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  width: 100%;
`;

const AfterLogin = observer(
  ({
    handleProductDetailModal,
    product_k,
    product_pp,
    product_oa,
    product_pp_caseBy,
    handleModifyModal,
    usePPData,
    autocompleteTopData,
  }) => {
    const screen = useBreakpoint();

    return (
      <Wrapper id="after_login">
        <Row justify="center" gutter={[20, 20]}>
          <Col xs={24} lg={12}>
            <MainBanner />
          </Col>
          <Col xs={24} lg={12}>
            <Row gutter={[20, 20]}>
              <Col xs={24}>
                <Card className="product-card">
                  <div className="product-wrapper">
                    <div className="title">K좋아요K팔로워K댓글</div>
                    {product_k && (
                      <Button
                        size="large"
                        type="primary"
                        ghost
                        onClick={handleProductDetailModal(
                          true,
                          product_k.detail.content,
                        )}
                      >
                        자세히보기 {'>'}
                      </Button>
                    )}
                  </div>
                </Card>
              </Col>
              <Col xs={24}>
                <Card className="product-card">
                  <div className="product-wrapper">
                    <div className="title">인기게시물 상위작업</div>

                    {product_pp && (
                      <Button
                        size="large"
                        type="primary"
                        ghost
                        onClick={handleProductDetailModal(
                          true,
                          product_pp.detail.content,
                        )}
                      >
                        자세히보기 {'>'}
                      </Button>
                    )}
                  </div>
                </Card>
              </Col>
              <Col xs={24}>
                <Card className="product-card">
                  <div className="product-wrapper">
                    <div className="title">인기게시물 건바이</div>

                    {product_pp_caseBy && (
                      <Button
                        size="large"
                        type="primary"
                        ghost
                        onClick={handleProductDetailModal(
                          true,
                          product_pp_caseBy.detail.content,
                        )}
                      >
                        자세히보기 {'>'}
                      </Button>
                    )}
                  </div>
                </Card>
              </Col>
              <Col xs={24}>
                <Card className="product-card">
                  <div className="product-wrapper">
                    <div className="title">최적화 계정육성</div>

                    {product_oa && (
                      <Button
                        size="large"
                        type="primary"
                        ghost
                        onClick={handleProductDetailModal(
                          true,
                          product_oa.detail.content,
                        )}
                      >
                        자세히보기 {'>'}
                      </Button>
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <Space size={30}>
          <PageTitle id="work">작업관리</PageTitle>
          {usePPData && usePPData.length ? (
            <Button
              type="primary"
              size="large"
              style={{ marginBottom: '0.5em' }}
              onClick={handleModifyModal(true, 'usePPModifyOpen')}
            >
              인기게시물 사용
            </Button>
          ) : null}
          {autocompleteTopData && autocompleteTopData.length ? (
            <Button
              type="primary"
              size="large"
              style={{ marginBottom: '0.5em' }}
              onClick={handleModifyModal(true, 'autocompleteTopModifyOpen')}
            >
              자동완성 상위노출
            </Button>
          ) : null}
        </Space>
        <Card>
          <Work />
        </Card>
      </Wrapper>
    );
  },
);

export default AfterLogin;
