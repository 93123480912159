import gql from 'graphql-tag';
import { invoiceFragment } from '@shared/fragments';

export const INVOICE = gql`
  ${invoiceFragment}
  query Invoice($findQuery: JSON) {
    invoice(findQuery: $findQuery) {
      ...InvoiceFragment
    }
  }
`;

export const INVOICES = gql`
  ${invoiceFragment}
  query Invoices($page: Int, $limit: Int, $findQuery: JSON) {
    invoices(page: $page, limit: $limit, findQuery: $findQuery) {
      docs {
        ...InvoiceFragment
      }
      totalDocs
      limit
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      pagingCounter
    }
  }
`;

export const INVOICE_LIST = gql`
  ${invoiceFragment}
  query InvoiceList($findQuery: JSON) {
    invoiceList(findQuery: $findQuery) {
      ...InvoiceFragment
    }
  }
`;

export const ADD_INVOICE = gql`
  ${invoiceFragment}
  mutation AddInvoice($data: InputInvoice) {
    addInvoice(data: $data) {
      ...InvoiceFragment
    }
  }
`;

export const UPDATE_INVOICE = gql`
  ${invoiceFragment}
  mutation UpdateInvoice($id: ID!, $data: InputInvoice) {
    updateInvoice(id: $id, data: $data) {
      ...InvoiceFragment
    }
  }
`;

export const REMOVE_INVOICE = gql`
  mutation RemoveInvoice($id: ID!) {
    removeInvoice(id: $id)
  }
`;
