/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { Button, Comment, Collapse, Tooltip, Tag } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import { toJS } from 'mobx';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment';

import { REMOVE_COMMENT } from '@shared/queries/CommentQueries';
import useStore from '@stores/useStore';

import CommentModify from './CommentModify';

const { Panel } = Collapse;

const Wrapper = styled.div`
  width: 100%;

  .content {
    padding-top: 15px;
    padding-bottom: 10px;
  }
`;

const CommentList = observer(({ list, fetchData }) => {
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    modifyIdx: -1,
    modifyChildIdx: -1,
  }));

  const [remove] = useMutation(REMOVE_COMMENT);

  const handleModify = useCallback(
    (idx, isChild) => (e) => {
      if (isChild) {
        if (state.modifyChildIdx === idx) {
          state.modifyChildIdx = -1;
        } else {
          state.modifyChildIdx = idx;
        }
      } else {
        if (state.modifyIdx === idx) {
          state.modifyIdx = -1;
        } else {
          state.modifyIdx = idx;
        }
      }
    },
    [],
  );
  const handleFinish = useCallback(async (e) => {
    state.modifyIdx = -1;
    state.modifyChildIdx = -1;
    fetchData();
  }, []);

  const handleRemove = useCallback(
    (id) => (e) => {
      window.ask({
        title: '계속 진행하시겠습니까?',
        content: '선택하신 댓글을 삭제하시겠습니까?',
        async onOk() {
          await remove({ variables: { id } });
          fetchData();
        },
      });
    },
    [],
  );

  useEffect(() => {
    if (list) {
      state.modifyIdx = -1;
      state.modifyChildIdx = -1;
    }
  }, [list]);

  return (
    <Wrapper>
      {list.map((item, idx) => (
        <div key={item.id}>
          <Comment
            actions={[
              commonStore.user && commonStore.user.id === item.created.id && (
                <Button
                  type="default"
                  onClick={handleModify(idx)}
                  style={{ marginRight: '1rem' }}
                >
                  {state.modifyIdx === idx ? '취소' : '수정'}
                </Button>
              ),
              commonStore.user && commonStore.user.id === item.created.id && (
                <Button type="default" onClick={handleRemove(item.id)} danger>
                  삭제
                </Button>
              ),
            ]}
            author={
              <Tag
                color={item.created.isAdmin ? '#55a8f4' : null}
                style={{ color: item.created.isAdmin ? '#fff' : '#333' }}
              >
                {item.created.name}
              </Tag>
            }
            content={
              <div className="content">
                {state.modifyIdx === idx ? (
                  <CommentModify
                    board={item.board}
                    postId={item.post.id}
                    propData={item}
                    handleFinish={handleFinish}
                  />
                ) : (
                  <pre>{item.content}</pre>
                )}
              </div>
            }
            datetime={
              <span style={{ marginLeft: 5 }}>
                {moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')} (
                {moment(item.createdAt).fromNow()})
              </span>
            }
          >
            {item.children.map((c, cIdx) => (
              <Comment
                key={c.id}
                actions={[
                  commonStore.user && commonStore.user.id === c.created.id && (
                    <Button
                      type="default"
                      onClick={handleModify(cIdx, true)}
                      style={{ marginRight: '1rem' }}
                    >
                      {state.modifyChildIdx === cIdx ? '취소' : '수정'}
                    </Button>
                  ),
                  commonStore.user && commonStore.user.id === c.created.id && (
                    <Button type="default" onClick={handleRemove(c.id)} danger>
                      삭제
                    </Button>
                  ),
                ]}
                author={c.created.name}
                content={
                  state.modifyChildIdx === cIdx ? (
                    <CommentModify
                      board={c.board}
                      postId={c.post.id}
                      propData={c}
                      parent={item.id}
                      handleFinish={handleFinish}
                    />
                  ) : (
                    <pre>{c.content}</pre>
                  )
                }
                datetime={
                  <Tooltip
                    title={moment(c.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                  >
                    <span>{moment(c.createdAt).fromNow()}</span>
                  </Tooltip>
                }
              />
            ))}
          </Comment>

          {/* <Collapse defaultActiveKey={[]} ghost>
            <Panel header="답글" key="1">
              <CommentModify
                board={item.board}
                postId={item.post.id}
                parent={item.id}
                handleFinish={handleFinish}
              />
            </Panel>
          </Collapse> */}
        </div>
      ))}
    </Wrapper>
  );
});

export default CommentList;
