import React from 'react';
import styled from 'styled-components';

const SiteTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  font-family: 'GmarketSansBold';

  .admin-label {
    font-size: 1.2rem;
    color: #333;
  }

  ${(props) => props.size && `font-size: ${props.size}`};
`;

const SiteTitleContainer = (props) => (
  <SiteTitle {...props}>
    인싸닷컴{' '}
    <span className="admin-label">{props.isAdmin ? '관리자' : ''}</span>
  </SiteTitle>
);

export default SiteTitleContainer;
