/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/anchor-is-valid */
import React, { useEffect, useCallback } from 'react';
import { Layout, Row, Col, Grid, Divider, Space, Input } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import Cookies from 'js-cookie';
import { isMobile } from 'react-device-detect';

import { METAS } from '@shared/queries';
import useStore from '@stores/useStore';

import CustomModal from '@Common/CustomModal';

const { Footer, Content } = Layout;
const { useBreakpoint } = Grid;
const { TextArea } = Input;

const Wrapper = styled.div`
  margin-top: 20px;
  width: 100%;

  .footer {
    color: #f0f0f0;
    background-color: #000;
    width: 100%;

    .footer-content {
      font-size: 12px;

      .footer-contact {
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
      }

      .ant-divider {
        border-color: #fff;
      }

      & * {
        font-size: 12px;
      }
    }
    .footer-left {
      margin-left: 10px;
      text-align: left;
      ${(props) => props.nologin && 'margin-left: 120px'};
    }
    .footer-right {
      margin-right: 10px;
      text-align: right;
      ${(props) => props.nologin && 'margin-right: 120px'};
    }
    .footer-content-title {
      font-size: 18px;
      color: #fff;
    }

    .change-device-btn {
      text-decoration: underline;
    }
  }

  ${(props) => props.theme.breakpoint('xs', 'lg')`
    .footer-left {
        margin: 0;
    }
    .footer-right {
      margin: 0;
      .ant-space {
        display: flex;
        flex-direction: column;
      }
    }    
  `}
`;

const FooterContainer = observer(({ noLogin }) => {
  const screen = useBreakpoint();
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    termsText: '',
    privacyText: '',

    modalVisible: false,
    modalTitle: '',
    modalText: '',
  }));

  const metaRes = useQuery(METAS, {
    variables: { keys: ['member_terms', 'member_privacy'] },
  });

  const handleModal = useCallback(
    (type) => () => {
      state.modalTitle = type;
      if (type === '이용약관') {
        state.modalText = state.termsText;
      } else {
        state.modalText = state.privacyText;
      }
      state.modalVisible = true;
    },
    [],
  );

  useEffect(() => {
    if (metaRes.data && metaRes.data.metas) {
      for (const item of metaRes.data.metas) {
        if (item.key === 'member_terms') {
          state.termsText = item.value;
        } else {
          state.privacyText = item.value;
        }
      }
    }
  }, [metaRes.data]);

  return (
    <Wrapper nologin={noLogin ? 1 : 0}>
      <Footer className="footer">
        <Row gutter={[32, 16]} justify="space-between" align="middle">
          <Col className="footer-content footer-left" xs={24} lg={10}>
            {screen.lg ? (
              <div className="footer-contact">
                <div>
                  <strong>업체명 :</strong> (주)애드픽네트워크
                  <Divider type="vertical" />
                  <strong>대표자명 :</strong> 유시청
                </div>
                <div>
                  <Space>
                    <strong>사업자번호 :</strong> 336-88-00644{' '}
                    <a className="underline" href="#">
                      사업자정보확인
                    </a>
                  </Space>
                </div>
                <div>
                  <strong>대표전화 :</strong> 02-1661-9613
                  <Divider type="vertical" />
                  <strong>Fax :</strong> 02-2169-2777
                </div>
                <div>
                  <strong>주소 :</strong> 서울 금천구 독산동 291-1
                  현대지식산업센터 b동 1104-1105호
                </div>
                <div>
                  <strong>Email :</strong> addpicknetwork@naver.com
                </div>
              </div>
            ) : (
              <div className="footer-contact">
                <div>
                  <strong>업체명 :</strong> (주)애드픽네트워크
                </div>
                <div>
                  <strong>대표자명 :</strong> 유시청
                </div>
                <div>
                  <Space>
                    <strong>사업자번호 :</strong> 336-88-00644{' '}
                    <a className="underline" href="#">
                      사업자정보확인
                    </a>
                  </Space>
                </div>
                <div>
                  <strong>대표전화 :</strong> 02-1661-9613
                </div>
                <div>
                  <strong>Fax :</strong> 02-2169-2777
                </div>
                <div>
                  <strong>주소 :</strong> 서울 금천구 독산동 291-1
                  현대지식산업센터 b동 1104-1105호
                </div>
                <div>
                  <strong>Email :</strong> addpicknetwork@naver.com
                </div>
              </div>
            )}
          </Col>
          <Col className="footer-content footer-right" xs={24} lg={10}>
            <div>
              <Space>
                <a
                  className="click underline"
                  onClick={handleModal('이용약관')}
                >
                  이용약관
                </a>
                <a
                  className="click underline"
                  onClick={handleModal('개인정보처리방침')}
                >
                  개인정보처리방침
                </a>
                <div>
                  <strong>개인정보관리책임자 :</strong> 유시청
                </div>
              </Space>
            </div>
            <div>© 2020. ADDPICKNETWORK Inc. ALL RIGHTS RESERVED.</div>
            <div
              className="change-device-btn click"
              onClick={() => {
                Cookies.set('deviceMode', 'mobile');
                window.location.reload();
              }}
            >
              Mobile버전으로 보기
            </div>
          </Col>
        </Row>
      </Footer>

      <CustomModal
        title={state.modalTitle}
        visible={state.modalVisible}
        onCancel={(e) => (state.modalVisible = false)}
        footer={null}
      >
        <TextArea
          value={state.modalText}
          rows={15}
          disabled
          style={{ color: '#333' }}
        />
      </CustomModal>
    </Wrapper>
  );
});

export default FooterContainer;
