/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Space, Button, notification, Row, Col, Collapse } from 'antd';
import { EditOutlined, MinusOutlined } from '@ant-design/icons';
import { toJS } from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import moment from 'moment';
import styled from 'styled-components';

import useStore from '@stores/useStore';
import {
  POST_LIST,
  ADD_POST,
  UPDATE_POST,
  REMOVE_POST,
} from '@shared/queries/PostQueries';

import PageTitle from '@Common/PageTitle';

import QnAModify from './QnAModify';

const { Panel } = Collapse;

const Wrapper = styled.div`
  width: 100%;
  .add-btn {
    z-index: 1;
    float: right;
  }
`;

const DetailView = styled.div`
  width: 100%;
  overflow: auto;
  margin-top: 1rem;
`;

const QnA = observer(() => {
  const { commonStore } = useStore();

  const state = useLocalStore(() => ({
    modifyOpen: false,
    selectedData: {},

    list: [],

    selectedList: [],
    selectedRowKeys: [],
  }));

  const client = useApolloClient();
  const [add] = useMutation(ADD_POST);
  const [update] = useMutation(UPDATE_POST);
  const [remove] = useMutation(REMOVE_POST);

  const fetchData = useCallback(async (query = {}) => {
    let findQuery = { board: 'qna' };

    window.maskOn();

    const result = await client.query({
      query: POST_LIST,
      variables: {
        findQuery,
      },
    });

    if (result.data && result.data.postList) {
      state.list = result.data.postList;
    }
    window.maskOff();
  }, []);

  const handleModify = useCallback(
    (modalOpen, data) => (e) => {
      if (e) {
        e.stopPropagation();
      }
      if (modalOpen && data) {
        state.selectedData = data;
      } else {
        state.selectedData = {};
      }
      state.modifyOpen = modalOpen;
    },
    [],
  );

  const handleRemove = useCallback(
    (id, title, isMulti) => (e) => {
      if (e) {
        e.stopPropagation();
      }
      if (!isMulti) {
        window.ask({
          title: `계속 진행하시겠습니까?`,
          content: (
            <div>
              {title} 사용방법이 삭제됩니다.
              <br />
              <strong>삭제하시면 복구하실 수 없습니다.</strong>
            </div>
          ),
          async onOk() {
            await remove({ variables: { id } });
            fetchData();
          },
        });
      } else {
        window.ask({
          title: `계속 진행하시겠습니까?`,
          content: (
            <div>
              선택하신 사용방법들이 삭제됩니다.
              <br />
              <strong>삭제하시면 복구하실 수 없습니다.</strong>
            </div>
          ),
          async onOk() {
            const selectedList = toJS(state.selectedList);
            for (const item of selectedList) {
              await remove({ variables: { id: item.id } });
              notification.success({
                message: `${item.title} 사용방법이 삭제되었습니다.`,
                duration: 7,
              });
            }
            state.selectedList = [];
            state.selectedRowKeys = [];
            fetchData();
          },
        });
      }
    },
    [],
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Wrapper>
      <PageTitle>사용방법</PageTitle>

      <Row
        justify="end"
        gutter={16}
        style={{ marginTop: 40, marginBottom: 20 }}
      >
        <Col>
          <Button type="primary" onClick={handleModify(true, null)}>
            글쓰기
          </Button>
        </Col>
      </Row>

      <Collapse defaultActiveKey={[]}>
        {toJS(state.list).map((item) => (
          <Panel
            header={
              <Row justify="space-between" gutter={[16, 0]} align="middle">
                <Col>{item.title}</Col>
                <Col>
                  <Space>
                    <Button type="primary" onClick={handleModify(true, item)}>
                      수정
                    </Button>
                    <Button type="primary" onClick={handleRemove(item)} danger>
                      삭제
                    </Button>
                  </Space>
                </Col>
              </Row>
            }
            key={item.id}
          >
            <div dangerouslySetInnerHTML={{ __html: item.content }} />
          </Panel>
        ))}
      </Collapse>

      <QnAModify
        modifyOpen={state.modifyOpen}
        refetch={fetchData}
        propData={toJS(state.selectedData)}
        handleModify={handleModify}
      />

      {/* <CustomModal
        title={`${
          state.selectedDetail ? state.selectedDetail.title : ''
        } 게시글 내용`}
        visible={state.viewOpen}
        onCancel={handleCloseView}
        footer={null}
      >
        {state.selectedDetail && (
          <>
            <Row justify="end">
              <Col>
                입력자: <Tag>{state.selectedDetail.created.name}</Tag>
                입력일시:{' '}
                <Tag>
                  {moment(state.selectedDetail.createdAt).format(
                    'YYYY-MM-DD HH:mm:ss',
                  )}
                </Tag>
              </Col>
              <Col>
                수정자: <Tag>{state.selectedDetail.updated.name}</Tag>
                수정일시:{' '}
                <Tag>
                  {moment(state.selectedDetail.updatedAt).format(
                    'YYYY-MM-DD HH:mm:ss',
                  )}
                </Tag>
              </Col>
            </Row>
            <DetailView
              dangerouslySetInnerHTML={{ __html: state.selectedDetail.content }}
            />
          </>
        )}
      </CustomModal> */}
    </Wrapper>
  );
});

export default QnA;
