/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback } from 'react';
import { Tabs, Row, Col } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';

import PageTitle from '@Common/PageTitle';

import DefaultConfig from './DefaultConfig';
import BoardConfig from './BoardConfig';
import MemberConfig from './MemberConfig';
import RoleConfig from './RoleConfig';

const { TabPane } = Tabs;

const Wrapper = styled.div`
  width: 100%;
`;

const Config = observer(() => {
  const state = useLocalStore(() => ({
    tab: '1',
  }));

  const handleChangeTab = useCallback((key) => {
    state.tab = key;
  }, []);

  return (
    <Wrapper>
      <Row>
        <Col xs={24}>
          <PageTitle>사이트 관리</PageTitle>
        </Col>
      </Row>

      <Tabs activeKey={state.tab} onChange={handleChangeTab}>
        <TabPane tab="기본환경설정" key="1">
          <DefaultConfig />
        </TabPane>
        <TabPane tab="관리권한설정" key="2">
          <RoleConfig />
        </TabPane>
        <TabPane tab="회원가입설정" key="3">
          <MemberConfig />
        </TabPane>
        <TabPane tab="게시판설정" key="4">
          <BoardConfig />
        </TabPane>
      </Tabs>
    </Wrapper>
  );
});

export default Config;
