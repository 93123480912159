/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback } from 'react';
import { Input, Button, Row, Col, Result } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/react-hooks';

import { USER_ONE } from '@shared/queries/UserQueries';

import CustomModal from '@Common/CustomModal';

const Wrapper = styled.div`
  width: 100%;
`;

const FindId = observer((props) => {
  const state = useLocalStore(() => ({
    modalOpen: false,
    name: '',
    email: '',
    resultData: null,
  }));

  const client = useApolloClient();

  const handleClose = useCallback(() => {
    state.name = '';
    state.email = '';
    state.resultData = null;
    state.modalOpen = false;
  }, []);

  const handleChangeInput = useCallback(
    (type) => (e) => {
      state[type] = e.target.value;
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    const res = await client.query({
      query: USER_ONE,
      variables: {
        findQuery: {
          name: state.name,
          email: state.email,
        },
      },
    });

    if (res.data && res.data.userOne) {
      state.resultData = res.data.userOne;
    } else {
      window.alert({ title: '사용자를 찾을 수 없습니다.' });
    }
  }, []);

  return (
    <Wrapper>
      <Button
        type="text"
        onClick={() => (state.modalOpen = true)}
        style={{ padding: 0 }}
      >
        아이디 찾기
      </Button>

      <CustomModal
        title="아이디 찾기"
        visible={state.modalOpen}
        onCancel={handleClose}
        footer={null}
        minwidth={1}
      >
        <Row gutter={[10, 10]}>
          <Col xs={24}>
            <Input
              size="large"
              placeholder="성명을 입력해주세요"
              value={state.name}
              onChange={handleChangeInput('name')}
            />
          </Col>
          <Col xs={24}>
            <Input
              size="large"
              placeholder="이메일을 입력해주세요"
              value={state.email}
              onChange={handleChangeInput('email')}
            />
          </Col>
        </Row>

        {state.resultData ? (
          <>
            <Result
              status="success"
              title={`아이디 : ${state.resultData.username}`}
              extra={[
                <Button size="large" key="close_btn" onClick={handleClose}>
                  닫기
                </Button>,
              ]}
            />
          </>
        ) : (
          <Button type="primary" size="large" block onClick={handleSubmit}>
            아이디 찾기
          </Button>
        )}
      </CustomModal>
    </Wrapper>
  );
});

export default FindId;
