/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback } from 'react';
import { Row, Col, Tabs } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';

import PageTitle from '@Common/PageTitle';

import Banner from './Banner';

const { TabPane } = Tabs;

const Wrapper = styled.div`
  width: 100%;
`;

const BannerContainer = observer(() => {
  const state = useLocalStore(() => ({
    tab: '1',
  }));

  const handleChangeTab = useCallback((key) => {
    state.tab = key;
  }, []);

  return (
    <Wrapper>
      <Row>
        <Col xs={24}>
          <PageTitle>배너 관리</PageTitle>
        </Col>
      </Row>

      <Tabs activeKey={state.tab} onChange={handleChangeTab}>
        <TabPane tab="메인배너" key="1">
          <Banner metaKey="banner_main" uploadPath="banner/main" />
        </TabPane>
        <TabPane tab="우측배너" key="2">
          <Banner metaKey="banner_right" uploadPath="banner/right" />
        </TabPane>
      </Tabs>
    </Wrapper>
  );
});

export default BannerContainer;
