/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import { toJS } from 'mobx';
import { useQuery } from '@apollo/react-hooks';

import useStore from '@stores/useStore';
import { METAS_REGEXP } from '@shared/queries';

import adminMenuData from '@Admin/adminMenuData';
import memberMenuData from '@Member/memberMenuData';

const { SubMenu } = Menu;

const Menus = observer((props) => {
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    menuData: [],
  }));
  const metaRes = useQuery(METAS_REGEXP, {
    variables: {
      key: 'marketing_',
    },
  });

  const onOpenChange = useCallback((openKeys) => {
    commonStore.menuOpenKeys = openKeys;
  }, []);
  const onSelect = useCallback(({ key }) => {
    commonStore.menuSelectedKeys = [key];
  }, []);

  useEffect(() => {
    let menuData = [];
    if (metaRes.data && metaRes.data.metasRegexp) {
      if (commonStore.isAdminLayout) {
        menuData = adminMenuData;
      } else {
        menuData = memberMenuData;

        const filter = metaRes.data.metasRegexp.filter(
          (item) => !item.value.isActivated,
        );

        for (const item of menuData) {
          if (item.key === 'program') {
            for (const f of filter) {
              const fIdx = item.children.findIndex((c) => c.key === f.key);
              if (fIdx !== -1) {
                item.children.splice(fIdx, 1);
              }
            }
          }
        }
      }
    }
    state.menuData = menuData;
  }, [metaRes.data]);

  const menuItems = (item) => {
    return item.children ? (
      <SubMenu key={item.key} icon={item.Icon()} title={item.label}>
        {item.children.map((c) => (
          <Menu.Item key={c.link}>
            <Link to={c.link}>{c.label}</Link>
          </Menu.Item>
        ))}
      </SubMenu>
    ) : (
      <Menu.Item key={item.key} icon={item.Icon()}>
        <Link to={item.link}>{item.label}</Link>
      </Menu.Item>
    );
  };

  return (
    <Menu
      theme="light"
      mode="inline"
      openKeys={commonStore.menuOpenKeys}
      selectedKeys={commonStore.menuSelectedKeys}
      defaultSelectedKeys={['home']}
      onOpenChange={onOpenChange}
      onSelect={onSelect}
    >
      {toJS(state.menuData).map((item) =>
        item.loginOnly
          ? commonStore.user
            ? menuItems(item)
            : null
          : menuItems(item),
      )}
    </Menu>
  );
});

export default Menus;
