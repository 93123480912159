/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col, Space, Select, Tag } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import { toJS } from 'mobx';

import useStore from '@stores/useStore';
import {
  POST,
  PREV_NEXT_POST,
  ADD_POST,
  UPDATE_POST,
  REMOVE_POST,
} from '@shared/queries/PostQueries';
import PageTitle from '@Common/PageTitle';
import CustomModal from '@Common/CustomModal';
import Comment from '@Common/Comment';

import PostModify from './PostModify';

const { Option } = Select;

const Wrapper = styled.div`
  width: 100%;
  .post-title {
    margin-top: 40px;
    font-weight: bold;
    padding: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
  .post-info {
    background: ${(props) => props.theme.lightGreyColor};
    padding: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  .files {
    padding: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  .post-content {
    padding: 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.06);

    img {
      max-width: 100%;
    }
  }
`;

const PostDetail = observer(({ board, title, match, isAdmin }) => {
  const router = useHistory();
  const { commonStore } = useStore();

  const state = useLocalStore(() => ({
    post: null,
    prevNextPost: null,

    modifyOpen: false,
    selectedData: {},

    actionType: '복사',
    actionOpen: false,
    actionBoard: null,
  }));

  const postRes = useQuery(POST, {
    variables: { id: match.params.id },
  });
  const prevNextRes = useQuery(PREV_NEXT_POST, {
    variables: { id: match.params.id, board },
  });
  const [add] = useMutation(ADD_POST);
  const [update] = useMutation(UPDATE_POST);
  const [remove] = useMutation(REMOVE_POST);

  const handleRemove = useCallback(
    (id, title) => () => {
      return window.ask({
        title: `계속 진행하시겠습니까?`,
        content: `${title} 게시글이 삭제됩니다.`,
        async onOk() {
          await remove({ variables: { id } });
          handleGoTo('list')();
        },
      });
    },
    [],
  );

  const handleModify = useCallback(
    (modalOpen, data) => () => {
      if (modalOpen && data) {
        state.selectedData = data;
      } else {
        state.selectedData = {};
      }
      state.modifyOpen = modalOpen;
    },
    [],
  );

  const handleChangeBoard = useCallback((value) => {
    state.actionBoard = value;
  }, []);
  const handleAction = useCallback(
    (type) => () => {
      state.actionType = type;
      state.actionOpen = true;
    },
    [],
  );
  const handleCloseAction = useCallback(
    (modalOpen) => () => {
      state.actionOpen = modalOpen;
    },
    [],
  );
  const handleSubmitAction = useCallback(async () => {
    window.maskOn();
    const item = toJS(state.post);
    if (state.actionType === '복사') {
      const data = {
        board: state.actionBoard,
        title: item.title,
        content: item.content,
        images: item.images,
        files: item.files,
        views: item.views,
        isNotice: item.isNotice,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
      };
      if (item.created) {
        data.created = item.created.id;
      }
      if (item.updated) {
        data.updated = item.updated.id;
      }
      if (item.author) {
        data.author = item.author;
      }
      await add({ variables: { data } });
    } else if (state.actionType === '이동') {
      await update({
        variables: { id: item.id, data: { board: state.actionBoard } },
      });
    }
    window.maskOff();
    window.success({ title: `${state.actionType} 작업이 완료되었습니다.` });
    state.actionBoard = board;
    state.actionOpen = false;
    handleGoTo('list')();
  }, [state.actionType, state.actionBoard, state.post, board]);

  const handleGoTo = useCallback(
    (type) => () => {
      const url = match.url.replace(`/${match.params.id}`, '');
      if (type === 'list') {
        router.push(url);
      } else {
        router.push(`${url}/${state.prevNextPost[type]}`);
      }
    },
    [state.prevNextPost],
  );

  useEffect(() => {
    if (postRes.data && postRes.data.post) {
      state.post = postRes.data.post;
    } else if (!postRes.loading) {
      handleGoTo('list');
    }
  }, [postRes.loading, postRes.data]);
  useEffect(() => {
    if (prevNextRes.data && prevNextRes.data.prevNextPost) {
      state.prevNextPost = prevNextRes.data.prevNextPost;
    }
  }, [prevNextRes.data]);
  useEffect(() => {
    state.actionBoard = board;
  }, []);

  return (
    <Wrapper>
      <PageTitle>{title}</PageTitle>

      {state.post && (
        <>
          <div className="post-title">
            <Space size={10}>
              {state.post.isNotice && <Tag color="#55a8f4">공지</Tag>}
              <span>{state.post.title}</span>
            </Space>
          </div>
          <Row className="post-info" align="middle" gutter={16}>
            <Col xs={24} lg={state.post.author ? 5 : 3}>
              <Space size={10}>
                <strong>작성자</strong>
                {state.post.author ? (
                  <Space size={5}>
                    <span>{state.post.author.name}</span>
                    {isAdmin && <span>({state.post.author.phone})</span>}
                  </Space>
                ) : state.post.created ? (
                  <span>{state.post.created.name}</span>
                ) : null}
              </Space>
            </Col>
            <Col xs={24} lg={5}>
              <Space size={10}>
                <strong>날짜</strong>
                <span>
                  {moment(state.post.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </span>
              </Space>
            </Col>
            <Col xs={24} lg={3}>
              <Space size={10}>
                <strong>조회수</strong>
                <span>{state.post.views ? state.post.views : 0}</span>
              </Space>
            </Col>
            {board === '고객문의' && (
              <Col xs={24} lg={3}>
                <Space size={10}>
                  <strong>답변여부</strong>
                  <span>
                    {state.post.commentCnt ? (
                      <Tag color="blue">답변완료</Tag>
                    ) : (
                      <Tag color="orange">답변대기</Tag>
                    )}
                  </span>
                </Space>
              </Col>
            )}
          </Row>
          {state.post.files && state.post.files.length ? (
            <Row className="files" align="middle" gutter={[16, 16]}>
              {toJS(state.post.files).map((item, idx) => (
                <Col xs={24} key={item.name}>
                  <Space size={10}>
                    <strong>파일{idx + 1}</strong>
                    <Button
                      type="link"
                      htmlType="button"
                      href={item.url}
                      download
                    >
                      {item.filename}
                    </Button>
                  </Space>
                </Col>
              ))}
            </Row>
          ) : null}

          <div
            className="post-content ql-viewer"
            dangerouslySetInnerHTML={{ __html: state.post.content }}
          />

          {board === '고객문의' && (
            <Comment board={board} postId={state.post.id} isAdmin={isAdmin} />
          )}

          <Row justify="space-between" align="middle" style={{ marginTop: 20 }}>
            <Col>
              <Space size={10}>
                <Button onClick={handleGoTo('list')}>목록</Button>
                {isAdmin || board === '공지사항' ? (
                  <>
                    {state.prevNextPost && state.prevNextPost.prev && (
                      <Button onClick={handleGoTo('prev')}>이전글</Button>
                    )}
                    {state.prevNextPost && state.prevNextPost.next && (
                      <Button onClick={handleGoTo('next')}>다음글</Button>
                    )}
                  </>
                ) : null}
              </Space>
            </Col>
            <Col>
              <Space size={10}>
                {isAdmin && (
                  <Space size={10}>
                    <Button onClick={handleAction('복사')}>복사</Button>
                    <Button onClick={handleAction('이동')}>이동</Button>
                  </Space>
                )}
                {isAdmin ||
                (commonStore.user &&
                  state.post.created &&
                  commonStore.user.id === state.post.created.id) ? (
                  <Space size={10}>
                    <Button onClick={handleModify(true, toJS(state.post))}>
                      수정
                    </Button>
                    <Button
                      onClick={handleRemove(state.post.id, state.post.title)}
                      danger
                    >
                      삭제
                    </Button>
                  </Space>
                ) : null}

                {board === '고객문의' && (
                  <Button type="primary" onClick={handleModify(true)}>
                    글쓰기
                  </Button>
                )}
              </Space>
            </Col>
          </Row>

          <PostModify
            modifyOpen={state.modifyOpen}
            refetch={postRes.refetch}
            propData={toJS(state.selectedData)}
            handleModify={handleModify}
            board={board}
            isAdmin={isAdmin}
          />

          <CustomModal
            title={`${state.post.title} ${state.actionType}`}
            visible={state.actionOpen}
            onCancel={handleCloseAction(false)}
            onOk={handleSubmitAction}
          >
            <Select
              value={state.actionBoard}
              onChange={handleChangeBoard}
              style={{ width: 200 }}
            >
              <Option value="공지사항">공지사항</Option>
              <Option value="고객문의">고객문의</Option>
            </Select>
          </CustomModal>
        </>
      )}
    </Wrapper>
  );
});

export default PostDetail;
