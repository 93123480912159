/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback, useMemo } from 'react';
import {
  Row,
  Col,
  Table,
  Select,
  Input,
  Button,
  DatePicker,
  Divider,
} from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import { toJS } from 'mobx';
import styled from 'styled-components';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import moment from 'moment';

import useStore from '@stores/useStore';
import { POINT_LOGS, UPDATE_POINT_LOG } from '@shared/queries/PointLogQueries';
import { numberWithCommas } from '@utils/common';

import CustomModal from '@Common/CustomModal';
import PageTitle from '@Common/PageTitle';

import PointVbank from './PointVbank';
import RefundInfoForm from './RefundInfoForm';

const { Option } = Select;
const { Search } = Input;

const Wrapper = styled.div`
  width: 100%;
`;

const PointList = observer(({ tab }) => {
  const { commonStore } = useStore();

  const state = useLocalStore(() => ({
    list: [],
    paginate: {
      docs: [],
      totalDocs: 0,
      limit: 10,
      page: 1,
      totalPages: 1,
    },

    selectedData: {},
    vbankOpen: false,
    selectedRefundData: {},
    refundInfoOpen: false,

    searchType: '전체',
    search: null,
    searchStartDate: null,
    searchEndDate: null,

    now: null,
  }));

  const client = useApolloClient();
  const [update] = useMutation(UPDATE_POINT_LOG);

  const fetchData = useCallback(async (findQuery = {}) => {
    window.maskOn();
    state.now = await commonStore.getServerNow();

    if (!findQuery['$and']) {
      findQuery['$and'] = [];
    }

    findQuery['$and'].push({
      $or: [
        { $and: [{ pointType: '포인트충전' }, { status: '결제완료' }] },
        { pointType: '포인트사용' },
      ],
    });
    findQuery.user = { _id: commonStore.user.id };

    const result = await client.query({
      query: POINT_LOGS,
      variables: {
        page: state.paginate.page,
        limit: state.paginate.limit,
        findQuery,
      },
    });

    if (result.data && result.data.pointLogs) {
      state.paginate = result.data.pointLogs;
      state.list = result.data.pointLogs.docs;
    }
    window.maskOff();
  }, []);

  const handleVbank = useCallback(
    (modalOpen, data) => (e) => {
      if (modalOpen && data) {
        state.selectedData = data;
      } else {
        state.selectedData = {};
      }
      state.vbankOpen = modalOpen;
    },
    [],
  );

  const handleChangePage = useCallback((page) => {
    if (state.paginate.page !== page) {
      state.paginate.page = page;
      fetchData();
    }
  }, []);

  const handleChangeDate = useCallback((type) => (date) => {
    state[`search${type}`] = date;
  });
  const handleChangeSearch = useCallback((value) => {
    state.searchType = value;
  }, []);
  const handleSearch = useCallback(
    (value) => {
      const findQuery = {};
      findQuery['$and'] = [];

      // if (state.searchType) {
      //   findQuery['$and'] = [];

      //   switch (state.searchType) {
      //     case '일자':
      //       if (state.searchStartDate || state.searchEndDate) {
      //         if (state.searchStartDate) {
      //           findQuery['$and'].push({
      //             orderDate: {
      //               $gte: state.searchStartDate.format('YYYY-MM-DD'),
      //             },
      //           });
      //         }
      //         if (state.searchEndDate) {
      //           findQuery['$and'].push({
      //             orderDate: { $lte: state.searchEndDate.format('YYYY-MM-DD') },
      //           });
      //         }
      //       }
      //       break;
      //     case '내용':
      //       findQuery['$and'].push({
      //         orderType: { $regex: value, $options: 'ig' },
      //       });
      //       break;
      //     case '작업목록':
      //       findQuery['$and'].push({
      //         $or: [
      //           { marketingName: { $regex: value, $options: 'ig' } },
      //           { marketingType: { $regex: value, $options: 'ig' } },
      //         ],
      //       });
      //       break;
      //     case '카테고리':
      //       findQuery['$and'].push({
      //         $or: [
      //           { 'category.main.name': { $regex: value, $options: 'ig' } },
      //           { 'category.middle.name': { $regex: value, $options: 'ig' } },
      //           { 'category.last.name': { $regex: value, $options: 'ig' } },
      //         ],
      //       });
      //       break;
      //     case '결제금액':
      //       findQuery['$and'].push({
      //         itemPrice: value,
      //       });
      //       break;
      //     default:
      //   }
      // }

      if (value) {
        findQuery['$and'].push({
          description: { $regex: value, $options: 'ig' },
        });
      }

      if (state.searchStartDate || state.searchEndDate) {
        if (state.searchStartDate) {
          findQuery['$and'].push({
            createdAt: {
              $gte: moment(state.searchStartDate).startOf('date'),
            },
          });
        }
        if (state.searchEndDate) {
          findQuery['$and'].push({
            createdAt: { $lte: moment(state.searchEndDate).endOf('date') },
          });
        }
      }

      if (commonStore.user) {
        fetchData(findQuery);
      }
    },
    [state.searchType],
  );

  const handleCancel = useCallback(
    (row) => () => {
      window.ask({
        title: '계속 진행하시겠습니까?',
        content: `${row.description} 포인트 반환 신청을 하시겠습니까?`,
        async onOk() {
          if (row.status === '결제대기') {
            await update({
              variables: {
                id: row.id,
                data: {
                  status: '결제취소',
                },
              },
            });
            await fetchData();
            window.alert({
              title: `${row.description} - 결제취소가 완료되었습니다.`,
            });
          } else if (row.status === '결제완료') {
            if (row.pay_method === 'vbank') {
              state.selectedRefundData = row;
              state.refundInfoOpen = true;
            } else {
              await update({
                variables: {
                  id: row.id,
                  data: {
                    pointType: '포인트반환신청',
                  },
                },
              });
              await fetchData();
              window.alert({
                title: `${row.description} - 포인트 반환 신청이 완료되었습니다.`,
              });
            }
          }
        },
      });
    },
    [],
  );

  useEffect(() => {
    if (commonStore.user && tab === '2') {
      fetchData();
    }
  }, [tab]);

  const columns = useMemo(
    () => [
      {
        title: '날짜',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt) => moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
        align: 'center',
      },
      {
        title: '내용',
        dataIndex: 'description',
        key: 'description',
        align: 'center',
      },
      {
        title: '지급포인트',
        dataIndex: 'point',
        key: 'point',
        render: (_, row) => {
          return row.pointType === '포인트충전' ? (
            <span className="blue">+{numberWithCommas(row.point)}</span>
          ) : (
            ''
          );
        },
        align: 'center',
      },
      {
        title: '사용포인트',
        dataIndex: 'point',
        key: 'point',
        render: (_, row) => {
          return row.pointType === '포인트사용' ? (
            <span className="red">-{numberWithCommas(row.point)}</span>
          ) : (
            ''
          );
        },
        align: 'center',
      },
      {
        title: '영수증',
        dataIndex: 'receipt_url',
        key: 'receipt_url',
        render: (receipt_url, row) =>
          row.pointType === '포인트충전' &&
          row.status === '결제완료' &&
          receipt_url ? (
            <Button href={receipt_url} target="_blank">
              보기
            </Button>
          ) : row.pointType === '포인트충전' && row.status === '결제대기' ? (
            <Button onClick={handleVbank(true, row)}>보기</Button>
          ) : (
            // <Button type="primary" onClick={handleVbank(true, row)}>
            //   계좌정보
            // </Button>
            ''
          ),
        align: 'center',
      },
      {
        title: '취소요청',
        dataIndex: 'cancel',
        key: 'cancel',
        render: (_, row) =>
          (row.status === '결제대기' || row.status === '결제완료') &&
          row.pointType === '포인트충전' &&
          moment(row.createdAt).add(5, 'days').format('YYYY-MM-DD') >
            moment(state.now).format('YYYY-MM-DD') ? (
            <Button type="primary" ghost onClick={handleCancel(row)}>
              결제취소
            </Button>
          ) : (
            ''
          ),
        align: 'center',
      },
    ],
    [state.now],
  );

  return (
    <Wrapper>
      <PageTitle>결제내역</PageTitle>

      <Divider />

      <h4>
        포인트 합계 :{' '}
        <span className="highlight">
          {numberWithCommas(commonStore.user.point)}
        </span>{' '}
        포인트
      </h4>

      <Divider />

      <Row gutter={[10, 10]}>
        <Col xs={24} lg={3}>
          <DatePicker
            value={state.searchStartDate}
            placeholder="검색시작일"
            onChange={handleChangeDate('StartDate')}
            style={{ width: '100%' }}
          />
        </Col>
        <Col xs={24} lg={3}>
          <DatePicker
            value={state.searchEndDate}
            placeholder="검색종료일"
            onChange={handleChangeDate('EndDate')}
            style={{ width: '100%' }}
          />
        </Col>
        <Col xs={24} lg={4}>
          <Search placeholder="내용 검색" onSearch={handleSearch} enterButton />
        </Col>
      </Row>

      <Table
        dataSource={toJS(state.list)}
        columns={columns}
        scroll={{ x: 992 }}
        pagination={{
          current: state.paginate.page,
          total: state.paginate.totalDocs,
          showSizeChanger: false,
          onChange: handleChangePage,
        }}
        rowKey={(row) => row.id}
      />

      <CustomModal
        visible={state.vbankOpen}
        onCancel={handleVbank(false)}
        forceRender={true}
        footer={null}
        minwidth={900}
      >
        {state.selectedData && state.selectedData.id && (
          <PointVbank propData={toJS(state.selectedData)} />
        )}
      </CustomModal>

      <CustomModal
        visible={state.refundInfoOpen}
        onCancel={() => (state.refundInfoOpen = false)}
        forceRender={true}
        footer={null}
        minwidth={900}
      >
        {state.selectedRefundData && state.selectedRefundData.id && (
          <RefundInfoForm
            propData={toJS(state.selectedRefundData)}
            handleClose={() => {
              state.selectedRefundData = {};
              state.refundInfoOpen = false;
            }}
            refetch={handleSearch}
          />
        )}
      </CustomModal>
    </Wrapper>
  );
});

export default PointList;
