/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Row, Col, Drawer, Space, Menu, Grid, Button } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { observer, useLocalStore } from 'mobx-react';
import { toJS } from 'mobx';
import styled from 'styled-components';
import { lighten } from 'polished';

import useStore from '@stores/useStore';
import { numberWithCommas } from '@utils/common';
import adminMenuData from '@Admin/adminMenuData';

import SiteTitle from '@Styled/SiteTitle';
import Menus from '@Common/Menus';

import Footer from './Footer';

const { Header, Content } = Layout;
const { useBreakpoint } = Grid;

const headerKeys = ['home', 'notice', 'contact', 'mypage'];

const Wrapper = styled(Layout)`
  .content-layout {
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
  }
  .header {
    position: fixed;
    width: 100%;
    z-index: 2;
    padding: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background: #fff;
    height: 66px;

    .header-left,
    .header-right {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 24px 16px 0;
    width: 100%;
  }
  .content-children {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    padding: 2rem;
    min-height: 500px;

    ${(props) => !props.ishome && `background-color: #fff`};
  }

  .profile {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0.5rem;
    border: 1px dashed #e0e0e0;
    padding: 0.5rem;

    div {
      width: 100%;
      text-align: center;
    }
  }
  .trigger {
    font-size: 18px;
  }

  ${(props) => props.theme.breakpoint('xs', 'lg')`
    .header-right {
      display: none !important;
    }
  `}
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`;

const SubHeader = styled(Header)`
  background: ${(props) => lighten(0.1, props.theme.primaryColor)};
  color: #fff;
  margin-top: 66px;
`;

const MobileMenu = styled.div`
  width: 100%;
  height: 55px;
  display: none;
  border-bottom: 1px solid #f0f0f0;

  .ant-row {
    height: 100%;
  }
  .ant-col {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      border-right: 1px solid #f0f0f0;
    }
  }

  ${(props) => props.theme.breakpoint('xs', 'lg')`
    display: block;
  `}
`;

const DrawerLayout = observer((props) => {
  const router = useLocation();

  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    headerCurrent: 'home',
    collapsed: false,
    routes: [],
    get routesVal() {
      return toJS(state.routes);
    },
  }));

  const toggle = useCallback(() => {
    state.collapsed = !state.collapsed;
  }, []);

  const handleClickHeaderMenu = useCallback((e) => {
    state.headerCurrent = e.key;
    commonStore.menuOpenKeys = [e.key];
  }, []);

  useEffect(() => {
    const menus = toJS(adminMenuData);
    const pathnameArr = router.pathname.split('/');

    if (pathnameArr[1]) {
      const routes = [];
      const fIdx = menus.findIndex((item) => item.key === pathnameArr[1]);
      if (fIdx !== -1) {
        if (menus[fIdx].children) {
          routes.push({ label: menus[fIdx].label, key: menus[fIdx].key });

          const f = menus[fIdx].children.find((r) => {
            const regExp = new RegExp(`${r.link}$`, 'i');
            return regExp.test(router.pathname);
          });
          routes.push(f);

          const menuOpenKeys = toJS(commonStore.menuOpenKeys);
          const fKey = menuOpenKeys.find((key) => key === pathnameArr[1]);
          if (!fKey) {
            menuOpenKeys.push(pathnameArr[1]);
          }
          commonStore.menuSelectedKeys = [router.pathname];
          commonStore.menuOpenKeys = menuOpenKeys;

          const fHeaderKey = headerKeys.find((item) => {
            const regExp = new RegExp(item, 'i');
            return regExp.test(router.pathname);
          });
          state.headerCurrent = fHeaderKey || menus[0].key;
        } else {
          routes.push({ label: menus[fIdx].label, link: menus[fIdx].link });
          commonStore.menuSelectedKeys = [menus[fIdx].key];
          const fKey = headerKeys.find((item) => item === menus[fIdx].key);
          state.headerCurrent = fKey || menus[0].key;
        }

        routes.unshift({
          label: menus[0].label,
          link: menus[0].link,
        });

        state.routes = routes;
      }
    } else {
      state.routes = [{ label: menus[0].label, link: menus[0].link }];
      commonStore.menuSelectedKeys = [menus[0].key];
      state.headerCurrent = menus[0].key;
    }
  }, [router]);

  useEffect(() => {
    if (!commonStore.user || !commonStore.user.isAdmin) {
      window.location.href = '/login';
    }
  }, []);

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
    >
      <Wrapper
        collapsed={state.collapsed ? 1 : 0}
        ishome={router.pathname === '/' ? 1 : 0}
      >
        <StyledDrawer
          // title={<SiteTitle size="24px" />}
          title=" "
          placement="left"
          closable={false}
          onClose={toggle}
          visible={state.collapsed}
        >
          <MobileMenu>
            <Row gutter={[10, 10]}>
              <Col xs={12}>
                {commonStore.user ? (
                  <Button type="text" block onClick={commonStore.logout}>
                    로그아웃
                  </Button>
                ) : (
                  <Link to="/login">로그인</Link>
                )}
              </Col>
              <Col xs={12} style={{ textAlign: 'center' }}>
                관리자 {commonStore.user ? commonStore.user.name : ''}
              </Col>
            </Row>
          </MobileMenu>
          <Menus isAdmin={true} />
        </StyledDrawer>

        <Layout className="content-layout">
          <Header className="header">
            <div className="header-left">
              <Space size={16}>
                {React.createElement(
                  state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: 'trigger',
                    onClick: toggle,
                  },
                )}
                <Link to="/" style={{ color: '#000' }}>
                  <SiteTitle size="24px" isAdmin={true} />
                </Link>
              </Space>
            </div>
            <div className="header-right">
              <Menu
                onClick={handleClickHeaderMenu}
                selectedKeys={[state.headerCurrent]}
                mode="horizontal"
              >
                <Menu.Item key="home">
                  <Link to="/">HOME</Link>
                </Menu.Item>
                <Menu.Item key="notice">
                  <Link to={{ pathname: `/board`, query: { tab: '1' } }}>
                    공지사항
                  </Link>
                </Menu.Item>
                <Menu.Item key="contact">
                  <Link to={{ pathname: `/board`, query: { tab: '2' } }}>
                    고객문의
                  </Link>
                </Menu.Item>
                <Menu.Item key="qna">
                  <Link to={{ pathname: `/board`, query: { tab: '3' } }}>
                    사용방법
                  </Link>
                </Menu.Item>

                <Menu.Item key="logout">
                  {commonStore.user ? (
                    <span onClick={commonStore.logout}>로그아웃</span>
                  ) : (
                    <Link to="/login">로그인</Link>
                  )}
                </Menu.Item>
              </Menu>
            </div>
          </Header>
          <SubHeader>
            <Space
              size={20}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <span>
                관리자 {commonStore.user ? commonStore.user.name : ''}
              </span>
            </Space>
          </SubHeader>

          <Content className="content">
            <div className="content-children">{props.children}</div>
          </Content>
        </Layout>
      </Wrapper>
      {/* <Footer /> */}
    </div>
  );
});

export default DrawerLayout;
