import gql from 'graphql-tag';

// 관리자
export const SALE_DASHBOARD = gql`
  query {
    saleDashboard
  }
`;
export const MEMBER_DASHBOARD = gql`
  query {
    memberDashboard
  }
`;
export const CONTACT_DASHBOARD = gql`
  query ContactDashboard($findQuery: JSON) {
    contactDashboard(findQuery: $findQuery)
  }
`;
export const NOTICE_DASHBOARD = gql`
  query {
    noticeDashboard
  }
`;
export const WORK_DASHBOARD = gql`
  query {
    workDashboard
  }
`;
export const REFUND_DASHBOARD = gql`
  query RefundDashboard($findQuery: JSON) {
    refundDashboard(findQuery: $findQuery)
  }
`;

// 사용자
export const PROGRAM_DASHBOARD = gql`
  query ProgramDashboard($findQuery: JSON) {
    programDashboard(findQuery: $findQuery)
  }
`;
