/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Card,
  Row,
  Col,
  Select,
  Input,
  Space,
  Button,
  Table,
  Grid,
  Descriptions,
} from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useApolloClient, useQuery, useMutation } from '@apollo/react-hooks';
import { toJS } from 'mobx';
import { unionBy, uniqBy } from 'lodash';
import moment from 'moment';

import {
  USERS_PAGINATE,
  USER_CNT,
  UPDATE_USER,
} from '@shared/queries/UserQueries';
import useStore from '@stores/useStore';
import { numberWithCommas } from '@utils/common';
import { ROLES } from '@shared/constants';

import CustomModal from '@Common/CustomModal';
import Work from '@Member/Work';

import Detail from './Detail';

const { Option } = Select;
const { Search } = Input;
const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  width: 100%;

  .ant-descriptions {
    table tr th {
      border: 1px solid #e0e0e0;
      background-color: #efefef;
    }
    &:hover {
      background-color: #fff;
    }

    th.action {
      border-bottom: none;
      padding: 0;
    }
  }

  .ant-table-row-selected {
    color: #fff;
  }
`;

const MemberStatus = observer(() => {
  const screen = useBreakpoint();
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    list: [],
    paginate: {
      docs: [],
      totalDocs: 0,
      limit: 10,
      page: 1,
      totalPages: 1,
    },

    detailOpen: false,
    selectedData: {},
    workOpen: false,
    selectedWorkData: {},
    postTypeOpen: false,
    defPostType: '',

    searchType: null,
    searchText: '',

    userCnt: null,

    updateData: [],
    selectedList: [],
    selectedRowKeys: [],
  }));

  const client = useApolloClient();
  const userCntRes = useQuery(USER_CNT, {
    variables: {
      isAdmin: false,
    },
  });
  const [update] = useMutation(UPDATE_USER);

  const fetchData = useCallback(async (findQuery = {}) => {
    window.maskOn();
    findQuery.isAdmin = false;

    const result = await client.query({
      query: USERS_PAGINATE,
      variables: {
        page: state.paginate.page,
        limit: state.paginate.limit,
        findQuery,
      },
    });

    if (result.data && result.data.usersPaginate) {
      state.paginate = result.data.usersPaginate;
      state.list = result.data.usersPaginate.docs;

      if (state.selectedData && state.selectedData.id) {
        const f = result.data.usersPaginate.docs.find(
          (item) => item.id === state.selectedData.id,
        );
        if (f) {
          state.selectedData = f;
        }
      }
    }
    window.maskOff();
  }, []);

  const handleChangePage = useCallback((page) => {
    if (state.paginate.page !== page) {
      state.paginate.page = page;
      handleSearch();
    }
  }, []);

  const handleDetail = useCallback(
    (modalOpen, data) => () => {
      if (modalOpen && data) {
        state.selectedData = data;
      } else {
        state.selectedData = {};
      }
      state.detailOpen = modalOpen;
    },
    [],
  );

  const handleWorkModal = useCallback(
    (modalOpen, data) => () => {
      if (modalOpen && data) {
        state.selectedWorkData = data;
      } else {
        state.selectedWorkData = {};
      }
      state.workOpen = modalOpen;
    },
    [],
  );
  const handleModal = useCallback(
    (key, modalOpen) => () => {
      if (key === 'postTypeOpen' && modalOpen) {
        state.defPostType = '';
      }
      state[key] = modalOpen;
    },
    [],
  );

  const handleChangeInput = useCallback(
    (key) => (e) => {
      state[key] = e.target.value;
    },
    [],
  );
  const handleChangeSelect = useCallback(
    (key) => (value) => {
      state[key] = value;
    },
    [],
  );

  const handleChangeSearch = useCallback((value) => {
    state.searchType = value;
  }, []);
  const handleSearch = useCallback(() => {
    const value = state.searchText;
    const findQuery = {};
    if (value && value.length) {
      if (state.searchType) {
        switch (state.searchType) {
          case 1:
            findQuery.userGroup = { $regex: value, $options: 'ig' };
            break;
          case 2:
            findQuery.companyName = { $regex: value, $options: 'ig' };
            break;
          case 4:
            findQuery.phone = { $regex: value, $options: 'ig' };
            break;
          case 5:
            findQuery.name = { $regex: value, $options: 'ig' };
            break;
          case 6:
            findQuery.username = { $regex: value, $options: 'ig' };
            break;
          default:
            break;
        }
      } else {
        findQuery['$or'] = [
          { userGroup: { $regex: value, $options: 'ig' } },
          { companyName: { $regex: value, $options: 'ig' } },
          { phone: { $regex: value, $options: 'ig' } },
          { name: { $regex: value, $options: 'ig' } },
          { username: { $regex: value, $options: 'ig' } },
        ];
      }
    }

    fetchData(findQuery);
  }, []);

  const handleSortUserCnt = useCallback(
    (type) => async () => {
      const findQuery = {};
      switch (type) {
        case 'today':
          const today = await commonStore.getServerNow();
          today.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          });
          findQuery.createdAt = { $gte: today.toDate() };
          break;
        case 'block':
          findQuery.isBlock = true;
          break;
        case 'remove':
          findQuery.isRemove = true;
          break;
        default:
          break;
      }

      handleSearch(findQuery);
    },
    [],
  );

  const handleChangeStatus = useCallback(
    (type, id, name) => () => {
      let data;
      let content = '';
      if (type === 'block') {
        content = `${name} 회원이 차단됩니다.`;
        data = { status: '차단', isBlock: true, isRemove: false };
      } else {
        content = `${name} 회원이 탈퇴됩니다.`;
        data = { status: '탈퇴', isBlock: false, isRemove: true };
      }
      window.ask({
        title: '계속 진행하시겠습니까?',
        content,
        async onOk() {
          await update({
            variables: {
              id,
              data,
            },
          });
          handleSearch();
        },
      });
    },
    [],
  );

  const handleChangeRole = useCallback(
    (row) => (value) => {
      let updateData = toJS(state.updateData);
      let fIdx = updateData.findIndex((item) => item.id === row.id);
      if (fIdx !== -1) {
        updateData[fIdx].data.role = value;
      } else {
        updateData.push({ id: row.id, data: { role: value } });
      }
      updateData = uniqBy(updateData, 'id');
      state.updateData = updateData;

      const selectedList = toJS(state.selectedList);
      fIdx = selectedList.findIndex((item) => item.id === row.id);
      if (fIdx !== -1) {
        selectedList[fIdx].role = value;
      } else {
        selectedList.push({ ...row, role: value });
      }
      state.selectedList = selectedList;
      state.selectedRowKeys = selectedList.map((item) => item.id);
    },
    [state.updateData, state.selectedList],
  );

  const handleUpdatePostType = useCallback(() => {
    const selectedList = toJS(state.selectedList);
    const updateData = [];
    for (const item of selectedList) {
      updateData.push({
        id: item.id,
        data: { defPostType: state.defPostType },
      });
    }
    handleUpdate(updateData, 'postTypeOpen');
  }, []);

  const handleUpdate = useCallback(async (updateArr, modalKey) => {
    let updateData = [];
    if (updateArr) {
      updateData = updateArr;
    } else {
      updateData = toJS(state.updateData);
    }

    if (updateData.length) {
      for (const data of updateData) {
        await update({ variables: { ...data } });
      }
      if (modalKey) {
        handleModal(modalKey, false)();
      }
      await handleSearch();
      window.success({ title: '수정이 완료되었습니다.' });
      state.selectedList = [];
      state.selectedRowKeys = [];
      state.updateData = [];
    }
  }, []);

  useEffect(() => {
    if (userCntRes.data && userCntRes.data.userCnt) {
      state.userCnt = userCntRes.data.userCnt;
    }
  }, [userCntRes.data]);

  useEffect(() => {
    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        title: '회원그룹',
        dataIndex: 'userGroup',
        key: 'userGroup',
        align: 'center',
      },
      {
        title: '권한',
        dataIndex: 'role',
        key: 'role',
        render: (_, row) => (
          <Space size={10}>
            <Select
              defaultValue={row.role}
              onChange={handleChangeRole(row)}
              style={{ width: 180 }}
            >
              {ROLES.map((item) => (
                <Option value={item.value} key={`role_${item.value}`}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Space>
        ),
        align: 'center',
      },
      {
        title: '게시물타입',
        dataIndex: 'defPostType',
        key: 'defPostType',
        align: 'center',
      },
      {
        title: '이름',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
      },
      {
        title: '아이디',
        dataIndex: 'username',
        key: 'username',
        align: 'center',
      },
      {
        title: '전화번호',
        dataIndex: 'phone',
        key: 'phone',
        align: 'center',
      },
      {
        title: '업체명',
        dataIndex: 'companyName',
        key: 'companyName',
        align: 'center',
      },
      {
        title: '포인트',
        dataIndex: 'point',
        key: 'point',
        render: (_, row) => numberWithCommas(row.point),
        align: 'center',
      },
      {
        title: '',
        key: 'action',
        render: (_, row) => (
          <>
            <Button type="primary" onClick={handleWorkModal(true, row)} block>
              서버설정
            </Button>
            <Button
              onClick={handleDetail(true, row)}
              block
              style={{ marginTop: 10 }}
            >
              상세보기
            </Button>
          </>
        ),
        width: 140,
        align: 'center',
      },
    ],
    [],
  );

  const mobileColumns = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'idx',
        key: 'idx',
        render: (_, row, idx) => {
          return (
            <Descriptions size="small" bordered layout="vertical" column={1}>
              <Descriptions.Item label="번호">{idx + 1}</Descriptions.Item>
              <Descriptions.Item label="회원그룹">
                {row.userGroup}
              </Descriptions.Item>
              <Descriptions.Item label="권한">
                <Space size={10}>
                  <Select
                    defaultValue={row.role}
                    onChange={handleChangeRole(row)}
                    style={{ width: '100%' }}
                  >
                    {ROLES.map((item) => (
                      <Option value={item.value} key={`role_${item.value}`}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="게시물타입">
                {row.defPostType}
              </Descriptions.Item>
              <Descriptions.Item label="이름">{row.name}</Descriptions.Item>
              <Descriptions.Item label="아이디">
                {row.username}
              </Descriptions.Item>
              <Descriptions.Item label="전화번호">
                {row.phone}
              </Descriptions.Item>
              <Descriptions.Item label="업체명">
                {row.companyName}
              </Descriptions.Item>
              <Descriptions.Item label="포인트">
                {numberWithCommas(row.point)}
              </Descriptions.Item>
              <Descriptions.Item className="action">
                <Button
                  type="primary"
                  onClick={handleWorkModal(true, row)}
                  block
                >
                  서버설정
                </Button>
                <Button
                  onClick={handleDetail(true, row)}
                  block
                  style={{ marginTop: 10 }}
                >
                  상세보기
                </Button>
              </Descriptions.Item>
            </Descriptions>
          );
        },
      },
    ],
    [],
  );

  const rowSelection = {
    type: 'checkbox',
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRows.length) {
        state.selectedList = selectedRows;
        state.selectedRowKeys = selectedRowKeys;
      } else {
        state.selectedList = [];
        state.selectedRowKeys = [];
      }
    },
    selectedRowKeys: state.selectedRowKeys,
  };

  return (
    <Wrapper>
      <Card
        title={
          <Row gutter={16} align="middle">
            <Col xs={24} lg={2}>
              전체목록
            </Col>
            <Col xs={24} lg={22}>
              {state.userCnt && (
                <Space size={10}>
                  <span>
                    오늘 가입{' '}
                    <Button type="link" onClick={handleSortUserCnt('today')}>
                      {state.userCnt.todayCnt}
                    </Button>
                    명
                  </span>
                  <span>/</span>
                  <span>
                    총 회원 수{' '}
                    <Button type="link" onClick={handleSortUserCnt('all')}>
                      {state.userCnt.allCnt}
                    </Button>
                    명
                  </span>
                  <span>/</span>
                  <span>
                    차단{' '}
                    <Button type="link" onClick={handleSortUserCnt('block')}>
                      {state.userCnt.blockCnt}
                    </Button>
                    명
                  </span>
                  <span>/</span>
                  <span>
                    탈퇴{' '}
                    <Button type="link" onClick={handleSortUserCnt('remove')}>
                      {state.userCnt.removeCnt}
                    </Button>
                    명
                  </span>
                </Space>
              )}
            </Col>
          </Row>
        }
        size="small"
      >
        <Row gutter={16} align="middle">
          <Col xs={24} lg={2}>
            <strong>전체검색</strong>
          </Col>
          <Col xs={24} lg={2}>
            <Select
              value={state.searchType}
              onChange={handleChangeSearch}
              style={{ width: '100%' }}
            >
              <Option value={null}>전체</Option>
              <Option value={1}>회원그룹</Option>
              <Option value={2}>업체명</Option>
              <Option value={4}>전화번호</Option>
              <Option value={5}>이름</Option>
              <Option value={6}>아이디</Option>
            </Select>
          </Col>
          <Col xs={24} lg={5}>
            <Search
              onChange={handleChangeInput('searchText')}
              onSearch={handleSearch}
              enterButton
            />
          </Col>
        </Row>
      </Card>

      <Row
        gutter={16}
        justify="end"
        style={{ marginTop: 20, marginBottom: 15 }}
      >
        <Col>
          <Button
            type="primary"
            ghost
            disabled={!state.selectedList.length}
            onClick={handleUpdate}
          >
            선택 권한수정
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            ghost
            disabled={!state.selectedList.length}
            onClick={handleModal('postTypeOpen', true)}
          >
            선택 게시물타입수정
          </Button>
        </Col>
      </Row>

      <Table
        size="small"
        bordered
        dataSource={toJS(state.list)}
        columns={screen.lg ? columns : mobileColumns}
        // scroll={{ x: 992, y: 400 }}
        pagination={{
          current: state.paginate.page,
          pageSize: state.paginate.limit,
          total: state.paginate.totalDocs,
          onChange: handleChangePage,
        }}
        rowSelection={rowSelection}
        rowKey={(row) => row.id}
      />

      <CustomModal
        wrapClassName="full-screen-modal"
        visible={state.detailOpen}
        onCancel={handleDetail(false)}
        forceRender={true}
        footer={null}
      >
        {state.selectedData && state.selectedData.id && (
          <Detail
            selectedData={toJS(state.selectedData)}
            refetch={handleSearch}
          />
        )}
      </CustomModal>

      <CustomModal
        visible={state.workOpen}
        onCancel={handleWorkModal(false)}
        forceRender={true}
        footer={null}
        minwidth={1200}
      >
        {state.selectedWorkData && state.selectedWorkData.id && (
          <div style={{ marginTop: 30 }}>
            <Work user={toJS(state.selectedWorkData)} />
          </div>
        )}
      </CustomModal>

      <CustomModal
        visible={state.postTypeOpen}
        onCancel={handleModal('postTypeOpen', false)}
        forceRender={true}
        footer={null}
        centered={true}
        minwidth={10}
        maxwidth="320px"
      >
        <Row gutter={[10, 10]} justify="center" align="middle">
          <Col>
            <Select
              value={state.defPostType}
              onChange={handleChangeSelect('defPostType')}
              style={{ width: 120 }}
            >
              <Option value="">설정안함</Option>
              <Option value="없음">없음</Option>
            </Select>
          </Col>
          <Col>
            <Button type="primary" onClick={handleUpdatePostType}>
              저장
            </Button>
          </Col>
        </Row>
      </CustomModal>
    </Wrapper>
  );
});

export default MemberStatus;
