/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback, useMemo } from 'react';
import {
  Table,
  Row,
  Col,
  Space,
  DatePicker,
  Button,
  Input,
  Select,
  Grid,
  Descriptions,
  Checkbox,
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import moment from 'moment';
import { useApolloClient, useQuery, useMutation } from '@apollo/react-hooks';
import { toJS } from 'mobx';
import uniqid from 'uniqid';

import useStore from '@stores/useStore';
import { SAVE_META, META } from '@shared/queries';
import {
  ACCOUNT_ERRORS,
  REMOVE_ACCOUNT_ERROR,
} from '@shared/queries/AccountErrorQueries';

import CustomModal from '@Common/CustomModal';

const { Search } = Input;
const { Option } = Select;
const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  width: 100%;

  .error {
    color: ${(props) => (props.error ? '#e74c3c' : props.theme.primaryColor)};
  }
`;

const AccountErrors = observer(({}) => {
  const screen = useBreakpoint();
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    account_error_sms_list: [],
    list: [],
    paginate: {
      docs: [],
      totalDocs: 0,
      limit: 10,
      page: 1,
      totalPages: 1,
    },

    selectedList: [],
    selectedRowKeys: [],

    smsOpen: false,
    selectedData: {},

    searchType: null,
    startDate: null,
    endDate: null,

    checkedSmsList: [],
  }));

  const client = useApolloClient();
  const [remove] = useMutation(REMOVE_ACCOUNT_ERROR);
  const [saveMeta] = useMutation(SAVE_META);

  const fetchMetaData = useCallback(async () => {
    state.account_error_sms_list = [];
    state.checkedSmsList = [];

    const result = await client.query({
      query: META,
      variables: { key: 'account_error_sms_list' },
    });
    if (result.data && result.data.meta) {
      state.checkedSmsList = result.data.meta.value;
      setTimeout(() => {
        state.account_error_sms_list = result.data.meta.value;
      }, 10);
    }
  }, []);

  const fetchData = useCallback(async (findQuery = {}) => {
    window.maskOn();

    const result = await client.query({
      query: ACCOUNT_ERRORS,
      variables: {
        page: state.paginate.page,
        limit: state.paginate.limit,
        findQuery,
      },
    });

    if (result.data && result.data.accountErrors) {
      state.paginate = result.data.accountErrors;
      state.list = result.data.accountErrors.docs;
    }
    window.maskOff();
  }, []);

  const handleChangeSmsChecked = useCallback(
    (idx) => (e) => {
      const checkedSmsList = toJS(state.checkedSmsList);
      checkedSmsList[idx].checked = e.target.checked;
      state.checkedSmsList = checkedSmsList;
    },
    [],
  );

  const handleChangeInput = useCallback(
    (type, idx) => (e) => {
      const list = toJS(state.account_error_sms_list);
      let value = e.target.value;
      if (type === 'phone') {
        value = value.replace(/-/g, '');
      }
      list[idx][type] = value;
      state.account_error_sms_list = list;
    },
    [],
  );

  const handleAddSmsList = useCallback(async () => {
    const list = toJS(state.account_error_sms_list);
    const checkedSmsList = toJS(state.checkedSmsList);
    const uid = uniqid.time();
    list.push({ uid, name: '', phone: '' });
    checkedSmsList.push({ uid, checked: false });
    state.account_error_sms_list = list;
    state.checkedSmsList = list;
  }, []);

  const handleRemoveSmsList = useCallback((e) => {
    const checkedSmsList = toJS(state.checkedSmsList);
    const list = toJS(state.account_error_sms_list);
    window.ask({
      title: '계속 진행하시겠습니까?',
      content: '선택하신 전송목록이 삭제됩니다.',
      async onOk() {
        for (const item of checkedSmsList) {
          if (item.checked) {
            const fIdx = list.findIndex((fItem) => fItem.uid === item.uid);
            if (fIdx !== -1) {
              list.splice(fIdx, 1);
            }
          }
        }
        state.account_error_sms_list = list;
      },
    });
  }, []);

  const handleSubmitSmsList = useCallback(async (e) => {
    window.maskOn();
    const list = toJS(state.account_error_sms_list);

    for (let i = list.length - 1; i >= 0; i--) {
      if (!list[i].name.length || !list[i].phone.length) {
        list.splice(i, 1);
      }
    }
    await saveMeta({
      variables: {
        key: 'account_error_sms_list',
        data: {
          key: 'account_error_sms_list',
          value: list,
        },
      },
    });
    await fetchMetaData();
    window.maskOff();
    window.success({ title: '전송목록이 저장되었습니다.' });
  }, []);

  const handleSmsModal = useCallback(
    (modalOpen, data) => (e) => {
      if (modalOpen && data) {
        state.selectedData = data;
      } else {
        state.selectedData = {};
      }
      state.smsOpen = modalOpen;
    },
    [],
  );

  const handleChangePage = useCallback((page) => {
    if (state.paginate.page !== page) {
      state.paginate.page = page;
      fetchData();
    }
  }, []);

  const handleChangeSearch = useCallback(
    (type) => (value) => {
      state[type] = value;
    },
    [],
  );
  const handleChangeDate = useCallback(
    (type) => (momentVal) => {
      state[type] = momentVal;
    },
    [],
  );

  const handleSearch = useCallback((value) => {
    const findQuery = {};

    if (value && value.length) {
      if (state.searchType) {
        findQuery[state.searchType] = { $regex: value, $options: 'ig' };
      } else {
        findQuery['$or'] = [];
        findQuery['$or'].push({ instaId: { $regex: value, $options: 'ig' } });
        findQuery['$or'].push({
          companyName: { $regex: value, $options: 'ig' },
        });
      }
    }

    findQuery['$and'] = [];
    if (state.startDate) {
      findQuery['$and'].push({
        updatedAt: {
          $gte: state.startDate.startOf('date').toDate(),
        },
      });
    }
    if (state.endDate) {
      findQuery['$and'].push({
        updatedAt: {
          $lte: state.endDate.endOf('date').toDate(),
        },
      });
    }
    if (!findQuery['$and'].length) {
      delete findQuery['$and'];
    }

    fetchData(findQuery);
  }, []);

  const handleRemove = useCallback(
    (row, isAll) => () => {
      window.ask({
        title: '계속 진행하시겠습니까?',
        content: '선택하신 스팸내역이 삭제됩니다.',
        async onOk() {
          if (isAll) {
            const ids = toJS(state.selectedRowKeys);
            for (const id of ids) {
              await remove({ variables: { id } });
            }
          } else {
            await remove({ variables: { id: row.id } });
          }
          handleSearch();
        },
      });
    },
    [],
  );

  useEffect(() => {
    fetchData();
    fetchMetaData();
  }, []);

  const columns = useMemo(
    () => [
      {
        title: '날짜',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt) =>
          createdAt ? moment(createdAt).format('YYYY-MM-DD HH:mm:ss') : '',
        align: 'center',
      },
      {
        title: '아이디',
        dataIndex: 'instaId',
        key: 'instaId',
        render: (_, row) => row.instaId,
        align: 'center',
      },
      {
        title: '업체명',
        dataIndex: 'companyName',
        key: 'companyName',
        align: 'center',
      },
      {
        title: '사유',
        dataIndex: 'reason',
        key: 'reason',
        align: 'center',
      },
      {
        title: '상태',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (_, row) => (
          <Button type="primary" onClick={handleRemove(row)} danger>
            삭제
          </Button>
        ),
        align: 'center',
      },
    ],
    [],
  );

  const mobileColumns = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'idx',
        key: 'idx',
        render: (_, row, idx) => {
          return (
            <Descriptions size="small" bordered column={1}>
              <Descriptions.Item label="날짜">
                {row.createdAt
                  ? moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')
                  : ''}
              </Descriptions.Item>
              <Descriptions.Item label="아이디">
                {row.instaId}
              </Descriptions.Item>
              <Descriptions.Item label="업체명">
                {row.companyName}
              </Descriptions.Item>
              <Descriptions.Item label="사유">{row.reason}</Descriptions.Item>
              <Descriptions.Item label="상태">{row.status}</Descriptions.Item>
              <Descriptions.Item label="">
                <Button type="primary" onClick={handleRemove(row)} danger>
                  삭제
                </Button>
              </Descriptions.Item>
            </Descriptions>
          );
        },
      },
    ],
    [],
  );

  const rowSelection = {
    type: 'checkbox',
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRows.length) {
        state.selectedList = selectedRows;
        state.selectedRowKeys = selectedRows.map((item) => item.id);
      } else {
        state.selectedList = [];
        state.selectedRowKeys = [];
      }
    },
    selectedRowKeys: toJS(state.selectedRowKeys),
  };

  return (
    <Wrapper>
      <Row gutter={[32, 16]} align="bottom">
        <Col xs={24} lg={6}>
          <h4>기간별 검색</h4>
          <Row gutter={[16, 0]}>
            <Col xs={24} lg={16}>
              <Space>
                <DatePicker
                  placeholder="시작일"
                  value={state.startDate}
                  onChange={handleChangeDate('startDate')}
                />
                <DatePicker
                  placeholder="종료일"
                  value={state.endDate}
                  onChange={handleChangeDate('endDate')}
                />
              </Space>
            </Col>
            <Col xs={24} lg={6}>
              <Button type="primary" block onClick={handleSearch}>
                검색
              </Button>
            </Col>
          </Row>
        </Col>

        <Col xs={24} lg={6}>
          <h4>전체검색</h4>
          <Space>
            <Select
              value={state.searchType}
              onChange={handleChangeSearch('searchType')}
              style={{ width: 120 }}
            >
              <Option value={null}>전체</Option>
              <Option value="instaId">아이디</Option>
              <Option value="companyName">업체명</Option>
            </Select>
            <Search onSearch={handleSearch} enterButton />
          </Space>
        </Col>

        <Col
          xs={24}
          lg={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Space>
            <Button onClick={handleSmsModal(true)}>스팸내역 전송목록</Button>
            <Button
              danger
              onClick={handleRemove(null, true)}
              disabled={!state.selectedRowKeys.length}
            >
              선택삭제
            </Button>
          </Space>
        </Col>
      </Row>

      <Table
        dataSource={toJS(state.list)}
        columns={screen.lg ? columns : mobileColumns}
        // scroll={{ x: 992 }}
        pagination={{
          current: state.paginate.page,
          total: state.paginate.totalDocs,
          showSizeChanger: false,
          onChange: handleChangePage,
        }}
        rowKey={(row) => row.id}
        rowSelection={rowSelection}
      />

      <CustomModal
        visible={state.smsOpen}
        onCancel={handleSmsModal(false)}
        onOk={handleSubmitSmsList}
        okText="저장"
        forceRender={true}
      >
        <Row gutter={[10, 10]} justify="end" style={{ marginTop: 30 }}>
          <Col>
            <Button
              ghost
              danger
              onClick={handleRemoveSmsList}
              disabled={!state.checkedSmsList.length}
            >
              선택삭제
            </Button>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          {toJS(state.account_error_sms_list).map((item, idx) => (
            <Col xs={24}>
              <Space>
                <Checkbox
                  checked={
                    toJS(state.checkedSmsList)[idx].checked === undefined
                      ? false
                      : toJS(state.checkedSmsList)[idx].checked
                  }
                  onChange={handleChangeSmsChecked(idx)}
                />
                <Input
                  value={item.name}
                  placeholder="이름"
                  onChange={handleChangeInput('name', idx)}
                />
                <Input
                  value={item.phone}
                  placeholder="- 없이 입력(전송번호)"
                  onChange={handleChangeInput('phone', idx)}
                />
              </Space>
            </Col>
          ))}
        </Row>

        <Row justify="center" style={{ marginTop: 15 }}>
          <Col>
            <Button icon={<PlusCircleOutlined />} onClick={handleAddSmsList}>
              추가
            </Button>
          </Col>
        </Row>
      </CustomModal>
    </Wrapper>
  );
});

export default AccountErrors;
