/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Divider, Space, Row, Col } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { observer, useLocalStore } from 'mobx-react';
import { toJS } from 'mobx';
import styled from 'styled-components';
import { lighten } from 'polished';

import useStore from '@stores/useStore';

import SiteTitle from '@Styled/SiteTitle';
import UserForm from '@Common/User/UserForm';

import SignupAgree from './SignupAgree';

const { Header, Content, Footer } = Layout;

const Wrapper = styled(Layout)`
  .content-layout {
    display: block;
  }
  .header {
    position: fixed;
    width: 100%;
    z-index: 1;
    padding: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background: #fff;
    height: 66px;

    .header-left,
    .header-right {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ${(props) =>
      !props.isadminlayout &&
      `
      background-color: #fff;
    `};
  }
  .content {
    padding: 24px 16px 0;
  }
  .content-children {
    background-color: #fff;
    padding: 2rem;
    min-height: 500px;
  }
  .footer {
    text-align: center;
  }
  .profile {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0.5rem;
    border: 1px dashed #e0e0e0;
    padding: 0.5rem;

    div {
      width: 100%;
      text-align: center;
    }
  }
  .trigger {
    font-size: 18px;
  }

  ${(props) => props.theme.breakpoint('xs', 'lg')`
    .header-right {
      display: none !important;
    }
  `}
`;

const SubHeader = styled(Header)`
  background: ${(props) => lighten(0.1, props.theme.primaryColor)};
  color: #fff;
`;

const Signup = observer((props) => {
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    isAgree: false,
    setAgree(value) {
      this.isAgree = value;
    },
  }));

  return (
    <Wrapper
      isadminlayout={commonStore.isAdminLayout ? 1 : 0}
      collapsed={state.collapsed ? 1 : 0}
    >
      <Layout className="content-layout">
        {state.isAgree ? (
          <>
            <SubHeader>
              <Row justify="center" align="middle">
                {/* <Col>
                  <SiteTitle size="24px" color="#fff" highlight="#fff" />
                </Col> */}
                <Col>
                  <Space size={32}>
                    <Link to="/">홈</Link>
                    <Link to="/login">로그인</Link>
                  </Space>
                </Col>
              </Row>
            </SubHeader>
            <Content className="content">
              <Row justify="center">
                {/* <Col xs={24}>
                  <SiteTitle
                    size="36px"
                    color="#545253"
                    highlight="#545253"
                    style={{ marginBottom: 20 }}
                  />
                </Col> */}
                <Col xs={24} lg={6}>
                  <UserForm />
                </Col>
              </Row>
            </Content>
          </>
        ) : (
          <SignupAgree setAgree={state.setAgree} />
        )}

        <Space style={{ display: 'flex', justifyContent: 'center' }}>
          <Link to="/">홈</Link>
          <Divider type="vertical" />
          <Link to="/login">로그인</Link>
        </Space>
        <Footer className="footer">
          © 2020. 인싸닷컴. ALL RIGHTS RESERVED.
        </Footer>
      </Layout>
    </Wrapper>
  );
});

export default Signup;
