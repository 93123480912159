/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Row, Col, Form, Input, Button } from 'antd';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';

import useStore from '@stores/useStore';
import { ADD_COMMENT, UPDATE_COMMENT } from '@shared/queries/CommentQueries';
import { validateMessages } from '@utils/customFormValidator';

const { TextArea } = Input;

const Wrapper = styled.div`
  width: 100%;
  padding-top: 20px;
`;

const CommentModify = observer(
  ({ propData = {}, board, postId, parent, handleFinish }) => {
    const [form] = Form.useForm();
    const { commonStore } = useStore();

    const [add] = useMutation(ADD_COMMENT);
    const [update] = useMutation(UPDATE_COMMENT);

    const handleSubmit = useCallback(
      async (values) => {
        const data = {
          ...values,
          board,
          post: postId,
          created: commonStore.user.id,
        };

        if (parent) {
          data.parent = parent;
        }

        let message = '답글 등록이 완료되었습니다.';
        if (propData && propData.id) {
          await update({ variables: { id: propData.id, data } });
          message = '답글 수정이 완료되었습니다.';
        } else {
          await add({ variables: { data } });
        }
        window.success({ title: message });
        form.resetFields();
        handleFinish();
      },
      [form, board, postId, parent, propData],
    );

    useEffect(() => {
      if (form) {
        form.resetFields();
      }
    }, [form, propData]);

    return (
      <Wrapper>
        <Form
          form={form}
          name={`comment_reply_${propData.id || parent}`}
          preserve={false}
          initialValues={{
            content: propData.content || '',
          }}
          validateMessages={validateMessages}
          onFinish={handleSubmit}
          scrollToFirstError
          autoComplete="off"
        >
          <Row>
            <Col xs={24} lg={20}>
              <Form.Item name="content" rules={[{ required: true }]} noStyle>
                <TextArea
                  placeholder="답글을 입력해 주세요"
                  rows={3}
                  autoSize={{ minRows: 3, maxRows: 3 }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={4}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!Boolean(commonStore.user)}
                block
                style={{ height: '100%' }}
              >
                {propData && propData.id ? '수정' : parent ? '답글' : '등록'}
              </Button>
            </Col>
          </Row>
        </Form>
      </Wrapper>
    );
  },
);

export default CommentModify;
