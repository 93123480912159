/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback, useMemo } from 'react';
import {
  Table,
  Popover,
  Button,
  Input,
  Select,
  Grid,
  Descriptions,
  Row,
  Col,
  Card,
  Space,
  Tooltip,
  Image,
} from 'antd';
import { VideoCameraOutlined } from '@ant-design/icons';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import moment from 'moment';
import { useApolloClient, useQuery, useMutation } from '@apollo/react-hooks';
import { toJS } from 'mobx';
import ReactPlayer from 'react-player';

import useStore from '@stores/useStore';
import { WORKS, REMOVE_WORK, UPDATE_WORK } from '@shared/queries/WorkQueries';

import CustomModal from '@Common/CustomModal';

import Modify from './Modify';
import Rank from './Rank';
import Detail from './Detail';

const { Search } = Input;
const { Option } = Select;
const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  width: 100%;

  .error {
    color: ${(props) => (props.error ? '#e74c3c' : props.theme.primaryColor)};
  }

  .data-row {
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .child-row {
    margin-top: 5px;
    margin-bottom: 5px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .list-img {
    width: 50px;
    height: 50px;
  }

  .insta-post {
    margin-right: 15px;
  }
`;

const WorkList = observer(
  ({
    workType,
    company,
    isRefetchWorkList,
    setRefetchWorkList,
    isAdmin,
    findQuery,
    user,
    ppCaseByType,
    programType,
  }) => {
    const screen = useBreakpoint();
    const { commonStore } = useStore();
    const state = useLocalStore(() => ({
      list: [],
      paginate: {
        docs: [],
        totalDocs: 0,
        limit: 10,
        page: 1,
        totalPages: 1,
      },

      searchType: null,
      startDate: null,
      endDate: null,

      modifyData: null,
      modifyModalOpen: false,
      modifyModalTitle: '',

      rankData: null,
      rankModalOpen: false,

      detailData: null,
      detailOpen: false,

      images: [],
      imageModalOpen: false,

      videos: [],
      videoModalOpen: false,

      selectedRowKeys: [],
      selectedList: [],
    }));

    const client = useApolloClient();
    const [update] = useMutation(UPDATE_WORK);
    const [remove] = useMutation(REMOVE_WORK);

    const fetchData = useCallback(
      async (query = {}) => {
        window.maskOn();

        if (/^k/i.test(workType)) {
          query.workType = { $regex: `^${workType}`, $options: 'ig' };
        } else {
          if (programType) {
            query.programType = programType;
          }
          query.workType = workType;
        }
        if (!user && !isAdmin) {
          query.user = commonStore.user.id;
          if (workType !== '최적화테스트') {
            query.company = company.id;
          }
        } else if (user) {
          query.user = user.id;
        }

        const result = await client.query({
          query: WORKS,
          variables: {
            page: state.paginate.page,
            limit: state.paginate.limit,
            findQuery: query,
          },
        });

        if (result.data && result.data.works) {
          state.paginate = result.data.works;
          state.list = result.data.works.docs;
        }
        window.maskOff();
      },
      [workType, company, isAdmin, user, programType],
    );

    const handleChangePage = useCallback(
      (page) => {
        if (state.paginate.page !== page) {
          state.paginate.page = page;
          fetchData(findQuery);
        }
      },
      [workType, company, findQuery, user, programType],
    );

    const handleModifyModal = useCallback(
      (modalOpen, modalTitle, data) => () => {
        if (modalTitle) {
          state.modifyModalTitle = `${
            data.company ? data.company.name : ''
          } ${modalTitle} 관리설정`;
        }
        if (modalOpen && data) {
          state.modifyData = data;
        } else {
          state.modifyData = null;
        }
        state.modifyModalOpen = modalOpen;
      },
      [],
    );

    const handleRankModal = useCallback(
      (modalOpen, data) => (e) => {
        if (modalOpen && data) {
          state.rankData = data;
        } else {
          state.rankData = {};
        }
        state.rankModalOpen = modalOpen;
      },
      [],
    );

    const handleDetailModal = useCallback(
      (modalOpen, data) => (e) => {
        if (modalOpen && data) {
          state.detailData = data;
        } else {
          state.detailData = {};
        }
        state.detailOpen = modalOpen;
      },
      [],
    );

    const handleImageModal = useCallback(
      (modalOpen, data) => (e) => {
        if (modalOpen && data) {
          state.images = data.images;
        } else {
          state.images = [];
        }
        state.imageModalOpen = modalOpen;
      },
      [],
    );

    const handleVideoModal = useCallback(
      (modalOpen, data) => (e) => {
        if (modalOpen && data) {
          state.videos = data.videos;
        } else {
          state.videos = [];
        }
        state.videoModalOpen = modalOpen;
      },
      [],
    );

    const handleRun = useCallback(
      (row) => (e) => {
        return window.ask({
          title: '계속 진행하시겠습니까?',
          content: `${row.company.name} ${row.workType} 작업을 ${
            row.isActivated ? '일시정지' : '다시시작'
          } 하시겠습니까?`,
          async onOk() {
            await update({
              variables: {
                id: row.id,
                data: {
                  isActivated: !Boolean(row.isActivated),
                },
              },
            });
            fetchData(findQuery);
          },
        });
      },
      [findQuery, programType],
    );

    const handleRemove = useCallback(
      (row, isAll) => () => {
        window.ask({
          title: '계속 진행하시겠습니까?',
          content: '선택하신 작업이 삭제됩니다.',
          async onOk() {
            if (isAll) {
              const ids = toJS(state.selectedRowKeys);
              for (const id of ids) {
                await remove({ variables: { id } });
              }
            } else {
              await remove({ variables: { id: row.id } });
            }
            fetchData(findQuery);
          },
        });
      },
      [findQuery, programType],
    );

    const mobileRender = (row) => {
      let valKey = 'buyCaseData';
      if (row.paymentType === '월구매') {
        valKey = 'buyMonthData';
      } else if (row.paymentType === '고객월건바이') {
        valKey = 'buyCustomerMonthData';
      }
      return row.isInstaPostRandom && row.randomInstaPostData ? (
        <Row gutter={[10, 10]} key={`${row.id}_mobile`}>
          <Col xs={24}>
            <Card style={{ marginBottom: 10 }}>
              <Space
                style={{
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'flex-start',
                }}
              >
                <div className="insta-post">
                  <h4>이미지</h4>
                  <img
                    className="list-img click"
                    src={
                      row.randomInstaPostData.images &&
                      row.randomInstaPostData.images[0]
                        ? row.randomInstaPostData.images[0].url
                        : '/images/no-image.png'
                    }
                    onClick={handleImageModal(true, row.randomInstaPostData)}
                  />
                </div>
                <div className="insta-post">
                  <h4>동영상</h4>
                  {row.randomInstaPostData.videos &&
                  row.randomInstaPostData.videos.length ? (
                    <Button
                      icon={<VideoCameraOutlined />}
                      onClick={handleVideoModal(true, row.randomInstaPostData)}
                    />
                  ) : (
                    ''
                  )}
                </div>
                <div className="insta-post">
                  <h4>URL</h4>
                  {row.randomInstaPostData.url
                    ? row.randomInstaPostData.url
                    : ''}
                </div>

                {Array.isArray(row.randomInstaPostData.instaContent) ? (
                  row.randomInstaPostData.instaContent.map((item, idx) => (
                    <div
                      className="insta-post"
                      key={`${row.id}_randomInstaPostData_instaContent_${idx}`}
                    >
                      <h4>내용 {idx + 1}</h4>
                      <pre>{item}</pre>
                    </div>
                  ))
                ) : (
                  <div className="insta-post">
                    <h4>내용</h4>
                    <pre>
                      {row.randomInstaPostData.instaContent
                        ? row.randomInstaPostData.instaContent
                        : ''}
                    </pre>
                  </div>
                )}

                <div className="insta-post">
                  <h4>해시태그</h4>
                  {row.randomInstaPostData.mainHashtag
                    ? row.randomInstaPostData.mainHashtag
                    : ''}
                </div>
                {row.paymentType !== '고객월건바이' && (
                  <div className="insta-post">
                    <h4>부해시태그</h4>
                    <pre>
                      {row.randomInstaPostData.hashtags ? (
                        <pre>{row.randomInstaPostData.hashtags}</pre>
                      ) : (
                        ''
                      )}
                    </pre>
                  </div>
                )}
              </Space>
            </Card>
          </Col>
          <Col xs={24}>
            {row[valKey].map((item) => (
              <Row gutter={[10, 10]} key={`${row.id}_mobile_${item.uid}`}>
                {item.instaPostData
                  ? item.instaPostData.map((pData, idx) => (
                      <Col xs={24} key={`${row.id}_mobile_${item.uid}_${idx}`}>
                        <Card style={{ marginBottom: 10 }}>
                          <Space
                            style={{
                              width: '100%',
                              display: 'flex',
                              flexWrap: 'wrap',
                              alignItems: 'flex-start',
                            }}
                          >
                            <div className="insta-post">
                              <h4>순위</h4>
                              {getMonthDataRank(row, pData)}
                            </div>
                            {isAdmin && (
                              <div className="insta-post">
                                <h4>좋아요</h4>
                                {pData.likeCnt ? pData.likeCnt : ''}
                              </div>
                            )}
                            {isAdmin && (
                              <div className="insta-post">
                                <h4>댓글</h4>
                                {pData.commentCnt ? pData.commentCnt : ''}
                              </div>
                            )}
                            {isAdmin && (
                              <div className="insta-post">
                                <h4>트래픽횟수</h4>
                                {pData.trafficCnt ? pData.trafficCnt : ''}
                              </div>
                            )}
                          </Space>
                        </Card>
                      </Col>
                    ))
                  : ''}
              </Row>
            ))}
          </Col>
        </Row>
      ) : (
        row[valKey].map((item) => (
          <Row gutter={[10, 10]} key={`${row.id}_mobile_${item.uid}`}>
            {item.instaPostData
              ? item.instaPostData.map((pData, idx) => (
                  <Col xs={24} key={`${row.id}_mobile_${item.uid}_${idx}`}>
                    <Card style={{ marginBottom: 10 }}>
                      <Space
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexWrap: 'wrap',
                          alignItems: 'flex-start',
                        }}
                      >
                        <div className="insta-post">
                          <h4>이미지</h4>
                          <img
                            className="list-img click"
                            src={
                              pData.images && pData.images[0]
                                ? pData.images[0].url
                                : '/images/no-image.png'
                            }
                            onClick={handleImageModal(true, pData)}
                          />
                        </div>
                        <div className="insta-post">
                          <h4>동영상</h4>
                          {pData.videos && pData.videos.length ? (
                            <Button
                              icon={<VideoCameraOutlined />}
                              onClick={handleVideoModal(true, pData)}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="insta-post">
                          <h4>URL</h4>
                          {pData.url ? pData.url : ''}
                        </div>
                        <div className="insta-post">
                          <h4>내용</h4>
                          {pData.instaContent ? pData.instaContent : ''}
                        </div>
                        <div className="insta-post">
                          <h4>해시태그</h4>
                          {pData.mainHashtag ? pData.mainHashtag : ''}
                        </div>
                        {row.paymentType !== '고객월건바이' && (
                          <div className="insta-post">
                            <h4>부해시태그</h4>
                            <pre>{pData.hashtags ? pData.hashtags : ''}</pre>
                          </div>
                        )}

                        <div className="insta-post">
                          <h4>순위</h4>
                          {getMonthDataRank(row, pData)}
                        </div>
                        {isAdmin && (
                          <div className="insta-post">
                            <h4>좋아요</h4>
                            {pData.likeCnt ? pData.likeCnt : ''}
                          </div>
                        )}
                        {isAdmin && (
                          <div className="insta-post">
                            <h4>댓글</h4>
                            {pData.commentCnt ? pData.commentCnt : ''}
                          </div>
                        )}
                        {isAdmin && (
                          <div className="insta-post">
                            <h4>트래픽횟수</h4>
                            {pData.trafficCnt ? pData.trafficCnt : ''}
                          </div>
                        )}
                      </Space>
                    </Card>
                  </Col>
                ))
              : ''}
          </Row>
        ))
      );
    };

    useEffect(() => {
      if (isRefetchWorkList && setRefetchWorkList) {
        setRefetchWorkList(false);
        fetchData(findQuery);
      }
    }, [isRefetchWorkList, setRefetchWorkList, findQuery, user, programType]);

    useEffect(() => {
      fetchData(findQuery);
    }, [workType, company, isAdmin, findQuery, user, programType]);

    const getMonthDataRank = (row, pData) => {
      // let rankType = 'caseDataRank';
      // if (row.paymentType === '월구매') {
      //   rankType = 'monthDataRank';
      // } else {
      //   rankType = 'caseDataRank';
      // }
      // let value = '';
      // if (row.rank && row.rank[rankType]) {
      //   const f = row.rank[rankType].find((r) => r.uid === pData.uid);
      //   if (f) {
      //     if (f && f.hasOwnProperty('interval')) {
      //       if (f.interval === 0) {
      //         value = (
      //           <div onClick={handleRankModal(true, row)}>
      //             {f.rank}
      //             <span>(-)</span>
      //           </div>
      //         );
      //       } else if (f.interval < 0) {
      //         value = (
      //           <div onClick={handleRankModal(true, row)}>
      //             {f.rank}
      //             <span className="red">(▲{Math.abs(f.interval)})</span>
      //           </div>
      //         );
      //       } else {
      //         value = (
      //           <div onClick={handleRankModal(true, row)}>
      //             {f.rank}
      //             <span className="blue">(▼{Math.abs(f.interval)})</span>
      //           </div>
      //         );
      //       }
      //     } else if (f.rank) {
      //       value = <span>{f.rank}(-)</span>;
      //     }
      //   }
      // }

      // return value;
      return pData.currentRank;
    };

    const columns = useMemo(() => {
      let value = [
        {
          title: '날짜',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (createdAt) =>
            createdAt ? moment(createdAt).format('MM-DD HH:mm') : '',
          align: 'center',
        },
      ];

      if (isAdmin) {
        value = value.concat([
          {
            title: '이름',
            dataIndex: 'name',
            key: 'name',
            render: (_, row) => (row.user ? row.user.name : ''),
            align: 'center',
          },
          {
            title: '아이디',
            dataIndex: 'username',
            key: 'username',
            render: (_, row) => (row.user ? row.user.username : ''),
            align: 'center',
          },
        ]);
        if (workType !== '최적화테스트') {
          value.push({
            title: '업체명',
            dataIndex: 'companyName',
            key: 'companyName',
            render: (_, row) => (row.company ? row.company.name : ''),
            align: 'center',
          });
        }
      }

      if (
        (workType === '인기게시물건바이' && ppCaseByType === '고객월건바이') ||
        workType !== '인기게시물건바이' ||
        ppCaseByType !== '건구매'
      ) {
        value.push({
          title: '인스타아이디',
          dataIndex: 'instaId',
          key: 'instaId',
          align: 'center',
        });
      }

      if (/^k/i.test(workType)) {
        if (workType !== 'K팔로워') {
          value.push({
            title: 'URL',
            dataIndex: 'url',
            key: 'url',
            align: 'center',
          });
        }
        value.push({
          title: '개수',
          dataIndex: 'buyCnt',
          key: 'buyCnt',
          align: 'center',
        });
        value.push({
          title: '진행률',
          dataIndex: 'progressRate',
          key: 'progressRate',
          render: (_, row) => (row.progressRate ? `${row.progressRate}%` : ''),
          align: 'center',
        });
        if (workType !== 'K팔로워') {
          value.push({
            title: '작업일수',
            dataIndex: 'counting',
            key: 'counting',
            render: (_, row) =>
              row.counting && row.maxCounting ? (
                <span>
                  {row.counting}/{row.maxCounting}일
                </span>
              ) : (
                ''
              ),
            align: 'center',
          });
        }
      } else if (workType === '인기게시물') {
        if (programType === '최적화아이디') {
          value = value.concat([
            {
              title: '이미지',
              dataIndex: 'image',
              key: 'image',
              render: (_, row) => {
                return (
                  <img
                    className="list-img click"
                    src={
                      row.images && row.images[0]
                        ? row.images[0].url
                        : '/images/no-image.png'
                    }
                    onClick={handleImageModal(true, row)}
                  />
                );
              },
              align: 'center',
            },
            {
              title: '동영상',
              dataIndex: 'videos',
              key: 'videos',
              render: (_, row) => {
                return row.videos && row.videos.length ? (
                  <Button
                    icon={<VideoCameraOutlined />}
                    onClick={handleVideoModal(true, row)}
                  />
                ) : (
                  ''
                );
              },
              align: 'center',
            },
            {
              title: '내용',
              dataIndex: 'instaContentText',
              key: 'instaContentText',
              render: (_, row) =>
                row.instaContentText && row.instaContentText ? (
                  <Tooltip title={<pre>{row.instaContentText}</pre>}>
                    {row.instaContentText}
                  </Tooltip>
                ) : (
                  ''
                ),
              align: 'center',
              ellipsis: true,
            },
            {
              title: '대표해시태그',
              dataIndex: 'mainHashtag',
              key: 'mainHashtag',
              render: (_, row) => {
                return (
                  <div className="child-row" key={`${row.id}_mainHashtag`}>
                    {row.mainHashtag ? row.mainHashtag : ''}
                  </div>
                );
              },
              align: 'center',
            },
            {
              title: '부해시태그',
              dataIndex: 'hashtags',
              key: 'hashtags',
              render: (_, row) => {
                return (
                  <div className="child-row" key={`${row.id}_hashtags`}>
                    {row.hashtags && row.hashtags[0] ? (
                      <Tooltip title={<pre>{row.hashtags.join('\n')}</pre>}>
                        {row.hashtags[0]}...
                      </Tooltip>
                    ) : (
                      ''
                    )}
                  </div>
                );
              },
              align: 'center',
            },
            {
              title: '댓글(추가옵션)',
              dataIndex: 'comment',
              key: 'comment',
              render: (_, row) =>
                row.comment ? (
                  <Tooltip title={<pre>{row.comment}</pre>}>
                    {row.comment.substring(0, 3)}...
                  </Tooltip>
                ) : (
                  ''
                ),
              align: 'center',
            },
          ]);
        } else {
          value = value.concat([
            {
              title: 'URL',
              dataIndex: 'url',
              key: 'url',
              align: 'center',
            },
            {
              title: '대표해시태그',
              dataIndex: 'mainHashtag',
              key: 'mainHashtag',
              render: (_, row) => {
                return (
                  <div className="child-row" key={`${row.id}_mainHashtag`}>
                    {row.mainHashtag ? row.mainHashtag : ''}
                  </div>
                );
              },
              align: 'center',
            },
          ]);
        }

        value = value.concat([
          {
            title: '순위',
            dataIndex: 'currentRank',
            key: 'currentRank',
            // render: (_, row) =>
            //   row.hashtagRank && row.hashtagRank[0] ? (
            //     row.hashtagRank[0].rank &&
            //     row.hashtagRank[0].hasOwnProperty('interval') ? (
            //       row.hashtagRank[0].interval === 0 ? (
            //         <div>
            //           {row.hashtagRank[0].rank}
            //           <span>(-)</span>
            //         </div>
            //       ) : row.hashtagRank[0].interval < 0 ? (
            //         <div>
            //           {row.hashtagRank[0].rank}
            //           <span className="red">
            //             (▲{Math.abs(row.hashtagRank[0].interval)})
            //           </span>
            //         </div>
            //       ) : (
            //         <div>
            //           {row.hashtagRank[0].rank}
            //           <span className="blue">
            //             (▼{Math.abs(row.hashtagRank[0].interval)})
            //           </span>
            //         </div>
            //       )
            //     ) : row.hashtagRank[0].rank ? (
            //       <span>{row.hashtagRank[0].rank}(-)</span>
            //     ) : (
            //       ''
            //     )
            //   ) : (
            //     ''
            //   ),
            align: 'center',
          },
          {
            title: '카운팅',
            dataIndex: 'counting',
            key: 'counting',
            render: (_, row) => (
              <span>
                {row.counting}/{row.maxCounting}일
              </span>
            ),
            align: 'center',
          },
        ]);
      } else if (workType === '최적화계정육성' || workType === '인플루언서') {
        value = value.concat([
          {
            title: '해시태그',
            dataIndex: 'hashtags',
            key: 'hashtags',
            render: (_, row) => (
              <>
                {row.hashtags.length > 1 ? (
                  <Popover
                    content={
                      <div>
                        {row.hashtags.map((hashtag, idx) => (
                          <div key={`${row.id}_hashtag_${idx}`}>{hashtag}</div>
                        ))}
                      </div>
                    }
                    title=""
                  >
                    {row.hashtags[0]}...
                  </Popover>
                ) : row.hashtags.length === 1 ? (
                  <div>{row.hashtags[0]}</div>
                ) : (
                  <div>&nbsp;</div>
                )}
              </>
            ),
            align: 'center',
          },
          {
            title: '게시물',
            dataIndex: 'postRank',
            key: 'postRank',
            render: (_, row) =>
              row.postRank ? (
                row.postRank.rank && row.postRank.hasOwnProperty('interval') ? (
                  row.postRank.interval === 0 ? (
                    <div>
                      {row.postRank.rank}
                      <span>(-)</span>
                    </div>
                  ) : row.postRank.interval < 0 ? (
                    <div>
                      {row.postRank.rank}
                      <span className="red">
                        (▲{Math.abs(row.postRank.interval)})
                      </span>
                    </div>
                  ) : (
                    <div>
                      {row.postRank.rank}
                      <span className="blue">
                        (▼{Math.abs(row.postRank.interval)})
                      </span>
                    </div>
                  )
                ) : row.postRank.rank ? (
                  <span>{row.postRank.rank}(-)</span>
                ) : (
                  ''
                )
              ) : (
                ''
              ),
            align: 'center',
          },
          {
            title: '팔로워',
            dataIndex: 'followerRank',
            key: 'followerRank',
            render: (_, row) =>
              row.followerRank ? (
                row.followerRank.rank &&
                row.followerRank.hasOwnProperty('interval') ? (
                  row.followerRank.interval === 0 ? (
                    <div>
                      {row.followerRank.rank}
                      <span>(-)</span>
                    </div>
                  ) : row.followerRank.interval < 0 ? (
                    <div>
                      {row.followerRank.rank}
                      <span className="red">
                        (▲{Math.abs(row.followerRank.interval)})
                      </span>
                    </div>
                  ) : (
                    <div>
                      {row.followerRank.rank}
                      <span className="blue">
                        (▼{Math.abs(row.followerRank.interval)})
                      </span>
                    </div>
                  )
                ) : row.followerRank.rank ? (
                  <span>{row.followerRank.rank}(-)</span>
                ) : (
                  ''
                )
              ) : (
                ''
              ),
            align: 'center',
          },
          {
            title: '팔로잉',
            dataIndex: 'followingRank',
            key: 'followingRank',
            render: (_, row) =>
              row.followingRank ? (
                row.followingRank.rank &&
                row.followingRank.hasOwnProperty('interval') ? (
                  row.followingRank.interval === 0 ? (
                    <div>
                      {row.followingRank.rank}
                      <span>(-)</span>
                    </div>
                  ) : row.followingRank.interval < 0 ? (
                    <div>
                      {row.followingRank.rank}
                      <span className="red">
                        (▲{Math.abs(row.followingRank.interval)})
                      </span>
                    </div>
                  ) : (
                    <div>
                      {row.followingRank.rank}
                      <span className="blue">
                        (▼{Math.abs(row.followingRank.interval)})
                      </span>
                    </div>
                  )
                ) : row.followingRank.rank ? (
                  <span>{row.followingRank.rank}(-)</span>
                ) : (
                  ''
                )
              ) : (
                ''
              ),
            align: 'center',
          },
          {
            title: '작업일수',
            dataIndex: 'counting',
            key: 'counting',
            render: (_, row) => (
              <span>
                {row.counting}/{row.maxCounting}일
              </span>
            ),
            align: 'center',
          },
          {
            title: '상태',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
          },
        ]);
      } else if (workType === '최적화테스트') {
        value.splice(1, 0, {
          title: '시간',
          dataIndex: 'otTime',
          key: 'otTime',
          render: (_, row) =>
            row.otTime ? moment(row.otTime).format('HH:mm:ss') : '',
          align: 'center',
        });
        value = value.concat([
          {
            title: 'URL',
            dataIndex: 'url',
            key: 'url',
            align: 'center',
          },
          {
            title: '해시태그',
            dataIndex: 'hashtags',
            key: 'hashtags',
            render: (_, row) => (
              <>
                {row.hashtags.length > 1 ? (
                  <Popover
                    content={
                      <div>
                        {row.hashtags.map((hashtag, idx) => (
                          <div key={`${row.id}_hashtag_${idx}`}>{hashtag}</div>
                        ))}
                      </div>
                    }
                    title=""
                  >
                    {row.hashtags[0]}...
                  </Popover>
                ) : row.hashtags.length === 1 ? (
                  <div>{row.hashtags[0]}</div>
                ) : (
                  <div>&nbsp;</div>
                )}
              </>
            ),
            align: 'center',
          },
          {
            title: '순위',
            dataIndex: 'currentRank',
            key: 'currentRank',
            // render: (_, row) =>
            //   row.hashtagRank && row.hashtagRank[0] ? (
            //     row.hashtagRank[0].rank &&
            //     row.hashtagRank[0].hasOwnProperty('interval') ? (
            //       row.hashtagRank[0].interval === 0 ? (
            //         <div>
            //           {row.hashtagRank[0].rank}
            //           <span>(-)</span>
            //         </div>
            //       ) : row.hashtagRank[0].interval < 0 ? (
            //         <div>
            //           {row.hashtagRank[0].rank}
            //           <span className="red">
            //             (▲{Math.abs(row.hashtagRank[0].interval)})
            //           </span>
            //         </div>
            //       ) : (
            //         <div>
            //           {row.hashtagRank[0].rank}
            //           <span className="blue">
            //             (▼{Math.abs(row.hashtagRank[0].interval)})
            //           </span>
            //         </div>
            //       )
            //     ) : row.hashtagRank[0].rank ? (
            //       <span>{row.hashtagRank[0].rank}(-)</span>
            //     ) : (
            //       ''
            //     )
            //   ) : (
            //     ''
            //   ),
            align: 'center',
          },
          {
            title: '결과여부',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
          },
        ]);
      } else if (workType === '인기게시물건바이') {
        value.push({
          title: '게시물구분',
          dataIndex: 'isInstaPostRandom',
          key: 'isInstaPostRandom',
          render: (_, row) => (row.isInstaPostRandom ? '랜덤' : '순서'),
          align: 'center',
        });
        let children = [
          {
            title: '이미지',
            dataIndex: 'image',
            key: 'image',
            render: (_, row) => {
              let valKey = 'buyCaseData';
              if (row.paymentType === '월구매') {
                valKey = 'buyMonthData';
              } else if (row.paymentType === '고객월건바이') {
                valKey = 'buyCustomerMonthData';
              }
              return row.isInstaPostRandom && row.randomInstaPostData ? (
                <div className="data-row" key={`${row.id}_image`}>
                  <img
                    className="list-img click"
                    src={
                      row.randomInstaPostData.images &&
                      row.randomInstaPostData.images[0]
                        ? row.randomInstaPostData.images[0].url
                        : '/images/no-image.png'
                    }
                    onClick={handleImageModal(true, row.randomInstaPostData)}
                  />
                </div>
              ) : (
                row[valKey].map((item) => (
                  <div className="data-row" key={`${row.id}_image_${item.uid}`}>
                    {item.instaPostData
                      ? item.instaPostData.map((pData, idx) => (
                          <div
                            className="child-row"
                            key={`${row.id}_image_${item.uid}_${idx}`}
                          >
                            <img
                              className="list-img click"
                              src={
                                pData.images && pData.images[0]
                                  ? pData.images[0].url
                                  : '/images/no-image.png'
                              }
                              onClick={handleImageModal(true, pData)}
                            />
                          </div>
                        ))
                      : ''}
                  </div>
                ))
              );
            },
            align: 'center',
          },
          {
            title: '동영상',
            dataIndex: 'videos',
            key: 'videos',
            render: (_, row) => {
              let valKey = 'buyCaseData';
              if (row.paymentType === '월구매') {
                valKey = 'buyMonthData';
              } else if (row.paymentType === '고객월건바이') {
                valKey = 'buyCustomerMonthData';
              }
              return row.isInstaPostRandom && row.randomInstaPostData ? (
                <div className="data-row" key={`${row.id}_video`}>
                  {row.randomInstaPostData.videos &&
                  row.randomInstaPostData.videos.length ? (
                    <Button
                      icon={<VideoCameraOutlined />}
                      onClick={handleVideoModal(true, row.randomInstaPostData)}
                    />
                  ) : null}
                </div>
              ) : (
                row[valKey].map((item) => (
                  <div className="data-row" key={`${row.id}_video_${item.uid}`}>
                    {item.instaPostData
                      ? item.instaPostData.map((pData, idx) => (
                          <div
                            className="child-row"
                            key={`${row.id}_video_${item.uid}_${idx}`}
                          >
                            {pData.videos && pData.videos.length ? (
                              <Button
                                icon={<VideoCameraOutlined />}
                                onClick={handleVideoModal(true, pData)}
                              />
                            ) : null}
                          </div>
                        ))
                      : ''}
                  </div>
                ))
              );
            },
            align: 'center',
          },
          // {
          //   title: 'URL',
          //   dataIndex: 'url',
          //   key: 'url',
          //   render: (_, row) =>
          //     row.buyMonthData.map((item) => (
          //       <div className="child-row" key={`${row.id}_url_${item.uid}`}>
          //         {item.instaPostData.map((pData, idx) => (
          //           <div
          //             className="child-row"
          //             key={`${row.id}_url_${item.uid}_${idx}`}
          //           >
          //             {pData.url ? pData.url : ''}
          //           </div>
          //         ))}
          //       </div>
          //     )),
          //   align: 'center',
          // },
          {
            title: '내용',
            dataIndex: 'instaContent',
            key: 'instaContent',
            render: (_, row) => {
              let valKey = 'buyCaseData';
              if (row.paymentType === '월구매') {
                valKey = 'buyMonthData';
              } else if (row.paymentType === '고객월건바이') {
                valKey = 'buyCustomerMonthData';
              }
              return row.isInstaPostRandom && row.randomInstaPostData ? (
                <div className="data-row" key={`${row.id}_instaContent`}>
                  {row.randomInstaPostData.instaContent ? (
                    <>
                      {Array.isArray(row.randomInstaPostData.instaContent) ? (
                        row.randomInstaPostData.instaContent.map(
                          (item, idx) => (
                            <Tooltip
                              title={<pre>{item}</pre>}
                              key={`${row.id}_randomInstaPostData_instaContent_tooltip_${idx}`}
                            >
                              {item.substring(0, 4)}
                              ...
                            </Tooltip>
                          ),
                        )
                      ) : (
                        <Tooltip
                          title={
                            <pre>{row.randomInstaPostData.instaContent}</pre>
                          }
                        >
                          {row.randomInstaPostData.instaContent.substring(0, 4)}
                          ...
                        </Tooltip>
                      )}
                    </>
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                row[valKey].map((item) => (
                  <div
                    className="data-row"
                    key={`${row.id}_instaContent_${item.uid}`}
                  >
                    {item.instaPostData
                      ? item.instaPostData.map((pData, idx) => (
                          <div
                            className="child-row"
                            key={`${row.id}_instaContent_${item.uid}_${idx}`}
                          >
                            {pData.instaContent ? (
                              <Tooltip title={<pre>{pData.instaContent}</pre>}>
                                {pData.instaContent.substring(0, 4)}...
                              </Tooltip>
                            ) : (
                              ''
                            )}
                          </div>
                        ))
                      : ''}
                  </div>
                ))
              );
            },
            align: 'center',
          },
          {
            title: '대표해시태그',
            dataIndex: 'mainHashtag',
            key: 'mainHashtag',
            render: (_, row) => {
              let valKey = 'buyCaseData';
              if (row.paymentType === '월구매') {
                valKey = 'buyMonthData';
              } else if (row.paymentType === '고객월건바이') {
                valKey = 'buyCustomerMonthData';
              }
              return row.isInstaPostRandom && row.randomInstaPostData ? (
                <div className="data-row" key={`${row.id}_mainHashtag`}>
                  {row.randomInstaPostData.mainHashtag
                    ? row.randomInstaPostData.mainHashtag
                    : ''}
                </div>
              ) : (
                row[valKey].map((item) => (
                  <div
                    className="data-row"
                    key={`${row.id}_mainHashtag_${item.uid}`}
                  >
                    {item.instaPostData
                      ? item.instaPostData.map((pData, idx) => (
                          <div
                            className="child-row"
                            key={`${row.id}_mainHashtag_${item.uid}_${idx}`}
                          >
                            {pData.mainHashtag ? pData.mainHashtag : ''}
                          </div>
                        ))
                      : ''}
                  </div>
                ))
              );
            },
            align: 'center',
          },
        ];

        // if (ppCaseByType !== '고객월건바이') {
        //   children.push({
        //     title: '부해시태그',
        //     dataIndex: 'hashtags',
        //     key: 'hashtags',
        //     render: (_, row) => {
        //       let valKey = 'buyCaseData';
        //       if (row.paymentType === '월구매') {
        //         valKey = 'buyMonthData';
        //       } else if (row.paymentType === '고객월건바이') {
        //         valKey = 'buyCustomerMonthData';
        //       }
        //       return row.isInstaPostRandom && row.randomInstaPostData ? (
        //         <div className="data-row" key={`${row.id}_hashtags`}>
        //           {row.randomInstaPostData.hashtags ? (
        //             <Tooltip
        //               title={<pre>{row.randomInstaPostData.hashtags}</pre>}
        //             >
        //               {row.randomInstaPostData.hashtags.substring(0, 4)}...
        //             </Tooltip>
        //           ) : (
        //             ''
        //           )}
        //         </div>
        //       ) : (
        //         row[valKey].map((item) => (
        //           <div
        //             className="data-row"
        //             key={`${row.id}_hashtags_${item.uid}`}
        //           >
        //             {item.instaPostData
        //               ? item.instaPostData.map((pData, idx) => (
        //                   <div
        //                     className="child-row"
        //                     key={`${row.id}_hashtags_${item.uid}_${idx}`}
        //                   >
        //                     {pData.hashtags ? (
        //                       <Tooltip title={<pre>{pData.hashtags}</pre>}>
        //                         {pData.hashtags.substring(0, 4)}...
        //                       </Tooltip>
        //                     ) : (
        //                       ''
        //                     )}
        //                   </div>
        //                 ))
        //               : ''}
        //           </div>
        //         ))
        //       );
        //     },
        //     align: 'center',
        //   });
        // }

        children.push({
          title: '순위',
          dataIndex: 'rank',
          key: 'rank',
          render: (_, row) => {
            let valKey = 'buyCaseData';
            if (row.paymentType === '월구매') {
              valKey = 'buyMonthData';
            } else if (row.paymentType === '고객월건바이') {
              valKey = 'buyCustomerMonthData';
            }
            return row.isInstaPostRandom && row.randomInstaPostData ? (
              <div
                className="data-row"
                key={`${row.id}_randomInstaPostData_rank`}
              >
                {row.randomInstaPostData.currentRank}
              </div>
            ) : (
              row[valKey].map((item) => (
                <div className="data-row" key={`${row.id}_rank_${item.uid}`}>
                  {item.instaPostData
                    ? item.instaPostData.map((pData, idx) => (
                        <div
                          className="child-row"
                          key={`${row.id}_rank_${item.uid}_${idx}`}
                        >
                          {getMonthDataRank(row, pData)}
                        </div>
                      ))
                    : ''}
                </div>
              ))
            );
          },
          align: 'center',
        });

        if (isAdmin) {
          children = children.concat([
            // {
            //   title: '좋아요',
            //   dataIndex: 'likeCnt',
            //   key: 'likeCnt',
            //   render: (_, row) => {
            //     let valKey = 'buyCaseData';
            //     if (row.paymentType === '월구매') {
            //       valKey = 'buyMonthData';
            //     } else if (row.paymentType === '고객월건바이') {
            //       valKey = 'buyCustomerMonthData';
            //     }
            //     return row[valKey].map((item) => (
            //       <div
            //         className="data-row"
            //         key={`${row.id}_likeCnt_${item.uid}`}
            //       >
            //         {item.instaPostData
            //           ? item.instaPostData.map((pData, idx) => (
            //               <div
            //                 className="child-row"
            //                 key={`${row.id}_likeCnt_${item.uid}_${idx}`}
            //               >
            //                 {pData.likeCnt ? pData.likeCnt : ''}
            //               </div>
            //             ))
            //           : ''}
            //       </div>
            //     ));
            //   },
            //   align: 'center',
            // },
            // {
            //   title: '댓글',
            //   dataIndex: 'commentCnt',
            //   key: 'commentCnt',
            //   render: (_, row) => {
            //     let valKey = 'buyCaseData';
            //     if (row.paymentType === '월구매') {
            //       valKey = 'buyMonthData';
            //     } else if (row.paymentType === '고객월건바이') {
            //       valKey = 'buyCustomerMonthData';
            //     }
            //     return row[valKey].map((item) => (
            //       <div
            //         className="data-row"
            //         key={`${row.id}_commentCnt_${item.uid}`}
            //       >
            //         {item.instaPostData
            //           ? item.instaPostData.map((pData, idx) => (
            //               <div
            //                 className="child-row"
            //                 key={`${row.id}_commentCnt_${item.uid}_${idx}`}
            //               >
            //                 {pData.commentCnt ? pData.commentCnt : ''}
            //               </div>
            //             ))
            //           : ''}
            //       </div>
            //     ));
            //   },
            //   align: 'center',
            // },
            // {
            //   title: '트래픽횟수',
            //   dataIndex: 'trafficCnt',
            //   key: 'trafficCnt',
            //   render: (_, row) => {
            //     let valKey = 'buyCaseData';
            //     if (row.paymentType === '월구매') {
            //       valKey = 'buyMonthData';
            //     } else if (row.paymentType === '고객월건바이') {
            //       valKey = 'buyCustomerMonthData';
            //     }
            //     return row[valKey].map((item) => (
            //       <div
            //         className="data-row"
            //         key={`${row.id}_trafficCnt_${item.uid}`}
            //       >
            //         {item.instaPostData
            //           ? item.instaPostData.map((pData, idx) => (
            //               <div
            //                 className="child-row"
            //                 key={`${row.id}_trafficCnt_${item.uid}_${idx}`}
            //               >
            //                 {pData.trafficCnt ? pData.trafficCnt : ''}
            //               </div>
            //             ))
            //           : ''}
            //       </div>
            //     ));
            //   },
            //   align: 'center',
            // },
          ]);
        }

        value = value.concat([
          {
            title: '게시물',
            dataIndex: 'instaPost',
            key: 'instaPost',
            children,
            align: 'center',
          },
          {
            title: '일일 글 작성 횟수',
            dataIndex: 'cnt',
            key: 'cnt',
            render: (_, row) => {
              let valKey = 'buyCaseData';
              if (row.paymentType === '월구매') {
                valKey = 'buyMonthData';
              } else if (row.paymentType === '고객월건바이') {
                valKey = 'buyCustomerMonthData';
              }

              return row[valKey].map((item) => (
                <div
                  className="data-row"
                  key={`${row.id}_visibleCnt_${item.uid}`}
                >
                  {item.cnt}
                </div>
              ));
            },
            align: 'center',
          },
          // {
          //   title: '댓글(추가옵션)',
          //   dataIndex: 'comment',
          //   key: 'comment',
          //   render: (_, row) =>
          //     row.comment ? (
          //       <Tooltip title={<pre>{row.comment}</pre>}>
          //         {row.comment.substring(0, 3)}...
          //       </Tooltip>
          //     ) : (
          //       ''
          //     ),
          //   align: 'center',
          // },
        ]);

        if (ppCaseByType == '월구매') {
          value.push({
            title: '카운팅',
            dataIndex: 'counting',
            key: 'counting',
            render: (_, row) => (
              <span>
                {row.counting}/{row.maxCounting}일
              </span>
            ),
            align: 'center',
          });
        }
      }

      if (workType === 'K댓글') {
        value.push({
          title: '',
          dataIndex: 'action',
          key: 'action',
          render: (_, row) => (
            <>
              <Button
                type="primary"
                block
                onClick={handleModifyModal(true, 'K댓글', row)}
              >
                관리설정
              </Button>
            </>
          ),
          align: 'center',
          width: 120,
          fixed: 'right',
        });
      } else if (workType === '인기게시물') {
        value.push({
          title: '',
          dataIndex: 'action',
          key: 'action',
          render: (_, row) => (
            <>
              {screen.lg && (
                <Button
                  type="primary"
                  block
                  style={{ marginBottom: 5 }}
                  onClick={handleModifyModal(true, '인기게시물', row)}
                >
                  관리설정
                </Button>
              )}
              <Button
                block
                style={{ marginBottom: 5 }}
                onClick={handleRankModal(true, row)}
              >
                작업내역
              </Button>
              {isAdmin && (
                <Button
                  type="primary"
                  danger={row.isActivated ? true : false}
                  ghost={row.isActivated ? false : true}
                  block
                  onClick={handleRun(row)}
                >
                  {row.isActivated ? '일시정지' : '다시시작'}
                </Button>
              )}
            </>
          ),
          align: 'center',
          width: 120,
          fixed: 'right',
        });
      } else if (workType === '최적화계정육성' || workType === '인플루언서') {
        value.push({
          title: '',
          dataIndex: 'action',
          key: 'action',
          render: (_, row) => (
            <>
              {screen.lg && (
                <Button
                  type="primary"
                  block
                  style={{ marginBottom: 5 }}
                  onClick={handleModifyModal(true, workType, row)}
                >
                  관리설정
                </Button>
              )}
              <Button block onClick={handleRankModal(true, row)}>
                작업내역
              </Button>
            </>
          ),
          align: 'center',
          width: 120,
          fixed: 'right',
        });
      } else if (workType === '최적화테스트') {
        value.push({
          title: '',
          dataIndex: 'action',
          key: 'action',
          render: (_, row) => (
            <>
              <Button block onClick={handleRankModal(true, row)}>
                작업내역
              </Button>
            </>
          ),
          align: 'center',
          width: 120,
          fixed: 'right',
        });
      } else if (workType === '인기게시물건바이') {
        value.push({
          title: '',
          dataIndex: 'action',
          key: 'action',
          render: (_, row) => (
            <>
              {screen.lg && (
                <Button
                  type="primary"
                  block
                  style={{ marginBottom: 5 }}
                  onClick={handleModifyModal(true, '인기게시물 건바이', row)}
                >
                  관리설정
                </Button>
              )}
              <Button
                block
                style={{ marginBottom: 5 }}
                onClick={handleDetailModal(true, row)}
              >
                작업내역
              </Button>
              {isAdmin && (
                <Button
                  type="primary"
                  danger={row.isActivated ? true : false}
                  ghost={row.isActivated ? false : true}
                  block
                  onClick={handleRun(row)}
                >
                  {row.isActivated ? '일시정지' : '다시시작'}
                </Button>
              )}
            </>
          ),
          align: 'center',
          width: 120,
          fixed: 'right',
        });
      }

      return value;
    }, [isAdmin, workType, ppCaseByType, programType, screen]);

    const mobileColumns = useMemo(
      () => [
        {
          title: '',
          dataIndex: 'idx',
          key: 'idx',
          render: (_, row, idx) => {
            return (
              <Descriptions size="small" bordered column={1}>
                <Descriptions.Item label="날짜">
                  {row.createdAt
                    ? moment(row.createdAt).format('YYYY-MM-DD')
                    : ''}
                </Descriptions.Item>

                {(workType === '인기게시물건바이' &&
                  ppCaseByType === '고객월건바이') ||
                  ((workType !== '인기게시물건바이' ||
                    ppCaseByType !== '건구매') && (
                    <Descriptions.Item label="인스타아이디">
                      {row.instaId}
                    </Descriptions.Item>
                  ))}

                {/^k/i.test(workType) && (
                  <>
                    {workType !== 'K팔로워' ? (
                      <Descriptions.Item label="URL">
                        {row.url}
                      </Descriptions.Item>
                    ) : null}
                    <Descriptions.Item label="개수">
                      {row.buyCnt}
                    </Descriptions.Item>
                    <Descriptions.Item label="진행률">
                      {row.progressRate}
                    </Descriptions.Item>
                    {workType !== 'K팔로워' ? (
                      <Descriptions.Item label="작업일수">
                        {row.counting && row.maxCounting ? (
                          <span>
                            {row.counting}/{row.maxCounting}일
                          </span>
                        ) : (
                          ''
                        )}
                      </Descriptions.Item>
                    ) : null}
                  </>
                )}

                {workType === '인기게시물' && programType === '최적화아이디' ? (
                  <>
                    <Descriptions.Item label="게시물">
                      <Row gutter={[10, 10]} key={`${row.id}_mobile`}>
                        <Col xs={24}>
                          <Card style={{ marginBottom: 10 }}>
                            <Space
                              style={{
                                width: '100%',
                                display: 'flex',
                                flexWrap: 'wrap',
                                alignItems: 'flex-start',
                              }}
                            >
                              <div className="insta-post">
                                <h4>이미지</h4>
                                <img
                                  className="list-img click"
                                  src={
                                    row.images && row.images[0]
                                      ? row.images[0].url
                                      : '/images/no-image.png'
                                  }
                                  onClick={handleImageModal(true, row)}
                                />
                              </div>
                              <div className="insta-post">
                                <h4>동영상</h4>
                                {row.videos && row.videos.length ? (
                                  <Button
                                    icon={<VideoCameraOutlined />}
                                    onClick={handleVideoModal(true, row)}
                                  />
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className="insta-post">
                                <h4>내용</h4>
                                {row.instaContentText ? (
                                  <pre>{row.instaContentText}</pre>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className="insta-post">
                                <h4>메인해시태그</h4>
                                {row.mainHashtag ? row.mainHashtag : ''}
                              </div>
                              <div className="insta-post">
                                <h4>부해시태그</h4>
                                <pre>
                                  {row.hashtags && row.hashtags[0] ? (
                                    <pre>{row.hashtags.join('\n')}</pre>
                                  ) : (
                                    ''
                                  )}
                                </pre>
                              </div>
                            </Space>
                          </Card>
                        </Col>
                      </Row>
                    </Descriptions.Item>
                    <Descriptions.Item label="댓글(추가옵션)">
                      <span>{row.comment ? <pre>{row.comment}</pre> : ''}</span>
                    </Descriptions.Item>
                  </>
                ) : (
                  <>
                    <Descriptions.Item label="URL">{row.url}</Descriptions.Item>
                    <Descriptions.Item label="해시태그">
                      <h4>메인해시태그</h4>
                      {row.mainHashtag ? row.mainHashtag : ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="순위">
                      {/* {row.hashtagRank && row.hashtagRank[0] ? (
                        row.hashtagRank[0].rank &&
                        row.hashtagRank[0].hasOwnProperty('interval') ? (
                          row.hashtagRank[0].interval === 0 ? (
                            <div>
                              {row.hashtagRank[0].rank}
                              <span>(-)</span>
                            </div>
                          ) : row.hashtagRank[0].interval < 0 ? (
                            <div>
                              {row.hashtagRank[0].rank}
                              <span className="red">
                                (▲{Math.abs(row.hashtagRank[0].interval)})
                              </span>
                            </div>
                          ) : (
                            <div>
                              {row.hashtagRank[0].rank}
                              <span className="blue">
                                (▼{Math.abs(row.hashtagRank[0].interval)})
                              </span>
                            </div>
                          )
                        ) : row.hashtagRank[0].rank ? (
                          <span>{row.hashtagRank[0].rank}(-)</span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )} */}
                      {row.currentRank}
                    </Descriptions.Item>
                    <Descriptions.Item label="카운팅">
                      <span>
                        {row.counting}/{row.maxCounting}일
                      </span>
                    </Descriptions.Item>
                  </>
                )}

                {(workType === '최적화계정육성' ||
                  workType === '인플루언서') && (
                  <>
                    <Descriptions.Item label="해시태그">
                      <>
                        {row.hashtags.length > 1 ? (
                          <Popover
                            content={
                              <div>
                                {row.hashtags.map((hashtag, idx) => (
                                  <div key={`${row.id}_hashtag_${idx}`}>
                                    {hashtag}
                                  </div>
                                ))}
                              </div>
                            }
                            title=""
                          >
                            {row.hashtags[0]}...
                          </Popover>
                        ) : row.hashtags.length === 1 ? (
                          <div>{row.hashtags[0]}</div>
                        ) : (
                          <div>&nbsp;</div>
                        )}
                      </>
                    </Descriptions.Item>
                    <Descriptions.Item label="게시물">
                      {row.postRank ? (
                        row.postRank.rank &&
                        row.postRank.hasOwnProperty('interval') ? (
                          row.postRank.interval === 0 ? (
                            <div>
                              {row.postRank.rank}
                              <span>(-)</span>
                            </div>
                          ) : row.postRank.interval < 0 ? (
                            <div>
                              {row.postRank.rank}
                              <span className="red">
                                (▲{Math.abs(row.postRank.interval)})
                              </span>
                            </div>
                          ) : (
                            <div>
                              {row.postRank.rank}
                              <span className="blue">
                                (▼{Math.abs(row.hpostRank.interval)})
                              </span>
                            </div>
                          )
                        ) : row.postRank.rank ? (
                          <span>{row.postRank.rank}(-)</span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="팔로워">
                      {row.followerRank ? (
                        row.followerRank.rank &&
                        row.followerRank.hasOwnProperty('interval') ? (
                          row.followerRank.interval === 0 ? (
                            <div>
                              {row.followerRank.rank}
                              <span>(-)</span>
                            </div>
                          ) : row.followerRank.interval < 0 ? (
                            <div>
                              {row.followerRank.rank}
                              <span className="red">
                                (▲{Math.abs(row.followerRank.interval)})
                              </span>
                            </div>
                          ) : (
                            <div>
                              {row.followerRank.rank}
                              <span className="blue">
                                (▼{Math.abs(row.followerRank.interval)})
                              </span>
                            </div>
                          )
                        ) : row.followerRank.rank ? (
                          <span>{row.followerRank.rank}(-)</span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="팔로잉">
                      {row.followingRank ? (
                        row.followingRank.rank &&
                        row.followingRank.hasOwnProperty('interval') ? (
                          row.followingRank.interval === 0 ? (
                            <div>
                              {row.followingRank.rank}
                              <span>(-)</span>
                            </div>
                          ) : row.followingRank.interval < 0 ? (
                            <div>
                              {row.followingRank.rank}
                              <span className="red">
                                (▲{Math.abs(row.followingRank.interval)})
                              </span>
                            </div>
                          ) : (
                            <div>
                              {row.followingRank.rank}
                              <span className="blue">
                                (▼{Math.abs(row.followingRank.interval)})
                              </span>
                            </div>
                          )
                        ) : row.followingRank.rank ? (
                          <span>{row.followingRank.rank}(-)</span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="작업일수">
                      <span>
                        {row.counting}/{row.maxCounting}일
                      </span>
                    </Descriptions.Item>
                    <Descriptions.Item label="상태">
                      {row.status}
                    </Descriptions.Item>
                  </>
                )}

                {workType === '최적화테스트' && (
                  <>
                    <Descriptions.Item label="시간">
                      {row.otTime ? moment(row.otTime).format('HH:mm:ss') : ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="URL">{row.url}</Descriptions.Item>
                    <Descriptions.Item label="해시태그">
                      <>
                        {row.hashtags.length > 1 ? (
                          <Popover
                            content={
                              <div>
                                {row.hashtags.map((hashtag, idx) => (
                                  <div key={`${row.id}_hashtag_${idx}`}>
                                    {hashtag}
                                  </div>
                                ))}
                              </div>
                            }
                            title=""
                          >
                            {row.hashtags[0]}...
                          </Popover>
                        ) : row.hashtags.length === 1 ? (
                          <div>{row.hashtags[0]}</div>
                        ) : (
                          <div>&nbsp;</div>
                        )}
                      </>
                    </Descriptions.Item>
                    <Descriptions.Item label="순위">
                      {/* {row.hashtagRank && row.hashtagRank[0] ? (
                        row.hashtagRank[0].rank &&
                        row.hashtagRank[0].hasOwnProperty('interval') ? (
                          row.hashtagRank[0].interval === 0 ? (
                            <div>
                              {row.hashtagRank[0].rank}
                              <span>(-)</span>
                            </div>
                          ) : row.hashtagRank[0].interval < 0 ? (
                            <div>
                              {row.hashtagRank[0].rank}
                              <span className="red">
                                (▲{Math.abs(row.hashtagRank[0].interval)})
                              </span>
                            </div>
                          ) : (
                            <div>
                              {row.hashtagRank[0].rank}
                              <span className="blue">
                                (▼{Math.abs(row.hashtagRank[0].interval)})
                              </span>
                            </div>
                          )
                        ) : row.hashtagRank[0].rank ? (
                          <span>{row.hashtagRank[0].rank}(-)</span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )} */}
                      {row.currentRank}
                    </Descriptions.Item>
                    <Descriptions.Item label="결과여부">
                      {row.status}
                    </Descriptions.Item>
                  </>
                )}

                {workType === '인기게시물건바이' && (
                  <>
                    <Descriptions.Item
                      label="게시물"
                      style={{ overflowX: 'auto' }}
                    >
                      {mobileRender(row)}
                    </Descriptions.Item>
                    <Descriptions.Item label="댓글(추가옵션)">
                      <span>{row.comment ? <pre>{row.comment}</pre> : ''}</span>
                    </Descriptions.Item>
                    {(ppCaseByType !== '건구매' ||
                      ppCaseByType !== '고객월건바이') && (
                      <Descriptions.Item label="카운팅">
                        <span>
                          {row.counting}/{row.maxCounting}일
                        </span>
                      </Descriptions.Item>
                    )}
                  </>
                )}

                <Descriptions.Item label="">
                  {workType === '인기게시물' && (
                    <>
                      <Button
                        block
                        style={{ marginBottom: 5 }}
                        onClick={handleRankModal(true, row)}
                      >
                        작업내역
                      </Button>
                      {isAdmin && (
                        <Button
                          type="primary"
                          danger={row.isActivated ? true : false}
                          ghost={row.isActivated ? false : true}
                          block
                          onClick={handleRun(row)}
                        >
                          {row.isActivated ? '일시정지' : '다시시작'}
                        </Button>
                      )}
                    </>
                  )}

                  {(workType === '최적화계정육성' ||
                    workType === '인플루언서') && (
                    <Button block onClick={handleRankModal(true, row)}>
                      작업내역
                    </Button>
                  )}

                  {workType === '최적화테스트' && (
                    <Button block onClick={handleRankModal(true, row)}>
                      작업내역
                    </Button>
                  )}

                  {workType === '인기게시물건바이' && (
                    <>
                      <Button
                        block
                        style={{ marginBottom: 5 }}
                        onClick={handleDetailModal(true, row)}
                      >
                        작업내역
                      </Button>
                      {isAdmin && (
                        <Button
                          type="primary"
                          danger={row.isActivated ? true : false}
                          ghost={row.isActivated ? false : true}
                          block
                          onClick={handleRun(row)}
                        >
                          {row.isActivated ? '일시정지' : '다시시작'}
                        </Button>
                      )}
                    </>
                  )}
                </Descriptions.Item>
              </Descriptions>
            );
          },
        },
      ],
      [isAdmin, workType, ppCaseByType, programType],
    );

    const rowSelection = {
      type: 'checkbox',
      onChange: (selectedRowKeys, selectedRows) => {
        if (selectedRows.length) {
          state.selectedList = selectedRows;
          state.selectedRowKeys = selectedRows.map((item) => item.id);
        } else {
          state.selectedList = [];
          state.selectedRowKeys = [];
        }
      },
      selectedRowKeys: toJS(state.selectedRowKeys),
    };

    return (
      <Wrapper>
        <Row justify="end" style={{ marginBottom: 15 }}>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                if (setRefetchWorkList) {
                  setRefetchWorkList(true);
                }
              }}
            >
              재조회
            </Button>
          </Col>
          {isAdmin && (
            <Col>
              <Button
                danger
                onClick={handleRemove(null, true)}
                disabled={!state.selectedRowKeys.length}
              >
                선택삭제
              </Button>
            </Col>
          )}
        </Row>

        <Table
          size="small"
          // scroll={{ x: screen.lg ? 1200 : isAdmin ? 1200 : 300 }}
          scroll={{ x: 1400 }}
          dataSource={toJS(state.list)}
          // columns={screen.lg ? columns : isAdmin ? columns : mobileColumns}
          columns={columns}
          // scroll={{ x: 992 }}
          pagination={{
            current: state.paginate.page,
            total: state.paginate.totalDocs,
            showSizeChanger: false,
            onChange: handleChangePage,
          }}
          rowKey={(row) => row.id}
          rowSelection={isAdmin ? rowSelection : null}
          bordered
        />

        {state.modifyData && state.modifyData.id && (
          <Modify
            company={company}
            propData={toJS(state.modifyData)}
            modalTitle={state.modifyModalTitle}
            modalOpen={state.modifyModalOpen}
            handleModal={handleModifyModal}
            refetch={() => fetchData(findQuery)}
            isAdmin={isAdmin}
          />
        )}

        {state.rankData && state.rankData.id && (
          <Rank
            propData={toJS(state.rankData)}
            modalOpen={state.rankModalOpen}
            handleModal={handleRankModal}
            isAdmin={isAdmin}
          />
        )}

        {state.detailData && state.detailData.id && (
          <Detail
            propData={toJS(state.detailData)}
            modalOpen={state.detailOpen}
            handleModal={handleDetailModal}
            company={company}
            getMonthDataRank={getMonthDataRank}
            isAdmin={isAdmin}
          />
        )}

        <CustomModal
          visible={state.imageModalOpen}
          onCancel={handleImageModal(false)}
          footer={null}
          minwidth={800}
          forceRender={true}
        >
          {toJS(state.images) && toJS(state.images).length ? (
            <Row
              gutter={[20, 20]}
              style={{ display: 'flex', flexWrap: 'wrap' }}
            >
              {toJS(state.images).map((item) => (
                <Col key={item.name}>
                  <Image
                    width={100}
                    height={100}
                    src={item.url ? item.url : '/images/no-image.png'}
                  />
                </Col>
              ))}
            </Row>
          ) : (
            '등록된 이미지가 없습니다.'
          )}
        </CustomModal>

        <CustomModal
          visible={state.videoModalOpen}
          onCancel={handleVideoModal(false)}
          footer={null}
          minwidth={600}
          forceRender={true}
        >
          <Row gutter={[20, 20]}>
            {toJS(state.videos) && toJS(state.videos).length
              ? toJS(state.videos).map((item) => (
                  <Col xs={24} key={item.name}>
                    <ReactPlayer
                      url={toJS(state.videos).map((item) => item.url)}
                      controls={true}
                      width="100%"
                    />
                  </Col>
                ))
              : '등록된 동영상이 없습니다.'}
          </Row>
        </CustomModal>
      </Wrapper>
    );
  },
);

export default WorkList;
