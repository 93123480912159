/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  Divider,
  Form,
  Grid,
  Card,
  Affix,
} from 'antd';
import styled from 'styled-components';
import { observer, useLocalStore } from 'mobx-react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { toJS } from 'mobx';

import { META, SAVE_META } from '@shared/queries';
import { validateMessages } from '@utils/customFormValidator';
import { ROLES } from '@shared/constants';
import useStore from '@stores/useStore';

const { Option } = Select;
const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  width: 100%;

  .ant-form-item-label {
    font-weight: bold;
  }
`;

const RoleFormItem = ({ label, name }) => (
  <Form.Item label={label} name={name}>
    <Select>
      {ROLES.map((item) => (
        <Option value={item.value} key={`${name}_role_${item.value}`}>
          {item.label}
        </Option>
      ))}
    </Select>
  </Form.Item>
);

const RoleConfig = observer(() => {
  const [form] = Form.useForm();
  const screens = useBreakpoint();

  const { metaStore } = useStore();

  const state = useLocalStore(() => ({}));

  const client = useApolloClient();
  const [save] = useMutation(SAVE_META);

  const fetchData = useCallback(async () => {
    const result = await client.query({
      query: META,
      variables: { key: 'roles' },
    });

    if (result.data && result.data.meta && result.data.meta.value) {
      metaStore.role = result.data.meta.value;

      for (const key in result.data.meta.value) {
        form.setFieldsValue({
          [key]: result.data.meta.value[key],
        });
      }
    }
  }, []);

  const handleSubmit = useCallback(async (values) => {
    window.maskOn();

    await save({
      variables: {
        key: `roles`,
        data: {
          key: `roles`,
          value: values,
        },
      },
    });

    await fetchData();
    window.maskOff();
    window.success({ title: '권한설정 저장이 완료되었습니다. ' });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Wrapper>
      <Form
        form={form}
        name="role_config_form"
        colon={false}
        labelAlign="left"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 10 }}
        layout={screens.lg ? 'horizontal' : 'vertical'}
        initialValues={{
          saleStatus: 5,
          refund: 5,
          pointRefund: 5,
          programWorkModify: 5,
          excelDownload: 5,
          account: 5,
          marketing: 5,
          paidPoint: 5,
        }}
        validateMessages={validateMessages}
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={[32, 16]}>
          <Col xs={24} lg={20}>
            <Card size="small" title="매출현황 권한 관리">
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={8}>
                  <RoleFormItem label="매출보기" name="saleStatus" />
                </Col>
                <Col xs={24} lg={8}>
                  <RoleFormItem label="환불 및 취소 처리" name="refund" />
                </Col>
                <Col xs={24} lg={8}>
                  <RoleFormItem label="포인트 환급처리" name="pointRefund" />
                </Col>
              </Row>
            </Card>

            <Divider />

            <Card size="small" title="프로그램 권한 관리">
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={8}>
                  <RoleFormItem
                    label="프로그램 작업수정관리"
                    name="programWorkModify"
                  />
                </Col>
                <Col xs={24} lg={8}>
                  <RoleFormItem label="엑셀다운로드" name="excelDownload" />
                </Col>
                <Col xs={24} lg={8}>
                  <RoleFormItem label="계정관리" name="account" />
                </Col>
              </Row>
            </Card>

            <Divider />

            <Card size="small" title="기타 권한 관리">
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={8}>
                  <RoleFormItem label="마케팅 관리" name="marketing" />
                </Col>
                <Col xs={24} lg={8}>
                  <RoleFormItem label="포인트 지급" name="paidPoint" />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} lg={4}>
            <Affix offsetTop={80}>
              <Form.Item noStyle>
                <Button type="primary" size="large" htmlType="submit" block>
                  저장
                </Button>
              </Form.Item>
            </Affix>
          </Col>
        </Row>
      </Form>
    </Wrapper>
  );
}, []);

export default RoleConfig;
