/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { Layout, Button, Checkbox, Input } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';

import useStore from '@stores/useStore';
import { METAS } from '@shared/queries';

import SiteTitle from '@Styled/SiteTitle';

const { TextArea } = Input;

const Wrapper = styled(Layout)`
  margin-bottom: 20px;
  padding: 20px;

  .content {
    width: 600px;
    margin: 0 auto;

    .description {
      background-color: #fff;
      text-align: center;
      border: 1px solid ${(props) => props.theme.primaryColor};
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  .agree {
    margin-bottom: 6px;
    span {
      font-size: 1.1rem;
      font-weight: bold;
    }
  }
  .agree-description {
    margin-bottom: 25px;
  }
  .ant-input-disabled {
    color: #333;
  }

  ${(props) => props.theme.breakpoint('xs', 'lg')`
    .content {
      width: 100%;  
    }
  `}
`;

const Signup = observer(({ setAgree }) => {
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    terms: false,
    privacy: false,
    termsText: '',
    privacyText: '',
  }));

  const metaRes = useQuery(METAS, {
    variables: { keys: ['member_terms', 'member_privacy'] },
  });

  const handleChange = useCallback(
    (type) => (e) => {
      state[type] = e.target.checked;
    },
    [],
  );

  const handleSubmit = useCallback((e) => {
    if (!state.terms) {
      return window.alert({ title: '이용약관에 동의해 주세요' });
    }
    if (!state.privacy) {
      return window.alert({ title: '개인정보처리방침안내에 동의해 주세요' });
    }

    setAgree(true);
  }, []);

  useEffect(() => {
    if (metaRes.data && metaRes.data.metas) {
      for (const item of metaRes.data.metas) {
        if (item.key === 'member_terms') {
          state.termsText = item.value;
        } else {
          state.privacyText = item.value;
        }
      }
    }
  }, [metaRes.data]);

  return (
    <Wrapper>
      <div className="content">
        {/* <SiteTitle /> */}
        <div className="description">
          회원가입약관 및 개인정보처리방침안내의 내용에 동의하셔야 회원가입을
          하실 수 있습니다.
        </div>
        <Checkbox
          className="agree"
          checked={state.terms}
          onChange={handleChange('terms')}
        >
          이용약관 동의
        </Checkbox>
        <TextArea
          className="agree-description"
          value={state.termsText}
          rows={10}
          disabled
        />
        <Checkbox
          className="agree"
          checked={state.privacy}
          onChange={handleChange('privacy')}
        >
          개인정보처리방침안내
        </Checkbox>
        <TextArea
          className="agree-description"
          value={state.privacyText}
          rows={10}
          disabled
        />

        <Button
          size="large"
          type="primary"
          onClick={handleSubmit}
          block
          disabled={!Boolean(state.terms && state.privacy)}
        >
          확인
        </Button>
      </div>
    </Wrapper>
  );
});

export default Signup;
