/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { Row, Col, Button, InputNumber, DatePicker, Space, Card } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { toJS } from 'mobx';
import moment from 'moment';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { useApolloClient, useMutation } from '@apollo/react-hooks';

import useStore from '@stores/useStore';
import { ACCOUNT_LIST, UPDATE_ACCOUNT } from '@shared/queries/AccountQueries';
import { excelResizeWidth } from '@utils/common';

import CustomModal from '@Common/CustomModal';

import AccountConfig from './AccountConfig';

const Wrapper = styled.div`
  width: 100%;

  .account-card {
    margin-bottom: 20px;
    .ant-space-item {
      width: 180px;
    }
  }
  .cnt-title {
    font-size: 1.1rem;
    font-weight: bold;
  }
  .cnt {
    text-align: right;
  }

  ${(props) => props.theme.breakpoint('xs', 'lg')`
    .account-card {
      .ant-space-item {
        margin-bottom: 15px;
      }
    }
  `}
`;

const Account = observer(() => {
  const state = useLocalStore(() => ({
    oa_list: [],
    ta_list: [],
    ca_list: [],
    sa_list: [],

    configModalOpen: false,
    configModalTime: null,
    configModalDays: null,
  }));

  const [update] = useMutation(UPDATE_ACCOUNT);
  const client = useApolloClient();

  const fetchData = useCallback(async (findQuery = {}) => {
    window.maskOn();

    const result = await client.query({
      query: ACCOUNT_LIST,
      variables: {
        findQuery,
      },
    });

    if (result.data && result.data.accountList) {
      const list = result.data.accountList;
      state.oa_list = list.filter((item) => item.accountList === '최적화계정');
      state.ta_list = list.filter((item) => item.accountList === '육성중계정');
      state.ca_list = list.filter((item) => item.accountList === '생성아이디');
      state.sa_list = list.filter((item) => item.accountList === '스팸성계정');
    }
    window.maskOff();
  }, []);

  const handleChangeInputNumber = useCallback(
    (type) => (value) => {
      state[type] = value;
    },
    [],
  );

  const handleSubmitConfigModal = useCallback(async () => {
    window.maskOn();

    const findQuery = { accountType: '스팸성계정' };
    const res = await client.query({
      query: ACCOUNT_LIST,
      variables: {
        findQuery,
      },
    });

    // if (res.data && res.data.accountList) {
    //   const list = res.data.accountList;
    //   for (const item of list) {
    //     await update({
    //       variables: {
    //         id: item.id,
    //         data: {
    //           time: state.configModalTime,
    //           days: state.configModalDays,
    //         },
    //       },
    //     });
    //   }
    // }

    state.configModalTime = null;
    state.configModalDays = null;
    state.configModalOpen = false;

    window.maskOff();
  }, []);

  const handleExcelDown = useCallback(
    (accountType) => async () => {
      window.maskOn();
      const findQuery = { accountType };
      const res = await client.query({
        query: ACCOUNT_LIST,
        variables: {
          findQuery,
        },
      });

      if (res.data && res.data.accountList) {
        const list = res.data.accountList;
        const workbook = new Excel.Workbook();

        let worksheet = workbook.addWorksheet(`${accountType} 리스트`);

        // worksheet.addRow([
        //   '아이디',
        //   '이름',
        //   '서버구분',
        //   '중분류',
        //   '소분류',
        //   '업체명 키워드',
        //   '노출키워드',
        //   // '사업자등록번호',
        //   '업체주소',
        //   '기간',
        //   '횟수',
        // ]);
        // worksheet.addRow([
        //   server.user.username,
        //   server.user.name,
        //   server.product.label,
        //   server.middleCategory ? server.middleCategory.name : '',
        //   server.lastCategory ? server.lastCategory.name : '',
        //   server.memberKeyword,
        //   server.topKeyword,
        //   server.address,
        //   server.counter,
        //   server.rollingCnt,
        // ]);
        // worksheet.addRow([]);
        worksheet.addRow(['갯수', '아이디']);
        let idx = 0;
        for (const item of list) {
          let value = [idx + 1, item.instaId];
          worksheet.addRow(value);
          idx++;
        }

        worksheet = await excelResizeWidth(worksheet);
        const xls64 = await workbook.xlsx.writeBuffer({ base64: true });

        saveAs(
          new Blob([xls64], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }),
          `${accountType} 리스트`,
        );
      } else {
        window.maskOff();
        return window.alert({
          title: '엑셀로 저장할 데이터를 찾을 수 없습니다.',
        });
      }

      window.maskOff();
    },
    [],
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Wrapper>
      <Card className="account-card" size="small">
        <Row gutter={[16, 0]} justify="space-between" align="middle">
          <Col xs={24} lg={8}>
            <Space size={30}>
              <span className="cnt-title">최적화 계정 개수</span>
              <span className="cnt">{state.oa_list.length}개</span>
            </Space>
          </Col>
          <Col xs={24} lg={4}>
            <Button
              type="primary"
              block
              onClick={handleExcelDown('최적화계정')}
            >
              엑셀 다운로드
            </Button>
          </Col>
        </Row>
      </Card>
      <Card className="account-card" size="small">
        <Row gutter={[16, 0]} justify="space-between" align="middle">
          <Col xs={24} lg={8}>
            <Space size={30}>
              <span className="cnt-title">육성중인 계정 개수</span>
              <span className="cnt">{state.ta_list.length}개</span>
            </Space>
          </Col>
          <Col xs={24} lg={4}>
            <Button
              type="primary"
              block
              onClick={handleExcelDown('육성중계정')}
            >
              엑셀 다운로드
            </Button>
          </Col>
        </Row>
      </Card>
      <Card className="account-card" size="small">
        <Row gutter={[16, 0]} justify="space-between" align="middle">
          <Col xs={24} lg={8}>
            <Space size={30}>
              <span className="cnt-title">생성 아이디 개수</span>
              <span className="cnt">{state.ca_list.length}개</span>
            </Space>
          </Col>
          <Col xs={24} lg={4}>
            <Button
              type="primary"
              block
              onClick={handleExcelDown('생성아이디')}
            >
              엑셀 다운로드
            </Button>
          </Col>
        </Row>
      </Card>
      <Card className="account-card" size="small">
        <Row gutter={[16, 0]} justify="space-between" align="middle">
          <Col xs={24} lg={8}>
            <Space size={30}>
              <span className="cnt-title">스팸성 계정 개수</span>
              <span className="cnt">{state.sa_list.length}개</span>
            </Space>
          </Col>
          <Col xs={24} lg={4}>
            <Button
              style={{ marginBottom: 10 }}
              block
              onClick={() => (state.configModalOpen = true)}
            >
              육성 변환일수 설정
            </Button>
            <Button
              type="primary"
              block
              onClick={handleExcelDown('스팸성계정')}
            >
              엑셀 다운로드
            </Button>
          </Col>
        </Row>
      </Card>

      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <AccountConfig metaKey="ta_config" configName="육성중 계정 설정" />
        </Col>
        <Col xs={24}>
          <AccountConfig metaKey="oa_config" configName="최적화 계정 설정" />
        </Col>
      </Row>

      <CustomModal
        title="육성 변환일수 설정"
        visible={state.configModalOpen}
        onCancel={(e) => (state.configModalOpen = false)}
        onOk={handleSubmitConfigModal}
        okText="저장"
      >
        <Space size={40}>
          <div>
            <h4>시간</h4>
            <InputNumber
              value={state.configModalTime}
              onChange={handleChangeInputNumber('configModalTime')}
            />
          </div>
          <div>
            <h4>일수</h4>
            <InputNumber
              value={state.configModalDays}
              onChange={handleChangeInputNumber('configModalDays')}
            />
          </div>
        </Space>
      </CustomModal>
    </Wrapper>
  );
});

export default Account;
