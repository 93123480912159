/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback } from 'react';
import { Tabs, Row, Col } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';

import PageTitle from '@Common/PageTitle';

import Account from './Account';
import AccountErrors from './AccountErrors';

const { TabPane } = Tabs;

const Wrapper = styled.div`
  width: 100%;
`;

const AccountContainer = observer(() => {
  const state = useLocalStore(() => ({
    tab: '1',
  }));

  const handleChangeTab = useCallback((key) => {
    state.tab = key;
  }, []);

  return (
    <Wrapper>
      <Row>
        <Col xs={24}>
          <PageTitle>계정 관리</PageTitle>
        </Col>
      </Row>

      <Tabs activeKey={state.tab} onChange={handleChangeTab}>
        <TabPane tab="계정관리" key="1">
          <Account />
        </TabPane>
        <TabPane tab="계정스팸내역" key="2">
          <AccountErrors />
        </TabPane>
      </Tabs>
    </Wrapper>
  );
});

export default AccountContainer;
