import { action, observable } from 'mobx';

import { METAS_REGEXP, META } from '@shared/queries';

class MetaStore {
  @observable df = {};
  @observable board = {};
  @observable member = {};
  @observable role = {};
  @observable payPoint = [];

  constructor() {}

  @action init = (client) => {
    return new Promise(async (resolve) => {
      const result = await client.query({
        query: METAS_REGEXP,
        variables: { key: `^(df_|board_|member_)` },
      });
      if (result.data && result.data.metasRegexp) {
        for (const item of result.data.metasRegexp) {
          const keys = item.key.split(/_(.+)/);
          this[keys[0]][keys[1]] = item.value;
        }
      }

      const roleRes = await client.query({
        query: META,
        variables: { key: `roles` },
      });
      if (roleRes.data && roleRes.data.meta) {
        this.role = roleRes.data.meta.value;
      }
      const payPointRes = await client.query({
        query: META,
        variables: { key: `pay_point` },
      });
      if (payPointRes.data && payPointRes.data.meta) {
        this.payPoint = payPointRes.data.meta.value;
      }
      resolve();
    });
  };
}
export default MetaStore;
