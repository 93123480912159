/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { Row, Col, Button, Divider, Input, Card } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';

import useStore from '@stores/useStore';

const Wrapper = styled.div`
  width: 100%;
`;

const ModifyK = observer(
  ({ propData = {}, handleSubmit, handleClose, isAdmin }) => {
    const { commonStore } = useStore();
    const state = useLocalStore(() => ({
      instaId: '',
      comment: '',
      commentStorage: '',
    }));

    const handleChangeInputNumber = useCallback(
      (type) => (value) => {
        state[type] = value;
      },
      [],
    );

    const handleChangeInput = useCallback(
      (type) => (e) => {
        state[type] = e.target.value;
      },
      [],
    );

    const handleCheckTextAreaRow = useCallback(
      (type) => (e) => {
        const lines = state[type].split('\n');
        let len = 0;
        if (type === 'comment') {
          len = propData.buyCnt;
        } else {
          len = lines.length;
        }

        let values = [];
        for (let i = 0; i < len; i++) {
          if (lines[i] && lines[i].length) {
            if (!/^[0-9]+\./.test(lines[i])) {
              values.push(`${i + 1}.${lines[i]}`);
            } else {
              let dotIdx = lines[i].indexOf('.');
              values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
            }
          }
        }
        state[type] = values.join('\n');
      },
      [propData],
    );

    const handlePressEnter = useCallback(
      (type) => (e) => {
        e.persist();
        setTimeout(() => {
          const lines = state[type].split('\n');
          let values = [];
          for (let i = 0; i < lines.length; i++) {
            if (!/^[0-9]+\./.test(lines[i])) {
              values.push(`${i + 1}.${lines[i]}`);
            } else {
              let dotIdx = lines[i].indexOf('.');
              values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
            }
          }
          state[type] = values.join('\n');
        }, 10);
      },
      [propData],
    );

    const handleClickSubmit = useCallback(() => {
      setTimeout(() => {
        const data = {
          instaId: state.instaId,
        };
        if (propData.commentType !== '랜덤댓글') {
          data.comment = state.comment;
          data.commentStorage = state.commentStorage;
        }
        handleSubmit(propData.id, data);
      }, 10);
    }, [propData, handleSubmit]);

    useEffect(() => {
      if (propData.instaId) {
        state.instaId = propData.instaId;
      }
      if (propData.comment) {
        state.comment = propData.comment;
      }
      if (propData.commentStorage) {
        state.commentStorage = propData.commentStorage;
      }
    }, [propData]);

    return (
      <Wrapper>
        <Row gutter={[10, 10]} justify="space-between" align="middle">
          <Col>인스타 댓글 입력</Col>
          {/* <Col>
          <Button>댓글 추가</Button>
        </Col> */}
        </Row>

        <Row gutter={[10, 10]}>
          {propData.workType === 'K팔로워' && (
            <Col xs={24}>
              <h4>인스타 아이디</h4>
              <Input
                value={state.instaId}
                onChange={handleChangeInput('instaId')}
              />
            </Col>
          )}

          {propData.commentType !== '랜덤댓글' && (
            <>
              <Col xs={24} lg={12}>
                <Card size="small" title="프로그램에서 사용중인 댓글">
                  <h4>구매한 댓글 개수 : {propData.buyCnt}</h4>
                  <Input.TextArea
                    rows={5}
                    value={state.comment}
                    onChange={handleChangeInput('comment')}
                    onBlur={handleCheckTextAreaRow('comment')}
                    onPressEnter={handlePressEnter('comment')}
                  />
                </Card>
              </Col>
              <Col xs={24} lg={12}>
                <Card size="small" title="댓글 저장 공간">
                  <h4>&nbsp;</h4>
                  <Input.TextArea
                    rows={5}
                    value={state.commentStorage}
                    onChange={handleChangeInput('commentStorage')}
                    onBlur={handleCheckTextAreaRow('commentStorage')}
                    onPressEnter={handlePressEnter('commentStorage')}
                  />
                </Card>
              </Col>
            </>
          )}
        </Row>

        <Divider />

        <Row gutter={[10, 10]} justify="center" align="middle">
          <Col xs={24} lg={4}>
            <Button block onClick={handleClose}>
              닫기
            </Button>
          </Col>
          <Col xs={24} lg={4}>
            <Button type="primary" block onClick={handleClickSubmit}>
              저장
            </Button>
          </Col>
        </Row>
      </Wrapper>
    );
  },
);

export default ModifyK;
