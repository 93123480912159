/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { Tabs } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';

import useStore from '@stores/useStore';

import CustomModal from '@Common/CustomModal';

import AutocompleteTopModifyForm from '@Admin/AdminSetting/AutocompleteTop/AutocompleteTopModifyForm';

const { TabPane } = Tabs;

const Wrapper = styled.div`
  width: 100%;
`;

const AutocompleteTop = observer(
  ({ propData = [], modalOpen, handleModal, refetch }) => {
    const { commonStore } = useStore();
    const state = useLocalStore(() => ({
      tab: '',
    }));

    const handleChangeTab = useCallback((key) => {
      state.tab = key;
    }, []);

    useEffect(() => {
      if (propData && propData[0]) {
        state.tab = propData[0].id;
      }
    }, [propData]);

    return (
      <CustomModal
        title={`자동완성 상위노출 수정`}
        visible={modalOpen}
        onCancel={handleModal(false)}
        minwidth={800}
        footer={null}
      >
        <Wrapper>
          <Tabs activeKey={state.tab} onChange={handleChangeTab}>
            {propData
              ? propData.map((item, idx) => (
                  <TabPane tab={`자동완성 상위노출${idx + 1}`} key={item.id}>
                    <AutocompleteTopModifyForm
                      propData={item}
                      modalOpen={modalOpen}
                      handleModal={handleModal}
                      refetch={refetch}
                    />
                  </TabPane>
                ))
              : null}
          </Tabs>
        </Wrapper>
      </CustomModal>
    );
  },
);

export default AutocompleteTop;
