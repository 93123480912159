/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import {
  Row,
  Col,
  Input,
  InputNumber,
  Space,
  Card,
  Divider,
  Button,
} from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import uniqid from 'uniqid';

import { numberWithCommas } from '@utils/common';

const Wrapper = styled.div`
  width: 100%;
`;

const ProductConfigPPCaseBy = observer(({ productValue, setProductValue }) => {
  const state = useLocalStore(() => ({}));

  const handleChangeInput = useCallback(
    (type, key, uid) => (e) => {
      if (uid) {
        if (!productValue[type] || !Array.isArray(productValue[type])) {
          productValue[type] = [];
        }
        const fIdx = productValue[type].findIndex((item) => item.uid === uid);
        if (fIdx !== -1) {
          productValue[type][fIdx][key] = e.target.value;
        }
      } else {
        productValue[type] = e.target.value;
      }
      setProductValue(productValue);
    },
    [productValue],
  );

  const handleChangeNumber = useCallback(
    (type) => (value) => {
      productValue[type] = value;
      setProductValue(productValue);
    },
    [productValue],
  );

  const handleChangeNumberArr = useCallback(
    (type, key, uid) => (value) => {
      if (!productValue[type]) {
        productValue[type] = [];
      }
      const fIdx = productValue[type].findIndex((item) => item.uid === uid);
      if (fIdx !== -1) {
        productValue[type][fIdx][key] = value;
      }
      setProductValue(productValue);
    },
    [productValue],
  );

  const handleAddPriceData = useCallback(
    (type) => (e) => {
      if (!productValue[type]) {
        productValue[type] = [];
      }
      productValue[type].push({
        uid: uniqid.time(),
      });
      setProductValue(productValue);
    },
    [productValue],
  );

  const handleRemovePriceData = useCallback(
    (type, uid) => (e) => {
      if (!productValue[type]) {
        productValue[type] = [];
      }
      const fIdx = productValue[type].findIndex((item) => item.uid === uid);
      if (fIdx !== -1) {
        productValue[type].splice(fIdx, 1);
      }
      setProductValue(productValue);
    },
    [productValue],
  );

  return (
    <Wrapper>
      <Card title="가격설정" size="small">
        <Row gutter={[40, 10]}>
          <Col xs={24} xl={14}>
            <h3>[건금액]</h3>
            <Row gutter={[10, 10]}>
              {productValue.casePrice &&
                productValue.casePrice.map((item) => (
                  <Col xs={24} key={item.uid}>
                    <Space size={15}>
                      {/* <Input
                        defaultValue={item.name}
                        onBlur={handleChangeInput(
                          'casePrice',
                          'name',
                          item.uid,
                        )}
                      /> */}
                      <Space>
                        <InputNumber
                          value={item.rank1}
                          onChange={handleChangeNumberArr(
                            'casePrice',
                            'rank1',
                            item.uid,
                          )}
                          style={{ width: 70 }}
                        />
                        <span>위</span>
                      </Space>
                      <span>~</span>
                      <Space>
                        <InputNumber
                          value={item.rank2}
                          onChange={handleChangeNumberArr(
                            'casePrice',
                            'rank2',
                            item.uid,
                          )}
                          style={{ width: 70 }}
                        />
                        <span>위</span>
                      </Space>
                      <Space>
                        <InputNumber
                          value={item.price}
                          onChange={handleChangeNumberArr(
                            'casePrice',
                            'price',
                            item.uid,
                          )}
                          formatter={(value) => numberWithCommas(value)}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: 140 }}
                        />
                        <span>원</span>
                      </Space>
                      <Space>
                        <InputNumber
                          placeholder="노출개수"
                          value={item.visibleCnt}
                          onChange={handleChangeNumberArr(
                            'casePrice',
                            'visibleCnt',
                            item.uid,
                          )}
                          formatter={(value) => numberWithCommas(value)}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: 100 }}
                        />
                        <span>개</span>
                      </Space>
                      <Space>
                        <InputNumber
                          placeholder="일수"
                          value={item.days}
                          onChange={handleChangeNumberArr(
                            'casePrice',
                            'days',
                            item.uid,
                          )}
                          style={{ width: 80 }}
                        />
                        <span>일</span>
                      </Space>
                      <Button
                        shape="circle"
                        icon={<MinusOutlined />}
                        size="small"
                        danger
                        onClick={handleRemovePriceData('casePrice', item.uid)}
                      />
                    </Space>
                  </Col>
                ))}
            </Row>
            <Button
              icon={<PlusOutlined />}
              block
              onClick={handleAddPriceData('casePrice')}
            >
              건금액 추가
            </Button>
          </Col>
          <Col xs={24} xl={14}>
            <h3>[월금액]</h3>
            <Row gutter={[10, 10]}>
              {productValue.monthPrice &&
                productValue.monthPrice.map((item) => (
                  <Col xs={24} key={item.uid}>
                    <Space size={15}>
                      {/* <Input
                        defaultValue={item.name}
                        onBlur={handleChangeInput(
                          'monthPrice',
                          'name',
                          item.uid,
                        )}
                      /> */}
                      <Space>
                        <InputNumber
                          value={item.rank1}
                          onChange={handleChangeNumberArr(
                            'monthPrice',
                            'rank1',
                            item.uid,
                          )}
                          style={{ width: 70 }}
                        />
                        <span>위</span>
                      </Space>
                      <span>~</span>
                      <Space>
                        <InputNumber
                          value={item.rank2}
                          onChange={handleChangeNumberArr(
                            'monthPrice',
                            'rank2',
                            item.uid,
                          )}
                          style={{ width: 70 }}
                        />
                        <span>위</span>
                      </Space>
                      <Space>
                        <InputNumber
                          value={item.price}
                          onChange={handleChangeNumberArr(
                            'monthPrice',
                            'price',
                            item.uid,
                          )}
                          formatter={(value) => numberWithCommas(value)}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: 140 }}
                        />
                        <span>원</span>
                      </Space>
                      <Space>
                        <InputNumber
                          placeholder="노출개수"
                          value={item.visibleCnt}
                          onChange={handleChangeNumberArr(
                            'monthPrice',
                            'visibleCnt',
                            item.uid,
                          )}
                          formatter={(value) => numberWithCommas(value)}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: 100 }}
                        />
                        <span>개</span>
                      </Space>
                      <Space>
                        <InputNumber
                          placeholder="일수"
                          value={item.days}
                          onChange={handleChangeNumberArr(
                            'monthPrice',
                            'days',
                            item.uid,
                          )}
                          style={{ width: 80 }}
                        />
                        <span>일</span>
                      </Space>
                      <Button
                        shape="circle"
                        icon={<MinusOutlined />}
                        size="small"
                        danger
                        onClick={handleRemovePriceData('monthPrice', item.uid)}
                      />
                    </Space>
                  </Col>
                ))}
            </Row>
            <Button
              icon={<PlusOutlined />}
              block
              onClick={handleAddPriceData('monthPrice')}
            >
              월금액 추가
            </Button>
          </Col>
          <Col xs={24} xl={14}>
            <h3>[고객월건바이 금액]</h3>
            <Row gutter={[10, 10]}>
              {productValue.customerMonthPrice &&
                productValue.customerMonthPrice.map((item) => (
                  <Col xs={24} key={item.uid}>
                    <Space size={15}>
                      {/* <Input
                        defaultValue={item.name}
                        onBlur={handleChangeInput(
                          'customerMonthPrice',
                          'name',
                          item.uid,
                        )}
                      /> */}
                      <Space>
                        <InputNumber
                          value={item.rank1}
                          onChange={handleChangeNumberArr(
                            'customerMonthPrice',
                            'rank1',
                            item.uid,
                          )}
                          style={{ width: 70 }}
                        />
                        <span>위</span>
                      </Space>
                      <span>~</span>
                      <Space>
                        <InputNumber
                          value={item.rank2}
                          onChange={handleChangeNumberArr(
                            'customerMonthPrice',
                            'rank2',
                            item.uid,
                          )}
                          style={{ width: 70 }}
                        />
                        <span>위</span>
                      </Space>
                      <Space>
                        <InputNumber
                          value={item.price}
                          onChange={handleChangeNumberArr(
                            'customerMonthPrice',
                            'price',
                            item.uid,
                          )}
                          formatter={(value) => numberWithCommas(value)}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: 140 }}
                        />
                        <span>원</span>
                      </Space>
                      <Space>
                        <InputNumber
                          placeholder="노출개수"
                          value={item.visibleCnt}
                          onChange={handleChangeNumberArr(
                            'customerMonthPrice',
                            'visibleCnt',
                            item.uid,
                          )}
                          formatter={(value) => numberWithCommas(value)}
                          parser={(value) => value.replace(/[^0-9]/g, '')}
                          style={{ width: 100 }}
                        />
                        <span>개</span>
                      </Space>
                      <Space>
                        <InputNumber
                          placeholder="일수"
                          value={item.days}
                          onChange={handleChangeNumberArr(
                            'customerMonthPrice',
                            'days',
                            item.uid,
                          )}
                          style={{ width: 80 }}
                        />
                        <span>일</span>
                      </Space>
                      <Button
                        shape="circle"
                        icon={<MinusOutlined />}
                        size="small"
                        danger
                        onClick={handleRemovePriceData(
                          'customerMonthPrice',
                          item.uid,
                        )}
                      />
                    </Space>
                  </Col>
                ))}
            </Row>
            <Button
              icon={<PlusOutlined />}
              block
              onClick={handleAddPriceData('customerMonthPrice')}
            >
              고객월건바이 금액 추가
            </Button>
          </Col>
          <Col xs={24}>
            <h3>[프로그램 추가옵션]</h3>
            <div>직접입력댓글</div>
            <Space>
              <InputNumber
                value={productValue.directCommentPrice}
                onChange={handleChangeNumber('directCommentPrice')}
                formatter={(value) => numberWithCommas(value)}
                parser={(value) => value.replace(/[^0-9]/g, '')}
                style={{ width: 140 }}
              />
              <span>원</span>
            </Space>
          </Col>
        </Row>
      </Card>
    </Wrapper>
  );
});

export default ProductConfigPPCaseBy;
