/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback, useMemo } from 'react';
import {
  Table,
  Row,
  Col,
  Space,
  DatePicker,
  Button,
  Input,
  Select,
  Grid,
  Descriptions,
} from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import moment from 'moment';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { toJS } from 'mobx';

import useStore from '@stores/useStore';
import { POINT_LOGS, UPDATE_POINT_LOG } from '@shared/queries/PointLogQueries';
import { UPDATE_USER, USER_ONE } from '@shared/queries/UserQueries';
import { numberWithCommas } from '@utils/common';

const { Search } = Input;
const { Option } = Select;
const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  width: 100%;

  .ant-table-summary {
    background-color: #fafafa;
    text-align: center;
  }
`;

const PointBank = observer(({ user, tab }) => {
  const screen = useBreakpoint();
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    list: [],
    paginate: {
      docs: [],
      totalDocs: 0,
      limit: 10,
      page: 1,
      totalPages: 1,
    },

    searchType: 'date',
    startDate: null,
    endDate: null,
    searchStatus: null,
  }));

  const [update] = useMutation(UPDATE_POINT_LOG);
  const [updateUser] = useMutation(UPDATE_USER);
  const client = useApolloClient();

  const initFetchData = useCallback(async () => {
    const now = await commonStore.getServerNow();
    const startDate = moment(now.startOf('month'));
    const endDate = moment(now.endOf('month'));
    state.startDate = startDate;
    state.endDate = endDate;
    await fetchData({
      $and: [
        { createdAt: { $gte: startDate } },
        { createdAt: { $lte: endDate } },
      ],
    });
  }, [user]);

  const fetchData = useCallback(
    async (findQuery = {}) => {
      window.maskOn();

      findQuery.pointType = '포인트충전';
      findQuery.status = '무통장';
      if (user) {
        findQuery.user = user.id;
      }

      const result = await client.query({
        query: POINT_LOGS,
        variables: {
          page: state.paginate.page,
          limit: state.paginate.limit,
          findQuery,
        },
      });

      if (result.data && result.data.pointLogs) {
        state.paginate = result.data.pointLogs;
        state.list = result.data.pointLogs.docs;
      }
      window.maskOff();
    },
    [user],
  );

  const handleChangePage = useCallback(
    (page) => {
      if (state.paginate.page !== page) {
        state.paginate.page = page;
        handleSearch(state.searchType)();
      }
    },
    [user],
  );

  const handleChangeDate = useCallback(
    (type) => (momentVal) => {
      state[type] = momentVal;
    },
    [],
  );

  const handleChangeSearch = useCallback(
    (type) => (value) => {
      state[type] = value;
    },
    [],
  );

  const handleSearch = useCallback(
    (type) => (value) => {
      state.searchType = type;
      const findQuery = {};

      if (value && value.length) {
        if (type === 'name') {
          findQuery.user = {
            $or: [
              { username: { $regex: value, $options: 'ig' } },
              { name: { $regex: value, $options: 'ig' } },
            ],
          };
        }
      }

      if (type === 'date') {
        findQuery['$and'] = [];
        if (state.startDate) {
          findQuery['$and'].push({
            createdAt: {
              $gte: state.startDate.set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              }),
            },
          });
        }
        if (state.endDate) {
          findQuery['$and'].push({
            createdAt: {
              $lte: state.endDate.set({
                hour: 23,
                minute: 59,
                second: 59,
                millisecond: 999,
              }),
            },
          });
        }
        if (!findQuery['$and'].length) {
          delete findQuery['$and'];
        }
      }

      fetchData(findQuery);
    },
    [user],
  );

  const handleSubmit = useCallback(
    (row) => () => {
      window.ask({
        title: '결제완료 처리 하시겠습니까?',
        async onOk() {
          const updateRes = await update({
            variables: {
              id: row.id,
              data: {
                status: '결제완료',
                manager: commonStore.user.id,
                paymentDate: moment().toDate(),
              },
            },
          });

          if (updateRes.data && updateRes.data.updatePointLog) {
            const userRes = await client.query({
              query: USER_ONE,
              variables: {
                findQuery: { _id: row.user.id },
              },
            });

            if (userRes.data && userRes.data.userOne) {
              const userData = userRes.data.userOne;
              let point = userData.point;
              let chargePoint = row.point;
              if (row.tax) {
                chargePoint -= row.tax;
              }
              point += chargePoint;
              await updateUser({
                variables: {
                  id: userData.id,
                  data: {
                    point,
                  },
                },
              });

              await handleSearch(state.searchType)();

              window.success({ title: '포인트 지급이 완료되었습니다.' });
            }
          }
        },
      });
    },
    [],
  );
  const handleRemove = useCallback(
    (row) => () => {
      window.ask({
        title: '취소처리 하시겠습니까?',
        async onOk() {
          await update({
            variables: {
              id: row.id,
              data: {
                status: '취소처리',
              },
            },
          });

          initFetchData();

          window.success({ title: '취소처리가 완료되었습니다.' });
        },
      });
    },
    [],
  );

  useEffect(() => {
    if (tab === '4') {
      initFetchData();
    }
  }, [tab]);

  useEffect(() => {
    initFetchData();
  }, [user]);

  const columns = useMemo(
    () => [
      {
        title: '날짜',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (_, row) => moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        align: 'center',
      },
      {
        title: '아이디',
        dataIndex: 'username',
        key: 'username',
        render: (_, row) => row.user.username,
        align: 'center',
      },
      {
        title: '성명',
        dataIndex: 'name',
        key: 'name',
        render: (_, row) => row.user.name,
        align: 'center',
      },
      {
        title: '내용',
        dataIndex: 'description',
        key: 'description',
        align: 'center',
      },
      {
        title: '상태',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
      },
      {
        title: '충전포인트',
        dataIndex: 'point',
        key: 'point',
        render: (_, row) => {
          let chargePoint = row.point;
          if (row.tax) {
            chargePoint -= row.tax;
          }
          return numberWithCommas(chargePoint, true);
        },
        align: 'center',
      },
      {
        title: '입금액',
        dataIndex: 'amount',
        key: 'amount',
        render: (_, row) => {
          return numberWithCommas(row.point, true);
        },
        align: 'center',
      },
      {
        title: '세액',
        dataIndex: 'tax',
        key: 'tax',
        render: (_, row) => {
          return numberWithCommas(row.tax, true);
        },
        align: 'center',
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (_, row) => (
          <Space>
            <Button type="primary" onClick={handleRemove(row)} danger>
              취소
            </Button>
            <Button type="primary" onClick={handleSubmit(row)}>
              지급
            </Button>
          </Space>
        ),
        align: 'center',
      },
    ],
    [],
  );

  const mobileColumns = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'idx',
        key: 'idx',
        render: (_, row, idx) => {
          return (
            <Descriptions size="small" bordered column={1}>
              <Descriptions.Item label="날짜">
                {moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')}
              </Descriptions.Item>
              <Descriptions.Item label="아이디">
                {row.user.username}
              </Descriptions.Item>
              <Descriptions.Item label="성명">
                {row.user.name}
              </Descriptions.Item>
              <Descriptions.Item label="내용">
                {row.description}
              </Descriptions.Item>
              <Descriptions.Item label="상태">{row.status}</Descriptions.Item>
              <Descriptions.Item label="충전포인트">
                {numberWithCommas(row.point - (row.tax || 0))}
              </Descriptions.Item>
              <Descriptions.Item label="입금액">
                {numberWithCommas(row.point)}
              </Descriptions.Item>
              <Descriptions.Item label="세액">
                {numberWithCommas(row.tax)}
              </Descriptions.Item>
              <Descriptions.Item label="">
                <Space>
                  <Button type="primary" onClick={handleRemove(row)} danger>
                    취소
                  </Button>
                  <Button type="primary" onClick={handleSubmit(row)}>
                    지급
                  </Button>
                </Space>
              </Descriptions.Item>
            </Descriptions>
          );
        },
      },
    ],
    [],
  );

  return (
    <Wrapper>
      <Row gutter={[32, 16]}>
        <Col xs={24} lg={6}>
          <h4>기간별 검색</h4>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={16}>
              <Space>
                <DatePicker
                  placeholder="시작일"
                  value={state.startDate}
                  onChange={handleChangeDate('startDate')}
                />
                <DatePicker
                  placeholder="종료일"
                  value={state.endDate}
                  onChange={handleChangeDate('endDate')}
                />
              </Space>
            </Col>
            <Col xs={24} lg={6}>
              <Button type="primary" block onClick={handleSearch('date')}>
                검색
              </Button>
            </Col>
          </Row>
        </Col>

        {!user && (
          <Col xs={24} lg={4}>
            <h4>ID검색</h4>
            <Search
              placeholder="이름 | 아이디"
              onSearch={handleSearch('name')}
              enterButton
            />
          </Col>
        )}
      </Row>

      <Table
        size="small"
        dataSource={toJS(state.list)}
        columns={screen.lg ? columns : mobileColumns}
        // scroll={{ x: 992 }}
        pagination={{
          current: state.paginate.page,
          total: state.paginate.totalDocs,
          showSizeChanger: false,
          onChange: handleChangePage,
        }}
        rowKey={(row) => row.id}
        bordered
        summary={(pageData) => {
          let pageOrderPoint = 0;
          let pageAmount = 0;
          let pageTax = 0;
          for (const row of pageData) {
            if (row.status !== '취소처리') {
              pageOrderPoint += row.point - (row.tax || 0);
              pageAmount += row.point;
              pageTax += row.tax;
            }
          }

          return screen.lg ? (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
              <Table.Summary.Cell>
                {numberWithCommas(pageOrderPoint, true)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {numberWithCommas(pageAmount, true)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {numberWithCommas(pageTax, true)}
              </Table.Summary.Cell>
              <Table.Summary.Cell></Table.Summary.Cell>
            </Table.Summary.Row>
          ) : (
            <Table.Summary.Row>
              <Table.Summary.Cell>
                <Descriptions size="small" bordered column={1}>
                  <Descriptions.Item label="충전포인트">
                    {numberWithCommas(pageOrderPoint, true)}
                  </Descriptions.Item>
                  <Descriptions.Item label="입금액">
                    {numberWithCommas(pageAmount, true)}
                  </Descriptions.Item>
                  <Descriptions.Item label="세액">
                    {numberWithCommas(pageTax, true)}
                  </Descriptions.Item>
                </Descriptions>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    </Wrapper>
  );
});

export default PointBank;
