/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { Descriptions, Input, Button } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import moment from 'moment';
import { useApolloClient, useMutation } from '@apollo/react-hooks';

import useStore from '@stores/useStore';
import { UPDATE_POINT_LOG } from '@shared/queries/PointLogQueries';
import { UPDATE_USER, USER_ONE } from '@shared/queries/UserQueries';
import { UPDATE_WORK } from '@shared/queries/WorkQueries';
import { numberWithCommas } from '@utils/common';

const PointDetail = observer(({ propData = {}, handleClose, refetch }) => {
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    cancelReason: '',
    memo: '',
  }));

  const client = useApolloClient();
  const [update] = useMutation(UPDATE_POINT_LOG);
  const [updateWork] = useMutation(UPDATE_WORK);
  const [updateUser] = useMutation(UPDATE_USER);

  const handleChangeInput = useCallback(
    (type) => (e) => {
      state[type] = e.target.value;
    },
    [],
  );

  const handleSubmit = useCallback(() => {
    if (propData && propData.id) {
      return window.ask({
        title: '계속 진행하시겠습니까?',
        content: `해당 내역을 취소처리 하시겠습니까?`,
        async onOk() {
          window.maskOn();
          const res = await client.query({
            query: USER_ONE,
            variables: {
              findQuery: { _id: propData.user.id },
            },
          });
          if (res.data && res.data.userOne) {
            const userPoint = res.data.userOne.point + propData.point;

            await update({
              variables: {
                id: propData.id,
                data: {
                  cancelReason: state.cancelReason,
                  memo: state.memo,
                  status: '취소처리',
                  manager: commonStore.user.id,
                },
              },
            });

            await updateWork({
              variables: {
                id: propData.work.id,
                data: {
                  isActivated: false,
                },
              },
            });

            await updateUser({
              variables: {
                id: propData.user.id,
                data: {
                  point: userPoint,
                },
              },
            });
          }

          if (refetch) {
            await refetch();
          }

          state.cancelReason = '';
          state.memo = '';
          window.maskOff();
          window.alert({ title: '취소처리가 완료되었습니다.' });
          handleClose(false)();
        },
      });
    }
  }, [propData, refetch, handleClose]);

  return (
    <>
      <Descriptions
        title="사용포인트 상세내용"
        bordered
        size="small"
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      >
        <Descriptions.Item label="사용일시">
          {moment(propData.createdAt).format('YYYY-MM-DD HH:mm:ss')}
        </Descriptions.Item>
        <Descriptions.Item label="아이디">
          {propData.user.username}
        </Descriptions.Item>
        <Descriptions.Item label="이름">{propData.user.name}</Descriptions.Item>
        <Descriptions.Item label="포인트금액">
          {numberWithCommas(propData.point)}
        </Descriptions.Item>
        <Descriptions.Item label="상태">{propData.status}</Descriptions.Item>
        {propData.status === '취소처리' ? (
          <>
            <Descriptions.Item label="취소사유">
              {propData.cancelReason ? <pre>{propData.cancelReason}</pre> : ''}
            </Descriptions.Item>
            <Descriptions.Item label="상담내용">
              {propData.memo ? <pre>{propData.memo}</pre> : ''}
            </Descriptions.Item>
            <Descriptions.Item label="취소진행자">
              {propData.manager ? propData.manager.name : ''}
            </Descriptions.Item>
          </>
        ) : (
          <>
            <Descriptions.Item label="취소사유">
              <Input.TextArea
                rows={4}
                value={state.cancelReason}
                onChange={handleChangeInput('cancelReason')}
              />
            </Descriptions.Item>
            <Descriptions.Item label="상담내용">
              <Input.TextArea
                rows={4}
                value={state.memo}
                onChange={handleChangeInput('memo')}
              />
            </Descriptions.Item>
            <Descriptions.Item label="">
              <Button type="primary" onClick={handleSubmit}>
                취소처리
              </Button>
            </Descriptions.Item>
          </>
        )}
      </Descriptions>
    </>
  );
});

export default PointDetail;
