/* eslint-disable react-hooks/exhaustive-deps*/
import React from 'react';
import { Row, Col } from 'antd';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { toJS } from 'mobx';

import useStore from '@stores/useStore';
import PageTitle from '@Common/PageTitle';
import UserForm from '@Common/User/UserForm';

const Wrapper = styled.div`
  width: 100%;
`;

const UserInfoModify = observer(() => {
  const { commonStore } = useStore();
  return (
    <Wrapper>
      <Row>
        <Col xs={24} lg={6}>
          <PageTitle>회원정보수정</PageTitle>
          <UserForm propData={toJS(commonStore.user)} />
        </Col>
      </Row>
    </Wrapper>
  );
});

export default UserInfoModify;
