import { observable, action } from 'mobx';
import axios from 'axios';
import moment from 'moment';
import Cookies from 'js-cookie';
require('dotenv').config();

class CommonStore {
  @observable baseApiUrl = 'http://localhost:4000/server';
  @observable baseIamportUrl = 'http://localhost:4000/iamport';
  @observable user = null;
  @observable isAdminLayout = null;
  @observable menuOpenKeys = [];
  @observable menuSelectedKeys = [];

  constructor() {
    if (process.env.REACT_APP_NODE_ENV === 'production') {
      this.baseApiUrl = 'https://www.xn--mk1bm71azldbvj.com/server';
      this.baseIamportUrl = 'https://www.xn--mk1bm71azldbvj.com/iamport';
    } else if (process.env.REACT_APP_NODE_ENV === 'test') {
      this.baseApiUrl = 'https://test.xn--mk1bm71azldbvj.com/server';
      this.baseIamportUrl = 'https://test.xn--mk1bm71azldbvj.com/iamport';
    }
  }

  @action logout = () => {
    this.user = null;
    Cookies.remove('addpick-instagram-jwt');
    window.localStorage.removeItem('addpick-instagram-jwt');
    window.location.reload();
  };

  @action getServerNow = async () => {
    const res = await axios.get(`${this.baseApiUrl}/getServerNow`);
    return res ? moment(res.data) : moment();
  };
}

export default CommonStore;
