/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Divider,
  Input,
  InputNumber,
  Space,
  Radio,
  Card,
  Tabs,
} from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { toJS } from 'mobx';
import JSZip from 'jszip';

import useStore from '@stores/useStore';
import { numberWithCommas } from '@utils/common';

import Description from '@Common/Description';
import Dropzone from '@Common/Dropzone';

const { TabPane } = Tabs;

const Wrapper = styled.div`
  width: 100%;
`;

const ModifyOA = observer(
  ({ propData = {}, handleClose, handleSubmit, isAdmin }) => {
    const { commonStore } = useStore();
    const state = useLocalStore(() => ({
      tab: '1',

      images: [],
      fileType: 'zip',
      handleUpload: null,
      setHandleUpload(uploadFunc) {
        this.handleUpload = uploadFunc;
      },
      setParentImages(images) {
        this.images = images;
      },
      zipFile: null,

      // DB 데이터
      instaId: '',
      instaPassword: '',
      instaContents: [''],
      hashtags: '',
      hashtagStorage: '',
      comment: '',
      commentStorage: '',
      phone: '',
    }));

    const handleChangeTab = useCallback((key) => {
      state.tab = key;
    }, []);

    const handleChangeFile = useCallback(async (e) => {
      if (e.target.files && e.target.files[0]) {
        window.maskOn(300000);
        const file = e.target.files[0];
        e.target.value = '';
        const lastIdx = file.name.lastIndexOf('.');
        const ext = file.name.substring(lastIdx + 1);

        if (!/zip/i.test(ext)) {
          window.maskOff();
          return window.alert({ title: 'zip 파일이 아닙니다.' });
        }

        JSZip.loadAsync(file).then(
          function (zip) {
            const images = [];
            if (zip.files && Object.keys(zip.files).length < 30) {
              state.images = [];
              window.maskOff();
              return window.alert({
                title: '이미지 30장 이상을 업로드해 주세요',
              });
            } else if (zip.files) {
              zip.forEach(function (relativePath, zipEntry) {
                images.push(zipEntry);
              });

              state.images = images;
            } else {
              window.maskOff();
              return window.alert({
                title: '압축파일 내의 목록을 읽어올 수 없습니다.',
              });
            }

            file.status = 'load';
            state.zipFile = file;
            window.maskOff();
          },
          function (e) {
            window.maskOff();
            window.alert({ title: e.message });
          },
        );
      }
    }, []);

    const handleChangeInput = useCallback(
      (type, idx) => (e) => {
        if (type === 'instaContents') {
          const instaContents = toJS(state.instaContents);
          if (!instaContents[idx] || typeof instaContents[idx] !== 'object') {
            instaContents[idx] = { isUse: true, content: e.target.value };
          } else {
            instaContents[idx].content = e.target.value;
          }
          state.instaContents = instaContents;
        } else {
          state[type] = e.target.value;
        }
      },
      [],
    );

    const handleChangeInputNumber = useCallback(
      (type) => (value) => {
        state[type] = value;
      },
      [],
    );

    const handleChangeRadio = useCallback(
      (type) => (e) => {
        if (type === 'fileType') {
          state.images = [];
          state.zipFile = null;
        }

        state[type] = e.target.value;
      },
      [],
    );

    const handleAddInstaContents = useCallback((e) => {
      const instaContents = toJS(state.instaContents);
      instaContents.push({ isUse: true, content: '' });
      state.instaContents = instaContents;
    }, []);

    const handleRemoveInstaContents = useCallback(
      (idx) => (e) => {
        const instaContents = toJS(state.instaContents);
        instaContents.splice(idx, 1);
        state.instaContents = instaContents;
      },
      [],
    );
    const handleUseInstaContents = useCallback(
      (idx) => (e) => {
        const instaContents = toJS(state.instaContents);
        instaContents[idx].isUse = e.target.value;
        state.instaContents = instaContents;
      },
      [],
    );

    const handleCheckTextAreaRow = useCallback(
      (type) => (e) => {
        const lines = state[type].split('\n');
        let len = 0;
        if (type === 'hashtags') {
          len = 20;
        } else if (type === 'comment') {
          len = propData.buyDirectCommentCnt;
        } else {
          len = lines.length;
        }

        let values = [];
        for (let i = 0; i < len; i++) {
          if (lines[i] && lines[i].length) {
            if (!/^[0-9]+\./.test(lines[i])) {
              values.push(`${i + 1}.${lines[i]}`);
            } else {
              let dotIdx = lines[i].indexOf('.');
              values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
            }
          }
        }
        state[type] = values.join('\n');
      },
      [propData],
    );

    const handlePressEnter = useCallback(
      (type) => (e) => {
        e.persist();
        setTimeout(() => {
          const lines = state[type].split('\n');
          let values = [];
          for (let i = 0; i < lines.length; i++) {
            if (!/^[0-9]+\./.test(lines[i])) {
              values.push(`${i + 1}.${lines[i]}`);
            } else {
              let dotIdx = lines[i].indexOf('.');
              values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
            }
          }
          state[type] = values.join('\n');
        }, 10);
      },
      [propData],
    );

    const handleClickSubmit = useCallback(async () => {
      setTimeout(() => {
        if (
          (!propData.images || propData.images.length < 30) &&
          state.images.length < 30
        ) {
          return window.alert({ title: '이미지 30장 이상을 업로드해 주세요' });
        }

        const data = {
          instaId: state.instaId,
          instaPassword: state.instaPassword,
          // instaContents: toJS(state.instaContents),
          // hashtags: toJS(state.hashtags).split('\n'),
          // hashtagStorage: state.hashtagStorage,
          comment: state.comment,
          commentStorage: state.commentStorage,
          phone: state.phone,
        };

        // handleSubmit(propData.id, data, {
        //   uploadPath: `work/oa/${propData.company.id}`,
        //   fileType: state.fileType,
        //   images: toJS(state.images),
        //   zipFile: toJS(state.zipFile),
        //   handleUpload: toJS(state.handleUpload),
        // });
        handleSubmit(propData.id, data);
      }, 10);
    }, [propData, handleSubmit]);

    useEffect(() => {
      if (propData.instaId) {
        state.instaId = propData.instaId;
      }
      if (propData.instaPassword) {
        state.instaPassword = propData.instaPassword;
      }
      // if (propData.hashtags) {
      //   state.hashtags = propData.hashtags.join('\n');
      // }
      // if (propData.hashtagStorage) {
      //   state.hashtagStorage = propData.hashtagStorage;
      // }
      if (propData.comment) {
        state.comment = propData.comment;
      }
      if (propData.commentStorage) {
        state.commentStorage = propData.commentStorage;
      }
      // if (propData.instaContents) {
      //   state.instaContents = propData.instaContents;
      // }
      // if (propData.fileType) {
      //   state.fileType = propData.fileType;
      //   if (propData.fileType === 'image') {
      //     state.images = propData.images;
      //   } else {
      //     state.zipFile = propData.zipFile;
      //   }
      // }
      if (propData.phone) {
        state.phone = propData.phone;
      }
    }, [propData]);

    return (
      <Wrapper>
        <Tabs activeKey={state.tab} onChange={handleChangeTab}>
          <TabPane tab="기본정보" key="1">
            <Row gutter={[10, 20]}>
              <Col xs={24}>
                <Space>
                  <div>
                    <h4>인스타 아이디</h4>
                    <Input
                      value={state.instaId}
                      onChange={handleChangeInput('instaId')}
                      disabled={!isAdmin}
                    />
                  </div>
                  <div>
                    <h4>인스타 비밀번호</h4>
                    <Input
                      value={state.instaPassword}
                      onChange={handleChangeInput('instaPassword')}
                      disabled={!isAdmin}
                    />
                  </div>
                </Space>
              </Col>
              <Col xs={24}>
                <h4>전화번호</h4>
                <Description text="계정 문제 발생 시 연락 가능한 연락처를 입력해주세요." />
                <Input
                  value={state.phone}
                  onChange={handleChangeInput('phone')}
                  placeholder="전화번호를 - 없이 입력하세요"
                />
              </Col>
              {/* <Col xs={24}>
                <h4>인스타 게시물 이미지 업로드 (zip)</h4>
                <Description text="이미지는 최소 30장 이상 알집파일(zip)로 묶어서 업로드 해주세요" />

                <Row gutter={[10, 10]} style={{ marginTop: 15 }}>
                  <Col xs={24}>
                    {state.fileType === 'image' && (
                      <Dropzone
                        multiple={true}
                        uploadPath={
                          propData.user.isTest
                            ? 'test'
                            : `work/oa/${
                                propData.company
                                  ? propData.company.id
                                  : 'no-company'
                              }`
                        }
                        setHandleUpload={state.setHandleUpload}
                        setParentImages={state.setParentImages}
                        images={toJS(state.images)}
                        width={100000}
                        height={100000}
                        descriptions={[
                          '위 사진들을 드래그하여 보여지는 순서를 변경할 수 있습니다.',
                        ]}
                        hideDescription={true}
                      />
                    )}
                    {state.fileType === 'zip' && (
                      <>
                        <input
                          id={`oa_file`}
                          accept=".zip"
                          type="file"
                          onChange={handleChangeFile}
                          style={{ display: 'none' }}
                        />
                        <Input
                          value={
                            state.zipFile
                              ? state.zipFile.filename
                                ? state.zipFile.filename
                                : state.zipFile.name
                              : null
                          }
                          disabled
                          addonAfter={
                            <Button
                              size="small"
                              type="text"
                              onClick={() =>
                                document.querySelector(`#oa_file`).click()
                              }
                              block
                            >
                              {state.zipFile ? '수정' : '업로드'}
                            </Button>
                          }
                        />
                      </>
                    )}
                  </Col>
                </Row>
              </Col> */}
            </Row>
          </TabPane>
          {/* <TabPane tab="해시태그" key="2">
            <Row gutter={[10, 20]}>
              <Col xs={24} lg={12}>
                <Card size="small" title="프로그램에서 사용중인 해시태그">
                  <Input.TextArea
                    rows={5}
                    value={state.hashtags}
                    onChange={handleChangeInput('hashtags')}
                    onBlur={handleCheckTextAreaRow('hashtags')}
                    onPressEnter={handlePressEnter('hashtags')}
                  />
                </Card>
              </Col>
              <Col xs={24} lg={12}>
                <Card size="small" title="해시태그 저장 공간">
                  <Input.TextArea
                    rows={5}
                    value={state.hashtagStorage}
                    onChange={handleChangeInput('hashtagStorage')}
                    onBlur={handleCheckTextAreaRow('hashtagStorage')}
                    onPressEnter={handlePressEnter('hashtagStorage')}
                  />
                </Card>
              </Col>
            </Row>
          </TabPane> */}
          {propData.isBuyDirectComment && (
            <TabPane tab="댓글" key="3">
              <Row gutter={[10, 20]}>
                <Col xs={24}>
                  <h4>인스타 댓글 입력</h4>
                </Col>
                <Col xs={24} lg={12}>
                  <Card size="small" title="프로그램에서 사용중인 댓글">
                    <h4>구매한 댓글 개수 : {propData.buyDirectCommentCnt}</h4>
                    <Input.TextArea
                      rows={5}
                      value={state.comment}
                      onChange={handleChangeInput('comment')}
                      onBlur={handleCheckTextAreaRow('comment')}
                      onPressEnter={handlePressEnter('comment')}
                    />
                  </Card>
                </Col>
                <Col xs={24} lg={12}>
                  <Card size="small" title="댓글 저장 공간">
                    <h4>&nbsp;</h4>
                    <Input.TextArea
                      rows={5}
                      value={state.commentStorage}
                      onChange={handleChangeInput('commentStorage')}
                      onBlur={handleCheckTextAreaRow('commentStorage')}
                      onPressEnter={handlePressEnter('commentStorage')}
                    />
                  </Card>
                </Col>
              </Row>
            </TabPane>
          )}

          {/* <TabPane tab="내용" key="4">
            <Row gutter={[10, 20]}>
              <Col xs={24}>
                <h4>인스타 게시물 내용 입력</h4>
                <Row gutter={[10, 10]} justify="center" align="middle">
                  {toJS(state.instaContents).map((item, idx) => (
                    <Col xs={24} key={`instaContents_${idx}`}>
                      <Card
                        size="small"
                        title={
                          <Radio.Group
                            value={item.isUse}
                            onChange={handleUseInstaContents(idx)}
                          >
                            <Radio value={true}>사용</Radio>
                            <Radio value={false}>미사용</Radio>
                          </Radio.Group>
                        }
                        extra={
                          <Button
                            icon={<MinusOutlined />}
                            shape="circle"
                            onClick={handleRemoveInstaContents(idx)}
                          />
                        }
                      >
                        <Input
                          value={item.content}
                          onChange={handleChangeInput('instaContents', idx)}
                        />
                      </Card>
                    </Col>
                  ))}

                  <Col>
                    <Button
                      icon={<PlusOutlined />}
                      shape="circle"
                      onClick={handleAddInstaContents}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </TabPane> */}
        </Tabs>

        <Divider />

        <Row gutter={[10, 10]} justify="end">
          <Col>
            <Button onClick={handleClose} style={{ width: 120 }}>
              닫기
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={handleClickSubmit}
              style={{ width: 120 }}
            >
              저장
            </Button>
          </Col>
        </Row>
      </Wrapper>
    );
  },
);

export default ModifyOA;
