/* eslint-disable react-hooks/exhaustive-deps*/
import React from 'react';
import {
  HomeOutlined,
  InfoCircleOutlined,
  CommentOutlined,
  UserOutlined,
  ProfileOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';

export default [
  {
    Icon: () => <HomeOutlined />,
    label: 'HOME',
    link: '/',
    key: 'home',
  },
  {
    Icon: () => <InfoCircleOutlined />,
    label: '공지사항',
    link: '/notice',
    key: 'notice',
  },
  {
    Icon: () => <InfoCircleOutlined />,
    label: '고객문의',
    link: '/contact',
    key: 'contact',
  },
  {
    Icon: () => <CommentOutlined />,
    label: '사용방법',
    link: '/qna',
    key: 'qna',
  },
  {
    Icon: () => <UserOutlined />,
    label: '마이페이지',
    link: '/mypage',
    key: 'mypage',
    loginOnly: true,
  },
];
