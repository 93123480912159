/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { toJS } from 'mobx';
import moment from 'moment';

import { CONTACT_DASHBOARD } from '@shared/queries/DashboardQueries';
import { numberWithCommas } from '@utils/common';

const Wrapper = styled.div`
  width: 100%;
`;

const ContactDashboard = observer(() => {
  const state = useLocalStore(() => ({
    data: [],
    get dataVal() {
      return toJS(this.data);
    },
  }));
  const queryRes = useQuery(CONTACT_DASHBOARD);

  useEffect(() => {
    if (queryRes.data && queryRes.data.contactDashboard) {
      state.data = queryRes.data.contactDashboard;
    }
  }, [queryRes.data]);

  return (
    <Wrapper>
      <Card
        title={<Link to={{ pathname: `/contact` }}>고객문의 관리</Link>}
        extra={<Link to={{ pathname: `/contact` }}>자세히보기 {'>'}</Link>}
      >
        {state.dataVal.map((item) => (
          <Link to={{ pathname: `/contact/${item.id}` }} key={item.id}>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>{moment(item.postDate).format('YYYY-MM-DD')}</Col>
              <Col>{item.title}</Col>
              <Col>
                {item.commentCnt ? (
                  <span className="blue">답변완료</span>
                ) : (
                  <span>답변대기</span>
                )}
              </Col>
            </Row>
          </Link>
        ))}
      </Card>
    </Wrapper>
  );
});

export default ContactDashboard;
