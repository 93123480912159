/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { Row, Col, Button, Divider, Input, Space } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { toJS } from 'mobx';

import useStore from '@stores/useStore';
import { numberWithCommas } from '@utils/common';

import Description from '@Common/Description';

const Wrapper = styled.div`
  width: 100%;
`;

const BuyOT = observer(({ data, handleClose, handleSubmit }) => {
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    // DB 데이터
    workType: '최적화테스트',
    paymentType: '건구매',
    instaId: '',
    url: '',
    mainHashtag: '',
    hashtags: '1.',
    buyCnt: 1,
    buyAmount: data.price,
    isMonth: false,
  }));

  const handleChangeInput = useCallback(
    (type) => (e) => {
      state[type] = e.target.value;
    },
    [],
  );

  const handleCheckTextAreaRow = useCallback(
    (type) => (e) => {
      const lines = state[type].split('\n');
      let len = 0;
      if (type === 'hashtags') {
        len = 10;
      } else {
        len = lines.length;
      }

      let values = [];
      for (let i = 0; i < len; i++) {
        if (lines[i] && lines[i].length) {
          if (!/^[0-9]+\./.test(lines[i])) {
            values.push(`${i + 1}.${lines[i]}`);
          } else {
            let dotIdx = lines[i].indexOf('.');
            values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
          }
        }
      }
      state[type] = values.join('\n');
    },
    [],
  );

  const handlePressEnter = useCallback(
    (type) => (e) => {
      e.persist();
      setTimeout(() => {
        const lines = state[type].split('\n');
        let values = [];
        for (let i = 0; i < lines.length; i++) {
          if (!/^[0-9]+\./.test(lines[i])) {
            values.push(`${i + 1}.${lines[i]}`);
          } else {
            let dotIdx = lines[i].indexOf('.');
            values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
          }
        }
        state[type] = values.join('\n');
      }, 10);
    },
    [],
  );

  const handleClickSubmit = useCallback(async () => {
    if (!state.instaId || !state.instaId.length) {
      return window.alert({ title: '인스타 아이디를 입력해 주세요.' });
    }
    // if (!state.url || !state.url.length) {
    //   return window.alert({ title: '인스타 게시물 URL을 입력해 주세요.' });
    // }
    // if (!state.mainHashtag || !state.mainHashtag.length) {
    //   return window.alert({ title: '대표 해시태그를 입력해 주세요.' });
    // }
    // if (!state.hashtags || !state.hashtags.length) {
    //   return window.alert({ title: '부 해시태그를 입력해 주세요.' });
    // }

    setTimeout(() => {
      const workData = {
        workType: state.workType,
        paymentType: state.paymentType,
        instaId: state.instaId,
        url: state.url,
        mainHashtag: state.mainHashtag,
        hashtags: toJS(state.hashtags).split('\n'),
        status: '진행중',
        isMonth: state.isMonth,
        counting: 0,
        buyAmount: state.buyAmount,
      };

      handleSubmit(workData);
    }, 10);
  }, [data]);

  useEffect(() => {}, []);

  return (
    <Wrapper>
      <h3>프로그램</h3>
      <div>건 {numberWithCommas(data.price)} 포인트</div>
      <Divider />

      <div dangerouslySetInnerHTML={{ __html: data.detail.content }} />

      <Divider />

      <Row gutter={[10, 10]}>
        <Col xs={24}>
          <h4>인스타 아이디</h4>
          <Input
            value={state.instaId}
            onChange={handleChangeInput('instaId')}
          />
        </Col>

        <Col xs={24}>
          <h4>인스타 게시물 URL</h4>
          <Input value={state.url} onChange={handleChangeInput('url')} />
        </Col>

        <Col xs={24}>
          <h4>대표 해시태그</h4>
          <Input
            value={state.mainHashtag}
            onChange={handleChangeInput('mainHashtag')}
          />
        </Col>

        <Col xs={24}>
          <h4>부 해시태그</h4>
          <Description text="해시태그 1~10개 입력(한줄에 하나씩)" />
          <Input.TextArea
            rows={4}
            value={state.hashtags}
            onChange={handleChangeInput('hashtags')}
            onBlur={handleCheckTextAreaRow('hashtags')}
            onPressEnter={handlePressEnter('hashtags')}
          />
        </Col>
      </Row>

      <Divider />

      <Row gutter={[10, 10]} justify="end">
        <Col>
          <Space>
            <span>총 결제 포인트</span>
            <span className="amount">
              {numberWithCommas(state.buyAmount, true)}
            </span>
            <span>포인트</span>
          </Space>
        </Col>
      </Row>

      <Row gutter={[10, 10]} justify="end">
        {handleClose && (
          <Col>
            <Button onClick={handleClose} style={{ width: 120 }}>
              취소
            </Button>
          </Col>
        )}

        <Col>
          <Button
            type="primary"
            onClick={handleClickSubmit}
            style={{ width: 120 }}
          >
            구매
          </Button>
        </Col>
      </Row>
    </Wrapper>
  );
});

export default BuyOT;
