/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Divider,
  Input,
  Space,
  Radio,
  InputNumber,
  Select,
  TimePicker,
} from 'antd';
import { VideoCameraAddOutlined, DeleteOutlined } from '@ant-design/icons';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { toJS } from 'mobx';
import JSZip from 'jszip';
import axios from 'axios';

import useStore from '@stores/useStore';
import { numberWithCommas } from '@utils/common';

import Description from '@Common/Description';
import Dropzone from '@Common/Dropzone';

const { Search } = Input;
const { Option } = Select;

const Wrapper = styled.div`
  width: 100%;

  .test-option {
    & > div {
      margin-bottom: 15px;
    }
  }
`;

const BuyPP = observer(({ data, handleClose, handleSubmit, company }) => {
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    images: [],
    fileType: 'image',
    handleUpload: null,
    setHandleUpload(uploadFunc) {
      this.handleUpload = uploadFunc;
    },
    setParentImages(images) {
      this.images = images;
    },
    zipFile: null,
    programType: '고객아이디',
    oaIdPriceData: null,
    searchHashtag: '',
    selectedOaIdRank: null,
    selectedOaIdProgram: null,
    totalAmount: 0,
    discountAmount: 0,
    selectedMonthPriceUid: null,
    videos: [],

    // DB 데이터
    workType: '인기게시물',
    paymentType: '건구매',
    instaId: '',
    url: '',
    instaContentText: '',
    mainHashtag: '',
    hashtags: '1.',
    buyCnt: 1,
    buyAmount: 0,
    isMonth: true,
    isBuyDirectComment: false,
    buyDirectCommentCnt: 0,
    comment: '',
    testLikeCnt: undefined,
    testTrafficCnt: undefined,
    testArrivalCnt: undefined,
    testPublishTime: [],
    testSaveCnt: undefined,
    testCommentCnt: undefined,
    testKeepTrafficCnt: undefined,
    testKeepLikeCnt: undefined,
    testKeepSaveCnt: undefined,
    testKeepFollowCnt: undefined,
    testKeepCommentCnt: undefined,
    productName: '',
  }));

  const calcPrice = useCallback(() => {
    let value = 0;
    state.discountAmount = 0;

    if (state.programType === '고객아이디') {
      value = data.casePrice;
    } else if (state.programType === '고객아이디사용월') {
      let f;
      if (state.selectedMonthPriceUid && Array.isArray(data.ppMonthPrice)) {
        f = data.ppMonthPrice.find(
          (item) => item.uid === state.selectedMonthPriceUid,
        );
      }

      if (f) {
        value = f.price;
        state.buyCnt = f.cnt;
        state.productName = f.name;
      }
    } else {
      if (state.oaIdPriceData && state.oaIdPriceData.price) {
        value = state.oaIdPriceData.price * state.oaIdPriceData.cnt;
      }
    }

    if (state.isBuyDirectComment) {
      value += state.buyDirectCommentCnt * (data.directCommentPrice || 0);
    }

    state.totalAmount = value;

    if (state.programType === '최적화아이디' && value) {
      if (
        data.oaIdDiscount &&
        data.oaIdDiscount.value &&
        state.oaIdPriceData.cnt > 1
      ) {
        const discountValue =
          (state.oaIdPriceData.cnt - 1) * data.oaIdDiscount.value;
        let discountAmount = 0;
        if (data.oaIdDiscount.discountType === '%') {
          discountAmount = (value * discountValue) / 100;
        } else {
          discountAmount = discountValue;
        }
        value = value - discountAmount;
        state.discountAmount = discountAmount;
      }
    }

    state.buyAmount = value;
    return numberWithCommas(value, true);
  }, [data]);

  const handleChangeRadio = useCallback(
    (type) => (e) => {
      state[type] = e.target.value;

      if (type === 'isBuyDirectComment') {
        if (e.target.value) {
          state.buyDirectCommentCnt = 1;
          state.comment = '1.';
        } else {
          state.buyDirectCommentCnt = 0;
          state.comment = '';
        }
      }

      if (type === 'programType') {
        state.testLikeCnt = undefined;
        state.testTrafficCnt = undefined;
        state.testArrivalCnt = undefined;
        state.testPublishTime = [];
        state.testSaveCnt = undefined;
        state.testCommentCnt = undefined;
        state.testKeepTrafficCnt = undefined;
        state.testKeepLikeCnt = undefined;
        state.testKeepSaveCnt = undefined;
        state.testKeepFollowCnt = undefined;
        state.testKeepCommentCnt = undefined;

        if (e.target.value === '고객아이디') {
          state.instaContentText = '';
          state.isBuyDirectComment = false;
          state.searchHashtag = '';
          state.selectedOaIdRank = null;
          state.oaIdPriceData = null;
          state.selectedMonthPriceUid = null;
          state.paymentType = '건구매';
        } else if (e.target.value === '최적화아이디') {
          state.instaContentText = '';
          state.isBuyDirectComment = false;
          state.selectedMonthPriceUid = null;
          state.paymentType = '건구매';
          state.productName = '';
        } else if (e.target.value === '고객아이디사용월') {
          state.instaContentText = '';
          state.isBuyDirectComment = false;
          state.searchHashtag = '';
          state.selectedOaIdRank = null;
          state.oaIdPriceData = null;
          state.paymentType = '월구매';
          state.productName = '';
        }

        setTimeout(() => {
          calcPrice();
        }, 10);
      }

      if (type === 'selectedOaIdRank') {
        const f = data.oaIdRanks.find((item) => item.uid === e.target.value);
        if (f) {
          state.oaIdPriceData = { ...f, cnt: 1 };
        }
        setTimeout(() => {
          calcPrice();
        }, 10);
      }
    },
    [data],
  );

  const handleChangeSelect = useCallback(
    (type) => (value) => {
      state[type] = value;

      setTimeout(() => {
        calcPrice();
      }, 10);
    },
    [],
  );

  const handleChangeZipFile = useCallback(async (e) => {
    if (e.target.files && e.target.files[0]) {
      window.maskOn(300000);
      const file = e.target.files[0];
      e.target.value = '';
      const lastIdx = file.name.lastIndexOf('.');
      const ext = file.name.substring(lastIdx + 1);

      if (!/zip/i.test(ext)) {
        window.maskOff();
        return window.alert({ title: 'zip 파일이 아닙니다.' });
      }

      JSZip.loadAsync(file).then(
        function (zip) {
          const images = [];
          if (zip.files && Object.keys(zip.files).length < 30) {
            state.images = [];
            window.maskOff();
            return window.alert({
              title: '이미지 30장 이상을 업로드해 주세요',
            });
          } else if (zip.files) {
            zip.forEach(function (relativePath, zipEntry) {
              images.push(zipEntry);
            });

            state.images = images;
          } else {
            window.maskOff();
            return window.alert({
              title: '압축파일 내의 목록을 읽어올 수 없습니다.',
            });
          }

          file.status = 'load';
          state.zipFile = file;
          window.maskOff();
        },
        function (e) {
          window.maskOff();
          window.alert({ title: e.message });
        },
      );
    }
  }, []);

  const handleAddVideo = useCallback(() => {
    const videos = toJS(state.videos);
    videos.push({});
    state.videos = videos;
  }, []);

  const handleRemoveVideo = useCallback(
    (idx) => () => {
      const videos = toJS(state.videos);
      videos.splice(idx, 1);
      state.videos = videos;
    },
    [],
  );

  const handleChangeVideoFile = useCallback(
    (idx) => async (e) => {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        e.target.value = '';

        const videoUrl = window.URL.createObjectURL(file);
        const videoElem = document.querySelector('#pp_video');
        videoElem.setAttribute('src', videoUrl);
        setTimeout(() => {
          if (!videoElem || !videoElem.duration) {
            return window.alert({ title: '동영상을 로드할 수 없습니다.' });
          }

          if (videoElem.duration > 60) {
            return window.alert({ title: '1분 이하로 업로드해 주세요.' });
          }
          if (file.size > 100000000) {
            return window.alert({ title: '100M 이하로 업로드해 주세요.' });
          }

          file.status = 'load';
          const videos = toJS(state.videos);
          videos[idx] = file;
          state.videos = videos;
        }, 500);
      }
    },
    [],
  );

  const handleChangeInput = useCallback(
    (type) => (e) => {
      state[type] = e.target.value;
    },
    [],
  );

  const handleChangeInputNumber = useCallback(
    (type) => (value) => {
      if (type === 'oaIdPriceData') {
        const data = toJS(state[type]);
        data.cnt = value;
        state[type] = data;
      } else {
        state[type] = value;
      }

      if (type === 'buyDirectCommentCnt' || type === 'oaIdPriceData') {
        setTimeout(() => {
          calcPrice();
        }, 10);
      }
    },
    [],
  );

  const handleCheckTextAreaRow = useCallback(
    (type) => (e) => {
      if (type === 'hashtags' || type === 'comment') {
        let len = 0;
        let lines = [];
        if (type === 'hashtags') {
          lines = state.hashtags.split('\n');
          len = 4;
        } else if (type === 'comment') {
          lines = state.comment.split('\n');
          len = state.buyDirectCommentCnt;
        }
        let values = [];
        for (let i = 0; i < len; i++) {
          if (lines[i] && lines[i].length) {
            if (!/^[0-9]+\./.test(lines[i])) {
              values.push(`${i + 1}.${lines[i]}`);
            } else {
              let dotIdx = lines[i].indexOf('.');
              values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
            }
          }
        }
        state[type] = values.join('\n');
      }
    },
    [],
  );

  const handlePressEnter = useCallback(
    (type) => (e) => {
      e.persist();
      if (type === 'hashtags' || type === 'comment') {
        setTimeout(() => {
          const lines = state[type].split('\n');
          let values = [];
          for (let i = 0; i < lines.length; i++) {
            if (!/^[0-9]+\./.test(lines[i])) {
              values.push(`${i + 1}.${lines[i]}`);
            } else {
              let dotIdx = lines[i].indexOf('.');
              values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
            }
          }
          state[type] = values.join('\n');
        }, 10);
      }
    },
    [],
  );

  const handleSearchHashtag = useCallback(
    async (value) => {
      if (value && value.length) {
        window.maskOn(60000);
        const res = await axios.get(
          `/tagsApi/tags/${window.encodeURIComponent(value)}`,
        );
        if (res.data) {
          const count = parseInt(res.data.count);
          if (count > 0) {
            const f = data.oaIdPrice.find(
              (item) => count >= item.viewCnt1 && count <= item.viewCnt2,
            );
            if (f) {
              const oaIdPriceData = toJS(state.oaIdPriceData);
              oaIdPriceData.price = f.price;
              state.oaIdPriceData = oaIdPriceData;
              state.mainHashtag = value;
              setTimeout(() => {
                calcPrice();
              }, 10);
            } else {
              state.searchHashtag = '';
              window.alert({ title: '설정된 금액이 없습니다.' });
            }
          } else {
            const oaIdPriceData = toJS(state.oaIdPriceData);
            delete oaIdPriceData.price;
            state.oaIdPriceData = oaIdPriceData;
            state.searchHashtag = '';
            setTimeout(() => {
              calcPrice();
            }, 10);
            window.alert({ title: '조회 결과가 없습니다.' });
          }
        }
        window.maskOff();
      }
    },
    [data],
  );

  const handleClickSubmit = useCallback(async () => {
    if (state.programType === '최적화아이디') {
      if (!state.oaIdPriceData) {
        return window.alert({ title: '수량을 선택해 주세요.' });
      } else if (!state.oaIdPriceData.price) {
        return window.alert({ title: '해시태그를 검색해 주세요.' });
      }
    } else {
      if (!state.buyAmount) {
        return window.alert({ title: '수량을 선택해 주세요.' });
      }
      if (!state.instaId || !state.instaId.length) {
        return window.alert({ title: '인스타 아이디를 입력해 주세요.' });
      }
    }

    if (!state.mainHashtag || !state.mainHashtag.length) {
      return window.alert({ title: '대표 해시태그를 입력해 주세요.' });
    }
    // if (!state.hashtags || !state.hashtags.length) {
    //   return window.alert({ title: '부 해시태그를 입력해 주세요.' });
    // }

    setTimeout(() => {
      const workData = {
        workType: state.workType,
        paymentType: state.paymentType,
        mainHashtag: state.mainHashtag,
        status: '진행중',
        isMonth: state.isMonth,
        maxCounting: data.monthDays,
        counting: 0,
        buyAmount: state.buyAmount,
        programType: state.programType,
        comment: state.comment,
        buyDirectCommentCnt: state.buyDirectCommentCnt,
        isBuyDirectComment: state.isBuyDirectComment,
        buyCnt: state.buyCnt,
      };

      // if (state.hashtags && state.hashtags.length) {
      //   workData.hashtags = state.hashtags.split('\n');
      // }
      if (state.instaContentText && state.instaContentText.length) {
        workData.instaContentText = state.instaContentText;
      }

      let upload = null;
      if (state.programType === '최적화아이디') {
        workData.buyOaIdData = toJS(state.oaIdPriceData);

        upload = {
          uploadPath: `work/pp/${company.id}`,
          fileType: state.fileType,
          images: toJS(state.images),
          zipFile: toJS(state.zipFile),
          handleUpload: toJS(state.handleUpload),
          videos: toJS(state.videos),
        };
      } else {
        workData.instaId = state.instaId;
        workData.url = state.url;
      }

      if (
        commonStore.user &&
        commonStore.user.isTest &&
        (state.programType === '고객아이디' ||
          state.programType === '고객아이디사용월')
      ) {
        workData.testLikeCnt = state.testLikeCnt;
        workData.testTrafficCnt = state.testTrafficCnt;
        workData.testArrivalCnt = state.testArrivalCnt;
        workData.testPublishTime = toJS(state.testPublishTime);
        workData.testSaveCnt = state.testSaveCnt;
        workData.testCommentCnt = state.testCommentCnt;
        workData.testKeepTrafficCnt = state.testKeepTrafficCnt;
        workData.testKeepLikeCnt = state.testKeepLikeCnt;
        workData.testKeepSaveCnt = state.testKeepSaveCnt;
        workData.testKeepFollowCnt = state.testKeepFollowCnt;
        workData.testKeepCommentCnt = state.testKeepCommentCnt;
      }

      if (state.programType === '고객아이디') {
        workData.productName = data.caseName;
      } else if (state.programType === '고객아이디사용월') {
        workData.productName = state.name;
      }

      handleSubmit(workData, upload);
    }, 10);
  }, [data, company]);

  useEffect(() => {
    if (data) {
      setTimeout(() => {
        calcPrice();
      }, 10);
    }
  }, [data]);

  return (
    <Wrapper>
      <h3>프로그램</h3>
      <div>
        <Radio.Group
          value={state.programType}
          onChange={handleChangeRadio('programType')}
          buttonStyle="solid"
        >
          <Radio.Button value={'고객아이디'}>고객 아이디 사용(건)</Radio.Button>
          <Radio.Button value={'고객아이디사용월'}>
            고객 아이디 사용(월)
          </Radio.Button>
          <Radio.Button value={'최적화아이디'}>최적화 아이디 사용</Radio.Button>
        </Radio.Group>
      </div>

      <Divider />

      <h3>수량</h3>
      <Row gutter={[10, 10]}>
        {state.programType === '고객아이디' && (
          <Col xs={24}>
            {data.caseName && data.caseName.length ? `${data.caseName} ` : ''}
            {numberWithCommas(data.casePrice)} 포인트 ({data.caseCnt}회)
          </Col>
        )}

        {state.programType === '고객아이디사용월' &&
          data.ppMonthPrice &&
          Array.isArray(data.ppMonthPrice) && (
            <Col xs={24}>
              <Space>
                <Select
                  value={state.selectedMonthPriceUid}
                  onChange={handleChangeSelect('selectedMonthPriceUid')}
                  style={{ width: 220 }}
                >
                  {data.ppMonthPrice.map((item) => (
                    <Option value={item.uid} key={item.uid}>
                      {item.name && item.name.length ? `${item.name} ` : ''}
                      {item.cnt}회 {numberWithCommas(item.price, true)} 포인트
                    </Option>
                  ))}
                </Select>
                <span>({numberWithCommas(state.buyAmount, true)} 포인트)</span>
              </Space>
            </Col>
          )}

        {state.programType === '최적화아이디' && (
          <Col xs={24}>
            <Radio.Group
              value={state.selectedOaIdRank}
              onChange={handleChangeRadio('selectedOaIdRank')}
            >
              <Space direction="vertical">
                {toJS(data.oaIdRanks).map((item) => (
                  <Radio value={item.uid} key={item.uid}>
                    {item.rank1}~{item.rank2}위
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Col>
        )}
      </Row>

      <Divider />

      {state.programType === '최적화아이디' && state.oaIdPriceData ? (
        <>
          <Row gutter={[10, 10]}>
            <Col xs={24}>
              <Space>
                <div>해시태그</div>
                <Search
                  value={state.searchHashtag}
                  onChange={handleChangeInput('searchHashtag')}
                  onSearch={handleSearchHashtag}
                  enterButton="검색"
                />
                <div>
                  ({numberWithCommas(state.oaIdPriceData.price, true)} 포인트)
                </div>
              </Space>
            </Col>
            <Col xs={24}>
              <Space>
                <div>게시물 상위노출 개수</div>
                <InputNumber
                  value={state.oaIdPriceData.cnt}
                  placeholder="노출개수"
                  min={1}
                  max={data.oaIdVisibleCnt}
                  onChange={handleChangeInputNumber('oaIdPriceData')}
                />
                <div>개</div>
                <div>
                  (
                  {numberWithCommas(
                    state.oaIdPriceData.price * state.oaIdPriceData.cnt,
                    true,
                  )}{' '}
                  포인트)
                </div>
              </Space>
            </Col>
          </Row>
          <Divider />
        </>
      ) : null}

      <Row gutter={[10, 10]}>
        {state.programType === '고객아이디' ? (
          <>
            <Col xs={24}>
              <h4>인스타 아이디</h4>
              <Input
                value={state.instaId}
                onChange={handleChangeInput('instaId')}
              />
            </Col>

            <Col xs={24}>
              <h4>인스타 게시물 URL</h4>
              <Input value={state.url} onChange={handleChangeInput('url')} />
            </Col>
          </>
        ) : state.programType === '고객아이디사용월' ? (
          <>
            <Col xs={24}>
              <h4>인스타 아이디</h4>
              <Input
                value={state.instaId}
                onChange={handleChangeInput('instaId')}
              />
            </Col>
          </>
        ) : (
          <>
            <Col xs={24}>
              <h4>
                인스타 게시물 이미지 업로드 (
                {state.fileType === 'image' ? 'jpg파일' : 'zip'})
              </h4>
              {/* <Description text="이미지는 최소 30장 이상 알집파일(zip)로 묶어서 업로드 해주세요" /> */}

              <Row gutter={[10, 10]} style={{ marginTop: 15 }}>
                <Col xs={24}>
                  {state.fileType === 'image' && (
                    <Dropzone
                      multiple={true}
                      uploadPath={
                        commonStore.user.isTest
                          ? 'test'
                          : `work/pp/${company ? company.id : 'no-company'}`
                      }
                      setHandleUpload={state.setHandleUpload}
                      setParentImages={state.setParentImages}
                      images={toJS(state.images)}
                      width={100000}
                      height={100000}
                      descriptions={[
                        '위 사진들을 드래그하여 보여지는 순서를 변경할 수 있습니다.',
                      ]}
                      hideDescription={true}
                    />
                  )}
                  {state.fileType === 'zip' && (
                    <>
                      <input
                        id={`pp_file`}
                        accept=".zip"
                        type="file"
                        onChange={handleChangeZipFile}
                        style={{ display: 'none' }}
                      />
                      <Input
                        value={
                          state.zipFile
                            ? state.zipFile.filename
                              ? state.zipFile.filename
                              : state.zipFile.name
                            : null
                        }
                        disabled
                        addonAfter={
                          <Button
                            size="small"
                            type="text"
                            onClick={() =>
                              document.querySelector(`#pp_file`).click()
                            }
                            block
                          >
                            {state.zipFile ? '수정' : '업로드'}
                          </Button>
                        }
                      />
                    </>
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <h4>동영상 업로드</h4>
              <video id="pp_video" style={{ display: 'none' }} />
              <Row gutter={[10, 10]}>
                {toJS(state.videos).map((item, idx) => (
                  <Col xs={24} key={`pp_video_${idx}`}>
                    <input
                      id={`pp_video_file_${idx}`}
                      accept="video/*"
                      type="file"
                      onChange={handleChangeVideoFile(idx)}
                      style={{ display: 'none' }}
                    />
                    <Space>
                      <Input
                        value={
                          item
                            ? item.filename
                              ? item.filename
                              : item.name
                            : null
                        }
                        disabled
                        addonAfter={
                          <Button
                            size="small"
                            type="text"
                            onClick={() =>
                              document
                                .querySelector(`#pp_video_file_${idx}`)
                                .click()
                            }
                            block
                          >
                            {item && item.size ? '수정' : '업로드'}
                          </Button>
                        }
                      />
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={handleRemoveVideo(idx)}
                      />
                    </Space>
                  </Col>
                ))}

                <Col xs={24}>
                  <Button
                    type="primary"
                    icon={<VideoCameraAddOutlined />}
                    onClick={handleAddVideo}
                  >
                    동영상 업로드 추가
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <h4>인스타 게시물 내용 입력</h4>
              <Input.TextArea
                rows={4}
                value={state.instaContentText}
                onChange={handleChangeInput('instaContentText')}
              />
            </Col>
          </>
        )}

        <Col xs={24}>
          <h4>대표 해시태그</h4>
          <Input
            value={state.mainHashtag}
            onChange={handleChangeInput('mainHashtag')}
            disabled={
              state.programType === '고객아이디' ||
              state.programType === '고객아이디사용월'
                ? false
                : true
            }
          />
        </Col>
        {/* <Col xs={24}>
          <h4>부 해시태그</h4>
          <Description text="해시태그 1~4개 입력(한줄에 하나씩)" />
          <Input.TextArea
            rows={4}
            value={state.hashtags}
            onChange={handleChangeInput('hashtags')}
            onBlur={handleCheckTextAreaRow('hashtags')}
            onPressEnter={handlePressEnter('hashtags')}
          />
        </Col> */}
      </Row>

      <Divider />

      {commonStore.user &&
        commonStore.user.isTest &&
        (state.programType === '고객아이디' ||
          state.programType === '고객아이디사용월') && (
          <>
            <h3>테스트 옵션</h3>
            <Space
              className="test-option"
              size={20}
              style={{ flexWrap: 'wrap' }}
            >
              <div>
                <h4>좋아요</h4>
                <InputNumber
                  value={state.testLikeCnt}
                  onChange={handleChangeInputNumber('testLikeCnt')}
                />
              </div>
              <div>
                <h4>트래픽</h4>
                <InputNumber
                  value={state.testTrafficCnt}
                  onChange={handleChangeInputNumber('testTrafficCnt')}
                />
              </div>
              <div>
                <h4>지속 작업 시간</h4>
                <InputNumber
                  value={state.testArrivalCnt}
                  onChange={handleChangeInputNumber('testArrivalCnt')}
                />
              </div>
              <div>
                <h4>발행시간</h4>
                <Space>
                  <TimePicker
                    value={toJS(state.testPublishTime)[0]}
                    placeholder="시작시간"
                    format="HH:mm"
                    onChange={(momentVal) => {
                      const time = toJS(state.testPublishTime);
                      time[0] = momentVal;
                      state.testPublishTime = time;
                    }}
                  />
                  <div>~</div>
                  <TimePicker
                    value={toJS(state.testPublishTime)[1]}
                    placeholder="종료시간"
                    format="HH:mm"
                    onChange={(momentVal) => {
                      const time = toJS(state.testPublishTime);
                      time[1] = momentVal;
                      state.testPublishTime = time;
                    }}
                  />
                </Space>
              </div>
              <div>
                <h4>저장</h4>
                <InputNumber
                  value={state.testSaveCnt}
                  onChange={handleChangeInputNumber('testSaveCnt')}
                />
              </div>
              <div>
                <h4>댓글</h4>
                <InputNumber
                  value={state.testCommentCnt}
                  onChange={handleChangeInputNumber('testCommentCnt')}
                />
              </div>
              <div>
                <h4>지속 트래픽</h4>
                <InputNumber
                  value={state.testKeepTrafficCnt}
                  onChange={handleChangeInputNumber('testKeepTrafficCnt')}
                />
              </div>
              <div>
                <h4>지속 좋아요</h4>
                <InputNumber
                  value={state.testKeepLikeCnt}
                  onChange={handleChangeInputNumber('testKeepLikeCnt')}
                />
              </div>
              <div>
                <h4>지속 저장</h4>
                <InputNumber
                  value={state.testKeepSaveCnt}
                  onChange={handleChangeInputNumber('testKeepSaveCnt')}
                />
              </div>
              <div>
                <h4>지속 팔로우</h4>
                <InputNumber
                  value={state.testKeepFollowCnt}
                  onChange={handleChangeInputNumber('testKeepFollowCnt')}
                />
              </div>
              <div>
                <h4>지속 댓글</h4>
                <InputNumber
                  value={state.testKeepCommentCnt}
                  onChange={handleChangeInputNumber('testKeepCommentCnt')}
                />
              </div>
            </Space>
            <Divider />
          </>
        )}

      {state.programType === '최적화아이디' && (
        <>
          <h3>프로그램 추가옵션</h3>
          <Row gutter={[10, 10]}>
            <Col xs={24}>
              <Radio.Group
                value={state.isBuyDirectComment}
                onChange={handleChangeRadio('isBuyDirectComment')}
              >
                <Radio value={true} disabled={true}>
                  직접입력댓글 사용
                </Radio>
                <Radio value={false}>사용안함</Radio>
              </Radio.Group>
            </Col>
            {state.isBuyDirectComment && (
              <>
                <Col xs={24}>
                  <h4>직접입력댓글</h4>
                  <Space>
                    <InputNumber
                      min={1}
                      value={state.buyDirectCommentCnt}
                      onChange={handleChangeInputNumber('buyDirectCommentCnt')}
                    />
                    <span>개</span>
                    <span>
                      (
                      {numberWithCommas(
                        state.buyDirectCommentCnt * data.directCommentPrice,
                        true,
                      )}{' '}
                      포인트)
                    </span>
                  </Space>
                </Col>
                <Col xs={24}>
                  <h4>인스타 댓글 입력 (추가옵션)</h4>
                  <Description text="구매 개수 만큼 일괄입력(한줄에 하나씩)" />
                  <Input.TextArea
                    rows={5}
                    value={state.comment}
                    onChange={handleChangeInput('comment')}
                    onBlur={handleCheckTextAreaRow('comment')}
                    onPressEnter={handlePressEnter('comment')}
                  />
                </Col>
              </>
            )}
          </Row>

          <Divider />
        </>
      )}

      <Row gutter={[10, 10]} justify="end" style={{ marginBottom: 15 }}>
        <Col>
          {state.discountAmount ? (
            <>
              <Space direction="vertical">
                <Space>
                  <div>총</div>
                  <div
                    style={{ textDecoration: 'line-through', color: '#aaa' }}
                  >
                    {numberWithCommas(state.totalAmount, true)}
                  </div>
                  <div>포인트</div>
                </Space>
                <Space>
                  <div>할인</div>
                  <div style={{ fontWeight: 'bold' }}>
                    {numberWithCommas(state.discountAmount, true)}
                  </div>
                  <div>포인트</div>
                </Space>
              </Space>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </>
          ) : null}

          <Space>
            <span>총 결제 포인트</span>
            <span className="amount">
              {numberWithCommas(state.buyAmount, true)}
            </span>
            <span>포인트</span>
          </Space>
        </Col>
      </Row>

      <Row gutter={[10, 10]} justify="end">
        <Col>
          <Button onClick={handleClose} style={{ width: 120 }}>
            취소
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={handleClickSubmit}
            style={{ width: 120 }}
          >
            구매
          </Button>
        </Col>
      </Row>
    </Wrapper>
  );
});

export default BuyPP;
