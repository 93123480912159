/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import uniqid from 'uniqid';

import useStore from '@stores/useStore';
import { ADD_WORK, UPDATE_WORK } from '@shared/queries/WorkQueries';
import { DEFAULT_ZIP_UPLOAD, UPLOAD_PROGRESS } from '@shared/queries';

import CustomModal from '@Common/CustomModal';

import Modify from './Modify';

const Wrapper = styled.div`
  width: 100%;
`;

const SlotModify = observer(
  ({
    workType,
    propData = {},
    modalTitle,
    modalOpen,
    handleModal,
    refetch,
  }) => {
    const { commonStore } = useStore();
    const state = useLocalStore(() => ({}));

    const [add] = useMutation(ADD_WORK);
    const [update] = useMutation(UPDATE_WORK);
    const [zipFileUpload] = useMutation(DEFAULT_ZIP_UPLOAD);
    const client = useApolloClient();

    const handleClose = useCallback(() => {
      handleModal(false)();
    }, []);

    const saveZipFile = useCallback((resizeFile, path) => {
      return new Promise(async (resolve) => {
        const progressUid = uniqid();
        const data = {
          file: resizeFile,
          uploadPath: path,
          progressUid,
        };

        state.zipUploadProgress = 0;
        const timer = setInterval(() => {
          state.zipUploadProgress = Math.floor(window.uploadProgress);
          if (window.uploadProgress >= 100) {
            clearInterval(timer);
          }
          window.maskOn();
        }, 10);

        client
          .subscribe({
            query: UPLOAD_PROGRESS,
            variables: { uploadPath: path, progressUid },
          })
          .subscribe({
            next({ data }) {
              state.progress = Math.floor(data.uploadProgress);
              if (data.uploadProgress < 100) {
                window.maskOn();
              }
            },
          });

        const res = await zipFileUpload({
          variables: { data },
        });
        if (res.data && res.data.defaultZipUpload) {
          if (res.data.defaultZipUpload.errMsg) {
            return resolve({
              ok: false,
              errMsg: res.data.defaultZipUpload.errMsg,
            });
          }

          const filename = resizeFile.name || resizeFile.filename;
          return resolve({
            images: res.data.defaultZipUpload.images,
            filename,
            path,
            ok: true,
          });
        }
        resolve({
          ok: false,
          errMsg: '업로드 데이터 처리 중 오류가 발생하였습니다.',
        });
      });
    }, []);

    const handleSubmit = useCallback(
      async (id, data, upload) => {
        window.maskOn();
        if (upload) {
          data.fileType = upload.fileType;
          if (upload.fileType === 'image') {
            const images = await upload.handleUpload();
            data.images = images;
          } else if (upload.zipFile && upload.zipFile.status === 'load') {
            const res = await saveZipFile(upload.zipFile, upload.uploadPath);
            if (res.ok) {
              data.images = res.images;
              data.zipFile = {
                filename: res.filename,
                path: res.path,
              };
            }
          }
        }

        if (!data.hashtags || !data.hashtags[0] || !data.hashtags[0].length) {
          data.hashtags = [];
        }
        if (!data.instaContents || !data.instaContents[0]) {
          data.instaContents = [];
        }

        if (id) {
          await update({
            variables: {
              id,
              data,
            },
          });
        } else {
          await add({
            variables: {
              data,
            },
          });
        }

        window.maskOff();

        if (refetch) {
          await refetch();
        }

        window.success({ title: '저장이 완료되었습니다.' });
        handleClose();
      },
      [refetch],
    );

    useEffect(() => {}, []);

    return (
      <CustomModal
        title={modalTitle}
        visible={modalOpen}
        onCancel={handleClose}
        minwidth={800}
        footer={null}
      >
        <Wrapper>
          {workType === '도배형인기게시물' && (
            <Modify
              workType={workType}
              propData={propData}
              handleSubmit={handleSubmit}
              handleClose={handleClose}
            />
          )}
        </Wrapper>
      </CustomModal>
    );
  },
);

export default SlotModify;
