/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback } from 'react';
import { Row, Col, Tabs } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';

import PageTitle from '@Common/PageTitle';

import GivePoint from './GivePoint';
import PointConfig from './PointConfig';

const { TabPane } = Tabs;

const Wrapper = styled.div`
  width: 100%;
`;

const PointContainer = observer(() => {
  const state = useLocalStore(() => ({
    tab: '1',
  }));

  const handleChangeTab = useCallback((key) => {
    state.tab = key;
  }, []);

  return (
    <Wrapper>
      <Row>
        <Col xs={24}>
          <PageTitle>포인트 관리</PageTitle>
        </Col>
      </Row>

      <Tabs activeKey={state.tab} onChange={handleChangeTab}>
        <TabPane tab="포인트 지급" key="1">
          <GivePoint />
        </TabPane>
        <TabPane tab="포인트 충전 설정" key="2">
          <PointConfig />
        </TabPane>
      </Tabs>
    </Wrapper>
  );
});

export default PointContainer;
