/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { Grid } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { observer, useLocalStore } from 'mobx-react';
import { useApolloClient } from '@apollo/react-hooks';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import Cookies from 'js-cookie';

import useStore from '@stores/useStore';

import Loading from '@Common/Loading';
import AdminRoutes from '@Admin';
import MemberRoutes from '@Member';

const { useBreakpoint } = Grid;

const helmetToComponent = (str) => {
  // stop if str is empty
  if (!str.length) {
    return;
  }

  // an array of React components
  let Components = [];

  // react-helmet returns a line-break delimited list of tags
  // split so we can deal with each individually
  str.split(/\n/).forEach((node, i) => {
    // extrapolate node type
    let nodeType = str.match(/[a-z]+/)[0];

    // container for props
    let props = {
      key: i,
    };

    // match attr="value" pattern
    // store props
    node.match(/([a-z\-]+=".*?")/g).forEach((attr) => {
      let matches = attr.match(/([a-z\-]+)="(.*?)"/);
      props[matches[1]] = matches[2];
    });

    // create and save the component
    Components.push(React.createElement(nodeType, props));
  });

  // return the array of components
  return Components;
};

const App = observer(() => {
  const screen = useBreakpoint();
  const { authStore, commonStore, metaStore } = useStore();
  const state = useLocalStore(() => ({
    loading: true,
  }));

  const client = useApolloClient();

  const loginCheck = useCallback(async () => {
    // if (document.referrer.length) {
    //   await axios({
    //     method: 'post',
    //     url: `${commonStore.baseApiUrl}/setReferrer`,
    //     data: {
    //       prevUrl: document.referrer,
    //       browserName,
    //       osName,
    //       mobileModel,
    //     },
    //   });
    // }

    await metaStore.init(client);
    const user = await authStore.loginCheck(client);

    setTimeout(() => {
      state.loading = false;
    }, 50);
  }, [screen]);

  useEffect(() => {
    loginCheck();
    window.IMP.init('imp78858077');
  }, []);

  if (state.loading) {
    return <Loading />;
  }

  return (
    <>
      {metaStore.df.site_title && metaStore.df.meta_tag && (
        <Helmet>
          <title>{metaStore.df.site_title}</title>
          {helmetToComponent(metaStore.df.meta_tag)}
          {Cookies.get('deviceMode') && Cookies.get('deviceMode') === 'pc' ? (
            <meta
              name="viewport"
              content="width=device-width, initial-scale=0.3"
            />
          ) : (
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
          )}
        </Helmet>
      )}

      <BrowserRouter>
        {commonStore.user && commonStore.user.isAdmin ? (
          <AdminRoutes />
        ) : (
          <MemberRoutes />
        )}
      </BrowserRouter>
    </>
  );
});

export default App;
