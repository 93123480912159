/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { Button, InputNumber, Space, Card } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { toJS } from 'mobx';

import { META, SAVE_META } from '@shared/queries';

const Wrapper = styled.div`
  width: 100%;
`;

const AccountConfig = observer(({ metaKey, configName }) => {
  const state = useLocalStore(() => ({
    data: {
      description: configName,
    },
    isActivated: true,

    get dataVal() {
      return toJS(this.data);
    },
  }));

  const client = useApolloClient();
  const [save] = useMutation(SAVE_META);

  const fetchData = useCallback(async () => {
    const result = await client.query({
      query: META,
      variables: { key: metaKey },
    });
    if (result.data && result.data.meta) {
      state.data = result.data.meta.value;
    }
  }, []);

  const handleChangeNumber = useCallback(
    (type) => (value) => {
      const data = state.dataVal;
      data[type] = value;
      state.data = data;
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    window.maskOn();

    const value = state.dataVal;
    value.isActivated = state.isActivated;

    await save({
      variables: {
        key: metaKey,
        data: {
          key: metaKey,
          value,
        },
      },
    });

    await fetchData();
    window.maskOff();
    window.success({ title: `${configName} 설정 저장이 완료되었습니다.` });
  }, [metaKey, configName]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Wrapper>
      <Card
        title={
          <Space size={20}>
            <span>{configName}</span>
            <Button type="primary" onClick={handleSubmit}>
              저장
            </Button>
          </Space>
        }
        size="small"
      >
        <Space size={40} style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div>
            <h4>좋아요</h4>
            <InputNumber
              value={state.dataVal.likeCnt}
              onChange={handleChangeNumber('likeCnt')}
              style={{ width: 80 }}
            />
          </div>
          <div>
            <h4>팔로우</h4>
            <InputNumber
              value={state.dataVal.followCnt}
              onChange={handleChangeNumber('followCnt')}
              style={{ width: 80 }}
            />
          </div>
          <div>
            <h4>댓글</h4>
            <InputNumber
              value={state.dataVal.commentCnt}
              onChange={handleChangeNumber('commentCnt')}
              style={{ width: 80 }}
            />
          </div>
          <div>
            <h4>저장</h4>
            <InputNumber
              value={state.dataVal.saveCnt}
              onChange={handleChangeNumber('saveCnt')}
              style={{ width: 80 }}
            />
          </div>
          <div>
            <h4>게시물수</h4>
            <InputNumber
              value={state.dataVal.postCnt}
              onChange={handleChangeNumber('postCnt')}
              style={{ width: 80 }}
            />
          </div>
          <div>
            <h4>프로필 공유하기</h4>
            <InputNumber
              value={state.dataVal.profileShareCnt}
              onChange={handleChangeNumber('profileShareCnt')}
              style={{ width: 80 }}
            />
          </div>
          <div>
            <h4>프로필 URL복사</h4>
            <InputNumber
              value={state.dataVal.profileUrlCopyCnt}
              onChange={handleChangeNumber('profileUrlCopyCnt')}
              style={{ width: 80 }}
            />
          </div>
        </Space>
      </Card>
    </Wrapper>
  );
});

export default AccountConfig;
