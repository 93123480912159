/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { toJS } from 'mobx';

import useStore from '@stores/useStore';
import { METAS } from '@shared/queries';
import { USE_PP_LIST } from '@shared/queries/UsePPQueries';
import { AUTOCOMPLETE_TOP_LIST } from '@shared/queries/AutocompleteTopQueries';

import UsePP from './UsePP';
import AutocompleteTop from './AutocompleteTop';

import ProductDetail from './ProductDetail';
import BeforeLogin from './BeforeLogin';
import AfterLogin from './AfterLogin';
import MobileMain from './MobileMain';

const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  width: 100%;

  .title {
    font-weight: bold;
    font-size: 1.4rem;
  }
  .description {
    position: relative;
    margin-top: 20px;
    margin-bottom: 30px;
    min-height: 160px;
    p {
      width: 100%;
      margin: 0;
    }
    .ql-size-large {
      font-family: Montserrat;
      font-size: 1.4rem;
      font-weight: bold;
      color: ${(props) => props.theme.primaryColor};
    }
  }

  #before_login {
    .menu-btn {
      height: 50px;
      span {
        font-size: 1.2rem;
      }
    }

    & * {
      text-align: center;
    }
  }

  #after_login {
    .product-card .ant-card-body {
      height: 83px;
      display: flex;
      align-items: center;
    }
    .product-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ant-btn {
        width: 200px;
      }
    }
  }

  ${(props) => props.theme.breakpoint('xs', 'lg')`
    .title {
      font-size: 1.2rem;
    }
    .description {
      .ql-size-large {
        font-size: 1.2rem;
      }
    }
  `}
`;

const Home = observer(() => {
  const screen = useBreakpoint();
  const { commonStore } = useStore();
  const router = useHistory();

  const state = useLocalStore(() => ({
    product_k: null,
    product_pp: null,
    product_oa: null,
    product_pp_caseBy: null,

    productDetailOpen: false,
    productDetailContent: '',

    buyOTOpen: false,

    usePPData: null,
    usePPModifyOpen: false,
    autocompleteTopData: null,
    autocompleteTopModifyOpen: false,
  }));

  const metaRes = useQuery(METAS, {
    variables: {
      keys: ['product_k', 'product_pp', 'product_oa', 'product_pp_caseBy'],
    },
  });
  const client = useApolloClient();

  const fetchUsePP = useCallback(async () => {
    const res = await client.query({
      query: USE_PP_LIST,
      variables: {
        findQuery: {
          user: commonStore.user.id,
        },
      },
    });
    if (res.data && res.data.usePPList) {
      state.usePPData = res.data.usePPList;
    }
  }, []);

  const fetchAutocompleteTop = useCallback(async () => {
    const res = await client.query({
      query: AUTOCOMPLETE_TOP_LIST,
      variables: {
        findQuery: {
          user: commonStore.user.id,
        },
      },
    });
    if (res.data && res.data.autocompleteTopList) {
      state.autocompleteTopData = res.data.autocompleteTopList;
    }
  }, []);

  const handleModifyModal = useCallback(
    (modalOpen, type) => () => {
      state[type] = modalOpen;
    },
    [],
  );

  const handleProductDetailModal = useCallback(
    (modalOpen, content, isNotYet) => (e) => {
      if (isNotYet) {
        return window.alert({
          title: (
            <div>
              현재 더 나은 서비스를 위해 개발중에 있습니다.
              <br />곧 서비스가 될 수 있도록 노력하겠습니다.
            </div>
          ),
        });
      }
      if (modalOpen && content) {
        state.productDetailContent = content;
      } else {
        state.productDetailContent = '';
      }
      state.productDetailOpen = modalOpen;
    },
    [],
  );

  const handleBuyOTModal = useCallback(
    (modalOpen) => () => {
      state.buyOTOpen = modalOpen;
    },
    [],
  );

  useEffect(() => {
    if (metaRes.data && metaRes.data.metas) {
      for (const meta of metaRes.data.metas) {
        state[meta.key] = meta.value;
      }
    }
  }, [metaRes.data]);

  useEffect(() => {
    if (state.usePPModifyOpen) {
      fetchUsePP();
    }
    if (state.autocompleteTopModifyOpen) {
      fetchAutocompleteTop();
    }
  }, [state.usePPModifyOpen, state.autocompleteTopModifyOpen]);

  useEffect(() => {
    if (commonStore.user && commonStore.user.id) {
      fetchUsePP();
      fetchAutocompleteTop();
    }
  }, []);

  return (
    <Wrapper>
      {screen.lg ? (
        commonStore.user ? (
          <AfterLogin
            handleProductDetailModal={handleProductDetailModal}
            product_k={state.product_k}
            product_pp={state.product_pp}
            product_oa={state.product_oa}
            product_pp_caseBy={state.product_pp_caseBy}
            handleModifyModal={handleModifyModal}
            usePPData={toJS(state.usePPData)}
            autocompleteTopData={toJS(state.autocompleteTopData)}
          />
        ) : (
          <BeforeLogin
            handleProductDetailModal={handleProductDetailModal}
            product_k={state.product_k}
            product_pp={state.product_pp}
            product_oa={state.product_oa}
            product_pp_caseBy={state.product_pp_caseBy}
          />
        )
      ) : (
        <MobileMain
          handleProductDetailModal={handleProductDetailModal}
          product_k={state.product_k}
          product_pp={state.product_pp}
          product_oa={state.product_oa}
          product_pp_caseBy={state.product_pp_caseBy}
          handleModifyModal={handleModifyModal}
          usePPData={toJS(state.usePPData)}
          autocompleteTopData={toJS(state.autocompleteTopData)}
        />
      )}

      <ProductDetail
        modalOpen={state.productDetailOpen}
        handleModal={handleProductDetailModal}
        content={state.productDetailContent}
      />

      <UsePP
        propData={toJS(state.usePPData)}
        modalOpen={state.usePPModifyOpen}
        handleModal={(modalOpen) => () =>
          handleModifyModal(modalOpen, 'usePPModifyOpen')()}
        refetch={fetchUsePP}
      />
      <AutocompleteTop
        propData={toJS(state.autocompleteTopData)}
        modalOpen={state.autocompleteTopModifyOpen}
        handleModal={(modalOpen) => () =>
          handleModifyModal(modalOpen, 'autocompleteTopModifyOpen')()}
        refetch={fetchAutocompleteTop}
      />
    </Wrapper>
  );
});

export default Home;
