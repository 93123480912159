/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import {
  Row,
  Col,
  InputNumber,
  Button,
  Form,
  Grid,
  Affix,
  Card,
  Select,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { observer, useLocalStore } from 'mobx-react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import uniqid from 'uniqid';
import { sortBy } from 'lodash';
import { toJS } from 'mobx';

import { METAS, METAS_REGEXP, SAVE_META } from '@shared/queries';
import { USER_GROUP } from '@shared/constants';
import { validateMessages } from '@utils/customFormValidator';
import useStore from '@stores/useStore';

const { useBreakpoint } = Grid;
const { Option } = Select;

const Wrapper = styled.div`
  width: 100%;

  .ant-form-item-label {
    font-weight: bold;
  }

  .ant-space {
    display: flex;
    align-items: center;
  }
`;

const getUid = (values) => {
  const uid = uniqid();
  const f = values.find((item) => item.uid && item.uid === uid);
  if (f) {
    return getUid(values);
  }
  return uid;
};

const PointConfig = observer(() => {
  const [form] = Form.useForm();
  const screen = useBreakpoint();
  const { commonStore } = useStore();

  const state = useLocalStore(() => ({}));

  const client = useApolloClient();
  const [save] = useMutation(SAVE_META);

  const fetchData = useCallback(async () => {
    const result = await client.query({
      query: METAS,
      variables: { keys: ['point_config'] },
    });

    if (result.data && result.data.metas) {
      for (const meta of result.data.metas) {
        form.setFieldsValue({ [meta.key]: meta.value });
      }
    }
  }, []);

  const handleSubmit = useCallback(async (values) => {
    window.maskOn();
    for (const item of values.point_config) {
      if (!item.uid) {
        item.uid = getUid(values.point_config);
      }
    }

    await save({
      variables: {
        key: `point_config`,
        data: {
          key: `point_config`,
          value: values.point_config,
        },
      },
    });

    await fetchData();
    window.maskOff();
    window.success({ title: '포인트 충전 설정 저장이 완료되었습니다. ' });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Wrapper>
      <Form
        form={form}
        name="point_config_form"
        colon={false}
        labelAlign="left"
        // labelCol={{ span: 4 }}
        // wrapperCol={{ span: 10 }}
        layout={screen.lg ? 'horizontal' : 'vertical'}
        initialValues={{
          point_config: [],
        }}
        validateMessages={validateMessages}
        scrollToFirstError
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Row gutter={[32, 16]}>
          <Col xs={24} lg={20}>
            <Card size="small">
              <Form.List name="point_config" style={{ width: '100%' }}>
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field) => (
                        <Row key={field.key} gutter={[16, 16]} align="middle">
                          <Col xs={20} lg={20}>
                            <Row gutter={16}>
                              <Col xs={24} lg={10}>
                                <Form.Item
                                  {...field}
                                  label="포인트"
                                  name={[field.name, 'point']}
                                  fieldKey={[field.fieldKey, 'point']}
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    placeholder="포인트"
                                    style={{ width: '100%' }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} lg={14}>
                                <Form.Item
                                  {...field}
                                  label="지급가격"
                                  name={[field.name, 'price']}
                                  fieldKey={[field.fieldKey, 'price']}
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    placeholder="지급가격"
                                    style={{ width: '100%' }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={4} lg={2}>
                            <MinusCircleOutlined
                              onClick={() => {
                                remove(field.name);
                              }}
                              style={{ fontSize: 18, marginBottom: 24 }}
                            />
                          </Col>
                        </Row>
                      ))}

                      <Form.Item wrapperCol={{ span: 20 }}>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                          }}
                          block
                        >
                          <PlusOutlined /> 포인트 충전 가격 추가
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
            </Card>
          </Col>
          <Col xs={24} lg={4}>
            <Affix offsetTop={80}>
              <Form.Item noStyle>
                <Button type="primary" size="large" htmlType="submit" block>
                  저장
                </Button>
              </Form.Item>
            </Affix>
          </Col>
        </Row>
      </Form>
    </Wrapper>
  );
}, []);

export default PointConfig;
