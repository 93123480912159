/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Space,
  Button,
  Divider,
  Collapse,
  Grid,
  Tabs,
} from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { toJS } from 'mobx';
import { useApolloClient } from '@apollo/react-hooks';

import useStore from '@stores/useStore';

import CompanyModify from '../CompanyModify';
import WorkList from './WorkList';
import BuyWork from './BuyWork';
import CustomerSearch from './CustomerSearch';

const { Panel } = Collapse;
const { TabPane } = Tabs;
const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  width: 100%;
`;

const WorkDetailContainer = observer(({ propData, listRefetch, user }) => {
  const screen = useBreakpoint();

  const state = useLocalStore(() => ({
    tab: '1',

    isRefetchWorkList: false,
    setRefetchWorkList(value) {
      this.isRefetchWorkList = value;
    },

    productMetaKey: null,
    buyModalOpen: false,
    buyModalTitle: '',

    kLikeSearch: {},
    kLikeSearchDate: null,
    kFollowerSearch: {},
    kFollowerSearchDate: null,
    kCommentSearch: {},
    kCommentSearchDate: null,
    ppSearch: {},
    ppCaseBySearch: {},
    oaSearch: {},
    setSearchDate(key, value) {
      this[key] = value;
    },
    setSearch(key, value) {
      this[key] = value;
      setTimeout(() => {
        this.isRefetchWorkList = true;
      }, 10);
    },
  }));

  const handleChangeTab = useCallback((key) => {
    state.tab = key;

    setTimeout(() => {
      state.isRefetchWorkList = true;
    }, 10);
  }, []);

  const handleBuyModal = useCallback(
    (modalOpen, productMetaKey, modalTitle) => () => {
      if (productMetaKey) {
        state.productMetaKey = productMetaKey;
      }
      if (modalTitle) {
        state.buyModalTitle = modalTitle;
      }
      state.buyModalOpen = modalOpen;
    },
    [],
  );

  useEffect(() => {
    if (state.isRefetchWorkList && listRefetch) {
      listRefetch();
    }
  }, [state.isRefetchWorkList, listRefetch]);

  return (
    <Wrapper>
      <Card
        title={
          screen.lg ? (
            <CompanyModify
              refetch={listRefetch}
              propData={propData}
              user={user}
            />
          ) : null
        }
        extra={
          screen.lg ? (
            <Space>
              <Button
                type="primary"
                size="large"
                block
                onClick={handleBuyModal(
                  true,
                  'product_k',
                  'K좋아요 K팔로워 K댓글',
                )}
              >
                K좋아요 K팔로워 K댓글
              </Button>
              <Button
                type="primary"
                size="large"
                block
                onClick={handleBuyModal(
                  true,
                  'product_pp',
                  '인기게시물 상위작업',
                )}
              >
                인기게시물 상위작업
              </Button>
              <Button
                type="primary"
                size="large"
                block
                onClick={handleBuyModal(
                  true,
                  'product_pp_caseBy',
                  '인기게시물 건바이',
                )}
              >
                인기게시물 건바이
              </Button>
              <Button
                type="primary"
                size="large"
                block
                onClick={handleBuyModal(true, 'product_oa', '최적화 계정육성')}
              >
                최적화 계정육성
              </Button>
            </Space>
          ) : (
            <Row gutter={[10, 10]} align="middle">
              <Col xs={24}>
                <CompanyModify
                  refetch={listRefetch}
                  propData={propData}
                  user={user}
                />
                <Divider />
              </Col>
              <Col xs={24}>
                <Button
                  type="primary"
                  size="large"
                  block
                  onClick={handleBuyModal(
                    true,
                    'product_k',
                    'K좋아요 K팔로워 K댓글',
                  )}
                >
                  K좋아요 K팔로워 K댓글
                </Button>
              </Col>
              <Col xs={24}>
                <Button
                  type="primary"
                  size="large"
                  block
                  onClick={handleBuyModal(
                    true,
                    'product_pp',
                    '인기게시물 상위작업',
                  )}
                >
                  인기게시물 상위작업
                </Button>
              </Col>
              <Col xs={24}>
                <Button
                  type="primary"
                  size="large"
                  block
                  onClick={handleBuyModal(
                    true,
                    'product_pp_caseBy',
                    '인기게시물 건바이',
                  )}
                >
                  인기게시물 건바이
                </Button>
              </Col>
              <Col xs={24}>
                <Button
                  type="primary"
                  size="large"
                  block
                  onClick={handleBuyModal(
                    true,
                    'product_oa',
                    '최적화 계정육성',
                  )}
                >
                  최적화 계정육성
                </Button>
              </Col>
            </Row>
          )
        }
      >
        {propData && propData.id && (
          <Collapse>
            <Panel header="K좋아요 K팔로워 K댓글" key="1">
              <Tabs activeKey={state.tab} onChange={handleChangeTab}>
                <TabPane tab="전체" key="1">
                  <Row gutter={[10, 10]}>
                    <Col xs={24}>
                      <Divider orientation="left">K좋아요</Divider>
                      <CustomerSearch
                        dataKey="kLikeSearch"
                        setSearch={state.setSearch}
                        dateData={toJS(state.kLikeSearchDate)}
                        setSearchDate={state.setSearchDate}
                      />
                      <Divider />
                      <h4>[월구매]</h4>
                      <WorkList
                        workType="K좋아요"
                        company={propData}
                        isRefetchWorkList={state.isRefetchWorkList}
                        setRefetchWorkList={state.setRefetchWorkList}
                        user={user}
                        findQuery={{
                          paymentType: '월구매',
                          ...toJS(state.kLikeSearch),
                        }}
                      />
                      <Divider />
                      <h4>[건구매]</h4>
                      <WorkList
                        workType="K좋아요"
                        company={propData}
                        isRefetchWorkList={state.isRefetchWorkList}
                        setRefetchWorkList={state.setRefetchWorkList}
                        user={user}
                        findQuery={{
                          paymentType: '건구매',
                          ...toJS(state.kLikeSearch),
                        }}
                      />
                    </Col>
                    <Col xs={24}>
                      <Divider orientation="left">K팔로워</Divider>
                      <CustomerSearch
                        dataKey="kFollowerSearch"
                        setSearch={state.setSearch}
                        dateData={toJS(state.kFollowerSearchDate)}
                        setSearchDate={state.setSearchDate}
                      />
                      <Divider />
                      <WorkList
                        workType="K팔로워"
                        company={propData}
                        isRefetchWorkList={state.isRefetchWorkList}
                        setRefetchWorkList={state.setRefetchWorkList}
                        user={user}
                        findQuery={{
                          ...toJS(state.kFollowerSearch),
                        }}
                      />
                    </Col>
                    <Col xs={24}>
                      <Divider orientation="left">K댓글</Divider>
                      <CustomerSearch
                        dataKey="kCommentSearch"
                        setSearch={state.setSearch}
                        dateData={toJS(state.kCommentSearchDate)}
                        setSearchDate={state.setSearchDate}
                      />
                      <Divider />
                      <h4>[월구매]</h4>
                      <WorkList
                        workType="K댓글"
                        company={propData}
                        isRefetchWorkList={state.isRefetchWorkList}
                        setRefetchWorkList={state.setRefetchWorkList}
                        user={user}
                        findQuery={{
                          paymentType: '월구매',
                          ...toJS(state.kCommentSearch),
                        }}
                      />
                      <Divider />
                      <h4>[건구매]</h4>
                      <WorkList
                        workType="K댓글"
                        company={propData}
                        isRefetchWorkList={state.isRefetchWorkList}
                        setRefetchWorkList={state.setRefetchWorkList}
                        user={user}
                        findQuery={{
                          paymentType: '건구매',
                          ...toJS(state.kCommentSearch),
                        }}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="K좋아요" key="2">
                  <CustomerSearch
                    dataKey="kLikeSearch"
                    setSearch={state.setSearch}
                    dateData={toJS(state.kLikeSearchDate)}
                    setSearchDate={state.setSearchDate}
                  />
                  <Divider />
                  <h4>[월구매]</h4>
                  <WorkList
                    workType="K좋아요"
                    company={propData}
                    isRefetchWorkList={state.isRefetchWorkList}
                    setRefetchWorkList={state.setRefetchWorkList}
                    user={user}
                    findQuery={{
                      paymentType: '월구매',
                      ...toJS(state.kLikeSearch),
                    }}
                  />
                  <Divider />
                  <h4>[건구매]</h4>
                  <WorkList
                    workType="K좋아요"
                    company={propData}
                    isRefetchWorkList={state.isRefetchWorkList}
                    setRefetchWorkList={state.setRefetchWorkList}
                    user={user}
                    findQuery={{
                      paymentType: '건구매',
                      ...toJS(state.kLikeSearch),
                    }}
                  />
                </TabPane>
                <TabPane tab="K팔로워" key="3">
                  <CustomerSearch
                    dataKey="kFollowerSearch"
                    setSearch={state.setSearch}
                    dateData={toJS(state.kFollowerSearchDate)}
                    setSearchDate={state.setSearchDate}
                  />
                  <Divider />
                  <WorkList
                    workType="K팔로워"
                    company={propData}
                    isRefetchWorkList={state.isRefetchWorkList}
                    setRefetchWorkList={state.setRefetchWorkList}
                    user={user}
                    findQuery={{
                      ...toJS(state.kFollowerSearch),
                    }}
                  />
                </TabPane>
                <TabPane tab="K댓글" key="4">
                  <CustomerSearch
                    dataKey="kCommentSearch"
                    setSearch={state.setSearch}
                    dateData={toJS(state.kCommentSearchDate)}
                    setSearchDate={state.setSearchDate}
                  />
                  <Divider />
                  <h4>[월구매]</h4>
                  <WorkList
                    workType="K댓글"
                    company={propData}
                    isRefetchWorkList={state.isRefetchWorkList}
                    setRefetchWorkList={state.setRefetchWorkList}
                    user={user}
                    findQuery={{
                      paymentType: '월구매',
                      ...toJS(state.kCommentSearch),
                    }}
                  />
                  <Divider />
                  <h4>[건구매]</h4>
                  <WorkList
                    workType="K댓글"
                    company={propData}
                    isRefetchWorkList={state.isRefetchWorkList}
                    setRefetchWorkList={state.setRefetchWorkList}
                    user={user}
                    findQuery={{
                      paymentType: '건구매',
                      ...toJS(state.kCommentSearch),
                    }}
                  />
                </TabPane>
              </Tabs>
            </Panel>
            <Panel header="인기게시물 상위작업" key="2">
              <CustomerSearch dataKey="ppSearch" setSearch={state.setSearch} />
              <Divider />
              <h4>인기게시물 상위작업:고객 아이디 사용(건)</h4>
              <WorkList
                workType="인기게시물"
                company={propData}
                isRefetchWorkList={state.isRefetchWorkList}
                setRefetchWorkList={state.setRefetchWorkList}
                user={user}
                programType="고객아이디"
                findQuery={{
                  ...toJS(state.ppSearch),
                }}
              />
              <Divider />
              <h4>인기게시물 상위작업:고객 아이디 사용(월)</h4>
              <WorkList
                workType="인기게시물"
                company={propData}
                isRefetchWorkList={state.isRefetchWorkList}
                setRefetchWorkList={state.setRefetchWorkList}
                user={user}
                programType="고객아이디사용월"
                findQuery={{
                  ...toJS(state.ppSearch),
                }}
              />
              <Divider />
              <h4>인기게시물 상위작업:최적화 아이디 사용</h4>
              <WorkList
                workType="인기게시물"
                company={propData}
                isRefetchWorkList={state.isRefetchWorkList}
                setRefetchWorkList={state.setRefetchWorkList}
                user={user}
                programType="최적화아이디"
                findQuery={{
                  ...toJS(state.ppSearch),
                }}
              />
            </Panel>
            <Panel header="인기게시물 건바이" key="3">
              <CustomerSearch
                dataKey="ppCaseBySearch"
                setSearch={state.setSearch}
              />
              <Divider />
              <h4>월 건바이노출</h4>
              <WorkList
                workType="인기게시물건바이"
                company={propData}
                isRefetchWorkList={state.isRefetchWorkList}
                setRefetchWorkList={state.setRefetchWorkList}
                user={user}
                findQuery={{
                  paymentType: '월구매',
                  ...toJS(state.ppCaseBySearch),
                }}
                ppCaseByType="월구매"
              />
              <Divider />
              <h4>건바이노출</h4>
              <WorkList
                workType="인기게시물건바이"
                company={propData}
                isRefetchWorkList={state.isRefetchWorkList}
                setRefetchWorkList={state.setRefetchWorkList}
                user={user}
                findQuery={{
                  paymentType: '건구매',
                  ...toJS(state.ppCaseBySearch),
                }}
                ppCaseByType="건구매"
              />
              <Divider />
              <h4>고객 월 건바이</h4>
              <WorkList
                workType="인기게시물건바이"
                company={propData}
                isRefetchWorkList={state.isRefetchWorkList}
                setRefetchWorkList={state.setRefetchWorkList}
                user={user}
                findQuery={{
                  paymentType: '고객월건바이',
                  ...toJS(state.ppCaseBySearch),
                }}
                ppCaseByType="고객월건바이"
              />
            </Panel>
            <Panel header="최적화 계정육성" key="4">
              <CustomerSearch dataKey="oaSearch" setSearch={state.setSearch} />
              <Divider />
              <WorkList
                workType="최적화계정육성"
                company={propData}
                isRefetchWorkList={state.isRefetchWorkList}
                setRefetchWorkList={state.setRefetchWorkList}
                user={user}
                findQuery={{
                  ...toJS(state.oaSearch),
                }}
              />
            </Panel>
          </Collapse>
        )}
      </Card>

      {state.productMetaKey && (
        <BuyWork
          company={propData}
          metaKey={state.productMetaKey}
          modalTitle={state.buyModalTitle}
          modalOpen={state.buyModalOpen}
          handleModal={handleBuyModal}
          setRefetchWorkList={state.setRefetchWorkList}
          user={user}
        />
      )}
    </Wrapper>
  );
});

export default WorkDetailContainer;
