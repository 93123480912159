import gql from 'graphql-tag';
import { accountFragment } from '@shared/fragments';

export const ACCOUNTS = gql`
  ${accountFragment}
  query Accounts($page: Int, $limit: Int, $findQuery: JSON) {
    accounts(page: $page, limit: $limit, findQuery: $findQuery) {
      docs {
        ...AccountFragment
      }
      totalDocs
      limit
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      pagingCounter
    }
  }
`;

export const ACCOUNT = gql`
  ${accountFragment}
  query Account($findQuery: JSON) {
    account(findQuery: $findQuery) {
      ...AccountFragment
    }
  }
`;

export const ACCOUNT_LIST = gql`
  ${accountFragment}
  query AccountList($findQuery: JSON) {
    accountList(findQuery: $findQuery) {
      ...AccountFragment
    }
  }
`;

export const ADD_ACCOUNT = gql`
  ${accountFragment}
  mutation AddAccount($data: InputAccount) {
    addAccount(data: $data) {
      ...AccountFragment
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  ${accountFragment}
  mutation UpdateAccount($id: ID!, $data: InputAccount) {
    updateAccount(id: $id, data: $data) {
      ...AccountFragment
    }
  }
`;

export const REMOVE_ACCOUNT = gql`
  mutation RemoveAccount($id: ID!) {
    removeAccount(id: $id)
  }
`;
