export const ROLES = [
  { label: '가입회원', value: 1 },
  { label: '결제된 일반회원', value: 2 },
  { label: '결제된 광고대행사회원', value: 3 },
  { label: '직원관리자', value: 4 },
  { label: '최고관리자', value: 5 },
];

export const USER_GROUP = ['일반회원', '광고대행사'];

export const PAYMENT_STATUS = [
  '결제대기',
  '결제완료',
  '결제취소',
  '환불요청',
  '환불완료',
];
export const ORDER_TYPE = ['프로그램 결제', '포인트 충전', '포인트 지급'];

export const BANKS = [
  { label: 'KB국민은행', value: 'BK04' },
  { label: 'SC제일은행', value: 'BK23' },
  { label: '경남은행', value: 'BK39' },
  { label: '광주은행', value: 'BK34' },
  { label: '기업은행', value: 'BK03' },
  { label: '농협', value: 'BK11' },
  { label: '대구은행', value: 'BK31' },
  { label: '부산은행', value: 'BK32' },
  { label: '산업은행', value: 'BK02' },
  { label: '새마을금고', value: 'BK45' },
  { label: '수협', value: 'BK07' },
  { label: '신한은행', value: 'BK88' },
  { label: '신협', value: 'BK48' },
  { label: '외환은행', value: 'BK81' },
  { label: '우리은행', value: 'BK20' },
  { label: '우체국', value: 'BK71' },
  { label: '전북은행', value: 'BK37' },
  // { label: '축협', value: '16' },
  { label: '카카오뱅크', value: 'BK90' },
  { label: '케이뱅크', value: 'BK89' },
  { label: '하나은행(서울은행)', value: 'BK81' },
  { label: '한국씨티은행(한미은행)', value: 'BK27' },
];
