/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback, useMemo } from 'react';
import { Table, Grid, Descriptions, Space, Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import moment from 'moment';
import { useApolloClient } from '@apollo/react-hooks';
import { toJS } from 'mobx';

import useStore from '@stores/useStore';
import { POINT_LOGS } from '@shared/queries/PointLogQueries';
import { numberWithCommas } from '@utils/common';

const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  width: 100%;

  .ant-table-summary {
    background-color: #fafafa;
    text-align: center;
  }
`;

const PointChargingList = observer(({ user, tab, setListRefetch }) => {
  const screen = useBreakpoint();
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    list: [],
    paginate: {
      docs: [],
      totalDocs: 0,
      limit: 10,
      page: 1,
      totalPages: 1,
    },
  }));

  const client = useApolloClient();

  const fetchData = useCallback(
    async (findQuery = {}) => {
      window.maskOn();

      findQuery.pointType = '포인트충전';
      findQuery.status = '무통장';
      if (user) {
        findQuery.user = user.id;
      }

      const result = await client.query({
        query: POINT_LOGS,
        variables: {
          page: state.paginate.page,
          limit: state.paginate.limit,
          findQuery,
        },
      });

      if (result.data && result.data.pointLogs) {
        state.paginate = result.data.pointLogs;
        state.list = result.data.pointLogs.docs;
      }
      window.maskOff();
    },
    [user],
  );

  const handleChangePage = useCallback(
    (page) => {
      if (state.paginate.page !== page) {
        state.paginate.page = page;
        fetchData();
      }
    },
    [user],
  );

  const setExpire = useCallback((row) => {
    let targetMoment = moment(row.createdAt).add(3, 'hours');
    let value = moment.duration(targetMoment.diff(moment()));

    setInterval(() => {
      const elem = document.querySelector(`#expire_${row.id}`);
      if (elem) {
        let targetMoment = moment(row.createdAt).add(3, 'hours');
        let value = moment.duration(targetMoment.diff(moment()));
        elem.innerText = `${value.hours()}시간 ${value.minutes()}분 ${value.seconds()}초 남음`;
      }
    }, 1000);

    return (
      <span id={`expire_${row.id}`}>
        {value.hours()}시간 {value.minutes()}분 {value.seconds()}초 남음
      </span>
    );
  }, []);

  useEffect(() => {
    if (tab === '3') {
      fetchData();
    }
  }, [tab]);

  useEffect(() => {
    fetchData();
    setListRefetch(fetchData);
  }, [user]);

  const columns = useMemo(
    () => [
      {
        title: '입금 신청날짜',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (_, row) => moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        align: 'center',
      },
      {
        title: '입금금액',
        dataIndex: 'point',
        key: 'point',
        render: (_, row) => numberWithCommas(row.point, true),
        align: 'center',
      },
      {
        title: '입금 마감시간',
        dataIndex: 'expire',
        key: 'expire',
        render: (_, row) => setExpire(row),
        align: 'center',
      },
    ],
    [],
  );

  const mobileColumns = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'idx',
        key: 'idx',
        render: (_, row, idx) => {
          return (
            <Descriptions size="small" bordered column={1}>
              <Descriptions.Item label="입금 신청날짜">
                {moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')}
              </Descriptions.Item>
              <Descriptions.Item label="입금금액">
                {numberWithCommas(row.point)}
              </Descriptions.Item>
              <Descriptions.Item label="입금 마감시간">
                {setExpire(row)}
              </Descriptions.Item>
            </Descriptions>
          );
        },
      },
    ],
    [],
  );

  return (
    <Wrapper>
      <Table
        size="small"
        dataSource={toJS(state.list)}
        scroll={{ y: 300 }}
        columns={screen.lg ? columns : mobileColumns}
        // scroll={{ x: 992 }}
        pagination={false}
        rowKey={(row) => row.id}
        bordered
      />
    </Wrapper>
  );
});

export default PointChargingList;
