/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { toJS } from 'mobx';

import { META } from '@shared/queries';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 25px;

  img {
    width: 100%;
  }
`;

const RightBanner = observer(() => {
  const state = useLocalStore(() => ({
    list: [],

    get listVal() {
      return toJS(this.list);
    },
  }));

  const metaRes = useQuery(META, { variables: { key: 'banner_right' } });

  useEffect(() => {
    if (metaRes.data && metaRes.data.meta) {
      state.list = metaRes.data.meta.value;
    }
  }, [metaRes.data]);

  return (
    <Wrapper>
      {state.listVal.map((item) => (
        <div key={item.uid} style={{ marginBottom: 15 }}>
          {item.link ? (
            <a href={item.link} target="_blank">
              <img className="click" src={item.url ? item.url : ''} alt="" />
            </a>
          ) : (
            <img src={item.url ? item.url : ''} alt="" />
          )}
        </div>
      ))}
    </Wrapper>
  );
});

export default RightBanner;
