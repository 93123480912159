/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs, Row, Col } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';

import PageTitle from '@Common/PageTitle';

import SaleListContainer from './SaleList';
import RefundList from './RefundList';
import Point from './Point';
import PointBank from './PointBank';
import Invoice from './Invoice';

const { TabPane } = Tabs;

const Wrapper = styled.div`
  width: 100%;
`;

const SaleStatus = observer(() => {
  const router = useHistory();
  const state = useLocalStore(() => ({
    tab: '1',
    subTab: '1',
  }));

  const handleChangeTab = useCallback((key) => {
    state.tab = key;
  }, []);

  useEffect(() => {
    if (router.location.query && router.location.query.tab) {
      state.tab = router.location.query.tab;
    }
    if (router.location.query && router.location.query.subTab) {
      state.subTab = router.location.query.subTab;
    }
  }, []);

  return (
    <Wrapper>
      <Row>
        <Col xs={24}>
          <PageTitle>매출관리</PageTitle>
        </Col>
      </Row>

      <Tabs activeKey={state.tab} onChange={handleChangeTab}>
        <TabPane tab="매출내역" key="1">
          <SaleListContainer tab={state.tab} subTab={state.subTab} />
        </TabPane>
        <TabPane tab="환불관리" key="2">
          <RefundList tab={state.tab} />
        </TabPane>
        <TabPane tab="포인트 사용내역" key="3">
          <Point tab={state.tab} />
        </TabPane>
        <TabPane tab="무통장관리" key="4">
          <PointBank tab={state.tab} />
        </TabPane>
        <TabPane tab="발행신청관리" key="5">
          <Invoice tab={state.tab} />
        </TabPane>
      </Tabs>
    </Wrapper>
  );
});

export default SaleStatus;
