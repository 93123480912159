/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Row,
  Col,
  Space,
  Button,
  Select,
  Input,
  Divider,
  Table,
  Popover,
  Grid,
} from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { toJS } from 'mobx';
import { useApolloClient, useMutation } from '@apollo/react-hooks';

import {
  AUTOCOMPLETE_TOPS,
  REMOVE_AUTOCOMPLETE_TOP,
  UPDATE_AUTOCOMPLETE_TOP,
} from '@shared/queries/AutocompleteTopQueries';

import PageTitle from '@Common/PageTitle';

import AutocompleteTopModify from './AutocompleteTopModify';

const { Option } = Select;
const { Search } = Input;
const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  width: 100%;

  .children-data {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
    }
  }

  ${(props) => props.theme.breakpoint('xs', 'lg')`
  .children-data {
    li {
      width: 100% !important;
    }
  }
  `}
`;

const AutocompleteTop = observer(({ rootTab }) => {
  const screen = useBreakpoint();
  const state = useLocalStore(() => ({
    list: [],
    paginate: {
      docs: [],
      totalDocs: 0,
      limit: 10,
      page: 1,
      totalPages: 1,
    },

    searchType: null,

    modifyData: null,
    modifyModalOpen: false,

    selectedRowKeys: [],
    selectedList: [],
  }));

  const client = useApolloClient();
  const [update] = useMutation(UPDATE_AUTOCOMPLETE_TOP);
  const [remove] = useMutation(REMOVE_AUTOCOMPLETE_TOP);

  const fetchData = useCallback(async (findQuery = {}) => {
    window.maskOn();

    const result = await client.query({
      query: AUTOCOMPLETE_TOPS,
      variables: {
        page: state.paginate.page,
        limit: state.paginate.limit,
        findQuery,
      },
    });

    if (result.data && result.data.autocompleteTops) {
      state.paginate = result.data.autocompleteTops;
      state.list = result.data.autocompleteTops.docs;
    }
    window.maskOff();
  }, []);

  const handleChangePage = useCallback((page) => {
    if (state.paginate.page !== page) {
      state.paginate.page = page;
      handleSearch();
    }
  }, []);

  const handleModifyModal = useCallback(
    (modalOpen, data) => () => {
      if (modalOpen && data) {
        state.modifyData = data;
      } else {
        state.modifyData = null;
      }
      state.modifyModalOpen = modalOpen;
    },
    [],
  );

  const handleRemove = useCallback(
    (row, isAll) => () => {
      window.ask({
        title: '계속 진행하시겠습니까?',
        content: '선택하신 자동완성 상위노출이 삭제됩니다.',
        async onOk() {
          if (isAll) {
            const ids = toJS(state.selectedRowKeys);
            for (const id of ids) {
              await remove({ variables: { id } });
            }
          } else {
            await remove({ variables: { id: row.id } });
          }
          handleSearch();
        },
      });
    },
    [],
  );

  const handleChangeSearch = useCallback(
    (type) => (value) => {
      state[type] = value;
    },
    [],
  );

  const handleSearch = useCallback((value) => {
    const findQuery = {};

    if (value && value.length) {
      if (state.searchType) {
        findQuery[state.searchType] = { $regex: value, $options: 'ig' };
      } else {
        findQuery['$or'] = [
          { userId: { $regex: value, $options: 'ig' } },
          { userName: { $regex: value, $options: 'ig' } },
        ];
      }
    }

    fetchData(findQuery);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (rootTab === '3') {
      handleSearch();
    }
  }, [rootTab]);

  const columns = useMemo(() => {
    let value = [
      {
        title: '성함',
        dataIndex: 'userName',
        key: 'userName',
        render: (_, row) => row.userName,
        align: 'center',
      },
      {
        title: '고객아이디',
        dataIndex: 'userId',
        key: 'userId',
        render: (_, row) => row.userId,
        align: 'center',
      },
      {
        title: '슬롯',
        children: [
          {
            title: '슬롯번호',
            dataIndex: 'slotIdx',
            key: 'slotIdx',
            render: (_, row) => (
              <ul className="children-data">
                {row.slots.map((item, idx) => (
                  <li key={`${row.id}_slotIdx_${idx}`}>슬롯 {idx + 1}</li>
                ))}
              </ul>
            ),
            align: 'center',
            width: 100,
          },
          {
            title: '인스타아이디',
            dataIndex: 'instaId',
            key: 'instaId',
            render: (_, row) => (
              <ul className="children-data">
                {row.slots.map((item, idx) => (
                  <li key={`${row.id}_instaid_${idx}`}>{item.instaId}</li>
                ))}
              </ul>
            ),
            align: 'center',
          },
          {
            title: 'URL',
            dataIndex: 'url',
            key: 'url',
            render: (_, row) => (
              <ul className="children-data">
                {row.slots.map((item, idx) => (
                  <li key={`${row.id}_url_${idx}`}>{item.url}</li>
                ))}
              </ul>
            ),
            align: 'center',
          },
          {
            title: '해시태그',
            dataIndex: 'hashtags',
            key: 'hashtags',
            render: (hashtags, row) => (
              <ul className="children-data">
                {row.slots.map((item, idx) => (
                  <li key={`${row.id}_hashtags_${idx}`}>
                    <>
                      {item.hashtags && item.hashtags.length > 1 ? (
                        <Popover
                          content={
                            <div>
                              {item.hashtags.map((hashtag, hIdx) => (
                                <div key={`${row.id}_hashtag_${idx}_${hIdx}`}>
                                  {hashtag}
                                </div>
                              ))}
                            </div>
                          }
                          title=""
                        >
                          {item.hashtags[0]}...
                        </Popover>
                      ) : item.hashtags && item.hashtags.length === 1 ? (
                        <div>{item.hashtags[0]}</div>
                      ) : (
                        <div>&nbsp;</div>
                      )}
                    </>
                  </li>
                ))}
              </ul>
            ),
            align: 'center',
          },
          {
            title: '남은기간',
            dataIndex: 'count',
            key: 'count',
            render: (_, row) => (
              <ul className="children-data">
                {row.slots.map((item, idx) => (
                  <li key={`${row.id}_count_${idx}`}>
                    {item.counting || 0}/{item.maxCounting || 0}일
                  </li>
                ))}
              </ul>
            ),
            align: 'center',
          },
        ],
        align: 'center',
      },
      {
        title: '총등록수',
        dataIndex: 'rankAllCnt',
        key: 'rankAllCnt',
        align: 'center',
      },
      {
        title: '10위내',
        dataIndex: 'rank10Cnt',
        key: 'rank10Cnt',
        render: (_, row) => (
          <div>
            {row.rank10Cnt}(
            {(row.rank10Cnt / row.rankAllCnt) * 100
              ? Number((row.rank10Cnt / row.rankAllCnt) * 100).toFixed(1)
              : 0}
            %)
          </div>
        ),
        align: 'center',
      },
      {
        title: '20위내',
        dataIndex: 'rank20Cnt',
        key: 'rank20Cnt',
        render: (_, row) => (
          <div>
            {row.rank20Cnt}(
            {(row.rank20Cnt / row.rankAllCnt) * 100
              ? Number((row.rank20Cnt / row.rankAllCnt) * 100).toFixed(1)
              : 0}
            %)
          </div>
        ),
        align: 'center',
      },
      {
        title: '누락',
        dataIndex: 'rankOmissionCnt',
        key: 'rankOmissionCnt',
        render: (_, row) => (
          <div>
            {row.rankOmissionCnt}(
            {(row.rankOmissionCnt / row.rankAllCnt) * 100
              ? Number((row.rankOmissionCnt / row.rankAllCnt) * 100).toFixed(1)
              : 0}
            %)
          </div>
        ),
        align: 'center',
      },
      {
        title: '좋아요개수',
        dataIndex: 'likeCnt',
        key: 'likeCnt',
        render: (_, row) => row.likeCnt,
        align: 'center',
      },
      {
        title: '댓글개수',
        dataIndex: 'commentCnt',
        key: 'commentCnt',
        render: (_, row) => row.commentCnt,
        align: 'center',
      },
      {
        title: '저장개수',
        dataIndex: 'saveCnt',
        key: 'saveCnt',
        render: (_, row) => row.saveCnt,
        align: 'center',
      },
      {
        title: '팔로우개수',
        dataIndex: 'followCnt',
        key: 'followCnt',
        render: (_, row) => row.followCnt,
        align: 'center',
      },
      {
        title: '트래픽횟수',
        dataIndex: 'trafficCnt',
        key: 'trafficCnt',
        render: (_, row) => row.trafficCnt,
        align: 'center',
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (_, row) => (
          <>
            <Button
              type="primary"
              block
              style={{ marginBottom: 5 }}
              onClick={handleModifyModal(true, row)}
            >
              관리설정
            </Button>
            <Button type="primary" danger block onClick={handleRemove(row)}>
              삭제
            </Button>
          </>
        ),
        align: 'center',
        width: 120,
      },
    ];

    return value;
  }, []);

  const rowSelection = {
    type: 'checkbox',
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRows.length) {
        state.selectedList = selectedRows;
        state.selectedRowKeys = selectedRows.map((item) => item.id);
      } else {
        state.selectedList = [];
        state.selectedRowKeys = [];
      }
    },
    selectedRowKeys: toJS(state.selectedRowKeys),
  };

  return (
    <Wrapper>
      <Row>
        <Col xs={24}>
          <PageTitle>자동완성 상위노출</PageTitle>
        </Col>
      </Row>

      <Row gutter={[32, 16]} align="bottom">
        <Col xs={24} lg={24}>
          <h4>전체검색</h4>
          <Space>
            <Select
              value={state.searchType}
              onChange={handleChangeSearch('searchType')}
              style={{ width: 160 }}
            >
              <Option value={null}>전체</Option>
              <Option value="userName">성함</Option>
              <Option value="userId">고객아이디</Option>
            </Select>
            <Search onSearch={handleSearch} enterButton />
          </Space>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[10, 10]} justify="end" style={{ marginBottom: 15 }}>
        <Col>
          <Button type="primary" onClick={handleModifyModal(true)}>
            사용추가
          </Button>
        </Col>
        <Col>
          <Button
            danger
            onClick={handleRemove(null, true)}
            disabled={!state.selectedRowKeys.length}
          >
            선택삭제
          </Button>
        </Col>
      </Row>

      <Table
        size="small"
        dataSource={toJS(state.list)}
        columns={columns}
        scroll={{ x: 1400 }}
        pagination={{
          current: state.paginate.page,
          total: state.paginate.totalDocs,
          showSizeChanger: false,
          onChange: handleChangePage,
        }}
        rowKey={(row) => row.id}
        rowSelection={rowSelection}
        bordered
      />

      <AutocompleteTopModify
        propData={toJS(state.modifyData)}
        modalOpen={state.modifyModalOpen}
        handleModal={handleModifyModal}
        refetch={handleSearch}
        isAdmin={true}
      />
    </Wrapper>
  );
});

export default AutocompleteTop;
