import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'mobx-react-lite/batchingForReactDom';
import 'moment/locale/ko';

import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import ko_KR from 'antd/es/locale/ko_KR';
import { ApolloProvider } from '@apollo/react-hooks';
import { Provider as MobxProvider } from 'mobx-react';
import { ThemeProvider } from 'styled-components';
import moment from 'moment';
import SwiperCore, { Pagination, Navigation, Autoplay, A11y } from 'swiper';

import initializeStore from '@stores';
import theme from '@utils/styled-theme';
import globalModal from '@utils/global-modal';
import GlobalStyle from '@utils/global-styles';
import * as serviceWorker from './serviceWorker';
import client from './apollo';

import App from './app';

import 'react-quill/dist/quill.snow.css';
import 'swiper/swiper-bundle.min.css';
import '@utils/mask/mask.css';

globalModal();

moment.locale('ko');
SwiperCore.use([Pagination, Navigation, Autoplay, A11y]);

ReactDOM.render(
  // <React.StrictMode>
  <ApolloProvider client={client}>
    <MobxProvider {...initializeStore()}>
      <ConfigProvider locale={ko_KR}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <App />
        </ThemeProvider>
      </ConfigProvider>
    </MobxProvider>
  </ApolloProvider>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
