/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Divider,
  Input,
  Radio,
  Space,
  InputNumber,
} from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { toJS } from 'mobx';
import JSZip from 'jszip';

import useStore from '@stores/useStore';

import Description from '@Common/Description';
import Dropzone from '@Common/Dropzone';

const Wrapper = styled.div`
  width: 100%;
`;

const ModifyPP = observer(
  ({ propData = {}, handleSubmit, handleClose, isAdmin, isContentModify }) => {
    const { commonStore } = useStore();
    const state = useLocalStore(() => ({
      images: [],
      fileType: 'image',
      handleUpload: null,
      setHandleUpload(uploadFunc) {
        this.handleUpload = uploadFunc;
      },
      setParentImages(images) {
        this.images = images;
      },
      zipFile: null,

      // DB 데이터
      instaId: '',
      url: '',
      mainHashtag: '',
      hashtags: '1.',
      isBuyDirectComment: false,
      buyDirectCommentCnt: 0,
      comment: '1.',
      instaContentText: '1.',
    }));

    const handleChangeRadio = useCallback(
      (type) => (e) => {
        state[type] = e.target.value;

        if (type === 'isBuyDirectComment') {
          if (e.target.value) {
            state.buyDirectCommentCnt = 1;
            state.comment = '1.';
          } else {
            state.buyDirectCommentCnt = 0;
            state.comment = '';
          }
        }
      },
      [],
    );

    const handleChangeFile = useCallback(async (e) => {
      if (e.target.files && e.target.files[0]) {
        window.maskOn(300000);
        const file = e.target.files[0];
        e.target.value = '';
        const lastIdx = file.name.lastIndexOf('.');
        const ext = file.name.substring(lastIdx + 1);

        if (!/zip/i.test(ext)) {
          window.maskOff();
          return window.alert({ title: 'zip 파일이 아닙니다.' });
        }

        JSZip.loadAsync(file).then(
          function (zip) {
            const images = [];
            if (zip.files && Object.keys(zip.files).length < 30) {
              state.images = [];
              window.maskOff();
              return window.alert({
                title: '이미지 30장 이상을 업로드해 주세요',
              });
            } else if (zip.files) {
              zip.forEach(function (relativePath, zipEntry) {
                images.push(zipEntry);
              });

              state.images = images;
            } else {
              window.maskOff();
              return window.alert({
                title: '압축파일 내의 목록을 읽어올 수 없습니다.',
              });
            }

            file.status = 'load';
            state.zipFile = file;
            window.maskOff();
          },
          function (e) {
            window.maskOff();
            window.alert({ title: e.message });
          },
        );
      }
    }, []);

    const handleChangeInput = useCallback(
      (type) => (e) => {
        state[type] = e.target.value;
      },
      [],
    );

    const handleChangeInputNumber = useCallback(
      (type) => (value) => {
        state[type] = value;
      },
      [],
    );

    const handleCheckTextAreaRow = useCallback(
      (type) => (e) => {
        let len = 0;
        let lines = [];
        if (type === 'hashtags') {
          lines = state.hashtags.split('\n');
          len = 4;
        } else if (type === 'instaContentText') {
          lines = state.instaContentText.split('\n');
          len = lines.length;
        } else if (type === 'comment') {
          lines = state.comment.split('\n');
          len = state.buyDirectCommentCnt;
        }
        let values = [];
        for (let i = 0; i < len; i++) {
          if (lines[i] && lines[i].length) {
            if (!/^[0-9]+\./.test(lines[i])) {
              values.push(`${i + 1}.${lines[i]}`);
            } else {
              let dotIdx = lines[i].indexOf('.');
              values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
            }
          }
        }
        state[type] = values.join('\n');
      },
      [],
    );

    const handlePressEnter = useCallback(
      (type) => (e) => {
        e.persist();
        setTimeout(() => {
          const lines = state[type].split('\n');
          let values = [];
          for (let i = 0; i < lines.length; i++) {
            if (!/^[0-9]+\./.test(lines[i])) {
              values.push(`${i + 1}.${lines[i]}`);
            } else {
              let dotIdx = lines[i].indexOf('.');
              values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
            }
          }
          state[type] = values.join('\n');
        }, 10);
      },
      [],
    );

    const handleClickSubmit = useCallback(async () => {
      setTimeout(() => {
        // if (!state.instaId || !state.instaId.length) {
        //   return window.alert({ title: '인스타 아이디를 입력해 주세요.' });
        // }
        // if (!state.url || !state.url.length) {
        //   return window.alert({ title: '인스타 게시물 URL을 입력해 주세요.' });
        // }
        // if (!state.mainHashtag || !state.mainHashtag.length) {
        //   return window.alert({ title: '대표 해시태그를 입력해 주세요.' });
        // }
        // if (!state.hashtags || !state.hashtags.length) {
        //   return window.alert({ title: '부 해시태그를 입력해 주세요.' });
        // }

        const data = {
          mainHashtag: state.mainHashtag,
          // hashtags: toJS(state.hashtags).split('\n'),
        };

        if (propData.programType === '최적화아이디') {
          data.instaContentText = state.instaContentText;
          data.comment = state.comment;
          data.buyDirectCommentCnt = state.buyDirectCommentCnt;
          data.isBuyDirectComment = state.isBuyDirectComment;

          handleSubmit(propData.id, data, {
            uploadPath: `work/pp/${propData.company.id}`,
            fileType: state.fileType,
            images: toJS(state.images),
            zipFile: toJS(state.zipFile),
            handleUpload: toJS(state.handleUpload),
          });
        } else {
          data.instaId = state.instaId;
          data.url = state.url;
          handleSubmit(propData.id, data);
        }
      }, 10);
    }, [propData, handleSubmit]);

    useEffect(() => {
      if (propData.instaId) {
        state.instaId = propData.instaId;
      }
      if (propData.url) {
        state.url = propData.url;
      }
      if (propData.mainHashtag) {
        state.mainHashtag = propData.mainHashtag;
      }
      // if (propData.hashtags) {
      //   state.hashtags = propData.hashtags.join('\n');
      // }
      if (propData.fileType) {
        // if (propData.fileType === 'image') {
        //   state.images = propData.images || [];
        // } else {
        //   state.zipFile = propData.zipFile;
        // }
        state.images = propData.images || [];
      }
      if (propData.instaContentText) {
        state.instaContentText = propData.instaContentText;
      }
      if (propData.comment) {
        state.comment = propData.comment;
      }
      if (propData.comment) {
        state.comment = propData.comment;
      }
      if (propData.buyDirectCommentCnt) {
        state.buyDirectCommentCnt = propData.buyDirectCommentCnt;
      }
      state.isBuyDirectComment = Boolean(propData.isBuyDirectComment);
    }, [propData]);

    return (
      <Wrapper>
        <Row gutter={[10, 10]}>
          {propData.programType === '최적화아이디' ? (
            <>
              <Col xs={24}>
                <h4>
                  인스타 게시물 이미지 업로드 (
                  {state.fileType === 'image' ? 'jpg파일' : 'zip'})
                </h4>
                {/* <Description text="이미지는 최소 30장 이상 알집파일(zip)로 묶어서 업로드 해주세요" /> */}

                <Row gutter={[10, 10]} style={{ marginTop: 15 }}>
                  <Col xs={24}>
                    {state.fileType === 'image' && (
                      <Dropzone
                        multiple={true}
                        uploadPath={
                          propData.user.isTest
                            ? 'test'
                            : `work/pp/${
                                propData.company
                                  ? propData.company.id
                                  : 'no-company'
                              }`
                        }
                        setHandleUpload={state.setHandleUpload}
                        setParentImages={state.setParentImages}
                        images={toJS(state.images)}
                        width={100000}
                        height={100000}
                        descriptions={[
                          '위 사진들을 드래그하여 보여지는 순서를 변경할 수 있습니다.',
                        ]}
                        hideDescription={true}
                      />
                    )}
                    {state.fileType === 'zip' && (
                      <>
                        <input
                          id={`pp_file`}
                          accept=".zip"
                          type="file"
                          onChange={handleChangeFile}
                          style={{ display: 'none' }}
                        />
                        <Input
                          value={
                            state.zipFile
                              ? state.zipFile.filename
                                ? state.zipFile.filename
                                : state.zipFile.name
                              : null
                          }
                          disabled
                          addonAfter={
                            <Button
                              size="small"
                              type="text"
                              onClick={() =>
                                document.querySelector(`#pp_file`).click()
                              }
                              block
                            >
                              {state.zipFile ? '수정' : '업로드'}
                            </Button>
                          }
                        />
                      </>
                    )}
                  </Col>
                </Row>
              </Col>

              {isAdmin || isContentModify ? (
                <Col xs={24}>
                  <h4>인스타 게시물 내용 입력</h4>
                  <Input.TextArea
                    rows={4}
                    value={state.instaContentText}
                    onChange={handleChangeInput('instaContentText')}
                  />
                </Col>
              ) : null}

              {isAdmin && (
                <>
                  <Col xs={24}>
                    <h4>대표 해시태그</h4>
                    <Input
                      value={state.mainHashtag}
                      onChange={handleChangeInput('mainHashtag')}
                    />
                  </Col>
                  {/* <Col xs={24}>
                 <h4>부 해시태그</h4>
                 <Description text="해시태그 1~4개 입력(한줄에 하나씩)" />
                 <Input.TextArea
                   rows={4}
                   value={state.hashtags}
                   onChange={handleChangeInput('hashtags')}
                   onBlur={handleCheckTextAreaRow('hashtags')}
                   onPressEnter={handlePressEnter('hashtags')}
                 />
               </Col> */}
                </>
              )}
            </>
          ) : (
            <>
              <Col xs={24}>
                <h4>인스타 아이디</h4>
                <Input
                  value={state.instaId}
                  onChange={handleChangeInput('instaId')}
                />
              </Col>
              {propData.programType === '고객아이디' && (
                <Col xs={24}>
                  <h4>인스타 게시물 URL</h4>
                  <Input
                    value={state.url}
                    onChange={handleChangeInput('url')}
                  />
                </Col>
              )}

              <Col xs={24}>
                <h4>대표 해시태그</h4>
                <Input
                  value={state.mainHashtag}
                  onChange={handleChangeInput('mainHashtag')}
                />
              </Col>
              {/* <Col xs={24}>
              <h4>부 해시태그</h4>
              <Description text="해시태그 1~4개 입력(한줄에 하나씩)" />
              <Input.TextArea
                rows={4}
                value={state.hashtags}
                onChange={handleChangeInput('hashtags')}
                onBlur={handleCheckTextAreaRow('hashtags')}
                onPressEnter={handlePressEnter('hashtags')}
              />
            </Col> */}
            </>
          )}
        </Row>

        <Divider />

        {propData.programType === '최적화아이디' && (
          <>
            <h3>프로그램 추가옵션</h3>
            <Row gutter={[10, 10]}>
              <Col xs={24}>
                <Radio.Group
                  value={state.isBuyDirectComment}
                  onChange={handleChangeRadio('isBuyDirectComment')}
                >
                  <Radio value={true} disabled={true}>
                    직접입력댓글 사용
                  </Radio>
                  <Radio value={false}>사용안함</Radio>
                </Radio.Group>
              </Col>
              {state.isBuyDirectComment && (
                <>
                  <Col xs={24}>
                    <h4>직접입력댓글</h4>
                    <Space>
                      <InputNumber
                        min={1}
                        value={state.buyDirectCommentCnt}
                        onChange={handleChangeInputNumber(
                          'buyDirectCommentCnt',
                        )}
                      />
                      <span>개</span>
                    </Space>
                  </Col>
                  <Col xs={24}>
                    <h4>인스타 댓글 입력 (추가옵션)</h4>
                    <Description text="구매 개수 만큼 일괄입력(한줄에 하나씩)" />
                    <Input.TextArea
                      rows={5}
                      value={state.comment}
                      onChange={handleChangeInput('comment')}
                      onBlur={handleCheckTextAreaRow('comment')}
                      onPressEnter={handlePressEnter('comment')}
                    />
                  </Col>
                </>
              )}
            </Row>

            <Divider />
          </>
        )}

        <Row gutter={[10, 10]} justify="end">
          <Col>
            <Button onClick={handleClose} style={{ width: 120 }}>
              닫기
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={handleClickSubmit}
              style={{ width: 120 }}
            >
              저장
            </Button>
          </Col>
        </Row>
      </Wrapper>
    );
  },
);

export default ModifyPP;
