/* eslint-disable react-hooks/exhaustive-deps*/
import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const CARD_INFO_DATA = [
  ['삼성카드', '-', '-', '-', '500,000'],
  ['신한카드', '-', '-', '50,000', '-'],
  ['BC카드', '-', '-', '-', '-'],
  ['KB국민카드', '-', '4', '-', '1,000,000'],
  ['하나카드', '-', '-', '-', '-'],
  ['현대카드', '-', '-', '-', '-'],
  ['롯데카드', '100,000', '3', '300,000', '-'],
  ['NH카드', '100,000', '10', '500,000', '-'],
];

const Wrapper = styled.div`
  width: 100%;

  .card-info-table {
    width: 100%;
    border: 1px solid #e0e0e0;
    border-top: 3px solid #aaa;
    border-left: none;
    border-right: none;
    border-collapse: collapse;

    td {
      text-align: center;
      padding: 10px;
      border: 1px solid #e0e0e0;

      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
    }
    thead {
      td {
        background-color: #fafafa;
      }
    }
  }
`;

const CardInfoTable = observer((props) => {
  return (
    <Wrapper>
      <table className="card-info-table">
        <thead>
          <tr>
            <td rowSpan={2}>카드사</td>
            <td colSpan={4}>결제 제한 조건</td>
          </tr>
          <tr>
            <td>
              건당
              <br />
              승인금액
            </td>
            <td>
              일 최대
              <br />
              승인횟수
            </td>
            <td>
              일 최대
              <br />
              승인금액
            </td>
            <td>
              월 최대
              <br />
              승인금액
            </td>
          </tr>
        </thead>
        <tbody>
          {CARD_INFO_DATA.map((item, itemIdx) => (
            <tr key={`card_info_data_${itemIdx}`}>
              {item.map((data, dataIdx) => (
                <td key={`card_info_data_${itemIdx}_${dataIdx}`}>{data}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Wrapper>
  );
});

export default CardInfoTable;
