/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Divider,
  Input,
  InputNumber,
  Checkbox,
  Card,
  Select,
  Space,
} from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { toJS } from 'mobx';
import { useApolloClient, useMutation } from '@apollo/react-hooks';

import useStore from '@stores/useStore';
import {
  ADD_USE_PP,
  UPDATE_USE_PP,
  USE_PP,
} from '@shared/queries/UsePPQueries';
import { USERS } from '@shared/queries/UserQueries';

import CustomModal from '@Common/CustomModal';

const { Option } = Select;

const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;

  .ant-space-item {
    margin-bottom: 15px;
    min-width: 120px;
  }

  .cnt {
    font-size: 18px;
  }
  .per {
    font-size: 14px;

    &.green {
      color: #27ae60;
    }
    &.red {
      color: #c0392b;
    }
  }
`;

const UsePPModifyForm = observer(
  ({ propData = {}, modalOpen, handleModal, refetch, isAdmin }) => {
    const { commonStore } = useStore();
    const state = useLocalStore(() => ({
      users: [],
      selectedUser: null,

      // DB 데이터
      slots: [],

      likeCnt: null,
      commentCnt: null,
      followCnt: null,
      saveCnt: null,
      trafficCnt: null,
      slotCnt: null,
    }));

    const [add] = useMutation(ADD_USE_PP);
    const [update] = useMutation(UPDATE_USE_PP);
    const client = useApolloClient();

    const handleClose = useCallback(() => {
      if (isAdmin) {
        state.selectedUser = null;
        state.slots = [];
        state.likeCnt = null;
        state.commentCnt = null;
        state.followCnt = null;
        state.saveCnt = null;
        state.trafficCnt = null;
        state.slotCnt = null;
        handleModal(false)();
      }
    }, [isAdmin]);

    const fetchUsers = useCallback(async () => {
      const res = await client.query({
        query: USERS,
        variables: {
          findQuery: {
            $or: [
              { isAdmin: false, isBlock: false, isRemove: false },
              { isTest: true },
            ],
          },
        },
      });
      if (res.data && res.data.users) {
        state.users = res.data.users;
      }
    }, []);

    const checkData = useCallback(async (findQuery = {}) => {
      const result = await client.query({
        query: USE_PP,
        variables: {
          findQuery,
        },
      });

      if (result.data && result.data.usePP) {
        return true;
      }
      return false;
    }, []);

    const handleChangeUser = useCallback((value) => {
      state.selectedUser = value;
    }, []);

    const handleChangeCheck = useCallback(
      (idx) => (e) => {
        const slots = toJS(state.slots);
        slots[idx].isAutoTracking = e.target.checked;
        state.slots = slots;
      },
      [],
    );

    const handleChangeInput = useCallback(
      (type, idx) => (e) => {
        const slots = toJS(state.slots);
        slots[idx][type] = e.target.value;
        state.slots = slots;
      },
      [],
    );

    const handleChangeInputNumber = useCallback(
      (type, idx) => (value) => {
        if (type === 'counting' || type === 'maxCounting') {
          const slots = toJS(state.slots);
          if (!propData || (propData && !propData.id)) {
            slots[idx].counting = value;
            slots[idx].maxCounting = value;
          } else {
            slots[idx][type] = value;
          }
          state.slots = slots;
        } else {
          if (type === 'slotCnt') {
            makeSlot(value);
          }
          state[type] = value;
        }
      },
      [propData],
    );

    const handleCheckTextAreaRow = useCallback(
      (type, idx) => (e) => {
        const slots = toJS(state.slots);
        if (type === 'hashtags' || type === 'comment') {
          let len = 0;
          let lines = [];
          if (type === 'hashtags') {
            lines = e.target.value.split('\n');
            len = 20;
          } else if (type === 'comment') {
            lines = slots[idx].comment.split('\n');
            len = 5;
          }
          let values = [];
          for (let i = 0; i < len; i++) {
            if (lines[i] && lines[i].length) {
              if (!/^[0-9]+\./.test(lines[i])) {
                values.push(`${i + 1}.${lines[i]}`);
              } else {
                let dotIdx = lines[i].indexOf('.');
                values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
              }
            }
          }
          slots[idx][type] = values.join('\n');
        }
        setTimeout(() => {
          state.slots = slots;
        }, 10);
      },
      [],
    );

    const handleSubmit = useCallback(async () => {
      setTimeout(async () => {
        window.maskOn();
        let data;
        let check = false;

        if (isAdmin) {
          data = {
            slots: toJS(state.slots),
            likeCnt: state.likeCnt,
            commentCnt: state.commentCnt,
            followCnt: state.followCnt,
            saveCnt: state.saveCnt,
            trafficCnt: state.trafficCnt,
            slotCnt: state.slotCnt,
          };
          data.user = state.selectedUser;
          const users = toJS(state.users);
          const f = users.find((item) => String(item.id) === String(data.user));
          if (f) {
            data.userId = f.username;
            data.userName = f.name;
          }

          if (!f || !state.selectedUser) {
            window.maskOff();
            return window.alert({ title: '고객 정보를 찾을 수 없습니다.' });
          }

          check = await checkData({ user: data.user });
        } else {
          data = {
            slots: toJS(state.slots),
          };
        }

        for (let i = 0; i < data.slots.length; i++) {
          data.slots[i].hashtags = data.slots[i].hashtags.split('\n');
          if (
            !data.slots[i].hashtags ||
            !data.slots[i].hashtags[0] ||
            !data.slots[i].hashtags[0].length
          ) {
            data.slots[i].hashtags = [];
          }
        }

        if (propData && propData.id) {
          await update({
            variables: {
              id: propData.id,
              data,
            },
          });
        } else {
          // if (check) {
          //   window.maskOff();
          //   return window.alert({
          //     title: '이미 인기게시물 사용이 적용된 유저입니다.',
          //   });
          // }
          await add({
            variables: {
              data,
            },
          });
        }

        window.maskOff();
        if (refetch) {
          await refetch();
        }
        window.success({ title: '저장이 완료되었습니다.' });
        handleClose();
      }, 10);
    }, [propData, refetch, isAdmin]);

    const makeSlot = useCallback((slotCnt) => {
      const slots = toJS(state.slots);
      if (slotCnt > slots.length) {
        for (let i = slots.length; i < slotCnt; i++) {
          slots.push({
            instaId: '',
            url: '',
            hashtags: '',
            isAutoTracking: true,
            counting: null, //남은기간
            maxCounting: null, //기간
            rank: null,
          });
        }
      } else if (slotCnt < slots.length) {
        const cnt = slots.length - slotCnt;
        let idx = slots.length - 1;
        for (let i = 0; i < cnt; i++) {
          slots.splice(idx, 1);
          idx = slots.length - 1;
        }
      }

      state.slots = slots;
    }, []);

    useEffect(() => {
      if (modalOpen && propData) {
        if (propData.user) {
          state.selectedUser = propData.user.id;
        }
        if (propData.slots) {
          const slots = propData.slots;
          for (let i = 0; i < slots.length; i++) {
            if (slots[i].hashtags && slots[i].hashtags.length) {
              slots[i].hashtags = slots[i].hashtags.join('\n');
            } else {
              slots[i].hashtags = '';
            }
          }
          state.slots = slots;
        }
        if (propData.likeCnt) {
          state.likeCnt = propData.likeCnt;
        }
        if (propData.commentCnt) {
          state.commentCnt = propData.commentCnt;
        }
        if (propData.followCnt) {
          state.followCnt = propData.followCnt;
        }
        if (propData.saveCnt) {
          state.saveCnt = propData.saveCnt;
        }
        if (propData.trafficCnt) {
          state.trafficCnt = propData.trafficCnt;
        }
        if (propData.slotCnt) {
          state.slotCnt = propData.slotCnt;
        }
      }
    }, [modalOpen, propData]);

    useEffect(() => {
      if (modalOpen) {
        if (isAdmin) {
          fetchUsers();
        }
      } else {
        state.selectedUser = null;
        state.slots = [];
        state.likeCnt = null;
        state.commentCnt = null;
        state.followCnt = null;
        state.saveCnt = null;
        state.trafficCnt = null;
        state.slotCnt = null;
      }
    }, [modalOpen, isAdmin]);

    return (
      <Wrapper>
        <Row gutter={[10, 20]}>
          {isAdmin && (
            <>
              <Col xs={24}>
                <Select
                  value={state.selectedUser}
                  showSearch
                  style={{ width: 240 }}
                  placeholder="고객선택"
                  optionFilterProp="children"
                  onChange={handleChangeUser}
                  // filterOption={(input, option) =>
                  //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  //   0
                  // }
                >
                  {toJS(state.users).map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.username}/{item.name}
                    </Option>
                  ))}
                </Select>
                <Divider />
              </Col>
              <Col xs={24}>
                <Row gutter={[10, 10]}>
                  <Col xs={12} lg={4}>
                    <h4>좋아요개수</h4>
                    <InputNumber
                      value={state.likeCnt}
                      onChange={handleChangeInputNumber('likeCnt')}
                    />
                  </Col>
                  <Col xs={12} lg={4}>
                    <h4>댓글개수</h4>
                    <InputNumber
                      value={state.commentCnt}
                      onChange={handleChangeInputNumber('commentCnt')}
                    />
                  </Col>
                  <Col xs={12} lg={4}>
                    <h4>팔로우개수</h4>
                    <InputNumber
                      value={state.followCnt}
                      onChange={handleChangeInputNumber('followCnt')}
                    />
                  </Col>
                  <Col xs={12} lg={4}>
                    <h4>저장개수</h4>
                    <InputNumber
                      value={state.saveCnt}
                      onChange={handleChangeInputNumber('saveCnt')}
                    />
                  </Col>
                  <Col xs={12} lg={4}>
                    <h4>트래픽횟수</h4>
                    <InputNumber
                      value={state.trafficCnt}
                      onChange={handleChangeInputNumber('trafficCnt')}
                    />
                  </Col>
                  <Col xs={12} lg={4}>
                    <h4>슬롯개수</h4>
                    <InputNumber
                      value={state.slotCnt}
                      onChange={handleChangeInputNumber('slotCnt')}
                    />
                  </Col>
                </Row>
              </Col>
            </>
          )}

          {propData && propData.id && (
            <Col xs={24}>
              {isAdmin && <Divider />}
              <Space size={20} style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div>
                  <h4>총등록수</h4>
                  <div className="cnt">{propData.rankAllCnt || 0}</div>
                </div>
                <div>
                  <h4>10위 내 노출 수</h4>
                  <div>
                    <span className="cnt">{propData.rank10Cnt || 0}</span>
                    <span className="per green">
                      (
                      {(propData.rank10Cnt / propData.rankAllCnt) * 100
                        ? Number(
                            (propData.rank10Cnt / propData.rankAllCnt) * 100,
                          ).toFixed(1)
                        : 0}
                      %)
                    </span>
                  </div>
                </div>
                <div>
                  <h4>20위 내 노출 수</h4>
                  <div>
                    <span className="cnt">{state.rank20Cnt}</span>
                    <span className="per green">
                      (
                      {(propData.rank20Cnt / propData.rankAllCnt) * 100
                        ? Number(
                            (propData.rank20Cnt / propData.rankAllCnt) * 100,
                          ).toFixed(1)
                        : 0}
                      %)
                    </span>
                  </div>
                </div>
                <div>
                  <h4>누락(150위 밖) 수</h4>
                  <div>
                    <span className="cnt">{propData.rankOmissionCnt}</span>
                    <span className="per red">
                      (
                      {(propData.rankOmissionCnt / propData.rankAllCnt) * 100
                        ? Number(
                            (propData.rankOmissionCnt / propData.rankAllCnt) *
                              100,
                          ).toFixed(1)
                        : 0}
                      %)
                    </span>
                  </div>
                </div>
              </Space>
            </Col>
          )}

          <Col xs={24}>
            {toJS(state.slots) && toJS(state.slots).length ? <Divider /> : null}
            {toJS(state.slots).map((item, idx) => (
              <Row gutter={[10, 10]} key={`usepp_slots_${idx}`}>
                <Col xs={24}>
                  <Row gutter={[10, 20]}>
                    <Col xs={10}>
                      <h4>슬롯 {idx + 1}</h4>
                      <Checkbox
                        checked={item.isAutoTracking}
                        onChange={handleChangeCheck(idx)}
                      >
                        고객 추적자동
                      </Checkbox>
                    </Col>
                    <Col xs={14}>
                      <h4>게시글 순위</h4>
                      {item.rank ? <strong>{item.rank}위</strong> : '없음'}
                    </Col>
                    <Col xs={24}>
                      <Space>
                        <div>
                          <h4>남은기간</h4>
                          <InputNumber
                            value={item.counting}
                            onChange={handleChangeInputNumber('counting', idx)}
                            readOnly={!isAdmin}
                          />
                        </div>
                        <div>
                          <h4>기간</h4>
                          <InputNumber
                            value={item.maxCounting}
                            onChange={handleChangeInputNumber(
                              'maxCounting',
                              idx,
                            )}
                            readOnly={!isAdmin}
                          />
                        </div>
                      </Space>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} lg={6}>
                  <h4>인스타 아이디</h4>
                  <Input
                    value={item.instaId}
                    onChange={handleChangeInput('instaId', idx)}
                  />
                </Col>
                {!item.isAutoTracking && (
                  <Col xs={24}>
                    <h4>인스타 URL</h4>
                    <Input
                      value={item.url}
                      onChange={handleChangeInput('url', idx)}
                    />
                  </Col>
                )}
                <Col xs={24}></Col>
                <Col xs={24} lg={12}>
                  <Card size="small" title="해시태그">
                    <Input.TextArea
                      rows={5}
                      value={item.hashtags}
                      onChange={handleChangeInput('hashtags', idx)}
                      onBlur={handleCheckTextAreaRow('hashtags', idx)}
                    />
                  </Card>
                </Col>
                <Col xs={24}>
                  <Divider />
                </Col>
              </Row>
            ))}
          </Col>
        </Row>

        <Divider />

        <Row gutter={[10, 10]} justify="end">
          {isAdmin && (
            <Col>
              <Button onClick={handleClose} style={{ width: 120 }}>
                닫기
              </Button>
            </Col>
          )}
          <Col>
            <Button
              type="primary"
              onClick={handleSubmit}
              style={{ width: 120 }}
            >
              저장
            </Button>
          </Col>
        </Row>
      </Wrapper>
    );
  },
);

export default UsePPModifyForm;
