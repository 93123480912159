/* eslint-disable react-hooks/exhaustive-deps*/
import React from 'react';
import {
  HomeOutlined,
  CreditCardOutlined,
  UserOutlined,
  InfoCircleOutlined,
  DesktopOutlined,
  PictureOutlined,
  ProfileOutlined,
  SettingOutlined,
  WalletOutlined,
  IdcardOutlined,
  ClusterOutlined,
} from '@ant-design/icons';

export default [
  {
    Icon: () => <HomeOutlined />,
    label: 'HOME',
    link: '/',
    key: 'home',
  },
  {
    Icon: () => <UserOutlined />,
    label: '회원 관리',
    link: '/memberStatus',
    key: 'memberStatus',
  },
  {
    Icon: () => <CreditCardOutlined />,
    label: '매출 관리',
    link: '/saleStatus',
    key: 'saleStatus',
  },
  {
    Icon: () => <InfoCircleOutlined />,
    label: '게시판 관리',
    link: '/board',
    key: 'board',
  },
  {
    Icon: () => <DesktopOutlined />,
    label: '작업현황 관리',
    link: '/workStatus',
    key: 'workStatus',
  },
  {
    Icon: () => <IdcardOutlined />,
    label: '계정 관리',
    link: '/account',
    key: 'account',
  },
  {
    Icon: () => <ProfileOutlined />,
    label: '상품 관리',
    link: '/product',
    key: 'product',
  },
  {
    Icon: () => <WalletOutlined />,
    label: '포인트 관리',
    link: '/point',
    key: 'point',
  },
  {
    Icon: () => <PictureOutlined />,
    label: '배너 관리',
    link: '/banner',
    key: 'banner',
  },
  {
    Icon: () => <SettingOutlined />,
    label: '환경설정',
    link: '/config',
    key: 'config',
  },
  {
    Icon: () => <ClusterOutlined />,
    label: '관리자전용',
    link: '/adminSetting',
    key: 'adminSetting',
  },
];
