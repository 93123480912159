/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Tabs } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { toJS } from 'mobx';

import useStore from '@stores/useStore';

import UserInfoModify from './UserInfoModify';
import PointList from './Point/PointList';
import PointCharging from './Point/PointCharging';

const { TabPane } = Tabs;
const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  width: 100%;
`;

const Mypage = observer(() => {
  const router = useHistory();
  const screen = useBreakpoint();
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    tab: '1',
  }));

  const handleChangeTab = useCallback((key) => {
    state.tab = key;
  }, []);

  useEffect(() => {
    if (router.location.query && router.location.query.tab) {
      state.tab = `${router.location.query.tab}`;
    }
  }, [router.location]);

  useEffect(() => {
    if (!commonStore.user) {
      router.push('/');
    }
  }, []);

  return (
    <Wrapper>
      {commonStore.user && (
        <Tabs
          activeKey={state.tab}
          onChange={handleChangeTab}
          tabPosition={screen.lg ? 'left' : 'top'}
        >
          <TabPane tab="회원정보수정" key="1">
            <UserInfoModify />
          </TabPane>
          <TabPane tab="결제내역" key="2">
            <PointList tab={state.tab} />
          </TabPane>
          <TabPane tab="포인트 충전" key="3">
            <PointCharging tab={state.tab} />
          </TabPane>
        </Tabs>
      )}
    </Wrapper>
  );
});

export default Mypage;
