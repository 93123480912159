/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback, useMemo } from 'react';
import {
  Table,
  Popover,
  Button,
  Input,
  Select,
  Grid,
  Descriptions,
  Row,
  Col,
} from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import moment from 'moment';
import { useApolloClient, useQuery, useMutation } from '@apollo/react-hooks';
import { toJS } from 'mobx';

import useStore from '@stores/useStore';
import { WORKS, REMOVE_WORK, UPDATE_WORK } from '@shared/queries/WorkQueries';

import SlotModify from './SlotModify';
import SlotRank from './SlotRank';
import SlotDetail from './SlotDetail';

const { Search } = Input;
const { Option } = Select;
const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  width: 100%;

  .error {
    color: ${(props) => (props.error ? '#e74c3c' : props.theme.primaryColor)};
  }
`;

const SlotList = observer(
  ({ workType, findQuery, isRefetchWorkList, setRefetchWorkList }) => {
    const screen = useBreakpoint();
    const { commonStore } = useStore();
    const state = useLocalStore(() => ({
      list: [],
      paginate: {
        docs: [],
        totalDocs: 0,
        limit: 10,
        page: 1,
        totalPages: 1,
      },

      searchType: null,
      startDate: null,
      endDate: null,

      modifyData: null,
      modifyModalOpen: false,
      modifyModalTitle: '',

      rankData: null,
      rankModalOpen: false,

      selectedRowKeys: [],
      selectedList: [],

      detailData: null,
      detailOpen: false,
    }));

    const client = useApolloClient();
    const [update] = useMutation(UPDATE_WORK);
    const [remove] = useMutation(REMOVE_WORK);

    const fetchData = useCallback(
      async (findQuery = {}) => {
        window.maskOn();

        findQuery.workType = { $regex: workType, $options: 'ig' };
        const result = await client.query({
          query: WORKS,
          variables: {
            page: state.paginate.page,
            limit: state.paginate.limit,
            findQuery,
          },
        });

        if (result.data && result.data.works) {
          state.paginate = result.data.works;
          state.list = result.data.works.docs;
        }
        window.maskOff();
      },
      [workType],
    );

    const handleChangePage = useCallback(
      (page) => {
        if (state.paginate.page !== page) {
          state.paginate.page = page;
          fetchData(findQuery);
        }
      },
      [workType, findQuery],
    );

    const handleModifyModal = useCallback(
      (modalOpen, modalTitle, data) => () => {
        if (modalTitle) {
          state.modifyModalTitle = `${
            data.company ? data.company.name : ''
          } ${modalTitle} 관리설정`;
        }
        if (modalOpen && data) {
          state.modifyData = data;
        } else {
          state.modifyData = null;
        }
        state.modifyModalOpen = modalOpen;
      },
      [],
    );

    const handleRankModal = useCallback(
      (modalOpen, data) => (e) => {
        if (modalOpen && data) {
          state.rankData = data;
        } else {
          state.rankData = {};
        }
        state.rankModalOpen = modalOpen;
      },
      [],
    );

    const handleDetailModal = useCallback(
      (modalOpen, data) => (e) => {
        if (modalOpen && data) {
          state.detailData = data;
        } else {
          state.detailData = {};
        }
        state.detailOpen = modalOpen;
      },
      [],
    );

    const handleRun = useCallback(
      (row) => (e) => {
        return window.ask({
          title: '계속 진행하시겠습니까?',
          content: `${row.company.name} ${row.workType} 작업을 일시정지 하시겠습니까?`,
          async onOk() {
            await update({
              variables: {
                id: row.id,
                data: {
                  isActivated: !row.isActivated,
                },
              },
            });
            fetchData(findQuery);
          },
        });
      },
      [findQuery],
    );

    const handleRemove = useCallback(
      (row, isAll) => () => {
        window.ask({
          title: '계속 진행하시겠습니까?',
          content: '선택하신 작업이 삭제됩니다.',
          async onOk() {
            if (isAll) {
              const ids = toJS(state.selectedRowKeys);
              for (const id of ids) {
                await remove({ variables: { id } });
              }
            } else {
              await remove({ variables: { id: row.id } });
            }
            fetchData(findQuery);
          },
        });
      },
      [findQuery],
    );

    useEffect(() => {
      if (isRefetchWorkList && setRefetchWorkList) {
        setRefetchWorkList(false);
        fetchData(findQuery);
      }
    }, [isRefetchWorkList, setRefetchWorkList, findQuery]);

    useEffect(() => {
      fetchData(findQuery);
    }, [workType, findQuery]);

    const columns = useMemo(() => {
      let value = [
        {
          title: '날짜',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (createdAt) =>
            createdAt ? moment(createdAt).format('YYYY-MM-DD') : '',
          align: 'center',
        },
        {
          title: '인스타아이디',
          dataIndex: 'instaId',
          key: 'instaId',
          render: (_, row) => row.instaId,
          align: 'center',
        },
        {
          title: 'URL',
          dataIndex: 'url',
          key: 'url',
          render: (_, row) => row.url,
          align: 'center',
        },
        {
          title: '해시태그',
          dataIndex: 'hashtags',
          key: 'hashtags',
          render: (_, row) => (
            <>
              {row.hashtags.length > 1 ? (
                <Popover
                  content={
                    <div>
                      {row.hashtags.map((hashtag, idx) => (
                        <div key={`${row.id}_hashtag_${idx}`}>{hashtag}</div>
                      ))}
                    </div>
                  }
                  title=""
                >
                  {row.hashtags[0]}...
                </Popover>
              ) : row.hashtags.length === 1 ? (
                <div>{row.hashtags[0]}</div>
              ) : (
                <div>&nbsp;</div>
              )}
            </>
          ),
          align: 'center',
        },
        {
          title: '순위',
          dataIndex: 'rank',
          key: 'rank',
          render: (_, row) => (
            <Button onClick={handleRankModal(true, row)}>자세히</Button>
          ),
          align: 'center',
        },
        {
          title: '게시물개수',
          dataIndex: 'postCnt',
          key: 'postCnt',
          render: (_, row) => row.postCnt,
          align: 'center',
        },
        {
          title: '트래픽횟수',
          dataIndex: 'trafficCnt',
          key: 'trafficCnt',
          render: (_, row) => row.trafficCnt,
          align: 'center',
        },
        {
          title: '좋아요개수',
          dataIndex: 'likeCnt',
          key: 'likeCnt',
          render: (_, row) => row.likeCnt,
          align: 'center',
        },
        {
          title: '저장개수',
          dataIndex: 'saveCnt',
          key: 'saveCnt',
          render: (_, row) => row.saveCnt,
          align: 'center',
        },
        {
          title: '팔로우개수',
          dataIndex: 'followCnt',
          key: 'followCnt',
          render: (_, row) => row.followCnt,
          align: 'center',
        },
        {
          title: '댓글개수',
          dataIndex: 'commentCnt',
          key: 'commentCnt',
          render: (_, row) => row.commentCnt,
          align: 'center',
        },
        {
          title: '진행할 일수',
          dataIndex: 'maxCounting',
          key: 'maxCounting',
          render: (_, row) => row.maxCounting,
          align: 'center',
        },
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          render: (_, row) => (
            <>
              <Button
                type="primary"
                block
                style={{ marginBottom: 5 }}
                onClick={handleModifyModal(true, '도배형 인기게시물', row)}
              >
                관리설정
              </Button>
              <Button
                block
                style={{ marginBottom: 5 }}
                onClick={handleDetailModal(true, row)}
              >
                작업내역
              </Button>
              <Button
                type="primary"
                danger
                block
                onClick={handleRun(row)}
                disabled={!row.isActivated}
              >
                일시정지
              </Button>
            </>
          ),
          align: 'center',
          width: 120,
        },
      ];

      return value;
    }, []);

    const mobileColumns = useMemo(
      () => [
        {
          title: '',
          dataIndex: 'idx',
          key: 'idx',
          render: (_, row, idx) => {
            return (
              <Descriptions size="small" bordered column={1}>
                <Descriptions.Item label="날짜">
                  {row.createdAt
                    ? moment(row.createdAt).format('YYYY-MM-DD')
                    : ''}
                </Descriptions.Item>
                <Descriptions.Item label="인스타아이디">
                  {row.instaId}
                </Descriptions.Item>
                <Descriptions.Item label="URL">{row.url}</Descriptions.Item>
                <Descriptions.Item label="해시태그">
                  <>
                    {row.hashtags.length > 1 ? (
                      <Popover
                        content={
                          <div>
                            {row.hashtags.map((hashtag, idx) => (
                              <div key={`${row.id}_hashtag_${idx}`}>
                                {hashtag}
                              </div>
                            ))}
                          </div>
                        }
                        title=""
                      >
                        {row.hashtags[0]}...
                      </Popover>
                    ) : row.hashtags.length === 1 ? (
                      <div>{row.hashtags[0]}</div>
                    ) : (
                      <div>&nbsp;</div>
                    )}
                  </>
                </Descriptions.Item>
                <Descriptions.Item label="순위">
                  <Button onClick={handleRankModal(true, row)}>자세히</Button>
                </Descriptions.Item>
                <Descriptions.Item label="게시물개수">
                  {row.postCnt}
                </Descriptions.Item>
                <Descriptions.Item label="트래픽횟수">
                  {row.trafficCnt}
                </Descriptions.Item>
                <Descriptions.Item label="좋아요개수">
                  {row.likeCnt}
                </Descriptions.Item>
                <Descriptions.Item label="댓글개수">
                  {row.commentCnt}
                </Descriptions.Item>
                <Descriptions.Item label="저장개수">
                  {row.saveCnt}
                </Descriptions.Item>
                <Descriptions.Item label="팔로우개수">
                  {row.followCnt}
                </Descriptions.Item>

                <Descriptions.Item label="">
                  <Button
                    block
                    style={{ marginBottom: 5 }}
                    onClick={handleDetailModal(true, row)}
                  >
                    작업내역
                  </Button>
                  <Button
                    type="primary"
                    danger
                    block
                    onClick={handleRun(row)}
                    disabled={!row.isActivated}
                  >
                    일시정지
                  </Button>
                </Descriptions.Item>
              </Descriptions>
            );
          },
        },
      ],
      [],
    );

    const rowSelection = {
      type: 'checkbox',
      onChange: (selectedRowKeys, selectedRows) => {
        if (selectedRows.length) {
          state.selectedList = selectedRows;
          state.selectedRowKeys = selectedRows.map((item) => item.id);
        } else {
          state.selectedList = [];
          state.selectedRowKeys = [];
        }
      },
      selectedRowKeys: toJS(state.selectedRowKeys),
    };

    return (
      <Wrapper>
        <Row gutter={[10, 10]} justify="end" style={{ marginBottom: 15 }}>
          <Col>
            <Button type="primary" onClick={handleModifyModal(true)}>
              슬롯추가
            </Button>
          </Col>
          <Col>
            <Button
              danger
              onClick={handleRemove(null, true)}
              disabled={!state.selectedRowKeys.length}
            >
              선택삭제
            </Button>
          </Col>
        </Row>

        <Table
          size="small"
          dataSource={toJS(state.list)}
          columns={columns}
          scroll={{ x: 1400 }}
          pagination={{
            current: state.paginate.page,
            total: state.paginate.totalDocs,
            showSizeChanger: false,
            onChange: handleChangePage,
          }}
          rowKey={(row) => row.id}
          rowSelection={rowSelection}
          bordered
        />

        <SlotModify
          workType={workType}
          propData={toJS(state.modifyData)}
          modalTitle={state.modifyModalTitle}
          modalOpen={state.modifyModalOpen}
          handleModal={handleModifyModal}
          refetch={fetchData}
        />

        {state.rankData && state.rankData.id && (
          <SlotRank
            propData={toJS(state.rankData)}
            modalOpen={state.rankModalOpen}
            handleModal={handleRankModal}
          />
        )}

        {state.detailData && state.detailData.id && (
          <SlotDetail
            propData={toJS(state.detailData)}
            modalOpen={state.detailOpen}
            handleModal={handleDetailModal}
          />
        )}
      </Wrapper>
    );
  },
);

export default SlotList;
