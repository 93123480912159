import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation Login($data: InputLogin!) {
    login(data: $data) {
      error
      token
    }
  }
`;

export const META = gql`
  query Meta($key: String!) {
    meta(key: $key) {
      key
      value
    }
  }
`;

export const METAS = gql`
  query Metas($keys: [String]) {
    metas(keys: $keys) {
      key
      value
    }
  }
`;

export const METAS_REGEXP = gql`
  query MetasRegexp($key: String!) {
    metasRegexp(key: $key) {
      key
      value
    }
  }
`;

export const SAVE_META = gql`
  mutation SaveMeta($key: String!, $data: InputMeta) {
    saveMeta(key: $key, data: $data) {
      key
      value
    }
  }
`;

export const SINGLE_FILE_UPLOAD = gql`
  mutation SingleFileUpload($data: InputUpload!) {
    singleFileUpload(data: $data) {
      uid
      name
      url
      status
    }
  }
`;

export const MULTI_FILE_UPLOAD = gql`
  mutation MultiFileUpload($datas: [InputUpload!]) {
    multiFileUpload(datas: $datas) {
      uid
      name
      url
      status
    }
  }
`;

export const SINGLE_FILE_REMOVE = gql`
  mutation SingleFileRemove($Key: String!) {
    singleFileRemove(Key: $Key)
  }
`;

export const MULTI_FILE_REMOVE = gql`
  mutation MultiFileRemove($Keys: [String!]) {
    multiFileRemove(Keys: $Keys)
  }
`;

export const DEFAULT_ZIP_UPLOAD = gql`
  mutation DefaultZipUpload($data: InputUpload!) {
    defaultZipUpload(data: $data)
  }
`;

export const DEFAULT_ZIP_REMOVE = gql`
  mutation DefaultZipRemove($path: String) {
    defaultZipRemove(path: $path)
  }
`;

export const UPLOAD_PROGRESS = gql`
  subscription UploadProgress($uploadPath: String!, $progressUid: String) {
    uploadProgress(uploadPath: $uploadPath, progressUid: $progressUid)
  }
`;
