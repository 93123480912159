/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import {
  Row,
  Col,
  Input,
  InputNumber,
  Select,
  Button,
  Divider,
  Form,
  Grid,
  Space,
  Affix,
} from 'antd';
import styled from 'styled-components';
import { observer, useLocalStore } from 'mobx-react';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { toJS } from 'mobx';

import { METAS_REGEXP, SAVE_META } from '@shared/queries';
import { validateMessages } from '@utils/customFormValidator';

import Description from '@Common/Description';

const { Option } = Select;
const { TextArea } = Input;
const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  width: 100%;

  .ant-form-item-label {
    font-weight: bold;
  }
`;

const BoardConfig = observer(() => {
  const [form] = Form.useForm();
  const screens = useBreakpoint();

  const state = useLocalStore(() => ({}));

  const client = useApolloClient();
  const [save] = useMutation(SAVE_META);

  const fetchData = useCallback(async () => {
    const result = await client.query({
      query: METAS_REGEXP,
      variables: { key: '^board_' },
    });

    if (result.data && result.data.metasRegexp) {
      for (const meta of result.data.metasRegexp) {
        const key = meta.key.replace('board_', '');
        form.setFieldsValue({
          [key]: meta.value,
        });
      }
    }
  }, []);

  const handleSubmit = useCallback(async (values) => {
    window.maskOn();

    for (const key in values) {
      await save({
        variables: {
          key: `board_${key}`,
          data: {
            key: `board_${key}`,
            value: values[key],
          },
        },
      });
    }

    await fetchData();
    window.maskOff();
    window.success({ title: '게시판 설정 저장이 완료되었습니다. ' });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Wrapper>
      <Form
        form={form}
        name="board_config_form"
        colon={false}
        labelAlign="left"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 10 }}
        layout={screens.lg ? 'horizontal' : 'vertical'}
        initialValues={{
          page_size: 15,
          write_interval: 30,
          ban_word: '',
        }}
        validateMessages={validateMessages}
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={[32, 16]}>
          <Col xs={24} lg={20}>
            <Description text="목록 한페이지당 라인수" />
            <Form.Item label="페이지 라인수">
              <Space size={10} align="baseline">
                <Form.Item name="page_size">
                  <InputNumber />
                </Form.Item>
                <span>라인</span>
              </Space>
            </Form.Item>

            <Divider />

            <Form.Item label="글쓰기 간격">
              <Space size={10} align="baseline">
                <Form.Item name="write_interval">
                  <InputNumber />
                </Form.Item>
                <span>초 지난 후 가능</span>
              </Space>
            </Form.Item>

            <Divider />

            <Description text="입력된 단어가 포함된 내용은 게시할 수 없습니다." />
            <Description text="단어와 단어 사이는 , 로 구분합니다." />
            <Form.Item label="단어 필터링" name="ban_word">
              <TextArea rows={5} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={4}>
            <Affix offsetTop={80}>
              <Form.Item noStyle>
                <Button type="primary" size="large" htmlType="submit" block>
                  저장
                </Button>
              </Form.Item>
            </Affix>
          </Col>
        </Row>
      </Form>
    </Wrapper>
  );
}, []);

export default BoardConfig;
