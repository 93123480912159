/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { Form, Input, Select, Button } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import { useMutation } from '@apollo/react-hooks';
import { toJS } from 'mobx';
import styled from 'styled-components';

import { UPDATE_POINT_LOG } from '@shared/queries/PointLogQueries';
import useStore from '@stores/useStore';
import { validateMessages } from '@utils/customFormValidator';
import { BANKS } from '@shared/constants';

const { Option } = Select;

const Wrapper = styled.div`
  width: 100%;
`;

const RefundInfoForm = observer(({ propData = {}, handleClose, refetch }) => {
  const [form] = Form.useForm();

  const { commonStore } = useStore();
  const state = useLocalStore(() => ({}));

  const [update] = useMutation(UPDATE_POINT_LOG);

  const handleSubmit = useCallback(
    async (values) => {
      window.maskOn();
      const data = {
        ...values,
        status: '환불요청',
      };

      if (data.refundAccount && data.refundAccount.refund_bank) {
        const f = BANKS.find(
          (item) => item.value === data.refundAccount.refund_bank,
        );
        if (f) {
          data.refundAccount.refund_bankName = f.label;
        }
      }

      if (propData.id) {
        await update({
          variables: { id: propData.id, data },
        });
      }
      if (refetch) {
        await refetch();
      }
      form.resetFields();
      window.maskOff();
      handleClose();
      window.success({
        title: `${propData.orderId} - 환불요청이 완료되었습니다.`,
      });
    },
    [propData, refetch, handleClose],
  );

  useEffect(() => {
    if (form) {
      form.resetFields();
    }
  }, [propData, form]);

  return (
    <Wrapper>
      <h4>{propData.orderId} - 환불요청</h4>
      <Form
        form={form}
        layout="vertical"
        name="user_form"
        initialValues={{
          refundAccount: {},
          refundReason: '단순변심',
        }}
        validateMessages={validateMessages}
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Form.Item
          name={['refundAccount', 'refund_holder']}
          label="예금주"
          rules={[{ required: true }]}
        >
          <Input size="large" />
        </Form.Item>

        <Form.Item
          name={['refundAccount', 'refund_bank']}
          label="은행명"
          rules={[{ required: true }]}
        >
          <Select size="large" style={{ width: '100%' }}>
            {BANKS.map((item, idx) => (
              <Option value={item.value} key={`bank_${idx}`}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name={['refundAccount', 'refund_account']}
          label="계좌번호"
          rules={[{ required: true }]}
        >
          <Input size="large" />
        </Form.Item>

        <Form.Item
          name="refundReason"
          label="취소사유"
          rules={[{ required: true }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item style={{ marginTop: 30 }}>
          <Button danger ghost size="large" htmlType="submit" block>
            환불요청
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  );
});

export default RefundInfoForm;
