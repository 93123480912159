/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Divider } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';

import {
  SALE_DASHBOARD,
  REFUND_DASHBOARD,
} from '@shared/queries/DashboardQueries';
import { numberWithCommas } from '@utils/common';

const Wrapper = styled.div`
  width: 100%;

  .highlight {
    font-size: 32px;
    font-weight: bold;
  }
  .ant-col {
    text-align: center;
  }
  .sub-text {
    margin-top: 15px;
  }
`;

const SaleDashboard = observer(() => {
  const state = useLocalStore(() => ({
    data: {},
    refund: {},
  }));
  const queryRes = useQuery(SALE_DASHBOARD);
  const refundRes = useQuery(REFUND_DASHBOARD);

  useEffect(() => {
    if (queryRes.data && queryRes.data.saleDashboard) {
      state.data = queryRes.data.saleDashboard;
    }
  }, [queryRes.data]);

  useEffect(() => {
    if (refundRes.data && refundRes.data.refundDashboard) {
      state.refund = refundRes.data.refundDashboard;
    }
  }, [refundRes.data]);

  return (
    <Wrapper>
      <Card
        title={<Link to={{ pathname: `/saleStatus` }}>매출 관리</Link>}
        extra={<Link to={{ pathname: `/saleStatus` }}>자세히보기 {'>'}</Link>}
      >
        <h4>
          <Link to={{ pathname: `/saleStatus` }}>매출현황</Link>
        </h4>
        <Row>
          <Col xs={24} lg={8} style={{ borderRight: '1px solid #e0e0e0' }}>
            <Link to={{ pathname: `/saleStatus`, query: { subTab: '1' } }}>
              <div>
                <span className="highlight">
                  {numberWithCommas(state.data.today, true)}
                </span>{' '}
                원
              </div>
              <div className="sub-text">금일 매출내역</div>
            </Link>
          </Col>
          <Col xs={24} lg={8} style={{ borderRight: '1px solid #e0e0e0' }}>
            <Link to={{ pathname: `/saleStatus`, query: { subTab: '2' } }}>
              <div>
                <span className="highlight">
                  {numberWithCommas(state.data.week, true)}
                </span>{' '}
                원
              </div>
              <div className="sub-text">한 주 매출내역</div>
            </Link>
          </Col>
          <Col xs={24} lg={8}>
            <Link to={{ pathname: `/saleStatus`, query: { subTab: '3' } }}>
              <div>
                <span className="highlight">
                  {numberWithCommas(state.data.month, true)}
                </span>{' '}
                원
              </div>
              <div className="sub-text">월 별 매출내역</div>
            </Link>
          </Col>
        </Row>

        <Divider />

        <h4>
          <Link to={{ pathname: `/saleStatus`, query: { tab: '2' } }}>
            환불처리
          </Link>
        </h4>
        <Row>
          <Col xs={24} lg={6} style={{ borderRight: '1px solid #e0e0e0' }}>
            <Link to={{ pathname: `/saleStatus`, query: { tab: '2' } }}>
              <div>
                <span className="highlight">{state.refund.refundCnt || 0}</span>{' '}
                건
              </div>
              <div className="sub-text">환불 요청</div>
            </Link>
          </Col>
          <Col xs={24} lg={6} style={{ borderRight: '1px solid #e0e0e0' }}>
            <Link to={{ pathname: `/saleStatus`, query: { tab: '2' } }}>
              <div>
                <span className="highlight">
                  {state.refund.refundDoneCnt || 0}
                </span>{' '}
                건
              </div>
              <div className="sub-text">환불 완료</div>
            </Link>
          </Col>
        </Row>
      </Card>
    </Wrapper>
  );
});

export default SaleDashboard;
