import React from 'react';
import { Modal } from 'antd';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  min-width: ${(props) => (props.minwidth ? props.minwidth : 800)}px !important;
  max-width: ${(props) =>
    props.maxwidth ? props.maxwidth : 'none'} !important;

  ${(props) => props.theme.breakpoint('xs', 'lg')`
    min-width: 50% !important;
    max-width: 100% !important;
  `}
`;

const CustomModal = observer((props) => {
  return <StyledModal {...props}>{props.children}</StyledModal>;
});

export default CustomModal;
