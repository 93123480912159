import moment from 'moment';
import mime from 'mime';

export const formatFilename = (mimetype, uploadPath) => {
  const date = moment().format('YYYYMMDD-HHmmss');
  const randomString = Math.random().toString(36).substring(2, 7);
  const newFilename = `${
    uploadPath ? uploadPath + '/' : ''
  }${date}-${randomString}.${
    mimetype ? mime.getExtension(mimetype) : 'image/png'
  }`;
  return newFilename;
};

export const fileType = (mimetype) => {
  return mime.getExtension(mimetype ? mimetype : 'image/png');
};

export const dateNumFormat = (value) => {
  value = parseInt(value);
  if (isNaN(value)) {
    return '';
  }
  return value < 10 ? '0' + value : value;
};

export const numberWithCommas = (x, isZeroShow) => {
  return x
    ? String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : isZeroShow
    ? 0
    : '';
};

export const excelResizeWidth = (worksheet) => {
  return new Promise((resolve) => {
    for (const column of worksheet.columns) {
      let dataMax = 0;
      column.eachCell({ includeEmpty: true }, function (cell) {
        var columnLength =
          cell && cell.value && cell.value.length ? cell.value.length : 0;
        if (columnLength > dataMax) {
          dataMax = columnLength;
        }
      });
      column.width = dataMax <= 4 ? 6 : dataMax <= 9 ? 12 : dataMax + 4;
    }
    resolve(worksheet);
  });
};
