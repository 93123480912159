/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback, useMemo } from 'react';
import {
  Table,
  Row,
  Col,
  Card,
  Space,
  DatePicker,
  Button,
  Input,
  InputNumber,
  Select,
  Grid,
  Descriptions,
  Divider,
} from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import moment from 'moment';
import { useApolloClient, useQuery, useMutation } from '@apollo/react-hooks';
import { toJS } from 'mobx';

import useStore from '@stores/useStore';
import { USERS, UPDATE_USER, USER_ONE } from '@shared/queries/UserQueries';
import {
  POINT_LOGS,
  ADD_POINT_LOG,
  REMOVE_POINT_LOG,
} from '@shared/queries/PointLogQueries';
import { numberWithCommas } from '@utils/common';

import CustomModal from '@Common/CustomModal';

const { Search } = Input;
const { Option } = Select;
const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  width: 100%;

  .error {
    color: ${(props) => (props.error ? '#e74c3c' : props.theme.primaryColor)};
  }
`;

const GivePoint = observer(({ user }) => {
  const screen = useBreakpoint();
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    users: [],
    list: [],
    paginate: {
      docs: [],
      totalDocs: 0,
      limit: 10,
      page: 1,
      totalPages: 1,
    },

    detailOpen: false,
    selectedData: {},

    searchType: null,
    startDate: null,
    endDate: null,

    givePointUser: null,
    givePoint: '',
    givePointMemo: '',
  }));

  const client = useApolloClient();
  const usersRes = useQuery(USERS, {
    variables: {
      findQuery: { role: { $lt: 4 } },
    },
  });
  const [add] = useMutation(ADD_POINT_LOG);
  const [remove] = useMutation(REMOVE_POINT_LOG);
  const [updateUser] = useMutation(UPDATE_USER);

  const fetchData = useCallback(
    async (findQuery = {}) => {
      window.maskOn();

      findQuery.pointType = '포인트지급';
      if (user) {
        findQuery.user = user.id;
      }

      const result = await client.query({
        query: POINT_LOGS,
        variables: {
          page: state.paginate.page,
          limit: state.paginate.limit,
          findQuery,
        },
      });

      if (result.data && result.data.pointLogs) {
        state.paginate = result.data.pointLogs;
        state.list = result.data.pointLogs.docs;
      }
      window.maskOff();
    },
    [user],
  );

  const handleDetail = useCallback(
    (modalOpen, data) => (e) => {
      if (modalOpen && data) {
        state.selectedData = data;
      } else {
        state.selectedData = {};
      }
      state.detailOpen = modalOpen;
    },
    [],
  );

  const handleChangePage = useCallback((page) => {
    if (state.paginate.page !== page) {
      state.paginate.page = page;
      handleSearch();
    }
  }, []);

  const handleChangeSearch = useCallback(
    (type) => (value) => {
      state[type] = value;
    },
    [],
  );
  const handleChangeDate = useCallback(
    (type) => (momentVal) => {
      state[type] = momentVal;
    },
    [],
  );

  const handleSearch = useCallback(
    (value) => {
      const findQuery = {};

      if (value && value.length) {
        findQuery.user = {
          $or: [
            { username: { $regex: value, $options: 'ig' } },
            { name: { $regex: value, $options: 'ig' } },
          ],
        };
      }

      findQuery['$and'] = [];
      if (state.startDate) {
        findQuery['$and'].push({
          updatedAt: {
            $gte: state.startDate.startOf('date').toDate(),
          },
        });
      }
      if (state.endDate) {
        findQuery['$and'].push({
          updatedAt: {
            $lte: state.endDate.endOf('date').toDate(),
          },
        });
      }
      if (!findQuery['$and'].length) {
        delete findQuery['$and'];
      }

      fetchData(findQuery);
    },
    [user],
  );

  const handleChangeGivePointUser = useCallback((value) => {
    state.givePointUser = value;
  }, []);
  const handleChangeGivePoint = useCallback((value) => {
    state.givePoint = value;
  }, []);
  const handleChangeGivePointMemo = useCallback((e) => {
    state.givePointMemo = e.target.value;
  }, []);
  const handleSubmitGivePoint = useCallback(async () => {
    if (!state.givePointUser) {
      return window.alert({ title: '지급할 회원을 선택해 주세요.' });
    }
    if (!state.givePoint || isNaN(parseInt(state.givePoint))) {
      return window.alert({ title: '지급할 포인트를 입력해 주세요.' });
    }
    window.maskOn();
    const userRes = await client.query({
      query: USER_ONE,
      variables: {
        findQuery: {
          _id: state.givePointUser,
        },
      },
    });
    if (userRes.data && userRes.data.userOne) {
      let point = userRes.data.userOne.point || 0;
      point += parseInt(state.givePoint);
      await updateUser({
        variables: {
          id: userRes.data.userOne.id,
          data: {
            point,
          },
        },
      });

      const data = {
        pointType: '포인트지급',
        description: '포인트 지급',
        point: state.givePoint,
        usePoint: state.givePoint,
        memo: state.givePointMemo,
        user: state.givePointUser,
      };

      await add({
        variables: {
          data,
        },
      });
      await handleSearch();
      window.maskOff();
      state.givePointUser = null;
      state.givePoint = '';
      state.givePointMemo = '';
      window.success({
        title: `${userRes.data.userOne.username} (${userRes.data.userOne.name})님에게 포인트 지급이 완료되었습니다.`,
      });
    } else {
      window.maskOff();
      window.alert({ title: '회원정보를 찾을 수 없습니다.' });
    }
  }, []);

  const handleRemove = useCallback(
    (row) => () => {
      window.ask({
        title: '계속 진행하시겠습니까?',
        content: '선택하신 포인트지급을 취소하시겠습니까?',
        async onOk() {
          const userRes = await client.query({
            query: USER_ONE,
            variables: {
              findQuery: {
                _id: row.user.id,
              },
            },
          });
          if (userRes.data && userRes.data.userOne) {
            await updateUser({
              variables: {
                id: row.user.id,
                data: {
                  point: userRes.data.userOne.point - row.point,
                },
              },
            });
            await remove({ variables: { id: row.id } });
            handleSearch();
          }
        },
      });
    },
    [],
  );

  useEffect(() => {
    if (usersRes.data && usersRes.data.users) {
      state.users = usersRes.data.users;
    }
  }, [usersRes.data]);

  useEffect(() => {
    fetchData();
  }, [user]);

  const columns = useMemo(
    () => [
      {
        title: '지급날짜',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt) =>
          createdAt ? moment(createdAt).format('YYYY-MM-DD HH:mm:ss') : '',
        align: 'center',
      },
      {
        title: '아이디',
        dataIndex: 'username',
        key: 'username',
        render: (_, row) => row.user.username,
        align: 'center',
      },
      {
        title: '성명',
        dataIndex: 'name',
        key: 'name',
        render: (_, row) => row.user.name,
        align: 'center',
      },
      {
        title: '내용',
        dataIndex: 'memo',
        key: 'memo',
        align: 'center',
      },
      {
        title: '지급포인트',
        dataIndex: 'point',
        key: 'point',
        render: (_, row) => numberWithCommas(row.point),
        align: 'center',
      },
      {
        title: '사용된포인트',
        dataIndex: 'usePoint',
        key: 'usePoint',
        render: (_, row) => numberWithCommas(row.usePoint),
        align: 'center',
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (_, row) =>
          row.usePoint === row.point ? (
            <Button type="primary" onClick={handleRemove(row)} danger>
              지급취소
            </Button>
          ) : (
            ''
          ),
        align: 'center',
      },
    ],
    [],
  );

  const mobileColumns = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'idx',
        key: 'idx',
        render: (_, row, idx) => {
          return (
            <Descriptions size="small" bordered column={1}>
              <Descriptions.Item label="지급날짜">
                {row.createdAt
                  ? moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')
                  : ''}
              </Descriptions.Item>
              <Descriptions.Item label="아이디">
                {row.user.username}
              </Descriptions.Item>
              <Descriptions.Item label="성명">
                {row.user.name}
              </Descriptions.Item>
              <Descriptions.Item label="내용">{row.memo}</Descriptions.Item>
              <Descriptions.Item label="지급포인트">
                {numberWithCommas(row.point)}
              </Descriptions.Item>
              <Descriptions.Item label="">
                <Button type="primary" onClick={handleRemove(row)} danger>
                  지급취소
                </Button>
              </Descriptions.Item>
            </Descriptions>
          );
        },
      },
    ],
    [],
  );

  return (
    <Wrapper>
      {!user && (
        <>
          <Card size="small" title="포인트 지급">
            <Row gutter={[16, 0]} align="middle">
              <Col xs={24} lg={4}>
                <Select
                  showSearch
                  value={state.givePointUser}
                  onChange={handleChangeGivePointUser}
                  style={{ width: '100%' }}
                  placeholder="아이디"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {toJS(state.users).map((item) => (
                    <Option
                      key={item.id}
                      value={item.id}
                    >{`${item.username} (${item.name})`}</Option>
                  ))}
                </Select>
              </Col>
              <Col xs={24} lg={4}>
                <InputNumber
                  placeholder="지급포인트"
                  value={state.givePoint}
                  onChange={handleChangeGivePoint}
                  formatter={(value) => numberWithCommas(value)}
                  parser={(value) => value.replace(/[^0-9]/g, '')}
                  min={0}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col xs={24} lg={8}>
                <Input
                  placeholder="지급내용"
                  value={state.givePointMemo}
                  onChange={handleChangeGivePointMemo}
                />
              </Col>
              <Col xs={24} lg={4}>
                <Button type="primary" onClick={handleSubmitGivePoint}>
                  포인트지급
                </Button>
              </Col>
            </Row>
          </Card>

          <Divider />
        </>
      )}

      <Row gutter={[32, 16]}>
        <Col xs={24} lg={6}>
          <h4>기간별 검색</h4>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={16}>
              <Space>
                <DatePicker
                  placeholder="시작일"
                  value={state.startDate}
                  onChange={handleChangeDate('startDate')}
                />
                <DatePicker
                  placeholder="종료일"
                  value={state.endDate}
                  onChange={handleChangeDate('endDate')}
                />
              </Space>
            </Col>
            <Col xs={24} lg={6}>
              <Button type="primary" block onClick={handleSearch}>
                검색
              </Button>
            </Col>
          </Row>
        </Col>

        {!user && (
          <Col xs={24} lg={4}>
            <h4>전체검색</h4>
            <Search
              placeholder="이름 | 아이디"
              onSearch={handleSearch}
              enterButton
            />
          </Col>
        )}
      </Row>

      <Table
        dataSource={toJS(state.list)}
        columns={screen.lg ? columns : mobileColumns}
        // scroll={{ x: 992 }}
        pagination={{
          current: state.paginate.page,
          total: state.paginate.totalDocs,
          showSizeChanger: false,
          onChange: handleChangePage,
        }}
        rowKey={(row) => row.id}
        summary={(pageData) => {
          let pointAmount = 0;
          for (const row of pageData) {
            pointAmount += row.point;
          }
          return screen.lg ? (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={4}>총 지급포인트</Table.Summary.Cell>
              <Table.Summary.Cell>
                {numberWithCommas(pointAmount)}
              </Table.Summary.Cell>
              <Table.Summary.Cell colSpan={2}></Table.Summary.Cell>
            </Table.Summary.Row>
          ) : (
            <Table.Summary.Row>
              <Table.Summary.Cell>
                <Descriptions size="small" bordered column={1}>
                  <Descriptions.Item label="총 지급포인트">
                    {numberWithCommas(pointAmount)}
                  </Descriptions.Item>
                </Descriptions>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />

      <CustomModal
        visible={state.detailOpen}
        onCancel={handleDetail(false)}
        forceRender={true}
        footer={null}
        minwidth={800}
      >
        {/* {state.selectedData && state.selectedData.id && (
          <PointLogDetail propData={toJS(state.selectedData)} />
        )} */}
      </CustomModal>
    </Wrapper>
  );
});

export default GivePoint;
