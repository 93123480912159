/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs, Row, Col } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';

import PageTitle from '@Common/PageTitle';

import SaleList from './SaleList';

const { TabPane } = Tabs;

const Wrapper = styled.div`
  width: 100%;
`;

const SaleStatus = observer(({ subTab, tab }) => {
  const router = useHistory();
  const state = useLocalStore(() => ({
    tab: '1',
  }));

  const handleChangeTab = useCallback((key) => {
    state.tab = key;
  }, []);

  useEffect(() => {
    if (subTab) {
      state.tab = subTab;
    }
  }, [subTab]);

  return (
    <Wrapper>
      {tab === '1' && (
        <Tabs activeKey={state.tab} onChange={handleChangeTab} type="card">
          {/* <TabPane tab="매출내역" key="1">
            <SaleList listType={'all'} />
          </TabPane> */}
          <TabPane tab="일별결제내역" key="1">
            <SaleList listType={'today'} />
          </TabPane>
          <TabPane tab="한주결제내역" key="2">
            <SaleList listType={'week'} />
          </TabPane>
          <TabPane tab="월별결제내역" key="3">
            <SaleList listType={'month'} />
          </TabPane>
        </Tabs>
      )}
    </Wrapper>
  );
});

export default SaleStatus;
