/* eslint-disable react-hooks/exhaustive-deps*/
import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import CustomModal from '@Common/CustomModal';

import AutocompleteTopModifyForm from './AutocompleteTopModifyForm';

const Wrapper = styled.div`
  width: 100%;
`;

const AutocompleteTopModify = observer(
  ({ propData = {}, modalOpen, handleModal, refetch, isAdmin }) => {
    return (
      <CustomModal
        title={`자동완성 상위노출 ${propData && propData.id ? '수정' : '등록'}`}
        visible={modalOpen}
        onCancel={handleModal(false)}
        minwidth={800}
        footer={null}
      >
        <Wrapper>
          <AutocompleteTopModifyForm
            propData={propData}
            modalOpen={modalOpen}
            handleModal={handleModal}
            refetch={refetch}
            isAdmin={isAdmin}
          />
        </Wrapper>
      </CustomModal>
    );
  },
);

export default AutocompleteTopModify;
