/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import {
  Row,
  Col,
  Divider,
  Radio,
  InputNumber,
  Card,
  Descriptions,
  Button,
  Space,
  Input,
  Form,
  Checkbox,
} from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import { toJS } from 'mobx';
import copy from 'copy-to-clipboard';

import {
  ADD_POINT_LOG,
  UPDATE_POINT_LOG,
} from '@shared/queries/PointLogQueries';
import { UPDATE_USER } from '@shared/queries/UserQueries';
import { ADD_INVOICE } from '@shared/queries/InvoiceQueries';
import useStore from '@stores/useStore';
import { numberWithCommas } from '@utils/common';
import { validateMessages } from '@utils/customFormValidator';
import { SINGLE_FILE_UPLOAD, SINGLE_FILE_REMOVE } from '@shared/queries';
import { checkExif } from '@utils/preUpload';
import { formatFilename } from '@utils/common';

import PageTitle from '@Common/PageTitle';
import CustomModal from '@Common/CustomModal';
import Description from '@Common/Description';

import CardInfoTable from './CardInfoTable';
import PointChargingList from './PointChargingList';

const Wrapper = styled.div`
  width: 100%;

  .highlight {
    color: ${(props) => props.theme.primaryColor};
    font-size: 20px;
  }
  .ant-radio-group {
    width: 100%;
  }
  .price-card {
    min-width: 280px;
    text-align: center;

    span {
      color: ${(props) => props.theme.primaryColor};
      font-size: 28px;
      font-weight: bold;
    }

    .ant-input-number-input-wrap {
      input {
        text-align: right;
        font-weight: bold;
        font-size: 1.4rem;
        padding-right: 30px;
      }
    }
  }

  ${(props) => props.theme.breakpoint('xs', 'lg')`
    .highlight {
      font-size: 1rem;
    }
  `}
`;

const POINT_OPTIONS = [[20000, 30000, 40000], [50000]];

const PointCharging = observer(({ handleClose, tab = '3' }) => {
  const [form] = Form.useForm();
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    price: POINT_OPTIONS[0][0],
    radioPrice: POINT_OPTIONS[0][0],
    pay_method: 'bank',
    points: [...POINT_OPTIONS],

    bankOpen: false,
    amount: POINT_OPTIONS[0][0],

    cardInfoOpen: false,
    invoiceType: '세금계산서',
    checkInvoice: false,

    taxBusinessImage: null,
    cashBusinessImage: null,

    listRefetch: null,
    setListRefetch(func) {
      this.listRefetch = func;
    },
  }));

  const [add] = useMutation(ADD_POINT_LOG);
  const [addInvoice] = useMutation(ADD_INVOICE);
  const [update] = useMutation(UPDATE_POINT_LOG);
  const [updateUser] = useMutation(UPDATE_USER);
  const [singleFileUpload] = useMutation(SINGLE_FILE_UPLOAD);
  const [singleFileRemove] = useMutation(SINGLE_FILE_REMOVE);

  const handleChangeRadio = useCallback(
    (type) => (e) => {
      state[type] = e.target.value;

      if (type === 'radioPrice') {
        state.amount = e.target.value;
        state.price = e.target.value;
      }
    },
    [],
  );
  const handleChangePrice = useCallback(
    (type) => (value) => {
      state[type] = value;
    },
    [],
  );

  const setInvoice = useCallback(() => {
    return new Promise(async (resolve) => {
      if (state.invoiceType === '') {
        return resolve(false);
      }

      const values = form.getFieldsValue(true);
      const user = toJS(commonStore.user);
      const data =
        state.invoiceType === '현금영수증' ? values.cashData : values.taxData;

      const taxBusinessImage = toJS(state.taxBusinessImage);
      const cashBusinessImage = toJS(state.cashBusinessImage);
      if (state.invoiceType === '현금영수증') {
        if (cashBusinessImage && cashBusinessImage.status === 'load') {
          const result = await saveFile(cashBusinessImage);
          if (result.err) {
            return window.alert({
              title: '파일 업로드 중 오류가 발생하였습니다.',
            });
          }
          data.businessImage = result.data;
        } else {
          data.businessImage = cashBusinessImage;
        }
      } else {
        if (taxBusinessImage && taxBusinessImage.status === 'load') {
          const result = await saveFile(taxBusinessImage);
          if (result.err) {
            return window.alert({
              title: '파일 업로드 중 오류가 발생하였습니다.',
            });
          }
          data.businessImage = result.data;
        } else {
          data.businessImage = taxBusinessImage;
        }
      }

      if (state.checkInvoice) {
        const userInvoiceData = {};
        if (state.invoiceType === '현금영수증') {
          userInvoiceData.cashData = data;
        } else {
          userInvoiceData.taxData = data;
        }
        await updateUser({
          variables: {
            id: user.id,
            data: userInvoiceData,
          },
        });
      }

      data.userId = user.username;
      data.userName = user.name;
      data.invoiceType = state.invoiceType;
      data.tax = state.amount * 0.1;
      data.amount = state.amount + data.tax;
      data.status = '발행처리전';
      data.user = user.id;

      await addInvoice({
        variables: {
          data,
        },
      });

      resolve({
        amount: data.amount,
        tax: data.tax,
      });
    });
  }, [form]);

  const handleOkBank = useCallback(async () => {
    let point = state.amount;
    let usePoint = state.amount;
    const data = {
      pointType: '포인트충전',
      description: '포인트 충전',
      status: '무통장',
      user: commonStore.user.id,
      created: commonStore.user.id,
      point,
      usePoint,
      pay_method: state.pay_method,
    };

    const resInvoice = await setInvoice();
    if (resInvoice) {
      data.point = resInvoice.amount;
      data.tax = resInvoice.tax;
    }

    const res = await add({
      variables: {
        data,
      },
    });

    if (res.data && res.data.addPointLog) {
      state.price = null;
      state.radioPrice = POINT_OPTIONS[0][0];
      state.pay_method = 'bank';
      state.amount = POINT_OPTIONS[0][0];
      state.invoiceType = '세금계산서';
      window.success({ title: '무통장 입금 신청이 완료되었습니다.' });
      state.bankOpen = false;

      if (state.listRefetch) {
        state.listRefetch();
      }
    } else {
      window.alert({ title: '무통장 입금정보 생성 중 오류가 발생하였습니다.' });
    }

    if (handleClose) {
      handleClose();
    }
  }, [handleClose, form]);

  const handleSubmit = useCallback(async () => {
    let amount = state.amount;
    // if (state.radioPrice) {
    //   amount = state.radioPrice;
    // } else {
    //   amount = state.price;
    // }
    if (!amount) {
      return window.alert({ title: '결제하실 금액을 입력해 주세요' });
    }
    // state.amount = amount;

    if (state.invoiceType !== '') {
      form
        .validateFields()
        .then((values) => {
          if (state.pay_method === 'bank') {
            state.bankOpen = true;
            return;
          }
          handlePay(amount);
        })
        .catch((errorInfo) => {
          if (errorInfo.errorFields[0]) {
            form.scrollToField(errorInfo.errorFields[0].name);
          }
        });
    } else {
      if (state.pay_method === 'bank') {
        state.bankOpen = true;
        return;
      }

      handlePay(amount);
    }
  }, [handleClose]);

  const handlePay = useCallback(
    async (amount) => {
      const res = await add({
        variables: {
          data: {
            pointType: '포인트충전',
            description: '포인트 충전',
            status: '결제대기',
            user: commonStore.user.id,
            created: commonStore.user.id,
            point: amount,
            usePoint: amount,
            pay_method: state.pay_method,
          },
        },
      });

      if (res.data && res.data.addPointLog) {
        const point = res.data.addPointLog;
        const merchant_uid = point.orderId;
        const param = {
          // param
          pg: 'kcp',
          pay_method: state.pay_method,
          merchant_uid,
          name: `포인트 충전`,
          // amount: commonStore.user.isTest ? 100 : amount,
          amount,
          buyer_email: commonStore.user.email,
          buyer_name: commonStore.user.name,
          buyer_tel: commonStore.user.phone,
        };
        if (param.pay_method === 'vbank') {
          const now = await commonStore.getServerNow();
          now.add(1, 'days');
          param.vbank_due = now.format('YYYYMMDDhhmm');
        }
        window.IMP.request_pay(param, async (rsp) => {
          // callback
          if (rsp.success) {
            // 성공 시 로직
            if (rsp.pay_method === 'vbank' && rsp.status === 'ready') {
              await update({
                variables: {
                  id: point.id,
                  data: {
                    imp_uid: rsp.imp_uid,
                    merchant_uid,
                    pay_method: state.pay_method,
                    status: '결제대기',
                    vbank_date: rsp.vbank_date,
                    vbank_holder: rsp.vbank_holder,
                    vbank_name: rsp.vbank_name,
                    vbank_num: rsp.vbank_num,
                  },
                },
              });

              window.maskOff();
              state.price = null;
              state.radioPrice = POINT_OPTIONS[0][0];
              state.pay_method = 'bank';

              window.success({
                title: '가상계좌 발급이 완료되었습니다.',
                content: (
                  <div>
                    <strong>
                      예금주 : {rsp.vbank_holder}
                      <br />
                      은행명 : {rsp.vbank_name}
                      <br />
                      계좌번호 : {rsp.vbank_num}
                    </strong>
                    <br />
                    <br />
                    24시간 이내에 입금해 주세요.
                  </div>
                ),
              });
            } else if (rsp.status === 'paid') {
              const updatePoint =
                (commonStore.user.point ? commonStore.user.point : 0) + amount;
              const userRes = await updateUser({
                variables: {
                  id: commonStore.user.id,
                  data: {
                    point: updatePoint,
                  },
                },
              });

              if (userRes.data && userRes.data.updateUser) {
                const now = await commonStore.getServerNow();
                await update({
                  variables: {
                    id: point.id,
                    data: {
                      imp_uid: rsp.imp_uid,
                      merchant_uid,
                      pay_method: state.pay_method,
                      status: '결제완료',
                      receipt_url: rsp.receipt_url,
                      paymentDate: now.toDate(),
                    },
                  },
                });

                commonStore.user = userRes.data.updateUser;
                window.maskOff();
                state.price = null;
                state.radioPrice = POINT_OPTIONS[0][0];
                state.pay_method = 'bank';
                window.success({
                  title: '결제가 성공적으로 완료되었습니다.',
                });
              } else {
                await update({
                  variables: {
                    id: point.id,
                    data: { status: '오류', memo: '사용자 정보 업데이트 오류' },
                  },
                });
                window.maskOff();
                window.alert({
                  title: '사용자 정보 업데이트에 실패하였습니다.',
                });
              }
            }
          } else {
            // 결제 실패 시 로직,
            window.maskOff();
            window.alert({
              title: '결제에 실패하였습니다.',
              content: rsp.error_msg,
            });
          }
        });
      } else {
        return window.alert({
          title: '충전정보 생성 중 오류가 발생하였습니다.',
        });
      }

      if (handleClose) {
        handleClose();
      }
    },
    [handleClose],
  );

  const handleChangeFile = useCallback(
    (type) => async (e) => {
      if (e.target.files && e.target.files[0]) {
        const stateFile = toJS(state[type]);
        const file = e.target.files[0];
        e.target.value = '';
        if (stateFile && stateFile.status === 'done') {
          window.ask({
            title: '계속 진행하시겠습니까?',
            content: `사업자등록증을 변경하시면 기존 파일은 삭제됩니다.`,
            async onOk() {
              await singleFileRemove({
                variables: { Key: stateFile.name },
              });
              try {
                // const resizeFile = await checkExif(file, 256, 256);
                file.status = 'load';
                state[type] = file;
              } catch (e) {
                if (e && e.message) {
                  window.alert({ title: e.message });
                }
              }
            },
          });
        } else {
          try {
            // const resizeFile = await checkExif(file, 256, 256);
            // resizeFile.status = 'load';
            file.status = 'load';
            state[type] = file;
          } catch (e) {
            if (e && e.message) {
              window.alert({ title: e.message });
            }
          }
        }
      }
    },
    [],
  );

  const saveFile = useCallback((resizeFile) => {
    return new Promise(async (resolve) => {
      const filename = formatFilename(resizeFile.type, `business-image`);
      const data = {
        filename,
        file: resizeFile,
      };

      const res = await singleFileUpload({
        variables: { data },
      });
      let uid, name, url, err;
      if (res.errors && res.errors.length) {
        err = res.errors[0].message;
      } else {
        uid = res.data.singleFileUpload.uid;
        url = res.data.singleFileUpload.url;
        name = filename;
      }

      resolve({
        data: {
          uid,
          name,
          filename: resizeFile.name || resizeFile.filename,
          url,
          status: 'done',
        },
        err,
      });
    });
  }, []);

  const handleCopyAccount = useCallback((value) => {
    copy(value);
    window.info({ title: '계좌번호가 복사되었습니다.' });
  }, []);

  useEffect(() => {
    const user = toJS(commonStore.user);
    if (user) {
      if (user.taxData) {
        state.checkInvoice = true;
        form.setFieldsValue({
          taxData: user.taxData,
        });
        if (user.taxData.businessImage) {
          state.taxBusinessImage = user.taxData.businessImage;
        }
      }
      if (user.cashData) {
        state.checkInvoice = true;
        form.setFieldsValue({
          cashData: user.cashData,
        });
        if (user.cashData.businessImage) {
          state.cashBusinessImage = user.cashData.businessImage;
        }
      }
    }
  }, [form]);

  return (
    <Wrapper>
      <PageTitle>포인트 충전</PageTitle>

      <Card style={{ marginBottom: 15 }}>
        <PointChargingList
          user={toJS(commonStore.user)}
          tab={tab}
          setListRefetch={state.setListRefetch}
        />
      </Card>

      <Row justify="space-between" align="middle">
        <Col>
          <h4 style={{ margin: 0 }}>충전포인트</h4>
        </Col>
        <Col>
          포인트 환율 : <span className="highlight">*1포인트 당 = 1원</span>
        </Col>
      </Row>
      <Divider style={{ marginTop: 5 }} />

      {/* <Radio.Group
        value={state.radioPrice}
        onChange={handleChangeRadio('radioPrice')}
      >
        {toJS(state.points).map((item, idx) => (
          <Row gutter={[16, 16]} key={`p_option_row${idx}`} align="middle">
            {item.map((val, valIdx) =>
              val ? (
                <Col xs={24} lg={8} key={`p_option_${val}`}>
                  <Radio value={val}>
                    {numberWithCommas(val)}포인트 ({numberWithCommas(val)})원
                  </Radio>
                </Col>
              ) : (
                <Col xs={24} lg={8} key={`p_option_${val}`}>
                  <Radio value={0}>
                    <InputNumber
                      placeholder="금액입력"
                      min={POINT_OPTIONS[0][0]}
                      value={state.price}
                      onChange={handleChangePrice('price')}
                      style={{ width: 160 }}
                    />{' '}
                    포인트
                  </Radio>
                </Col>
              ),
            )}
          </Row>
        ))}
      </Radio.Group> */}

      {/* <h4 style={{ marginTop: 30 }}>결제금액</h4> */}
      {/* <Divider style={{ marginTop: 5 }} /> */}
      <Row justify="center">
        <Col>
          <Card className="price-card">
            <Space>
              <span>￦</span>
              <InputNumber
                size="large"
                placeholder="금액입력"
                min={POINT_OPTIONS[0][0]}
                value={state.amount}
                onChange={handleChangePrice('amount')}
                formatter={(value) => numberWithCommas(value)}
                parser={(value) => parseInt(value.replace(/[^\d]/g, ''))}
                style={{ width: 200 }}
              />
              <span>원</span>
            </Space>
          </Card>
        </Col>
      </Row>

      <h4 style={{ marginTop: 30 }}>결제수단</h4>
      <Divider style={{ marginTop: 5 }} />
      <Radio.Group
        value={state.pay_method}
        onChange={handleChangeRadio('pay_method')}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={4}>
            <Radio value="card" disabled={true}>
              카드결제
            </Radio>
          </Col>
          <Col xs={24} lg={4}>
            <Radio value="trans" disabled={true}>
              실시간 계좌이체
            </Radio>
          </Col>
          <Col xs={24} lg={4}>
            <Radio value="phone" disabled={true}>
              휴대폰 소액결제
            </Radio>
          </Col>
          <Col xs={24} lg={4}>
            <Radio value="bank">무통장 입금</Radio>
          </Col>
          {/* <Col xs={24} lg={4}>
            <Radio value="vbank">가상계좌</Radio>
          </Col> */}
        </Row>
      </Radio.Group>

      <Divider />
      <h4>계산서 / 영수증</h4>
      <Radio.Group
        value={state.invoiceType}
        onChange={handleChangeRadio('invoiceType')}
        buttonStyle="solid"
        style={{ marginBottom: 15 }}
      >
        {/* <Radio.Button value={''}>신청안함</Radio.Button> */}
        <Radio.Button value={'세금계산서'}>세금계산서</Radio.Button>
        <Radio.Button value={'현금영수증'}>현금영수증</Radio.Button>
      </Radio.Group>

      <Form
        form={form}
        layout="vertical"
        name="invoice_form"
        initialValues={{
          taxData: {},
          cashData: {},
        }}
        validateMessages={validateMessages}
        scrollToFirstError
      >
        {state.invoiceType === '현금영수증' && (
          <Row gutter={[10, 10]}>
            <Col xs={24}>
              <Form.Item
                label="구분"
                name={['cashData', 'cashType']}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="지출증빙용">지출증빙용</Radio>
                  <Radio value="소득공제용">소득공제용</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label="연락처"
                name={['cashData', 'phone']}
                rules={[
                  {
                    required: true,
                    pattern: /^010[0-9]{4}[0-9]{4}$/,
                  },
                ]}
              >
                <Input size="large" placeholder="- 없이 입력해 주세요" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label="회사명"
                name={['cashData', 'companyName']}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label="사업자등록번호"
                name={['cashData', 'businessNum']}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
          </Row>
        )}
        {state.invoiceType === '세금계산서' && (
          <Row gutter={[10, 10]}>
            <Col xs={24} lg={12}>
              <Form.Item
                label="회사명"
                name={['taxData', 'companyName']}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label="사업자등록번호"
                name={['taxData', 'businessNum']}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label="사업자등록증">
                <input
                  id={`tax_business_image`}
                  accept="image/*"
                  type="file"
                  onChange={handleChangeFile('taxBusinessImage')}
                  style={{ display: 'none' }}
                />
                <Row align="middle" gutter={5}>
                  <Col xs={24} lg={4}>
                    <Button
                      htmlType="button"
                      onClick={() =>
                        document.querySelector(`#tax_business_image`).click()
                      }
                      block
                    >
                      파일선택
                    </Button>
                  </Col>
                  <Col xs={24} lg={20}>
                    <Input
                      value={
                        state.taxBusinessImage
                          ? state.taxBusinessImage.filename
                            ? state.taxBusinessImage.filename
                            : state.taxBusinessImage.name
                          : null
                      }
                      disabled
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label="대표자"
                name={['taxData', 'owner']}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label="담당자연락처"
                name={['taxData', 'phone']}
                rules={[
                  {
                    required: true,
                    pattern: /^010[0-9]{4}[0-9]{4}$/,
                  },
                ]}
              >
                <Input size="large" placeholder="- 없이 입력해 주세요" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label="사업장주소"
                name={['taxData', 'address']}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label="메일주소"
                name={['taxData', 'email']}
                rules={[
                  {
                    type: 'email',
                    required: true,
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
      {state.invoiceType !== '' && (
        <Checkbox
          checked={state.checkInvoice}
          onChange={(e) => (state.checkInvoice = e.target.checked)}
        >
          현재의 정보를 저장합니다.
        </Checkbox>
      )}

      <Divider />

      <Card style={{ height: 200, overflowY: 'auto', textAlign: 'left' }}>
        <p>
          충전한 포인트 전액 환불(결제 취소)은 신용카드, 무통장입금으로 포인트를
          구매한 뒤 사용한 이력이 없고 결제 후 5영업일 이내 요청이 접수된
          경우에만 가능합니다.
        </p>
        <p>
          휴대폰 결제의 경우 당월은 취소만 가능, 익월 이후 청구요금 수납 확인 후
          결제자 본인 계좌 환불 가능합니다.
        </p>
        <p>
          결제 취소는 포인트 내역에서 '결제 취소'를 클릭하여 직접 취소할 수
          있습니다.
        </p>
        <p>
          결제 취소란 결제 승인 자체를 취소하는 것으로 포인트 환불과 달리 환급
          수수료가 발생하지 않습니다.
        </p>

        <h4>[포인트 충전 규정]</h4>
        <p>
          • 최소 충전 금액이 5천 원인 것은 애드픽네트워트가 결제 대행사 등에
          납부하는 금융 지출 비용이 충전 금액의 10%를 넘지 않도록 하기 위함이며,
          최대 충전 금액이 5만 원인 것은 은행 및 카드사의 요청에 의한 것이므로
          양해 부탁드립니다.
        </p>
        <p>
          • 전자금융거래법에 따라 본인인증 및 본인계좌 등록 시 최대 200만원 충전
          및 사용이 가능하며, 그렇지 않은 경우 최대 50만원 한도내에서 캐시 충전
          및 보유가 가능합니다.
        </p>
        <p>
          • 포인트 유효기간은 결제한 날로 부터 3개월이며 미사용 포인트에 대하여
          환불 가능합니다.
        </p>
        <p>
          단, 직접 회원 탈퇴하거나 5년 이상 로그인하지 않아 계정이 삭제되면 그
          즉시 소멸되며 복구할 수 없습니다.
        </p>

        <h4>[포인트 환급 규정]</h4>
        <p>
          • 회원 탈퇴 후에는 포인트 내역을 확인할 수 없으므로 환급이 불가합니다.
          마찬가지로 환급 신청을 완료했더라도 환불 처리가 완료되기 전에 해당
          계정을 탈퇴하면, 이용자 정보를 확인할 수 없게 되므로 환불이
          불가합니다.
        </p>
        <p>
          -약관 제24조3항에 따라 1,000원 또는 잔액의 10% 중 큰 금액을 환급
          수수료로 공제합니다. 최소 환급 수수료인 1,000원 이하의 포인트를 보유
          중이면 환불 가능 금액이 0원으로 표시됩니다.
        </p>
        <p>
          -약관 제24조2항에 따라 전체 충전 금액의 20% 이상을 사용하지 않으면
          환불이 불가합니다. 전체 충전 금액의 20% 이상을 사용하지 않으면 환불
          가능 금액이 0원으로 표시됩니다.
        </p>
        <p>
          -약관 24조4항에 따라 ① 신용카드로 포인트 충전 후 ② 포인트 사용 내역이
          없고 ③ 결제 후 5영업일이 지나지 않으면 결제 취소가 가능합니다. 결제
          취소란 결제 승인 자체를 취소하는 것으로 환급 수수료가 발생하지
          않습니다.
        </p>
        <p>
          위 경우를 제외한 경우 [인터넷이용관련소비자피해보상]상 환급수수료인
          잔여액의 10%(최소 1000원)가 제외된 금액만큼 환불받을 수 있습니다.
        </p>
        <p>
          단, 각 회 충전한 포인트의 80% 이상을 보유하고 있는 경우 불법
          금융행위를 방지하기 위해 환불받을 수 없습니다.
        </p>
        <p>환불은 환급 신청 페이지를 통해 처리해드립니다.</p>
        <p>결제 취소와 환불은 접수 후 5영업일 이내 처리됩니다.</p>
        <p>자세한 내용은 서비스 이용 전 동의하신 이용약관을 참고해주세요.</p>

        <h4>[포인트 결제 취소]</h4>
        <p>{`• [포인트] > [포인트 내역]에서 '결제 취소'를 클릭하여 직접 취소할 수 있습니다.`}</p>
        <p>
          결제 취소란 결제 승인 자체를 취소하는 것으로 포인트 환불과 달리 환급
          수수료가 발생하지 않습니다.
        </p>
        <p>• 대행상품의 특성상 작업이 시작된 후 환불 처리가 불가합니다.</p>
        <p>
          단, 약관 24조4항에 따라 아래 조건을 모두 충족해야 결제 취소가
          가능합니다.
        </p>
        <p>
          신용카드 이용 (가상계좌, 휴대폰 소액결제는 결제 당월에만 취소 가능
          익월 취소일 경우 불가)
        </p>
        <p>
          충전 후 포인트를 사용하지 않은 경우 (사용 내역이 한 건이라도 있으면
          불가)
        </p>
        <p>
          충전 후 5영업일 이내 결제 취소를 신청한 경우 (5영업일 경과 시 취소
          불가)
        </p>
        <p>가상계좌 결제는 입금 기한까지 입금하지 않으면 자동 취소됩니다.</p>
        <p>
          입금을 완료했다면 결제 취소는 불가하므로 포인트 환불을 신청하시기
          바랍니다.
        </p>
        <p>
          체크카드는 결제 취소 후 결제 대금이 고객님의 계좌로 입금되기까지
          카드사 정책에 따라 1~3일 정도 소요될 수 있습니다.
        </p>
        <p>자세한 내용은 카드사로 문의하시기 바랍니다.</p>
      </Card>

      <Row justify="center" style={{ marginTop: 30 }}>
        <Col xs={24} lg={6}>
          <Button
            type="primary"
            size="large"
            block
            onClick={() => {
              if (state.pay_method === 'card') {
                state.cardInfoOpen = true;
              } else {
                handleSubmit();
              }
            }}
            disabled={!Boolean(commonStore.user)}
          >
            충전하기
          </Button>
        </Col>
      </Row>

      {commonStore.user && (
        <CustomModal
          title="무통장 입금"
          visible={state.bankOpen}
          onCancel={(e) => (state.bankOpen = false)}
          onOk={handleOkBank}
        >
          <Descriptions
            title=""
            bordered
            size="small"
            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
            style={{ marginBottom: 15 }}
          >
            <Descriptions.Item label="입금자명">
              {commonStore.user.name}님
            </Descriptions.Item>
            <Descriptions.Item label="입금금액">
              {numberWithCommas(
                state.invoiceType !== ''
                  ? state.amount + state.amount * 0.1
                  : state.amount,
                true,
              )}
              원
            </Descriptions.Item>
            <Descriptions.Item label="입금은행명">기업은행</Descriptions.Item>
            <Descriptions.Item label="입금아이디">
              {commonStore.user.username}
            </Descriptions.Item>
          </Descriptions>

          <Description
            text="무통장 입금시 결제금액 및 입금자명이 정확히 일치해야 합니다."
            style={{ marginBottom: 10 }}
          />
          <Description
            text="입금완료 또는 카드 승인후 평균 1~10분 이내 자동으로 포인트가 충전됩니다."
            style={{ marginBottom: 10 }}
          />
          {state.invoiceType !== '' ? (
            <Space align="start">
              <Description
                text="계좌번호 : 기업은행 54807033804011 | (주)애드픽네트워크"
                style={{ marginBottom: 10 }}
              />
              <div>|</div>
              <div
                className="blue click"
                onClick={() => handleCopyAccount('54807033804011')}
              >
                계좌복사
              </div>
            </Space>
          ) : (
            <Space align="start">
              <Description
                text="계좌번호 : 기업은행 285-076624-01-018 | 예금주 유시청"
                style={{ marginBottom: 10 }}
              />
              <div>|</div>
              <div
                className="blue click"
                onClick={() => handleCopyAccount('285-076624-01-018')}
              >
                계좌복사
              </div>
            </Space>
          )}

          <Description text="무통장 입금시 3시간이 지나면 충전이 자동으로 취소됩니다." />
          <Divider />
          <h3 style={{ textAlign: 'center' }}>입금을 하시겠습니까?</h3>
        </CustomModal>
      )}

      <CustomModal
        title="신용카드 결제한도"
        visible={state.cardInfoOpen}
        onCancel={(e) => (state.cardInfoOpen = false)}
        onOk={handleSubmit}
        minwidth={1}
      >
        <h4 className="red">
          * 신용카드사에서 개인에게 제공하는 한도까지 충전가능합니다.
        </h4>

        <CardInfoTable />
      </CustomModal>
    </Wrapper>
  );
});

export default PointCharging;
