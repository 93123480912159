/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Tabs,
  Row,
  Col,
  Divider,
  Space,
  DatePicker,
  Button,
  Select,
  Input,
  Radio,
} from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { toJS } from 'mobx';

import PageTitle from '@Common/PageTitle';

import WorkList from '@Member/Work/WorkDetail/WorkList';

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const Wrapper = styled.div`
  width: 100%;

  .radio-btn {
    position: absolute;
  }

  ${(props) => props.theme.breakpoint('xs', 'lg')`
    .radio-btn {
      position: relative;
    }
  `}
`;

const WorkStatusContainer = observer(() => {
  const router = useHistory();
  const state = useLocalStore(() => ({
    tab: '1',

    searchType: null,
    startDate: null,
    endDate: null,
    findQuery: {},

    isRefetchWorkList: false,
    setRefetchWorkList(value) {
      this.isRefetchWorkList = value;
    },

    kWorkType: 'K',
    ppProgramType: '고객아이디',
  }));

  const handleChangeTab = useCallback((key) => {
    state.tab = key;
  }, []);

  const handleChangeSearch = useCallback(
    (type) => (value) => {
      state[type] = value;
    },
    [],
  );
  const handleChangeDate = useCallback(
    (type) => (momentVal) => {
      state[type] = momentVal;
    },
    [],
  );

  const handleChangeRadio = useCallback(
    (type) => (e) => {
      state[type] = e.target.value;
    },
    [],
  );

  const handleSearch = useCallback((value) => {
    const findQuery = {};

    if (value && value.length) {
      if (state.searchType) {
        if (state.tab === '3') {
          findQuery['$or'] = [
            {
              'buyCaseData.instaPostData.mainHashtag': {
                $regex: value,
                $options: 'ig',
              },
            },
            {
              'buyMonthData.instaPostData.mainHashtag': {
                $regex: value,
                $options: 'ig',
              },
            },
            {
              'buyCustomerMonthData.instaPostData.mainHashtag': {
                $regex: value,
                $options: 'ig',
              },
            },
            {
              'randomInstaPostData.mainHashtag': {
                $regex: value,
                $options: 'ig',
              },
            },
          ];
        } else {
          findQuery[state.searchType] = { $regex: value, $options: 'ig' };
        }
      } else {
        findQuery.all = {
          user: {
            $or: [
              { name: { $regex: value, $options: 'ig' } },
              { username: { $regex: value, $options: 'ig' } },
            ],
          },
          company: { $or: [{ name: { $regex: value, $options: 'ig' } }] },
          instaId: { $regex: value, $options: 'ig' },
        };
      }
    }

    findQuery['$and'] = [];
    if (state.startDate) {
      findQuery['$and'].push({
        updatedAt: {
          $gte: state.startDate.startOf('date').toDate(),
        },
      });
    }
    if (state.endDate) {
      findQuery['$and'].push({
        updatedAt: {
          $lte: state.endDate.endOf('date').toDate(),
        },
      });
    }
    if (!findQuery['$and'].length) {
      delete findQuery['$and'];
    }

    state.findQuery = findQuery;
  }, []);

  useEffect(() => {
    if (router.location.query && router.location.query.tab) {
      state.tab = router.location.query.tab;
    }
  }, []);

  return (
    <Wrapper>
      <Row>
        <Col xs={24}>
          <PageTitle>프로그램 작업내역 관리</PageTitle>
        </Col>
      </Row>

      <Row gutter={[32, 16]} align="bottom">
        <Col xs={24} lg={6}>
          <h4>기간별 검색</h4>
          <Row gutter={[16, 0]}>
            <Col xs={24} lg={16}>
              <Space>
                <DatePicker
                  placeholder="시작일"
                  value={state.startDate}
                  onChange={handleChangeDate('startDate')}
                />
                <DatePicker
                  placeholder="종료일"
                  value={state.endDate}
                  onChange={handleChangeDate('endDate')}
                />
              </Space>
            </Col>
            <Col xs={24} lg={6}>
              <Button type="primary" block onClick={handleSearch}>
                검색
              </Button>
            </Col>
          </Row>
        </Col>

        <Col xs={24} lg={18}>
          <h4>전체검색</h4>
          <Space>
            <Select
              value={state.searchType}
              onChange={handleChangeSearch('searchType')}
              style={{ width: 140 }}
            >
              <Option value={null}>전체</Option>
              <Option value="userName">이름</Option>
              <Option value="userId">아이디</Option>
              <Option value="companyName">업체명</Option>
              <Option value="instaId">작업아이디</Option>
              {state.tab == '2' || state.tab === '3' ? (
                <Option value="mainHashtag">대표해시태그</Option>
              ) : null}
            </Select>
            <Search onSearch={handleSearch} enterButton />
          </Space>
        </Col>
      </Row>

      <Divider />

      <Tabs activeKey={state.tab} onChange={handleChangeTab}>
        <TabPane tab="K좋아요K팔로워K댓글" key="1">
          <Radio.Group
            className="radio-btn"
            buttonStyle="solid"
            value={state.kWorkType}
            onChange={handleChangeRadio('kWorkType')}
          >
            <Radio.Button value="K">전체</Radio.Button>
            <Radio.Button value="K좋아요">K좋아요</Radio.Button>
            <Radio.Button value="K팔로워">K팔로워</Radio.Button>
            <Radio.Button value="K댓글">K댓글</Radio.Button>
          </Radio.Group>

          <Divider orientation="left" style={{ marginTop: 45 }}>
            [월구매]
          </Divider>
          <WorkList
            workType={state.kWorkType}
            isAdmin={true}
            findQuery={{ ...toJS(state.findQuery), paymentType: '월구매' }}
            isRefetchWorkList={state.isRefetchWorkList}
            setRefetchWorkList={state.setRefetchWorkList}
          />

          <Divider orientation="left">[건구매]</Divider>
          <WorkList
            workType={state.kWorkType}
            isAdmin={true}
            findQuery={{ ...toJS(state.findQuery), paymentType: '건구매' }}
            isRefetchWorkList={state.isRefetchWorkList}
            setRefetchWorkList={state.setRefetchWorkList}
          />
        </TabPane>
        <TabPane tab="인기게시물 상위작업" key="2">
          <Radio.Group
            className="radio-btn"
            buttonStyle="solid"
            value={state.ppProgramType}
            onChange={handleChangeRadio('ppProgramType')}
          >
            <Radio.Button value="고객아이디">고객아이디 사용(건)</Radio.Button>
            <Radio.Button value="고객아이디사용월">
              고객아이디 사용(월)
            </Radio.Button>
            <Radio.Button value="최적화아이디">최적화아이디</Radio.Button>
          </Radio.Group>
          <WorkList
            workType="인기게시물"
            isAdmin={true}
            findQuery={toJS(state.findQuery)}
            isRefetchWorkList={state.isRefetchWorkList}
            setRefetchWorkList={state.setRefetchWorkList}
            programType={state.ppProgramType}
          />
        </TabPane>
        <TabPane tab="인기게시물 건바이" key="3">
          <h4>월보장</h4>
          <WorkList
            workType="인기게시물건바이"
            isAdmin={true}
            findQuery={{ ...toJS(state.findQuery), paymentType: '월구매' }}
            isRefetchWorkList={state.isRefetchWorkList}
            setRefetchWorkList={state.setRefetchWorkList}
            ppCaseByType="월구매"
          />
          <Divider />
          <h4>건바이</h4>
          <WorkList
            workType="인기게시물건바이"
            isAdmin={true}
            findQuery={{ ...toJS(state.findQuery), paymentType: '건구매' }}
            isRefetchWorkList={state.isRefetchWorkList}
            setRefetchWorkList={state.setRefetchWorkList}
            ppCaseByType="건구매"
          />
          <Divider />
          <h4>고객 월 건바이</h4>
          <WorkList
            workType="인기게시물건바이"
            isAdmin={true}
            findQuery={{
              ...toJS(state.findQuery),
              paymentType: '고객월건바이',
            }}
            isRefetchWorkList={state.isRefetchWorkList}
            setRefetchWorkList={state.setRefetchWorkList}
            ppCaseByType="고객월건바이"
          />
        </TabPane>
        <TabPane tab="최적화계정육성" key="4">
          <WorkList
            workType="최적화계정육성"
            isAdmin={true}
            findQuery={toJS(state.findQuery)}
            isRefetchWorkList={state.isRefetchWorkList}
            setRefetchWorkList={state.setRefetchWorkList}
          />
        </TabPane>
        <TabPane tab="최적화테스트" key="5">
          <WorkList
            workType="최적화테스트"
            isAdmin={true}
            findQuery={toJS(state.findQuery)}
            isRefetchWorkList={state.isRefetchWorkList}
            setRefetchWorkList={state.setRefetchWorkList}
          />
        </TabPane>
      </Tabs>
    </Wrapper>
  );
});

export default WorkStatusContainer;
