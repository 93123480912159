/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Space,
  Avatar,
  Button,
  Divider,
  Radio,
} from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import { observer, useLocalStore } from 'mobx-react';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import moment from 'moment';
import { toJS } from 'mobx';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';

import { ADD_USER, UPDATE_USER, CHECK_USER } from '@shared/queries/UserQueries';
import { SINGLE_FILE_UPLOAD, SINGLE_FILE_REMOVE } from '@shared/queries';
import { checkExif } from '@utils/preUpload';
import { formatFilename } from '@utils/common';
import useStore from '@stores/useStore';
import { validateMessages } from '@utils/customFormValidator';
import { USER_GROUP } from '@shared/constants';

const { Option } = Select;

const PASSWORD_ASK = [
  '다른 이메일 주소는?',
  '나의 보물 1호는?',
  '나의 출신 초등학교는?',
  '나의 출신 고향은?',
  '나의 이상형은?',
  '어머니 성함은?',
  '아버지 성함은?',
  '가장 좋아하는 색깔은?',
  '가장 좋아하는 음식은?',
];

const Wrapper = styled.div`
  .ant-input-group-addon {
    padding: 0;
    margin: 0;
  }

  iframe {
    height: auto !important;
  }
`;

const UserForm = observer(({ propData = {}, refetch, isAdmin }) => {
  const [form] = Form.useForm();

  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    businessImage: null,
    recaptchaVal: null,
    isAuth: false,
    phone: '',
  }));

  const [add] = useMutation(ADD_USER);
  const [update] = useMutation(UPDATE_USER);
  const [singleFileUpload] = useMutation(SINGLE_FILE_UPLOAD);
  const [singleFileRemove] = useMutation(SINGLE_FILE_REMOVE);
  const client = useApolloClient();

  const onChangeRecaptcha = useCallback((value) => {
    state.recaptchaVal = value;
  }, []);

  const handleAuth = useCallback(() => {
    window.IMP.certification(
      {
        phone: state.phone,
      },
      (rsp) => {
        // callback
        if (rsp.success) {
          // 인증 성공 시 로직
          state.isAuth = true;
        } else {
          // 인증 실패 시 로직
          state.isAuth = false;
        }
      },
    );
  }, []);

  const saveImage = useCallback((resizeFile, companyName) => {
    return new Promise(async (resolve) => {
      if (!companyName || !companyName.length) {
        return resolve({ ok: false, errMsg: '업체명을 입력해 주세요' });
      }
      const filename = formatFilename(
        resizeFile.type,
        `businessImage/${companyName}`,
      );
      const data = {
        filename,
        file: resizeFile,
      };

      const res = await singleFileUpload({
        variables: { data },
      });
      let uid, name, url, ok;
      if (res.errors && res.errors.length) {
        ok = false;
      } else {
        uid = res.data.singleFileUpload.uid;
        url = res.data.singleFileUpload.url;
        name = filename;
        ok = true;
      }

      resolve({
        data: {
          uid,
          name,
          url,
          filename: resizeFile.name || resizeFile.filename,
          status: 'done',
        },
        ok,
      });
    });
  }, []);

  const handleSubmit = useCallback(
    async (values) => {
      if (!propData || !propData.id) {
        /*if (!isAdmin && !state.isAuth) {
          return window.alert({ title: '본인인증을 완료해 주세요' });
        }*/

        if (!state.recaptchaVal) {
          return window.alert({ title: '자동등록방지를 체크해 주세요' });
        }
      }

      window.maskOn();
      let findQuery = { email: values.email };
      if (propData && propData.id) {
        findQuery.username = { $ne: propData.username };
      }

      const checkRes = await client.query({
        query: CHECK_USER,
        variables: { findQuery },
      });
      if (checkRes.data && checkRes.data.checkUser) {
        window.maskOff();
        return window.alert({ title: '이미 존재하는 이메일 입니다.' });
      }

      const data = {
        ...values,
      };

      // delete data.authNum;

      let title = '';

      if (propData.id) {
        if (commonStore.user && commonStore.user.id) {
          data.updated = commonStore.user.id;
        }
        await update({
          variables: { id: propData.id, data },
        });
        title = `${propData.name} 회원이 수정되었습니다.`;
      } else {
        if (!isAdmin) {
          data.status = '정상';
          data.isAdmin = false;
          data.point = 0;
          data.role = 1;
          data.username = data.phone;
        }
        if (commonStore.user && commonStore.user.id) {
          data.created = commonStore.user.id;
        }
        await add({ variables: { data } });
        if (isAdmin) {
          title = `${values.name} 회원이 추가되었습니다.`;
        } else {
          title = `${values.name} 회원님 등록이 완료되었습니다.`;
        }
      }
      if (refetch) {
        await refetch();
      }
      form.resetFields();
      window.maskOff();
      window.success({
        title,
        onOk() {
          if (!isAdmin) {
            if (propData.id) {
              window.location.reload();
            } else {
              window.location.href = '/login';
            }
          }
        },
        onCancel() {
          if (!isAdmin) {
            if (propData.id) {
              window.location.reload();
            } else {
              window.location.href = '/login';
            }
          }
        },
      });
    },
    [propData, refetch, isAdmin],
  );

  const handleLeave = useCallback(() => {
    window.ask({
      title: '정말 탈퇴하시겠습니까?',
      content: '탈퇴하시면 복구하실 수 없습니다.',
      async onOk() {
        await update({
          variables: {
            id: propData.id,
            data: {
              status: '탈퇴',
              isBlock: false,
              isRemove: true,
            },
          },
        });

        window.success({
          title: '탈퇴 처리되었습니다.',
          content: `${propData.name} 회원님 그동안 이용해 주셔서 감사합니다.`,
        });
        commonStore.logout();
      },
    });
  }, [propData]);

  useEffect(() => {
    if (propData.phone && propData.phone.length) {
      state.phone = propData.phone;
    }
    if (form) {
      form.resetFields();
    }
  }, [propData, form]);

  return (
    <Wrapper>
      <Form
        form={form}
        layout="vertical"
        name="user_form"
        initialValues={{
          userGroup: propData.userGroup || '일반고객',
          role: propData.role || '',
          name: propData.name || '',
          username: propData.username || '',
          phone: propData.phone || '',
          password: '',
          passwordAsk: propData.passwordAsk || null,
          passwordAnswer: propData.passwordAnswer || '',
          email: propData.email || '',
          companyName: propData.companyName || '',
          companyTel: propData.companyTel || '',
          owner: propData.owner || '',
          businessNum: propData.businessNum || '',
        }}
        validateMessages={validateMessages}
        scrollToFirstError
        onFinish={handleSubmit}
        onValuesChange={(changedValues) => {
          if (
            changedValues.phone &&
            /^010[0-9]{4}[0-9]{4}$/.test(changedValues.phone)
          ) {
            state.phone = changedValues.phone;
          }
        }}
      >
        <Form.Item name="userGroup" label="회원구분">
          <Radio.Group disabled={propData.id && !isAdmin}>
            {USER_GROUP.map((item, idx) => (
              <Radio value={item} key={`userGroup_${idx}`}>
                {item}
              </Radio>
            ))}
            {isAdmin && <Radio value="관리자">관리자</Radio>}
          </Radio.Group>
        </Form.Item>

        <Form.Item name="name" label="성명" rules={[{ required: true }]}>
          <Input size="large" />
        </Form.Item>

        {isAdmin && (
          <Form.Item
            name="username"
            label="아이디"
            rules={[{ required: true }]}
          >
            <Input size="large" placeholder="아이디를 입력하세요" />
          </Form.Item>
        )}

        <Form.Item shouldUpdate noStyle>
          {() => (
            <Form.Item
              label="핸드폰번호"
              name="phone"
              rules={[
                {
                  required: true,
                  pattern: isAdmin ? /.+/ : /^010[0-9]{4}[0-9]{4}$/,
                },
              ]}
            >
              <Input
                size="large"
                placeholder="핸드폰번호를 - 없이 입력하세요"
                disabled={Boolean(propData && propData.id)}
                addonAfter={
                  null
                  /*Boolean(propData && propData.id) ? null : (
                    <Button
                      type="primary"
                      htmlType="button"
                      style={{ height: 38 }}
                      disabled={
                        !/^010[0-9]{4}[0-9]{4}$/.test(
                          form.getFieldValue('phone'),
                        )
                      }
                      onClick={handleAuth}
                    >
                      본인인증
                    </Button>
                  )*/
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        {/* {!propData.id && (
          <Form.Item
            name="authNum"
            rules={[{ required: true, message: '인증번호를 입력해 주세요.' }]}
          >
            <Input size="large" placeholder="인증번호를 입력하세요" />
          </Form.Item>
        )} */}

        <Form.Item
          label="비밀번호"
          name="password"
          rules={[
            {
              required: Boolean(!propData),
              type: 'string',
              min: 4,
              max: 16,
            },
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>

        {/* <Form.Item
          label="비밀번호 재확인"
          name="passwordConfirm"
          dependencies={['password']}
          rules={[
            {
              required: Boolean(!propData),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('비밀번호가 틀렸습니다');
              },
            }),
          ]}
        >
          <Input.Password size="large" />
        </Form.Item> */}

        <Form.Item
          label="비밀번호 질문"
          name="passwordAsk"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="예시를 선택하세요"
            size="large"
            style={{ width: '100%' }}
          >
            <Option value={null}>예시를 선택하세요</Option>
            {PASSWORD_ASK.map((item, idx) => (
              <Option value={item} key={`password_ask_${idx}`}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="passwordAnswer"
          rules={[
            {
              required: true,
              message: '질문에 대한 대답을 입력해 주세요.',
            },
          ]}
        >
          <Input size="large" placeholder="질문에 대한 대답을 작성하세요" />
        </Form.Item>

        <Form.Item
          label="이메일"
          name="email"
          rules={[
            {
              type: 'email',
              required: true,
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>

        <Form.Item
          label="업체명"
          name="companyName"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>

        <Form.Item label="대표자명" name="owner">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="업체연락처" name="companyTel">
          <Input size="large" placeholder="번호를 - 없이 입력하세요" />
        </Form.Item>
        <Form.Item label="사업자번호" name="businessNum">
          <Input size="large" placeholder="000-00-00000" />
        </Form.Item>

        {!propData.id && (
          <Form.Item label="자동등록방지">
            <ReCAPTCHA
              sitekey="6Ld3hcIZAAAAALWDpmxUc6WJZ4m-moE4_D3Nc9wA"
              onChange={onChangeRecaptcha}
            />
          </Form.Item>
        )}

        <Form.Item>
          {propData.id ? (
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={8}>
                <Button
                  size="large"
                  htmlType="button"
                  block
                  danger
                  onClick={handleLeave}
                >
                  회원탈퇴
                </Button>
              </Col>
              <Col xs={24} lg={16}>
                <Button type="primary" size="large" htmlType="submit" block>
                  저장
                </Button>
              </Col>
            </Row>
          ) : (
            <Button type="primary" size="large" htmlType="submit" block>
              가입하기
            </Button>
          )}
        </Form.Item>
      </Form>
    </Wrapper>
  );
});

export default UserForm;
