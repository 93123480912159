/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { Row, Col, Button, Affix, Divider, Select, Space } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { toJS } from 'mobx';

import { META, SAVE_META } from '@shared/queries';
import { USERS, UPDATE_USER } from '@shared/queries/UserQueries';
import Editor from '@Common/Editor';

import ProductConfigK from './ProductConfigK';
import ProductConfigPP from './ProductConfigPP';
import ProductConfigPPCaseBy from './ProductConfigPPCaseBy';
import ProductConfigOA from './ProductConfigOA';
import ProductConfigOT from './ProductConfigOT';

const { Option } = Select;

const Wrapper = styled.div`
  width: 100%;
`;

const ProductConfig = observer(({ metaKey, productName, tab }) => {
  const state = useLocalStore(() => ({
    curTab: null,
    productValue: null,
    metaProductValue: null,
    preSave: {
      home: null,
      detail: null,
      caution: null,
    },
    setPreSave(preSaveFunc, key) {
      const preSave = toJS(this.preSave);
      preSave[key] = preSaveFunc;
      state.preSave = preSave;
    },

    home: {},
    detail: {},
    caution: {},
    isActivated: true,
    users: [],
    selectedUser: null,
    selectedUserData: null,

    get editorVal() {
      const home = toJS(this.home);
      const detail = toJS(this.detail);
      const caution = toJS(this.caution);
      return {
        home,
        detail,
        caution,
      };
    },

    get productValueVal() {
      return toJS(this.productValue);
    },
    setProductValue(value) {
      this.productValue = value;
    },
  }));

  const client = useApolloClient();
  const [save] = useMutation(SAVE_META);
  const [updateUser] = useMutation(UPDATE_USER);

  const fetchData = useCallback(async () => {
    window.maskOn();
    state.productValue = null;
    const result = await client.query({
      query: META,
      variables: { key: metaKey },
    });
    if (result.data && result.data.meta) {
      const value = result.data.meta.value;
      for (const key in value) {
        state[key] = value[key];
      }
      state.productValue = value;
      state.metaProductValue = value;
    }

    const resUsers = await client.query({
      query: USERS,
      variables: {
        findQuery: {
          $or: [
            { isAdmin: false, isBlock: false, isRemove: false },
            { isTest: true },
          ],
        },
      },
    });
    if (resUsers.data && resUsers.data.users) {
      const users = [...resUsers.data.users];
      state.users = users;
      if (state.selectedUser) {
        const f = users.find(
          (item) => String(item.id) === String(state.selectedUser),
        );
        if (f) {
          state.selectedUserData = f;
          if (f.productValue && f.productValue[metaKey]) {
            state.productValue = f.productValue[metaKey];
          }
        }
      }
    }
    window.maskOff();
  }, []);

  const handleChangeUser = useCallback(
    (value) => {
      state.selectedUser = value;
      if (value) {
        const users = toJS(state.users);
        const f = users.find((item) => String(item.id) === String(value));
        if (f) {
          state.selectedUserData = f;
          if (f.productValue && f.productValue[metaKey]) {
            state.productValue = f.productValue[metaKey];
          }
        }
      } else {
        state.productValue = toJS(state.metaProductValue);
        state.selectedUserData = null;
      }
    },
    [metaKey],
  );

  const handleRemoveUserPrice = useCallback(() => {
    const selectedUserData = toJS(state.selectedUserData);
    if (selectedUserData) {
      window.ask({
        title: '계속 진행하시겠습니까?',
        content: `${selectedUserData.name} 회원의 ${productName} 개별 설정을 해제 하시겠습니까?`,
        async onOk() {
          window.maskOn();
          let productValue = selectedUserData.productValue;
          if (productValue && productValue[metaKey]) {
            delete productValue[metaKey];

            await updateUser({
              variables: {
                id: selectedUserData.id,
                data: {
                  productValue,
                },
              },
            });

            await fetchData();
            window.maskOff();
            window.success({
              title: `${selectedUserData.name} 회원의 ${productName} 개별 설정 해제가 완료되었습니다.`,
            });
          } else {
            window.maskOff();
          }
        },
      });
    }
  }, [metaKey]);

  const handleSubmit = useCallback(async () => {
    window.maskOn();

    const value = state.productValueVal;

    for (const type of Object.keys(state.preSave)) {
      if (state.preSave[type]) {
        const [content, contentImages] = await state.preSave[type]();
        if (!value[type]) {
          value[type] = {};
        }
        value[type].content = content;
        value[type].contentImages = contentImages;
      }
    }
    value.isActivated = state.isActivated;

    let title;
    if (state.selectedUser) {
      const users = toJS(state.users);
      const f = users.find(
        (item) => String(item.id) === String(state.selectedUser),
      );
      let productValue = {};
      if (f && f.productValue) {
        productValue = f.productValue;
      }
      productValue[metaKey] = value;

      await updateUser({
        variables: {
          id: state.selectedUser,
          data: {
            productValue,
          },
        },
      });
      title = `${f.name} 회원의 ${productName} 개별 설정 저장이 완료되었습니다.`;
    } else {
      await save({
        variables: {
          key: metaKey,
          data: {
            key: metaKey,
            value,
          },
        },
      });
      title = `${productName} 설정 저장이 완료되었습니다.`;
    }

    await fetchData();
    window.maskOff();
    window.success({ title });
  }, [metaKey]);

  useEffect(() => {
    if (state.curTab !== tab) {
      state.curTab = tab;
      fetchData();
    }
  }, [tab]);

  return (
    <Wrapper>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={20}>
          <h4>회원선택</h4>
          <Space>
            <Select
              value={state.selectedUser}
              showSearch
              style={{ width: 240 }}
              placeholder="고객선택"
              optionFilterProp="children"
              onChange={handleChangeUser}
              // filterOption={(input, option) =>
              //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
              //   0
              // }
            >
              <Option value={null}>선택안함</Option>
              {toJS(state.users).map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.username}/{item.name}
                </Option>
              ))}
            </Select>
            {toJS(state.selectedUserData) &&
              toJS(state.selectedUserData).productValue &&
              toJS(state.selectedUserData).productValue[metaKey] && (
                <Button type="primary" onClick={handleRemoveUserPrice}>
                  개별설정해제
                </Button>
              )}
          </Space>

          <Divider />

          {state.productValueVal && (
            <>
              {metaKey === 'product_k' && (
                <ProductConfigK
                  productValue={state.productValueVal}
                  setProductValue={state.setProductValue}
                />
              )}
              {metaKey === 'product_pp' && (
                <ProductConfigPP
                  productValue={state.productValueVal}
                  setProductValue={state.setProductValue}
                />
              )}
              {metaKey === 'product_pp_caseBy' && (
                <ProductConfigPPCaseBy
                  productValue={state.productValueVal}
                  setProductValue={state.setProductValue}
                />
              )}
              {metaKey === 'product_oa' && (
                <ProductConfigOA
                  productValue={state.productValueVal}
                  setProductValue={state.setProductValue}
                />
              )}
              {/* {metaKey === 'product_influencer' && (
            <ProductConfigOA
              productValue={state.productValueVal}
              setProductValue={state.setProductValue}
            />
          )} */}
              {metaKey === 'product_ot' && (
                <ProductConfigOT
                  productValue={state.productValueVal}
                  setProductValue={state.setProductValue}
                />
              )}

              <Divider />

              <Row gutter={[10, 20]}>
                {metaKey !== 'product_ot' && (
                  <Col xs={24}>
                    <h4>HOME 문구</h4>
                    <Editor
                      setPreSave={state.setPreSave}
                      preSave={state.preSave}
                      uploadUrl={`product/${metaKey}/home`}
                      content={state.editorVal.home.content}
                      images={
                        state.editorVal.home.contentImages
                          ? state.editorVal.home.contentImages
                          : []
                      }
                      lang="home"
                    />
                  </Col>
                )}
                <Col xs={24}>
                  <h4>
                    {metaKey === 'product_ot'
                      ? '최적화테스트 설명'
                      : '상세페이지 내용'}
                  </h4>
                  <Editor
                    setPreSave={state.setPreSave}
                    preSave={state.preSave}
                    uploadUrl={`product/${metaKey}/detail`}
                    content={state.editorVal.detail.content}
                    images={
                      state.editorVal.detail.contentImages
                        ? state.editorVal.detail.contentImages
                        : []
                    }
                    lang="detail"
                  />
                </Col>
                {(metaKey === 'product_oa' ||
                  metaKey === 'product_influencer') && (
                  <Col xs={24}>
                    <h4>프로그램 주의사항</h4>
                    <Editor
                      setPreSave={state.setPreSave}
                      preSave={state.preSave}
                      uploadUrl={`product/${metaKey}/caution`}
                      content={state.editorVal.caution.content}
                      images={
                        state.editorVal.caution.contentImages
                          ? state.editorVal.caution.contentImages
                          : []
                      }
                      lang="caution"
                    />
                  </Col>
                )}
              </Row>
            </>
          )}
        </Col>
        <Col xs={24} lg={4}>
          <Affix offsetTop={80}>
            <Button type="primary" size="large" block onClick={handleSubmit}>
              {toJS(state.selectedUser) ? '개별설정저장' : '저장'}
            </Button>
          </Affix>
        </Col>
      </Row>
    </Wrapper>
  );
});

export default ProductConfig;
