/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import uniqid from 'uniqid';

import useStore from '@stores/useStore';
import { formatFilename } from '@utils/common';
import { UPDATE_WORK } from '@shared/queries/WorkQueries';
import {
  DEFAULT_ZIP_UPLOAD,
  UPLOAD_PROGRESS,
  SINGLE_FILE_UPLOAD,
} from '@shared/queries';

import CustomModal from '@Common/CustomModal';

import ModifyK from './ModifyK';
import ModifyPP from './ModifyPP';
import ModifyOA from './ModifyOA';
import ModifyPPCaseBy from './ModifyPPCaseBy';

const Wrapper = styled.div`
  width: 100%;
`;

const Modify = observer(
  ({ propData = {}, modalTitle, modalOpen, handleModal, refetch, isAdmin }) => {
    const { commonStore } = useStore();
    const state = useLocalStore(() => ({}));

    const [update] = useMutation(UPDATE_WORK);
    const [fileUpload] = useMutation(SINGLE_FILE_UPLOAD);
    const [zipFileUpload] = useMutation(DEFAULT_ZIP_UPLOAD);
    const client = useApolloClient();

    const handleClose = useCallback(() => {
      handleModal(false)();
    }, []);

    const saveZipFile = useCallback((resizeFile, path) => {
      return new Promise(async (resolve) => {
        const progressUid = uniqid();
        const data = {
          file: resizeFile,
          uploadPath: path,
          progressUid,
        };

        state.zipUploadProgress = 0;
        const timer = setInterval(() => {
          state.zipUploadProgress = Math.floor(window.uploadProgress);
          if (window.uploadProgress >= 100) {
            clearInterval(timer);
          }
          window.maskOn();
        }, 10);

        client
          .subscribe({
            query: UPLOAD_PROGRESS,
            variables: { uploadPath: path, progressUid },
          })
          .subscribe({
            next({ data }) {
              state.progress = Math.floor(data.uploadProgress);
              if (data.uploadProgress < 100) {
                window.maskOn();
              }
            },
          });

        const res = await zipFileUpload({
          variables: { data },
        });
        if (res.data && res.data.defaultZipUpload) {
          if (res.data.defaultZipUpload.errMsg) {
            return resolve({
              ok: false,
              errMsg: res.data.defaultZipUpload.errMsg,
            });
          }

          const filename = resizeFile.name || resizeFile.filename;
          return resolve({
            images: res.data.defaultZipUpload.images,
            filename,
            path,
            ok: true,
          });
        }
        resolve({
          ok: false,
          errMsg: '업로드 데이터 처리 중 오류가 발생하였습니다.',
        });
      });
    }, []);

    const saveFile = useCallback((resizeFile, uploadUrl) => {
      return new Promise(async (resolve) => {
        const filename = formatFilename(resizeFile.type, uploadUrl);
        const data = {
          filename,
          file: resizeFile,
        };

        const res = await fileUpload({
          variables: { data },
        });
        let uid, name, url, err;
        if (res.errors && res.errors.length) {
          err = res.errors[0].message;
        } else {
          uid = res.data.singleFileUpload.uid;
          url = res.data.singleFileUpload.url;
          name = filename;
        }

        resolve({
          data: {
            uid,
            name,
            url,
            status: 'done',
          },
          err,
        });
      });
    }, []);

    const handleSubmit = useCallback(
      async (id, data, upload, ppCaseByUpload) => {
        if (!id) {
          return window.alert({
            title: '업데이트할 데이터를 찾을 수 없습니다.',
          });
        }

        window.maskOn(300000);
        let isErr = false;
        try {
          if (upload) {
            data.fileType = upload.fileType;
            if (upload.fileType === 'image') {
              const images = await upload.handleUpload();
              data.images = images;
            } else if (upload.zipFile && upload.zipFile.status === 'load') {
              const res = await saveZipFile(upload.zipFile, upload.uploadPath);
              if (res.ok) {
                data.images = res.images;
                data.zipFile = {
                  filename: res.filename,
                  path: res.path,
                };
              }
            }

            if (upload.videos && upload.videos.length) {
              data.videos = [];
              for (const v of upload.videos) {
                if (v.status === 'load') {
                  const res = await saveFile(v, upload.uploadPath);
                  if (!res.err) {
                    data.videos.push(res.data);
                  }
                }
              }
            }
          }

          if (ppCaseByUpload) {
            if (ppCaseByUpload.handleUpload) {
              data.fileType = 'image';
              if (data.isInstaPostRandom) {
                const images = await ppCaseByUpload.handleUpload();
                data.randomInstaPostData.images = images;
              } else {
                let dataType = 'buyCaseData';
                if (data.paymentType === '월구매') {
                  dataType = 'buyMonthData';
                } else if (data.paymentType === '고객월건바이') {
                  dataType = 'buyCustomerMonthData';
                }

                for (const [idx, item] of data[dataType].entries()) {
                  for (const [
                    postIdx,
                    postData,
                  ] of item.instaPostData.entries()) {
                    const images = await ppCaseByUpload.handleUpload[
                      `${item.uid}_${postIdx}`
                    ]();
                    data[dataType][idx].instaPostData[postIdx].images = images;
                  }
                }
              }
            }

            //video upload
            if (data.isInstaPostRandom) {
              if (
                data.randomInstaPostData &&
                data.randomInstaPostData.videos &&
                data.randomInstaPostData.videos.length
              ) {
                const videos = [];
                for (const v of data.randomInstaPostData.videos) {
                  if (v.status === 'load') {
                    const res = await saveFile(v, ppCaseByUpload.uploadPath);
                    if (!res.err) {
                      videos.push(res.data);
                    }
                  }
                }
                data.randomInstaPostData.videos = videos;
              }
            } else {
              let dataType = 'buyCaseData';
              if (data.paymentType === '월구매') {
                dataType = 'buyMonthData';
              } else if (data.paymentType === '고객월건바이') {
                dataType = 'buyCustomerMonthData';
              }

              for (const [idx, item] of data[dataType].entries()) {
                for (const [
                  postIdx,
                  postData,
                ] of item.instaPostData.entries()) {
                  if (postData.videos && postData.videos.length) {
                    const videos = [];
                    for (const v of postData.videos) {
                      if (v.status === 'load') {
                        const res = await saveFile(
                          v,
                          ppCaseByUpload.uploadPath,
                        );
                        if (!res.err) {
                          videos.push(res.data);
                        }
                      }
                    }
                    data[dataType][idx].instaPostData[postIdx].videos = videos;
                  }
                }
              }
            }
          }
        } catch (error) {
          isErr = true;
        }

        if (isErr) {
          window.alert({ title: '등록 중 오류가 발생하였습니다.' });
          return;
        }

        if (!data.hashtags || !data.hashtags[0] || !data.hashtags[0].length) {
          data.hashtags = [];
        }
        if (!data.instaContents || !data.instaContents[0]) {
          data.instaContents = [];
        }

        await update({
          variables: {
            id,
            data,
          },
        });
        window.maskOff();

        if (refetch) {
          await refetch();
        }

        window.success({ title: '저장이 완료되었습니다.' });
        handleClose();
      },
      [refetch],
    );

    useEffect(() => {}, []);

    return (
      <CustomModal
        title={modalTitle}
        visible={modalOpen}
        onCancel={handleClose}
        minwidth={800}
        footer={null}
      >
        <Wrapper>
          {propData.workType === 'K댓글' && (
            <ModifyK
              propData={propData}
              handleSubmit={handleSubmit}
              handleClose={handleClose}
              isAdmin={isAdmin}
            />
          )}
          {propData.workType === '인기게시물' && (
            <ModifyPP
              propData={propData}
              handleSubmit={handleSubmit}
              handleClose={handleClose}
              isAdmin={isAdmin}
              isContentModify={/(test1)|(test2)|(test3)|(test4)|(eluen)/.test(
                commonStore.user.username,
              )}
            />
          )}
          {propData.workType === '최적화계정육성' && (
            <ModifyOA
              propData={propData}
              handleSubmit={handleSubmit}
              handleClose={handleClose}
              isAdmin={isAdmin}
            />
          )}
          {propData.workType === '인기게시물건바이' && (
            <ModifyPPCaseBy
              propData={propData}
              handleSubmit={handleSubmit}
              handleClose={handleClose}
              isAdmin={isAdmin}
            />
          )}
        </Wrapper>
      </CustomModal>
    );
  },
);

export default Modify;
