import gql from 'graphql-tag';
import { workFragment } from '@shared/fragments';

export const WORKS = gql`
  ${workFragment}
  query Works($page: Int, $limit: Int, $findQuery: JSON) {
    works(page: $page, limit: $limit, findQuery: $findQuery) {
      docs {
        ...WorkFragment
      }
      totalDocs
      limit
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      pagingCounter
    }
  }
`;

export const WORK = gql`
  ${workFragment}
  query Work($findQuery: JSON) {
    work(findQuery: $findQuery) {
      ...WorkFragment
    }
  }
`;

export const WORK_LIST = gql`
  ${workFragment}
  query WorkList($findQuery: JSON) {
    workList(findQuery: $findQuery) {
      ...WorkFragment
    }
  }
`;

export const ADD_WORK = gql`
  ${workFragment}
  mutation AddWork($data: InputWork) {
    addWork(data: $data) {
      ...WorkFragment
    }
  }
`;

export const UPDATE_WORK = gql`
  ${workFragment}
  mutation UpdateWork($id: ID!, $data: InputWork) {
    updateWork(id: $id, data: $data) {
      ...WorkFragment
    }
  }
`;

export const REMOVE_WORK = gql`
  mutation RemoveWork($id: ID!) {
    removeWork(id: $id)
  }
`;
