import { action, observable } from 'mobx';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';

import { userFragment } from '@shared/fragments';

class AuthStore {
  commonStore;
  @observable authId = '';

  constructor(root) {
    this.commonStore = root.commonStore;
  }
  @action loginCheck = (apolloClient) => {
    return new Promise(async (resolve) => {
      const result = await apolloClient.query({
        query: gql`
          ${userFragment}

          query User {
            user {
              ...UserFragment
            }
          }
        `,
      });

      let user = null;
      if (result && result.data && result.data.user) {
        user = result.data.user;
        if (!user.isBlock && !user.isRemove) {
          const isAdminLayout = user.isAdmin;
          this.commonStore.isAdminLayout = isAdminLayout;

          // const token = Cookies.get('jwt');
          // if (!token || user.accessToken !== token) {
          //   // Cookies.set('jwt', user.accessToken);
          //   this.commonStore.logout();
          // }
        }
      } else {
        this.commonStore.isAdminLayout = null;
      }

      this.commonStore.user = user;
      resolve(user);
    });
  };
}
export default AuthStore;
