/* eslint-disable react-hooks/exhaustive-deps*/
import React from 'react';
import { Card, Row, Col, Collapse } from 'antd';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import UserInfo from './UserInfo';
import CompanyInfo from './CompanyInfo';
import ActivityInfo from './ActivityInfo';
import PaymentInfo from './PaymentInfo';

const { Panel } = Collapse;

const Wrapper = styled.div`
  padding: 20px;
  padding-top: 50px;
  width: 100%;
`;

const Detail = observer(({ selectedData, refetch }) => {
  if (!selectedData || !selectedData.id) {
    return <div />;
  }
  return (
    <Wrapper>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={8}>
          <Card title="회원정보">
            <UserInfo propData={selectedData} refetch={refetch} />
          </Card>
        </Col>
        <Col xs={24} lg={8}>
          <Card title="업체정보">
            <CompanyInfo propData={selectedData} refetch={refetch} />
          </Card>
        </Col>
        <Col xs={24} lg={8}>
          <Card title="활동정보">
            <ActivityInfo propData={selectedData} />
          </Card>
        </Col>
      </Row>

      <Collapse defaultActiveKey={['1', '2', '3']}>
        <Panel header="결제내역" key="1">
          <PaymentInfo user={selectedData} refetch={refetch} />
        </Panel>
      </Collapse>
    </Wrapper>
  );
});

export default Detail;
