import gql from 'graphql-tag';
import { usePPFragment } from '@shared/fragments';

export const USE_PPS = gql`
  ${usePPFragment}
  query UsePPs($page: Int, $limit: Int, $findQuery: JSON) {
    usePPs(page: $page, limit: $limit, findQuery: $findQuery) {
      docs {
        ...UsePPFragment
      }
      totalDocs
      limit
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      pagingCounter
    }
  }
`;

export const USE_PP = gql`
  ${usePPFragment}
  query UsePP($findQuery: JSON) {
    usePP(findQuery: $findQuery) {
      ...UsePPFragment
    }
  }
`;

export const USE_PP_LIST = gql`
  ${usePPFragment}
  query UsePPList($findQuery: JSON) {
    usePPList(findQuery: $findQuery) {
      ...UsePPFragment
    }
  }
`;

export const ADD_USE_PP = gql`
  ${usePPFragment}
  mutation AddUsePP($data: InputUsePP) {
    addUsePP(data: $data) {
      ...UsePPFragment
    }
  }
`;

export const UPDATE_USE_PP = gql`
  ${usePPFragment}
  mutation UpdateUsePP($id: ID!, $data: InputUsePP) {
    updateUsePP(id: $id, data: $data) {
      ...UsePPFragment
    }
  }
`;

export const REMOVE_USE_PP = gql`
  mutation RemoveUsePP($id: ID!) {
    removeUsePP(id: $id)
  }
`;
