/* eslint-disable react-hooks/exhaustive-deps*/
import React from 'react';
import { observer } from 'mobx-react';

import PostList from './PostList';

const PostContainer = observer(({ board, title, isAdmin, userId }) => {
  return (
    <PostList board={board} title={title} isAdmin={isAdmin} userId={userId} />
  );
});

export default PostContainer;
