/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback, useMemo } from 'react';
import {
  Table,
  Row,
  Col,
  Space,
  DatePicker,
  Button,
  Input,
  Select,
  Grid,
  Descriptions,
  Image,
} from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import moment from 'moment';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { toJS } from 'mobx';

import useStore from '@stores/useStore';
import { INVOICES, UPDATE_INVOICE } from '@shared/queries/InvoiceQueries';
import { numberWithCommas } from '@utils/common';

import CustomModal from '@Common/CustomModal';

const { Search } = Input;
const { Option } = Select;
const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  width: 100%;

  .ant-table-summary {
    background-color: #fafafa;
    text-align: center;
  }
`;

const Invoice = observer(({ tab }) => {
  const screen = useBreakpoint();
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    list: [],
    paginate: {
      docs: [],
      totalDocs: 0,
      limit: 10,
      page: 1,
      totalPages: 1,
    },

    searchInvoiceType: null,
    startDate: null,
    endDate: null,
    searchStatus: null,
    searchText: '',

    detailOpen: false,
    detailData: {},

    get detailDataVal() {
      return toJS(this.detailData);
    },
  }));

  const [update] = useMutation(UPDATE_INVOICE);
  const client = useApolloClient();

  const initFetchData = useCallback(async () => {
    const now = await commonStore.getServerNow();
    const startDate = moment(now.startOf('month'));
    const endDate = moment(now.endOf('month'));
    state.startDate = startDate;
    state.endDate = endDate;
    await fetchData({
      $and: [
        { createdAt: { $gte: startDate } },
        { createdAt: { $lte: endDate } },
      ],
    });
  }, []);

  const fetchData = useCallback(async (findQuery = {}) => {
    window.maskOn();

    const result = await client.query({
      query: INVOICES,
      variables: {
        page: state.paginate.page,
        limit: state.paginate.limit,
        findQuery,
      },
    });

    if (result.data && result.data.invoices) {
      state.paginate = result.data.invoices;
      state.list = result.data.invoices.docs;
    }
    window.maskOff();
  }, []);

  const handleChangePage = useCallback((page) => {
    if (state.paginate.page !== page) {
      state.paginate.page = page;
      handleSearch();
    }
  }, []);

  const handleChangeDate = useCallback(
    (type) => (momentVal) => {
      state[type] = momentVal;
    },
    [],
  );

  const handleChangeSelect = useCallback(
    (type) => (value) => {
      state[type] = value;
    },
    [],
  );

  const handleChangeInput = useCallback(
    (type) => (e) => {
      state[type] = e.target.value;
    },
    [],
  );

  const handleSearch = useCallback((value) => {
    const findQuery = {};

    if (state.searchText.length) {
      findQuery['$or'] = [
        { userId: { $regex: state.searchText, $options: 'ig' } },
        { userName: { $regex: state.searchText, $options: 'ig' } },
      ];
    }

    if (state.searchStatus) {
      findQuery.status = state.searchStatus;
    }

    if (state.searchInvoiceType) {
      findQuery.invoiceType = state.searchInvoiceType;
    }

    findQuery['$and'] = [];
    if (state.startDate) {
      findQuery['$and'].push({
        createdAt: {
          $gte: state.startDate.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          }),
        },
      });
    }
    if (state.endDate) {
      findQuery['$and'].push({
        createdAt: {
          $lte: state.endDate.set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999,
          }),
        },
      });
    }
    if (!findQuery['$and'].length) {
      delete findQuery['$and'];
    }

    fetchData(findQuery);
  }, []);

  const handleSubmit = useCallback(
    (row) => () => {
      window.ask({
        title: '발행처리 하시겠습니까?',
        async onOk() {
          const updateRes = await update({
            variables: {
              id: row.id,
              data: {
                status: '완료',
                manager: commonStore.user.id,
              },
            },
          });

          await handleSearch();
          window.success({ title: '발행처리가 완료되었습니다.' });
        },
      });
    },
    [],
  );

  const handleDetailModal = useCallback(
    (modalOpen, data) => (e) => {
      if (modalOpen && data) {
        state.detailData = data;
      } else {
        state.detailData = {};
      }
      state.detailOpen = modalOpen;
    },
    [],
  );

  useEffect(() => {
    if (tab === '5') {
      initFetchData();
    }
  }, [tab]);

  useEffect(() => {
    initFetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        title: '날짜',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (_, row) => moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        align: 'center',
      },
      {
        title: '아이디',
        dataIndex: 'userId',
        key: 'userId',
        render: (_, row) => row.userId,
        align: 'center',
      },
      {
        title: '성명',
        dataIndex: 'userName',
        key: 'userName',
        render: (_, row) => row.userName,
        align: 'center',
      },
      {
        title: '회사명',
        dataIndex: 'companyName',
        key: 'companyName',
        align: 'center',
      },
      {
        title: '종류',
        dataIndex: 'invoiceType',
        key: 'invoiceType',
        render: (_, row) =>
          row.invoiceType === '현금영수증'
            ? `${row.invoiceType}(${row.cashType ? row.cashType : ''})`
            : row.invoiceType,
        align: 'center',
      },
      {
        title: '총주문액',
        dataIndex: 'orderAmount',
        key: 'orderAmount',
        render: (_, row) => numberWithCommas(row.amount - row.tax, true),
        align: 'center',
      },
      {
        title: '세액',
        dataIndex: 'tax',
        key: 'tax',
        render: (_, row) => numberWithCommas(row.tax, true),
        align: 'center',
      },
      {
        title: '총신청금액',
        dataIndex: 'amount',
        key: 'amount',
        render: (_, row) => numberWithCommas(row.amount, true),
        align: 'center',
      },
      {
        title: '상태',
        dataIndex: 'status',
        key: 'status',
        render: (_, row) => (
          <span className={row.status === '완료' ? 'blue' : 'red'}>
            {row.status}
          </span>
        ),
        align: 'center',
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (_, row) => (
          <Space>
            <Button onClick={handleDetailModal(true, row)}>상세</Button>
            {row.status !== '완료' && (
              <Button type="primary" onClick={handleSubmit(row)}>
                발행처리
              </Button>
            )}
          </Space>
        ),
        align: 'center',
      },
    ],
    [],
  );

  const mobileColumns = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'idx',
        key: 'idx',
        render: (_, row, idx) => {
          return (
            <Descriptions size="small" bordered column={1}>
              <Descriptions.Item label="날짜">
                {moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')}
              </Descriptions.Item>
              <Descriptions.Item label="아이디">{row.userId}</Descriptions.Item>
              <Descriptions.Item label="성명">{row.userName}</Descriptions.Item>
              <Descriptions.Item label="회사명">
                {row.companyName}
              </Descriptions.Item>
              <Descriptions.Item label="종류">
                {row.invoiceType === '현금영수증'
                  ? `${row.invoiceType}(${row.cashType ? row.cashType : ''})`
                  : row.invoiceType}
              </Descriptions.Item>
              <Descriptions.Item label="총주문액">
                {numberWithCommas(row.amount - row.tax, true)}
              </Descriptions.Item>
              <Descriptions.Item label="세액">
                {numberWithCommas(row.tax, true)}
              </Descriptions.Item>
              <Descriptions.Item label="총신청금액">
                {numberWithCommas(row.amount, true)}
              </Descriptions.Item>
              <Descriptions.Item label="상태">
                <span className={row.status === '완료' ? 'blue' : 'red'}>
                  {row.status}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="">
                <Space>
                  <Button onClick={handleDetailModal(true, row)}>상세</Button>
                  {row.status !== '완료' && (
                    <Button type="primary" onClick={handleSubmit(row)}>
                      발행처리
                    </Button>
                  )}
                </Space>
              </Descriptions.Item>
            </Descriptions>
          );
        },
      },
    ],
    [],
  );

  return (
    <Wrapper>
      <Row gutter={[32, 16]} align="bottom">
        <Col xs={24} lg={6}>
          <h4>기간별 검색</h4>
          <Space>
            <DatePicker
              placeholder="시작일"
              value={state.startDate}
              onChange={handleChangeDate('startDate')}
            />
            <DatePicker
              placeholder="종료일"
              value={state.endDate}
              onChange={handleChangeDate('endDate')}
            />
          </Space>
        </Col>

        <Col xs={24} lg={4}>
          <h4>처리방식</h4>
          <Select
            value={state.searchInvoiceType}
            onChange={handleChangeSelect('searchInvoiceType')}
            style={{ width: '100%' }}
          >
            <Option value={null}>전체</Option>
            <Option value={'현금영수증'}>현금영수증</Option>
            <Option value={'세금계산서'}>세금계산서</Option>
          </Select>
        </Col>
        <Col xs={24} lg={4}>
          <h4>상태검색</h4>
          <Select
            value={state.searchStatus}
            onChange={handleChangeSelect('searchStatus')}
            style={{ width: '100%' }}
          >
            <Option value={null}>전체</Option>
            <Option value={'발행처리전'}>발행처리전</Option>
            <Option value={'완료'}>완료</Option>
          </Select>
        </Col>
        <Col xs={24} lg={4}>
          <h4>ID검색</h4>
          <Input
            placeholder="이름 | 아이디"
            value={state.searchText}
            onChange={handleChangeInput('searchText')}
          />
        </Col>
        <Col xs={24} lg={4}>
          <Button type="primary" block onClick={handleSearch}>
            검색
          </Button>
        </Col>
      </Row>

      <Table
        size="small"
        dataSource={toJS(state.list)}
        columns={screen.lg ? columns : mobileColumns}
        // scroll={{ x: 992 }}
        pagination={{
          current: state.paginate.page,
          total: state.paginate.totalDocs,
          showSizeChanger: false,
          onChange: handleChangePage,
        }}
        rowKey={(row) => row.id}
        bordered
        summary={(pageData) => {
          let pageOrderAmount = 0;
          let pageTax = 0;
          let pageAmount = 0;
          for (const row of pageData) {
            pageOrderAmount += row.amount - row.tax;
            pageTax += row.tax;
            pageAmount += row.amount;
          }

          return screen.lg ? (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
              <Table.Summary.Cell>
                {numberWithCommas(pageOrderAmount, true)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {numberWithCommas(pageTax, true)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {numberWithCommas(pageAmount, true)}
              </Table.Summary.Cell>
              <Table.Summary.Cell colSpan={2}></Table.Summary.Cell>
            </Table.Summary.Row>
          ) : (
            <Table.Summary.Row>
              <Table.Summary.Cell>
                <Descriptions size="small" bordered column={1}>
                  <Descriptions.Item label="총주문액 합계">
                    {numberWithCommas(pageOrderAmount, true)}
                  </Descriptions.Item>
                  <Descriptions.Item label="세액 합계">
                    {numberWithCommas(pageTax, true)}
                  </Descriptions.Item>
                  <Descriptions.Item label="총신청금액 합계">
                    {numberWithCommas(pageAmount, true)}
                  </Descriptions.Item>
                </Descriptions>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />

      <CustomModal
        visible={state.detailOpen}
        onCancel={handleDetailModal(false)}
        forceRender={true}
        footer={null}
        minwidth={800}
      >
        {state.detailDataVal.id && (
          <Descriptions
            size="small"
            bordered
            column={1}
            style={{ marginTop: 30 }}
          >
            <Descriptions.Item label="날짜">
              {moment(state.detailDataVal.createdAt).format(
                'YYYY-MM-DD HH:mm:ss',
              )}
            </Descriptions.Item>
            <Descriptions.Item label="아이디">
              {state.detailDataVal.userId}
            </Descriptions.Item>
            <Descriptions.Item label="고객명">
              {state.detailDataVal.userName}
            </Descriptions.Item>
            <Descriptions.Item label="회사명">
              {state.detailDataVal.companyName}
            </Descriptions.Item>
            <Descriptions.Item label="사업자등록번호">
              {state.detailDataVal.businessNum}
            </Descriptions.Item>
            {state.detailDataVal.invoiceType === '현금영수증' && (
              <>
                <Descriptions.Item label="연락처">
                  {state.detailDataVal.phone}
                </Descriptions.Item>
              </>
            )}
            {state.detailDataVal.invoiceType === '세금계산서' && (
              <>
                <Descriptions.Item label="대표자">
                  {state.detailDataVal.owner}
                </Descriptions.Item>
                <Descriptions.Item label="담당자연락처">
                  {state.detailDataVal.phone}
                </Descriptions.Item>
                <Descriptions.Item label="사업장주소">
                  {state.detailDataVal.address}
                </Descriptions.Item>
                <Descriptions.Item label="메일주소">
                  {state.detailDataVal.email}
                </Descriptions.Item>
              </>
            )}
            <Descriptions.Item label="총주문액">
              {numberWithCommas(
                state.detailDataVal.amount - state.detailDataVal.tax,
                true,
              )}
            </Descriptions.Item>
            <Descriptions.Item label="세액">
              {numberWithCommas(state.detailDataVal.tax, true)}
            </Descriptions.Item>
            <Descriptions.Item label="총신청금액">
              {numberWithCommas(state.detailDataVal.amount, true)}
            </Descriptions.Item>
            <Descriptions.Item label="사업자등록증">
              {state.detailDataVal.businessImage &&
                state.detailDataVal.businessImage.url && (
                  <Image
                    src={state.detailDataVal.businessImage.url}
                    width="100px"
                  />
                )}
            </Descriptions.Item>
          </Descriptions>
        )}
      </CustomModal>
    </Wrapper>
  );
});

export default Invoice;
