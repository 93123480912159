/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Row, Col, Button, Grid } from 'antd';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import MainBanner from './MainBanner';

const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  width: 100%;
`;

const BeforeLogin = observer(
  ({
    handleProductDetailModal,
    product_k,
    product_pp,
    product_oa,
    product_pp_caseBy,
  }) => {
    const screen = useBreakpoint();
    const router = useHistory();

    return (
      <Wrapper id="before_login">
        <Row justify="center" gutter={[20, 20]}>
          <Col xs={24} lg={12}>
            <Row gutter={[20, 20]}>
              <Col xs={24}>
                <MainBanner />
              </Col>
              <Col xs={24}>
                <Button
                  className="menu-btn"
                  block
                  size="large"
                  onClick={() => router.push('/notice')}
                >
                  공지사항
                </Button>
              </Col>
              <Col xs={24}>
                <Button
                  className="menu-btn"
                  block
                  size="large"
                  onClick={() => router.push('/qna')}
                >
                  사용방법
                </Button>
              </Col>
              <Col xs={24}>
                <Button
                  className="menu-btn"
                  block
                  size="large"
                  onClick={() => router.push('/login')}
                >
                  포인트충전
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={12}>
            <Row gutter={[20, 20]}>
              <Col xs={12}>
                <Card>
                  <div className="title">K좋아요K팔로워K댓글</div>
                  {product_k && (
                    <>
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html: product_k.home.content,
                        }}
                      />
                      <Button
                        size="large"
                        type="primary"
                        ghost
                        block
                        onClick={handleProductDetailModal(
                          true,
                          product_k.detail.content,
                        )}
                      >
                        자세히보기 {'>'}
                      </Button>
                    </>
                  )}
                </Card>
              </Col>
              <Col xs={12}>
                <Card>
                  <div className="title">인기게시물 상위작업</div>
                  {product_pp && (
                    <>
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html: product_pp.home.content,
                        }}
                      />
                      <Button
                        size="large"
                        type="primary"
                        ghost
                        block
                        onClick={handleProductDetailModal(
                          true,
                          product_pp.detail.content,
                        )}
                      >
                        자세히보기 {'>'}
                      </Button>
                    </>
                  )}
                </Card>
              </Col>
              <Col xs={12}>
                <Card>
                  <div className="title">인기게시물 건바이</div>
                  {product_pp_caseBy && (
                    <>
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html: product_pp_caseBy.home.content,
                        }}
                      />
                      <Button
                        size="large"
                        type="primary"
                        ghost
                        block
                        onClick={handleProductDetailModal(
                          true,
                          product_pp_caseBy.detail.content,
                        )}
                      >
                        자세히보기 {'>'}
                      </Button>
                    </>
                  )}
                </Card>
              </Col>

              <Col xs={12}>
                <Card>
                  <div className="title">최적화 계정육성</div>
                  {product_oa && (
                    <>
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html: product_oa.home.content,
                        }}
                      />
                      <Button
                        size="large"
                        type="primary"
                        ghost
                        block
                        onClick={handleProductDetailModal(
                          true,
                          product_oa.detail.content,
                        )}
                      >
                        자세히보기 {'>'}
                      </Button>
                    </>
                  )}
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Wrapper>
    );
  },
);

export default BeforeLogin;
