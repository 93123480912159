import fetch from 'isomorphic-unfetch';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { ApolloLink, split } from 'apollo-link';
import moment from 'moment';
import Cookies from 'js-cookie';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
require('dotenv').config();

const defaultOptions = {
  query: {
    // fetchPolicy: 'network-only',
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

function customFetch(url, opts = {}) {
  window.uploadProgress = 0;
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open(opts.method || 'get', url);

    for (let k in opts.headers || {}) xhr.setRequestHeader(k, opts.headers[k]);

    xhr.onload = (e) =>
      resolve({
        ok: true,
        text: () => Promise.resolve(e.target.responseText),
        json: () => Promise.resolve(JSON.parse(e.target.responseText)),
      });

    xhr.onerror = reject;

    if (xhr.upload)
      xhr.upload.onprogress = (event) => {
        window.uploadProgress = (event.loaded / event.total) * 100;
        // console.log(`${(event.loaded / event.total) * 100}% uploaded`);
      };

    xhr.send(opts.body);
  });
}

const wsLink = new WebSocketLink({
  uri:
    process.env.REACT_APP_NODE_ENV === 'production'
      ? `wss://www.xn--mk1bm71azldbvj.com/subscriptions`
      : process.env.REACT_APP_NODE_ENV === 'test'
      ? `wss://test.xn--mk1bm71azldbvj.com/subscriptions`
      : 'ws://localhost:4000/subscriptions',
  options: {
    reconnect: true,
  },
});

const uploadLink = createUploadLink({
  fetch: typeof window === 'undefined' ? fetch : customFetch,
  uri:
    process.env.REACT_APP_NODE_ENV === 'production'
      ? `https://www.xn--mk1bm71azldbvj.com/api`
      : process.env.REACT_APP_NODE_ENV === 'test'
      ? `https://test.xn--mk1bm71azldbvj.com/api`
      : 'http://localhost:4000/api',
  credentials: 'same-origin',
});

const authLink = setContext((_, { headers }) => {
  const jwt =
    window.localStorage.getItem('addpick-instagram-jwt') ||
    Cookies.get('addpick-instagram-jwt');
  if (jwt) {
    if (headers) {
      headers.Authorization = `Bearer ${jwt}`;
    } else {
      headers = {
        Authorization: `Bearer ${jwt}`,
      };
    }
  }
  return {
    headers,
  };
});

const isFile = (value) =>
  (typeof File !== 'undefined' && value instanceof File) ||
  (typeof Blob !== 'undefined' && value instanceof Blob);

const omitDeep = (value, key) => {
  if (isFile(value) || value instanceof Date || moment.isMoment(value)) {
    return value;
  } else if (Array.isArray(value)) {
    return value.map((i) => omitDeep(i, key));
  } else if (typeof value === 'object' && value !== null && !isFile(value)) {
    return Object.keys(value).reduce((newObject, k) => {
      if (k === key) return newObject;
      return Object.assign({ [k]: omitDeep(value[k], key) }, newObject);
    }, {});
  }
  return value;
};

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, '__typename');
  }
  return forward(operation);
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  uploadLink,
);

const client = new ApolloClient({
  link: ApolloLink.from([authLink, cleanTypeName, splitLink]),
  cache: new InMemoryCache(),
  defaultOptions,
});

export default client;
