import gql from 'graphql-tag';
import { pointLogFragment } from '@shared/fragments';

export const POINT_LOGS = gql`
  ${pointLogFragment}
  query PointLogs($page: Int, $limit: Int, $findQuery: JSON) {
    pointLogs(page: $page, limit: $limit, findQuery: $findQuery) {
      docs {
        ...PointLogFragment
      }
      totalDocs
      limit
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      pagingCounter
    }
  }
`;

export const POINT_LOG = gql`
  ${pointLogFragment}
  query PointLog($findQuery: JSON) {
    pointLog(findQuery: $findQuery) {
      ...PointLogFragment
    }
  }
`;

export const POINT_LOG_LIST = gql`
  ${pointLogFragment}
  query PointLogList($findQuery: JSON) {
    pointLogList(findQuery: $findQuery) {
      ...PointLogFragment
    }
  }
`;

export const ADD_POINT_LOG = gql`
  ${pointLogFragment}
  mutation AddPointLog($data: InputPointLog) {
    addPointLog(data: $data) {
      ...PointLogFragment
    }
  }
`;

export const UPDATE_POINT_LOG = gql`
  ${pointLogFragment}
  mutation UpdatePointLog($id: ID!, $data: InputPointLog) {
    updatePointLog(id: $id, data: $data) {
      ...PointLogFragment
    }
  }
`;

export const REMOVE_POINT_LOG = gql`
  mutation RemovePointLog($id: ID!) {
    removePointLog(id: $id)
  }
`;
