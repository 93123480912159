/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { Row, Col, Space, DatePicker, Button, Select, Input } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';

const { Search } = Input;
const { Option } = Select;

const Wrapper = styled.div`
  width: 100%;

  ${(props) => props.theme.breakpoint('xs', 'lg')`
  `}
`;

const CustomerSearch = observer(
  ({ dataKey, setSearch, dateData, setSearchDate }) => {
    const state = useLocalStore(() => ({
      searchType: 'instaId',
      startDate: null,
      endDate: null,
    }));

    const handleChangeSearch = useCallback(
      (type) => (value) => {
        state[type] = value;
      },
      [],
    );
    const handleChangeDate = useCallback(
      (type) => (momentVal) => {
        // state[type] = momentVal;
        if (dateData) {
          setSearchDate(`${dataKey}Date`, { ...dateData, [type]: momentVal });
        } else {
          setSearchDate(`${dataKey}Date`, { [type]: momentVal });
        }
      },
      [dataKey, dateData],
    );

    const handleSearch = useCallback(
      (value) => {
        const findQuery = {};

        if (value && value.length) {
          if (state.searchType) {
            if (dataKey === 'ppCaseBySearch') {
              findQuery['$or'] = [
                {
                  'buyCaseData.instaPostData.mainHashtag': {
                    $regex: value,
                    $options: 'ig',
                  },
                },
                {
                  'buyMonthData.instaPostData.mainHashtag': {
                    $regex: value,
                    $options: 'ig',
                  },
                },
                {
                  'buyCustomerMonthData.instaPostData.mainHashtag': {
                    $regex: value,
                    $options: 'ig',
                  },
                },
                {
                  'randomInstaPostData.mainHashtag': {
                    $regex: value,
                    $options: 'ig',
                  },
                },
              ];
            } else {
              findQuery[state.searchType] = { $regex: value, $options: 'ig' };
            }
          } else {
            findQuery.all = {
              instaId: { $regex: value, $options: 'ig' },
            };
          }
        }

        findQuery['$and'] = [];
        if (state.startDate) {
          findQuery['$and'].push({
            updatedAt: {
              $gte: state.startDate.startOf('date').toDate(),
            },
          });
        }
        if (state.endDate) {
          findQuery['$and'].push({
            updatedAt: {
              $lte: state.endDate.endOf('date').toDate(),
            },
          });
        }
        if (!findQuery['$and'].length) {
          delete findQuery['$and'];
        }

        setSearch(dataKey, findQuery);
      },
      [dataKey, setSearch],
    );

    useEffect(() => {
      if (dateData) {
        if (dateData.startDate) {
          state.startDate = dateData.startDate;
        }
        if (dateData.endDate) {
          state.endDate = dateData.endDate;
        }
      }
    }, [dateData]);

    return (
      <Wrapper>
        <Row gutter={[32, 16]} align="bottom">
          <Col xs={24} lg={8}>
            <h4>기간별 검색</h4>
            <Row gutter={[16, 0]}>
              <Col xs={24} lg={16}>
                <Space>
                  <DatePicker
                    placeholder="시작일"
                    value={state.startDate}
                    onChange={handleChangeDate('startDate')}
                  />
                  <DatePicker
                    placeholder="종료일"
                    value={state.endDate}
                    onChange={handleChangeDate('endDate')}
                  />
                </Space>
              </Col>
              <Col xs={24} lg={6}>
                <Button type="primary" block onClick={handleSearch}>
                  검색
                </Button>
              </Col>
            </Row>
          </Col>

          <Col xs={24} lg={16}>
            <h4>전체검색</h4>
            <Space>
              <Select
                value={state.searchType}
                onChange={handleChangeSearch('searchType')}
                style={{ width: 140 }}
              >
                <Option value="instaId">작업아이디</Option>
                {dataKey === 'ppSearch' || dataKey === 'ppCaseBySearch' ? (
                  <Option value="mainHashtag">대표해시태그</Option>
                ) : null}
              </Select>
              <Search onSearch={handleSearch} enterButton />
            </Space>
          </Col>
        </Row>
      </Wrapper>
    );
  },
);

export default CustomerSearch;
