/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback } from 'react';
import { Input, Button, Row, Col, Form, Radio, Divider } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { validateMessages } from '@utils/customFormValidator';

import { USER_ONE, UPDATE_USER } from '@shared/queries/UserQueries';

import CustomModal from '@Common/CustomModal';

const Wrapper = styled.div`
  width: 100%;
`;

const FindPassword = observer((props) => {
  const [form] = Form.useForm();

  const state = useLocalStore(() => ({
    modalOpen: false,
    searchType: 'phone',
    passwordAnswer: '',
    userData: null,
    username: '',
    name: '',
    isQnaSearch: false,

    phone: '',
    isAuth: false,

    passwordChangeOpen: false,
  }));

  const [update] = useMutation(UPDATE_USER);
  const client = useApolloClient();

  const handleClose = useCallback(() => {
    state.searchType = 'phone';
    state.passwordAnswer = '';
    state.userData = null;
    state.username = '';
    state.name = '';
    state.phone = '';
    state.isQnaSearch = false;
    state.isAuth = false;
    state.passwordChangeOpen = false;
    state.modalOpen = false;
  }, []);

  const handleChangeRadio = useCallback(
    (type) => (e) => {
      state[type] = e.target.value;
    },
    [],
  );

  const handleChangeInput = useCallback(
    (type) => (e) => {
      state[type] = e.target.value;
    },
    [],
  );

  const handleAuth = useCallback(() => {
    window.IMP.certification(
      {
        phone: state.phone,
      },
      async (rsp) => {
        // callback
        if (rsp.success) {
          // 인증 성공 시 로직
          const res = await client.query({
            query: USER_ONE,
            variables: {
              findQuery: {
                phone: state.phone,
              },
            },
          });
          if (res.data && res.data.userOne) {
            state.userData = res.data.userOne;
            state.isAuth = true;
          } else {
            window.alert({ title: '사용자를 찾을 수 없습니다.' });
          }
        } else {
          // 인증 실패 시 로직
          state.isAuth = false;
        }
      },
    );
  }, []);

  const handleSearchQnaUser = useCallback(async () => {
    const res = await client.query({
      query: USER_ONE,
      variables: {
        findQuery: {
          username: state.username,
          name: state.name,
        },
      },
    });

    if (res.data && res.data.userOne) {
      state.userData = res.data.userOne;
      state.isQnaSearch = true;
    } else {
      state.isQnaSearch = false;
      window.alert({ title: '사용자를 찾을 수 없습니다.' });
    }
  }, []);
  const handleCheckPasswordAnswer = useCallback(async () => {
    if (
      state.userData &&
      state.userData.passwordAnswer === state.passwordAnswer
    ) {
      state.passwordChangeOpen = true;
    } else {
      window.alert({ title: '답변이 틀렸습니다.' });
    }
  }, []);

  const handleSubmitPassword = useCallback(async (value) => {
    window.maskOn();
    await update({
      variables: {
        id: state.userData.id,
        data: {
          password: value.password,
        },
      },
    });
    window.maskOff();
    window.success({ title: '비밀번호 변경이 완료되었습니다.' });
    handleClose();
  }, []);

  return (
    <Wrapper>
      <Button
        type="text"
        onClick={() => (state.modalOpen = true)}
        style={{ padding: 0 }}
      >
        비밀번호 찾기
      </Button>

      <CustomModal
        title="비밀번호 찾기"
        visible={state.modalOpen}
        onCancel={handleClose}
        footer={null}
        minwidth={1}
      >
        <Radio.Group
          value={state.searchType}
          onChange={handleChangeRadio('searchType')}
        >
          <Radio value="phone">휴대폰 번호로 찾기</Radio>
          <Radio value="qna">질문/답변으로 찾기</Radio>
        </Radio.Group>

        <Divider />

        <Row gutter={[10, 10]}>
          {state.searchType === 'phone' ? (
            <>
              {state.isAuth && state.userData ? (
                <>
                  <Button
                    type="primary"
                    size="large"
                    block
                    onClick={() => (state.passwordChangeOpen = true)}
                  >
                    비밀번호 변경
                  </Button>
                </>
              ) : (
                <>
                  <Col xs={24}>
                    <Input
                      size="large"
                      placeholder="휴대폰 번호를 - 없이 입력해주세요"
                      value={state.phone}
                      onChange={handleChangeInput('phone')}
                    />
                  </Col>
                  <Col xs={24}>
                    <Button
                      type="primary"
                      size="large"
                      onClick={handleAuth}
                      block
                      disabled={!/^010[0-9]{4}[0-9]{4}$/.test(state.phone)}
                    >
                      본인인증
                    </Button>
                  </Col>
                </>
              )}
            </>
          ) : (
            <>
              <Col xs={24}>
                <Input
                  size="large"
                  placeholder="아이디를 입력해주세요"
                  value={state.username}
                  onChange={handleChangeInput('username')}
                />
              </Col>
              <Col xs={24}>
                <Input
                  size="large"
                  placeholder="성명을 입력해주세요"
                  value={state.name}
                  onChange={handleChangeInput('name')}
                />
              </Col>

              {state.isQnaSearch && state.userData ? (
                <>
                  <Col xs={24}>
                    <Divider />
                    <h4>질문 : {state.userData.passwordAsk}</h4>
                  </Col>
                  <Col xs={24}>
                    <Input
                      size="large"
                      placeholder="답변을 입력해주세요"
                      value={state.passwordAnswer}
                      onChange={handleChangeInput('passwordAnswer')}
                    />
                  </Col>
                  <Col xs={24}>
                    <Button
                      type="primary"
                      size="large"
                      block
                      onClick={handleCheckPasswordAnswer}
                    >
                      비밀번호 변경
                    </Button>
                  </Col>
                </>
              ) : (
                <Col xs={24}>
                  <Button
                    type="primary"
                    size="large"
                    block
                    onClick={handleSearchQnaUser}
                  >
                    조회하기
                  </Button>
                </Col>
              )}
            </>
          )}
        </Row>
      </CustomModal>

      <CustomModal
        title="비밀번호 변경"
        visible={state.passwordChangeOpen}
        onCancel={() => (state.passwordChangeOpen = false)}
        footer={null}
        minwidth={1}
      >
        <Form
          form={form}
          layout="vertical"
          name="password_change_form"
          initialValues={{
            password: '',
          }}
          validateMessages={validateMessages}
          scrollToFirstError
          onFinish={handleSubmitPassword}
        >
          <Form.Item
            label="비밀번호"
            name="password"
            rules={[
              {
                required: true,
                type: 'string',
                min: 4,
                max: 16,
              },
            ]}
          >
            <Input.Password size="large" />
          </Form.Item>

          <Form.Item
            label="비밀번호 재확인"
            name="passwordConfirm"
            dependencies={['password']}
            rules={[
              {
                required: true,
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('비밀번호가 틀렸습니다');
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password size="large" />
          </Form.Item>

          <Button type="primary" size="large" htmlType="submit" block>
            저장
          </Button>
        </Form>
      </CustomModal>
    </Wrapper>
  );
});

export default FindPassword;
