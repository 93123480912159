/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Divider,
  Input,
  Space,
  Radio,
  Select,
  Checkbox,
  InputNumber,
  Collapse,
  TimePicker,
} from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { toJS } from 'mobx';
import uniqid from 'uniqid';
import moment from 'moment';

import useStore from '@stores/useStore';
import { numberWithCommas } from '@utils/common';

import Description from '@Common/Description';

import BuyPPCaseByInstaPost from './BuyPPCaseByInstaPost';

const { Option } = Select;
const { Panel } = Collapse;

const Wrapper = styled.div`
  width: 100%;

  .test-option {
    & > div {
      margin-bottom: 15px;
    }
  }
`;

const BuyPPCaseBy = observer(({ data, handleClose, handleSubmit, company }) => {
  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    priceType: 'casePriceData',
    casePriceData: [],
    monthPriceData: [],
    customerMonthPriceData: [],
    randomInstaPostData: null,
    isInstaPostRandom: false,

    handleUpload: {},
    setHandleUpload(uploadFunc, lang) {
      if (this.isInstaPostRandom) {
        this.handleUpload = uploadFunc;
      } else {
        const handleUpload = toJS(this.handleUpload);
        handleUpload[`${lang.itemUid}_${lang.idx}`] = uploadFunc;
        this.handleUpload = handleUpload;
      }
    },
    setParentImages(images, lang) {
      if (this.isInstaPostRandom) {
        this.randomInstaPostData.images = images;
      } else {
        const priceData = toJS(this[lang.priceType]);
        const fIdx = priceData.findIndex((item) => item.uid === lang.itemUid);
        if (fIdx !== -1) {
          priceData[fIdx].instaPostData[lang.idx].images = images;
        }
        this[lang.priceType] = priceData;
      }
    },

    // DB 데이터
    workType: '인기게시물건바이',
    paymentType: '건구매',
    instaId: '',
    instaPassword: '',
    url: '',
    comment: '',
    buyCnt: 1,
    buyAmount: 0,
    isMonth: false,
    buyDirectCommentCnt: 0,
    isBuyDirectComment: false,
    testLikeCnt: undefined,
    testTrafficCnt: undefined,
    testArrivalCnt: undefined,
    testPublishTime: [],
    testSaveCnt: undefined,
    testCommentCnt: undefined,
    testKeepTrafficCnt: undefined,
    testKeepLikeCnt: undefined,
    testKeepSaveCnt: undefined,
    testKeepFollowCnt: undefined,
    testKeepCommentCnt: undefined,
    postType: '광고',
  }));

  const calcPrice = useCallback(() => {
    let value = 0;
    let priceData = [];
    if (state.paymentType === '월구매') {
      priceData = toJS(state.monthPriceData);
    } else if (state.paymentType === '건구매') {
      priceData = toJS(state.casePriceData);
    } else if (state.paymentType === '고객월건바이') {
      priceData = toJS(state.customerMonthPriceData);
    }

    for (const item of priceData) {
      if (item.checked && item.cnt) {
        if (state.paymentType === '월구매') {
          value += item.price * item.cnt * item.days;
        } else {
          value += item.price * item.cnt;
        }
      }
    }

    if (state.isBuyDirectComment) {
      value += state.buyDirectCommentCnt * data.directCommentPrice;
    }

    state.buyAmount = value;
    return numberWithCommas(value, true);
  }, [data]);

  const handleChangeRadio = useCallback(
    (type) => (e) => {
      state[type] = e.target.value;

      if (type === 'paymentType') {
        state.testLikeCnt = undefined;
        state.testTrafficCnt = undefined;
        state.testArrivalCnt = undefined;
        state.testPublishTime = [];
        state.testSaveCnt = undefined;
        state.testCommentCnt = undefined;
        state.testKeepTrafficCnt = undefined;
        state.testKeepLikeCnt = undefined;
        state.testKeepSaveCnt = undefined;
        state.testKeepFollowCnt = undefined;
        state.testKeepCommentCnt = undefined;
      }

      if (state.paymentType === '건구매') {
        state.isMonth = false;
        state.priceType = 'casePriceData';

        const monthPriceData = data.monthPrice;
        for (const item of monthPriceData) {
          item.instaPostData = [];
        }
        state.monthPriceData = monthPriceData;

        const customerMonthPriceData = data.customerMonthPrice;
        for (const item of customerMonthPriceData) {
          item.instaPostData = [];
        }
        state.customerMonthPriceData = customerMonthPriceData;
      } else if (state.paymentType === '월구매') {
        state.isMonth = true;
        state.priceType = 'monthPriceData';

        const casePriceData = data.casePrice;
        for (const item of casePriceData) {
          item.instaPostData = [];
        }
        state.casePriceData = casePriceData;

        const customerMonthPriceData = data.customerMonthPrice;
        for (const item of customerMonthPriceData) {
          item.instaPostData = [];
        }
        state.customerMonthPriceData = customerMonthPriceData;
      } else if (state.paymentType === '고객월건바이') {
        state.isMonth = false;
        state.priceType = 'customerMonthPriceData';

        const casePriceData = data.casePrice;
        for (const item of casePriceData) {
          item.instaPostData = [];
        }
        state.casePriceData = casePriceData;

        const monthPriceData = data.monthPrice;
        for (const item of monthPriceData) {
          item.instaPostData = [];
        }
        state.monthPriceData = monthPriceData;
      }

      if (type === 'isBuyDirectComment') {
        if (e.target.value) {
          state.buyDirectCommentCnt = 1;
          state.comment = '1.';
        } else {
          state.buyDirectCommentCnt = 0;
          state.comment = '';
        }
      }

      if (type === 'isInstaPostRandom') {
        state.randomInstaPostData = {
          images: [],
          instaContent: [],
          mainHashtag: '',
          videos: [],
          // hashtags: '1.',
        };
      } else {
        state.randomInstaPostData = null;
      }

      setTimeout(() => {
        calcPrice();
      }, 10);
    },
    [],
  );

  const handleChangeInput = useCallback(
    (type) => (e) => {
      state[type] = e.target.value;
    },
    [],
  );

  const handleChangeInputNumber = useCallback(
    (type) => (value) => {
      state[type] = value;

      if (type === 'buyDirectCommentCnt') {
        setTimeout(() => {
          calcPrice();
        }, 10);
      }
    },
    [],
  );

  const handleChangeInputNumberArr = useCallback(
    (type, idx, key, maxCnt) => (value) => {
      const list = toJS(state[type]);
      if (maxCnt) {
        list[idx][key] = value >= maxCnt ? maxCnt : value;
      } else {
        list[idx][key] = value;
      }
      state[type] = list;

      if (key === 'cnt') {
        setTimeout(() => {
          calcPrice();
        }, 10);
      }
    },
    [],
  );

  const setInstaPostData = useCallback(
    (type, idx) => (e) => {
      if (e) {
        e.persist();
      }
      const list = toJS(state[type]);
      setTimeout(() => {
        const arr = [];
        for (let i = 0; i < list[idx].cnt; i++) {
          if (list[idx].instaPostData[i]) {
            arr[i] = list[idx].instaPostData[i];
          } else {
            arr[i] = {
              uid: uniqid(),
              images: [],
              instaContent: '',
              mainHashtag: '',
              // hashtags: '1.',
              url: '',
              trafficCnt: 0,
              likeCnt: 0,
              commentCnt: 0,
              videos: [],
            };
          }
        }
        list[idx].instaPostData = arr;
        state[type] = list;
      }, 10);
    },
    [],
  );

  const handleChangeCheckboxArr = useCallback(
    (type, idx, key, visibleCnt, productName) => (e) => {
      const list = toJS(state[type]);
      list[idx][key] = e.target.checked;

      if (
        (type === 'casePriceData' ||
          type === 'monthPriceData' ||
          type === 'customerMonthPriceData') &&
        key === 'checked'
      ) {
        if (e.target.checked) {
          list[idx].cnt = visibleCnt;
          list[idx].productName = productName;
        } else {
          list[idx].cnt = 0;
          list[idx].productName = '';
        }
      }

      state[type] = list;

      setTimeout(() => {
        setInstaPostData(type, idx)();
        calcPrice();
      }, 10);
    },
    [],
  );

  const handleCheckTextAreaRow = useCallback(
    (type) => (e) => {
      if (type === 'comment') {
        const lines = state.comment.split('\n');
        let values = [];
        for (let i = 0; i < state.buyDirectCommentCnt; i++) {
          if (lines[i] && lines[i].length) {
            if (!/^[0-9]+\./.test(lines[i])) {
              values.push(`${i + 1}.${lines[i]}`);
            } else {
              let dotIdx = lines[i].indexOf('.');
              values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
            }
          }
        }
        state.comment = values.join('\n');
      }
    },
    [],
  );

  const handlePressEnter = useCallback(
    (type) => (e) => {
      e.persist();
      if (type === 'comment') {
        setTimeout(() => {
          const lines = state[type].split('\n');
          let values = [];
          for (let i = 0; i < lines.length; i++) {
            if (!/^[0-9]+\./.test(lines[i])) {
              values.push(`${i + 1}.${lines[i]}`);
            } else {
              let dotIdx = lines[i].indexOf('.');
              values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
            }
          }
          state[type] = values.join('\n');
        }, 10);
      }
    },
    [],
  );

  const checkPostVisible = useCallback((data) => {
    if (!data.length) {
      return false;
    }
    let value = false;
    for (const item of data) {
      if (item.checked && item.cnt) {
        value = true;
        break;
      }
    }
    return value;
  }, []);

  const handleClickSubmit = useCallback(async () => {
    let imageCheck = true;
    let imageErrMsg = '';

    if (state.isInstaPostRandom) {
      if (
        (!state.randomInstaPostData.videos ||
          !state.randomInstaPostData.videos.length) &&
        (!state.randomInstaPostData.images ||
          state.randomInstaPostData.images.length < 10)
      ) {
        imageCheck = false;
        imageErrMsg = `게시물 이미지 최소 10장 이상 업로드해 주세요.`;
      }
    } else {
      let priceData = [];
      if (state.paymentType === '월구매') {
        priceData = toJS(state.monthPriceData);
      } else if (state.paymentType === '건구매') {
        priceData = toJS(state.casePriceData);
      } else if (state.paymentType === '고객월건바이') {
        priceData = toJS(state.customerMonthPriceData);
      }

      let postCnt = 1;
      for (const item of priceData) {
        if (item.checked) {
          for (const [idx, data] of item.instaPostData.entries()) {
            if (
              (!data.videos || !data.videos.length) &&
              (!data.images || !data.images.length)
            ) {
              imageCheck = false;
              imageErrMsg = `${item.rank1}~${item.rank2}위 옵션 ${
                idx + 1
              }게시물의 이미지를 업로드해 주세요.`;
              break;
            }
            postCnt++;
          }
        }

        if (!imageCheck) {
          break;
        }
      }
    }

    if (!imageCheck) {
      return window.alert({ title: imageErrMsg });
    }

    setTimeout(() => {
      const workData = {
        workType: state.workType,
        paymentType: state.paymentType,
        // url: state.url,
        comment: state.comment,
        buyDirectCommentCnt: state.buyDirectCommentCnt,
        isBuyDirectComment: state.isBuyDirectComment,
        status: '진행중',
        isMonth: state.isMonth,
        buyAmount: state.buyAmount,
        isInstaPostRandom: state.isInstaPostRandom,
        randomInstaPostData: toJS(state.randomInstaPostData),
        postType: state.postType,
      };

      let priceData = [];
      let priceType = null;
      if (state.paymentType === '건구매') {
        priceData = toJS(state.casePriceData);
        priceType = 'buyCaseData';
      } else if (state.paymentType === '월구매') {
        priceData = toJS(state.monthPriceData);
        priceType = 'buyMonthData';
      } else if (state.paymentType === '고객월건바이') {
        priceData = toJS(state.customerMonthPriceData);
        priceType = 'buyCustomerMonthData';
        workData.instaId = state.instaId;
        workData.instaPassword = state.instaPassword;
      }

      let errMsg = null;
      const buyPriceData = [];
      for (const item of priceData) {
        if (item.checked) {
          // if (!item.cnt) {
          //   errMsg = `${item.rank1}~${item.rank2}위 노출개수를 입력해 주세요`;
          //   break;
          // }
          const buyData = {
            days: item.days,
            cnt: item.cnt,
            price: item.price,
            rank1: item.rank1,
            rank2: item.rank2,
            uid: item.uid,
            productName: item.productName,
          };
          if (!state.isInstaPostRandom) {
            buyData.instaPostData = item.instaPostData;
          }
          buyPriceData.push(buyData);
        }
      }
      if (!errMsg && !buyPriceData.length) {
        errMsg = `구매하실 프로그램을 선택해 주세요`;
      }
      if (errMsg) {
        return window.alert({ title: errMsg });
      }

      workData[priceType] = buyPriceData;

      if (commonStore.user && commonStore.user.isTest) {
        workData.testLikeCnt = state.testLikeCnt;
        workData.testTrafficCnt = state.testTrafficCnt;
        workData.testArrivalCnt = state.testArrivalCnt;
        workData.testPublishTime = toJS(state.testPublishTime);
        workData.testSaveCnt = state.testSaveCnt;
        workData.testCommentCnt = state.testCommentCnt;
        workData.testKeepTrafficCnt = state.testKeepTrafficCnt;
        workData.testKeepLikeCnt = state.testKeepLikeCnt;
        workData.testKeepSaveCnt = state.testKeepSaveCnt;
        workData.testKeepFollowCnt = state.testKeepFollowCnt;
        workData.testKeepCommentCnt = state.testKeepCommentCnt;
      }

      handleSubmit(workData, null, {
        uploadPath: `work/pp_cb/${company.id}`,
        fileType: 'image',
        handleUpload: toJS(state.handleUpload),
      });
    }, 10);
  }, [data, company]);

  useEffect(() => {
    if (commonStore.user.defPostType && commonStore.user.defPostType.length) {
      state.postType = commonStore.user.defPostType;
    }
    if (data) {
      if (data.monthPrice) {
        const monthPriceData = data.monthPrice;
        for (const item of monthPriceData) {
          item.instaPostData = [];
        }
        state.monthPriceData = [...monthPriceData];
      }

      if (data.casePrice) {
        const casePriceData = data.casePrice;
        for (const item of casePriceData) {
          item.instaPostData = [];
        }
        state.casePriceData = [...casePriceData];
      }

      if (data.customerMonthPrice) {
        const customerMonthPrice = data.customerMonthPrice;
        for (const item of customerMonthPrice) {
          item.instaPostData = [];
        }
        state.customerMonthPriceData = [...customerMonthPrice];
      }

      setTimeout(() => {
        calcPrice();
      }, 10);
    }
  }, [data]);

  return (
    <Wrapper>
      <h3>프로그램</h3>
      <Row gutter={[15, 15]}>
        <Col xs={24}>
          <Radio.Group
            buttonStyle="solid"
            value={state.paymentType}
            onChange={handleChangeRadio('paymentType')}
          >
            <Radio.Button value="건구매">건바이노출</Radio.Button>
            <Radio.Button value="월구매">월 건바이노출</Radio.Button>
            <Radio.Button value="고객월건바이">고객 월 건바이</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>

      <Divider />

      {state.paymentType === '건구매' && (
        <Row gutter={[10, 10]}>
          {toJS(state.casePriceData).map((item, idx) => (
            <Col xs={24} key={item.uid}>
              <Space>
                <Checkbox
                  checked={item.checked}
                  onChange={handleChangeCheckboxArr(
                    'casePriceData',
                    idx,
                    'checked',
                    item.visibleCnt,
                    item.name,
                  )}
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {item.rank1}~{item.rank2}위
                </Checkbox>
                <Space
                  size={20}
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <span>({item.days}일)</span>
                  <Space>
                    <div className="bold">{item.visibleCnt}</div>
                    <div>개</div>
                  </Space>
                  <span>
                    ({numberWithCommas(item.price * item.cnt, true)}포인트)
                  </span>
                </Space>
              </Space>
            </Col>
          ))}
        </Row>
      )}

      {state.paymentType === '월구매' && (
        <Row gutter={[10, 10]}>
          {toJS(state.monthPriceData).map((item, idx) => (
            <Col xs={24} key={item.uid}>
              <Space>
                <Checkbox
                  checked={item.checked}
                  onChange={handleChangeCheckboxArr(
                    'monthPriceData',
                    idx,
                    'checked',
                    item.visibleCnt,
                    item.name,
                  )}
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {item.rank1}~{item.rank2}위
                </Checkbox>
                <Space
                  size={20}
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <span>({item.days}일)</span>
                  <Space>
                    <div className="bold">{item.visibleCnt}</div>
                    <div>개</div>
                  </Space>
                  <span>
                    ({numberWithCommas(item.price * item.cnt * item.days, true)}
                    포인트)
                  </span>
                </Space>
              </Space>
            </Col>
          ))}
        </Row>
      )}

      {state.paymentType === '고객월건바이' && (
        <Row gutter={[10, 10]}>
          {toJS(state.customerMonthPriceData).map((item, idx) => (
            <Col xs={24} key={item.uid}>
              <Space>
                <Checkbox
                  checked={item.checked}
                  onChange={handleChangeCheckboxArr(
                    'customerMonthPriceData',
                    idx,
                    'checked',
                    item.visibleCnt,
                    item.name,
                  )}
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {item.rank1}~{item.rank2}위
                </Checkbox>
                <Space
                  size={20}
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <span>({item.days}일)</span>
                  <Space>
                    <div className="bold">{item.visibleCnt}</div>
                    <div>개</div>
                  </Space>
                  <span>
                    ({numberWithCommas(item.price * item.cnt, true)}포인트)
                  </span>
                </Space>
              </Space>
            </Col>
          ))}
        </Row>
      )}

      <Divider />

      {commonStore.user && commonStore.user.isTest && (
        <>
          <h3>테스트 옵션</h3>
          <Space className="test-option" size={20} style={{ flexWrap: 'wrap' }}>
            <div>
              <h4>좋아요</h4>
              <InputNumber
                value={state.testLikeCnt}
                onChange={handleChangeInputNumber('testLikeCnt')}
              />
            </div>
            <div>
              <h4>트래픽</h4>
              <InputNumber
                value={state.testTrafficCnt}
                onChange={handleChangeInputNumber('testTrafficCnt')}
              />
            </div>
            <div>
              <h4>지속 작업 시간</h4>
              <InputNumber
                value={state.testArrivalCnt}
                onChange={handleChangeInputNumber('testArrivalCnt')}
              />
            </div>
            <div>
              <h4>발행시간</h4>
              <Space>
                <TimePicker
                  value={toJS(state.testPublishTime)[0]}
                  placeholder="시작시간"
                  format="HH:mm"
                  onChange={(momentVal) => {
                    const time = toJS(state.testPublishTime);
                    time[0] = momentVal;
                    state.testPublishTime = time;
                  }}
                />
                <div>~</div>
                <TimePicker
                  value={toJS(state.testPublishTime)[1]}
                  placeholder="종료시간"
                  format="HH:mm"
                  onChange={(momentVal) => {
                    const time = toJS(state.testPublishTime);
                    time[1] = momentVal;
                    state.testPublishTime = time;
                  }}
                />
              </Space>
            </div>
            <div>
              <h4>저장</h4>
              <InputNumber
                value={state.testSaveCnt}
                onChange={handleChangeInputNumber('testSaveCnt')}
              />
            </div>
            <div>
              <h4>댓글</h4>
              <InputNumber
                value={state.testCommentCnt}
                onChange={handleChangeInputNumber('testCommentCnt')}
              />
            </div>
            <div>
              <h4>지속 트래픽</h4>
              <InputNumber
                value={state.testKeepTrafficCnt}
                onChange={handleChangeInputNumber('testKeepTrafficCnt')}
              />
            </div>
            <div>
              <h4>지속 좋아요</h4>
              <InputNumber
                value={state.testKeepLikeCnt}
                onChange={handleChangeInputNumber('testKeepLikeCnt')}
              />
            </div>
            <div>
              <h4>지속 저장</h4>
              <InputNumber
                value={state.testKeepSaveCnt}
                onChange={handleChangeInputNumber('testKeepSaveCnt')}
              />
            </div>
            <div>
              <h4>지속 팔로우</h4>
              <InputNumber
                value={state.testKeepFollowCnt}
                onChange={handleChangeInputNumber('testKeepFollowCnt')}
              />
            </div>
            <div>
              <h4>지속 댓글</h4>
              <InputNumber
                value={state.testKeepCommentCnt}
                onChange={handleChangeInputNumber('testKeepCommentCnt')}
              />
            </div>
          </Space>
          <Divider />
        </>
      )}

      <h3>프로그램 추가옵션</h3>
      <Row gutter={[10, 10]}>
        <Col xs={24}>
          <Radio.Group
            value={state.isBuyDirectComment}
            onChange={handleChangeRadio('isBuyDirectComment')}
          >
            <Radio value={true} disabled={true}>
              직접입력댓글 사용
            </Radio>
            <Radio value={false}>사용안함</Radio>
          </Radio.Group>
        </Col>
        {state.isBuyDirectComment && (
          <Col xs={24}>
            <h4>직접입력댓글</h4>
            <Space>
              <InputNumber
                min={1}
                value={state.buyDirectCommentCnt}
                onChange={handleChangeInputNumber('buyDirectCommentCnt')}
              />
              <span>개</span>
              <span>
                (
                {numberWithCommas(
                  state.buyDirectCommentCnt * data.directCommentPrice,
                  true,
                )}{' '}
                포인트)
              </span>
            </Space>
          </Col>
        )}
      </Row>

      <Divider />

      <Row gutter={[10, 10]}>
        <Col xs={24}>
          <Row gutter={[10, 10]}>
            {state.paymentType === '고객월건바이' && (
              <>
                <Col xs={24}>
                  <Space>
                    <div>
                      <h4>인스타 아이디</h4>
                      <Input
                        value={state.instaId}
                        onChange={handleChangeInput('instaId')}
                      />
                    </div>
                    <div>
                      <h4>인스타 비밀번호</h4>
                      <Input
                        value={state.instaPassword}
                        onChange={handleChangeInput('instaPassword')}
                      />
                    </div>
                  </Space>
                </Col>
              </>
            )}

            {checkPostVisible(toJS(state[state.priceType])) ? (
              <>
                <Col xs={24}>
                  <Space>
                    <Radio.Group
                      value={state.postType}
                      onChange={handleChangeRadio('postType')}
                    >
                      {commonStore.user.defPostType === '없음' && (
                        <Radio value={'없음'}>없음</Radio>
                      )}
                      <Radio value={'광고'}>광고</Radio>
                      <Radio value={'협찬'}>협찬</Radio>
                    </Radio.Group>
                  </Space>
                  <Divider />
                </Col>
                <Col xs={24}>
                  <Space>
                    <Radio.Group
                      value={state.isInstaPostRandom}
                      onChange={handleChangeRadio('isInstaPostRandom')}
                    >
                      <Radio value={false}>게시물 순서 설정</Radio>
                      <Radio value={true}>게시물 랜덤 설정</Radio>
                    </Radio.Group>
                  </Space>
                </Col>

                <Col xs={24}>
                  <BuyPPCaseByInstaPost localStore={state} company={company} />
                </Col>
              </>
            ) : null}

            {state.isBuyDirectComment && (
              <Col xs={24}>
                <h4>인스타 댓글 입력 (추가옵션)</h4>
                <Description text="구매 개수 만큼 일괄입력(한줄에 하나씩)" />
                <Input.TextArea
                  rows={5}
                  value={state.comment}
                  onChange={handleChangeInput('comment')}
                  onBlur={handleCheckTextAreaRow('comment')}
                  onPressEnter={handlePressEnter('comment')}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[10, 10]} justify="end">
        <Col>
          <Space>
            <span>총 결제 포인트</span>
            <span className="amount">
              {numberWithCommas(state.buyAmount, true)}
            </span>
            <span>포인트</span>
          </Space>
        </Col>
      </Row>

      <Row gutter={[10, 10]} justify="end">
        <Col>
          <Button onClick={handleClose} style={{ width: 120 }}>
            취소
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={handleClickSubmit}
            style={{ width: 120 }}
          >
            구매
          </Button>
        </Col>
      </Row>
    </Wrapper>
  );
});

export default BuyPPCaseBy;
