/* eslint-disable react-hooks/exhaustive-deps*/
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Layout from '@Common/Layout/AdminLayout';

import Login from '@Common/Login';
import Signup from '@Member/Signup';

import Post from '@Common/Post';
import PostDetail from '@Common/Post/PostDetail';

import Home from './Home';

import SaleStatus from './SaleStatus';
import Board from './Board';
import WorkStatus from './WorkStatus';
import MemberStatus from './MemberStatus';
import Account from './Account';
import Product from './Product';
import Point from './Point';
import Banner from './Banner';
import Config from './Config';
import AdminSetting from './AdminSetting';

const LayoutWrapper = ({ props, Comp }) => (
  <Layout>
    <Comp {...props} />
  </Layout>
);

const Admin = (props) => {
  return (
    <Switch>
      <Route exact path={'/signup'} component={Signup} />
      <Route exact path={'/login'} component={Login} />

      <Route
        exact
        path="/notice/:id"
        component={(props) => (
          <LayoutWrapper
            Comp={() => (
              <PostDetail
                {...props}
                board="공지사항"
                title="공지사항"
                isAdmin={true}
              />
            )}
          />
        )}
      />
      <Route
        exact
        path="/notice"
        component={(props) => (
          <LayoutWrapper
            props={props}
            Comp={() => (
              <Post board="공지사항" title="공지사항" isAdmin={true} />
            )}
          />
        )}
      />

      <Route
        exact
        path="/contact/:id"
        component={(props) => (
          <LayoutWrapper
            Comp={() => (
              <PostDetail
                {...props}
                board="고객문의"
                title="고객문의"
                isAdmin={true}
              />
            )}
          />
        )}
      />
      <Route
        exact
        path="/contact"
        component={(props) => (
          <LayoutWrapper
            props={props}
            Comp={() => (
              <Post board="고객문의" title="고객문의" isAdmin={true} />
            )}
          />
        )}
      />

      <Route
        exact
        path="/memberStatus"
        component={(props) => (
          <LayoutWrapper props={props} Comp={MemberStatus} />
        )}
      />
      <Route
        exact
        path="/saleStatus"
        component={(props) => <LayoutWrapper props={props} Comp={SaleStatus} />}
      />
      <Route
        exact
        path="/board"
        component={(props) => <LayoutWrapper props={props} Comp={Board} />}
      />
      <Route
        exact
        path="/workStatus"
        component={(props) => <LayoutWrapper props={props} Comp={WorkStatus} />}
      />
      <Route
        exact
        path="/account"
        component={(props) => <LayoutWrapper props={props} Comp={Account} />}
      />
      <Route
        exact
        path="/product"
        component={(props) => <LayoutWrapper props={props} Comp={Product} />}
      />
      <Route
        exact
        path="/point"
        component={(props) => <LayoutWrapper props={props} Comp={Point} />}
      />
      <Route
        exact
        path="/banner"
        component={(props) => <LayoutWrapper props={props} Comp={Banner} />}
      />
      <Route
        exact
        path="/config"
        component={(props) => <LayoutWrapper props={props} Comp={Config} />}
      />
      <Route
        exact
        path="/adminSetting"
        component={(props) => (
          <LayoutWrapper props={props} Comp={AdminSetting} />
        )}
      />
      <Route
        exact
        path="/"
        component={(props) => <LayoutWrapper props={props} Comp={Home} />}
      />

      <Redirect from={'*'} to={'/'} />
    </Switch>
  );
};

export default Admin;
