/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback } from 'react';
import { Row, Col, Button, Affix, Input, Space } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { toJS } from 'mobx';

import { META, SAVE_META } from '@shared/queries';
import Dropzone from '@Common/Dropzone';

const Wrapper = styled.div`
  width: 100%;
`;

const Banner = observer(({ metaKey, uploadPath }) => {
  const state = useLocalStore(() => ({
    images: [],
    get imagesVal() {
      return toJS(this.images);
    },

    handleUpload: null,
    setHandleUpload(uploadFunc) {
      this.handleUpload = uploadFunc;
    },
    setParentImages(images) {
      this.images = images;
    },
  }));

  const client = useApolloClient();
  const [save] = useMutation(SAVE_META);

  const fetchData = useCallback(async () => {
    const result = await client.query({
      query: META,
      variables: { key: metaKey },
    });
    if (result.data && result.data.meta) {
      state.images = result.data.meta.value;
    }
  }, [metaKey]);

  const handleChangeLink = useCallback(
    (idx) => (e) => {
      const images = state.imagesVal;
      images[idx].link = e.target.value;
      state.images = images;
    },
    [metaKey],
  );

  const handleSaveData = useCallback(
    (images) => {
      return new Promise(async (resolve) => {
        const data = { key: metaKey, value: images };
        await save({
          variables: {
            key: metaKey,
            data,
          },
        });
        await fetchData();
        resolve();
      });
    },
    [metaKey],
  );

  const handleSubmit = useCallback(async () => {
    window.maskOn();

    const images = await state.handleUpload();
    const key = metaKey;
    await save({
      variables: {
        key,
        data: {
          key,
          value: images,
        },
      },
    });

    await fetchData();
    window.maskOff();
    window.success({ title: '배너가 저장되었습니다.' });
  }, [state.handleUpload, metaKey]);

  useEffect(() => {
    fetchData();
  }, [metaKey]);

  return (
    <Wrapper>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={20}>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={16}>
              <h4>이미지</h4>
              <Dropzone
                id={metaKey}
                multiple={true}
                uploadPath={uploadPath}
                setHandleUpload={state.setHandleUpload}
                setParentImages={state.setParentImages}
                handleSaveData={handleSaveData}
                images={state.imagesVal}
                width={2000}
                height={2000}
                descriptions={[
                  '위 사진들을 드래그하여 보여지는 순서를 변경할 수 있습니다.',
                ]}
              />
            </Col>
            <Col xs={24} lg={8}>
              <h4>링크</h4>
              <Row gutter={[0, 16]}>
                {state.imagesVal.map((item, idx) => (
                  <Col xs={24} key={`${metaKey}_${idx}`}>
                    <Input
                      addonBefore={`${idx + 1}.`}
                      value={item.link}
                      onChange={handleChangeLink(idx)}
                      style={{ width: '100%' }}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={4}>
          <Affix offsetTop={80}>
            <Button type="primary" size="large" block onClick={handleSubmit}>
              저장
            </Button>
          </Affix>
        </Col>
      </Row>
    </Wrapper>
  );
});

export default Banner;
