import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @font-face {
      font-family: 'GmarketSansBold';
      src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff') format('woff');
      font-weight: normal;
      font-style: normal;
  }

  * {
    font-family: 'Noto Sans KR', serif;
    box-sizing: border-box;
  }
  body {
    font-family: 'Noto Sans KR', serif;
    background: #f0f2f5;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  input, button, div, span, select {    
    font-size: 0.9rem;
    &:focus, &:active {
      outline: none;
    }

    &::placeholder {
      font-size: 0.9rem;
    }
  }
  pre {
    overflow: hidden;
    white-space: pre-line;
    background: none !important;
    border: none !important;
    font-family: inherit !important;
    color: #808080 !important;
  }

  .ql-editor{
    min-height: 200px !important;
    max-height: 400px;
  }  

  .hide {
    display: none;
  }
  
  .full-screen-modal {
    .ant-modal {
      width: 100% !important;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
    }
    .ant-modal-content {
      width: 100% !important;
      padding: 0;      
    }
    .ant-modal-body {
      width: 100% !important;
      height: 100% !important;
      padding: 0;
      background: white;      
    }
  }

  .listbox-title {
    padding: 5px;
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
    border: 1px solid #e0e0e0;
    background-color: #f0f2f5;
  }
  .p-listbox {
    min-width: 100% !important;
  }

  .highlight {
    color: ${(props) => props.theme.primaryColor};
  }
  .red {
    color: #e74c3c;
  }
  .blue {
    color: #3498db;
  }
  .click {
    cursor: pointer;
  }
  .underline {
    text-decoration: underline;
  }
  .swiper-container {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    height: 100%;
    min-height: 200px;
    z-index: 0;

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;

      img {
        width: 100%;
      }
    }

    .swiper-button-prev {
      color: ${(props) => props.theme.greyColor};
    }
    .swiper-button-next {
      color: ${(props) => props.theme.greyColor};
    }
    .swiper-pagination {
      color: ${(props) => props.theme.greyColor};
    }
  }

  .ant-table-summary {
    background-color: #fafafa;
    text-align: center;
  }

  .ql-tooltip {
    left: 0 !important;
    top: 10px !important;
  }

  .ql-video-wrapper {
    width: 100%;
    max-width: 800px;
  }
  
  .ql-video-inner {
      padding-top:56.25%;
      position:relative;
      height:0;
  }
  
  .ql-video-inner iframe,
  .ql-video-inner object,
  .ql-video-inner embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  }

  .bold {
    font-weight: bold;
  }

  .ql-viewer .ql-align-center {
    text-align: center;
  }
  .ql-viewer .ql-align-justify {
    text-align: justify;
  }
  .ql-viewer .ql-align-right {
    text-align: right;
  }
 
  ${(props) => props.theme.breakpoint('xs', 'lg')`
    .ant-table-cell {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .ant-descriptions * {
      font-size: 11px;
    }
  `}
`;

export default GlobalStyle;
