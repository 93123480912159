import React from 'react';
import { Modal } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { toJS } from 'mobx';
import { maskOn, maskOff } from './mask';

export default () => {
  window.maskOn = maskOn;
  window.maskOff = maskOff;
  window.alert = (props) => {
    return Modal.error({
      ...props,
      icon: <QuestionCircleOutlined style={{ color: '#55a8f4' }} />,
    });
  };
  window.ask = Modal.confirm;
  window.info = Modal.info;
  window.success = Modal.success;
  window.warning = Modal.warning;
  window.toJS = toJS;
};
