/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs, Row, Col } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';

import PageTitle from '@Common/PageTitle';

import Post from '@Common/Post';
import QnA from './QnA';

const { TabPane } = Tabs;

const Wrapper = styled.div`
  width: 100%;
`;

const BoardContainer = observer(() => {
  const router = useHistory();
  const state = useLocalStore(() => ({
    tab: '1',
  }));

  const handleChangeTab = useCallback((key) => {
    state.tab = key;
  }, []);

  useEffect(() => {
    if (router.location.query && router.location.query.tab) {
      state.tab = router.location.query.tab;
    }
  }, [router.location]);

  return (
    <Wrapper>
      <Row>
        <Col xs={24}>
          <PageTitle>게시판 관리</PageTitle>
        </Col>
      </Row>

      <Tabs activeKey={state.tab} onChange={handleChangeTab}>
        <TabPane tab="공지사항 관리" key="1">
          <Post board="공지사항" title="공지사항" isAdmin={true} />
        </TabPane>
        <TabPane tab="고객문의" key="2">
          <Post board="고객문의" title="고객문의" isAdmin={true} />
        </TabPane>
        <TabPane tab="사용방법" key="3">
          <QnA />
        </TabPane>
      </Tabs>
    </Wrapper>
  );
});

export default BoardContainer;
