/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Empty, Select, Space, Button, Divider, Input } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { ListBox } from '@bit/primefaces.primereact.listbox';
import PrimereactStyle from '@bit/primefaces.primereact.internal.stylelinks';
import { toJS } from 'mobx';
import { useApolloClient } from '@apollo/react-hooks';

import { COMPANIES } from '@shared/queries/CompanyQueries';
import useStore from '@stores/useStore';

import CompanyModify from './CompanyModify';
import WorkDetail from './WorkDetail';

const { Option } = Select;
const { Search } = Input;

const Wrapper = styled.div`
  width: 100%;

  .search-bar {
    .ant-space {
      width: 100%;

      & .ant-space-item:first-child {
        width: 100%;
        .ant-select {
          width: 100%;
        }
      }
    }
  }

  .companies-list {
    width: 100%;

    .label {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .work-description {
      margin: 0;
      font-size: 0.8rem;
    }
  }
`;

const WorkContainer = observer(({ user }) => {
  const router = useHistory();
  const { commonStore } = useStore();

  const state = useLocalStore(() => ({
    list: [],
    options: [],
    selected: null,
    selectedData: null,

    searchWorkType: null,
    searchStatus: null,
    curSearch: null,
    searchText: '',
  }));

  const client = useApolloClient();

  const fetchData = useCallback(
    async (findQuery = {}) => {
      window.maskOn();
      if (user) {
        findQuery.user = user.id;
      } else {
        findQuery.user = commonStore.user.id;
      }

      const res = await client.query({
        query: COMPANIES,
        variables: {
          findQuery,
        },
      });

      if (res.data && res.data.companies) {
        let companies = res.data.companies;

        if (state.curSearch === 'WorkType' && state.searchWorkType) {
          companies = companies.filter((item) => {
            const f = item.works.filter(
              (w) => w.workType === state.searchWorkType,
            );
            return f.length ? true : false;
          });
        } else if (state.curSearch === 'Status' && state.searchStatus) {
          companies = companies.filter((item) => {
            const f = item.works.filter((w) => w.status === state.searchStatus);
            return f.length ? true : false;
          });
        }
        const checkItem = [];
        for (const item of companies) {
          const fIdx = checkItem.findIndex((ci) => ci.name === item.name);
          if (fIdx !== -1) {
            item.name += `(중복${checkItem[fIdx].cnt + 1})`;
            checkItem[fIdx].cnt += 1;
          } else {
            checkItem.push({ name: item.name, cnt: 0 });
          }
        }
        state.list = companies;
        state.options = companies.map((item) => {
          return { label: item.name, value: item.id, works: item.works };
        });
      }

      window.maskOff();
    },
    [user],
  );

  const handleSelected = useCallback((e) => {
    const list = toJS(state.list);
    const f = list.find((item) => item.id === e.value);
    state.selected = e.value;

    if (f) {
      state.selectedData = f;
    }
  }, []);

  const handleChangeSearchSelect = useCallback(
    (type) => (value) => {
      state[`search${type}`] = value;
    },
    [],
  );

  const handleClickSearchBtn = useCallback(
    (type) => () => {
      state.curSearch = type;

      setTimeout(() => {
        handleSearch();
      }, 10);
    },
    [],
  );

  const handleSearch = useCallback(
    (value) => {
      const findQuery = {};

      if (!value || !value.length) {
        state.curSearch = null;
      }

      if (value) {
        state.curSearch = 'CompanyName';
        state.searchText = value;
        findQuery.name = { $regex: value, $options: 'ig' };
      } else if (state.curSearch === 'CompanyName') {
        findQuery.name = { $regex: state.searchText, $options: 'ig' };
      }

      fetchData(findQuery);
    },
    [user],
  );

  useEffect(() => {
    if (!user) {
      if (commonStore.user) {
        fetchData();
      } else {
        router.push('/');
      }
    } else {
      fetchData();
    }
  }, [user]);

  return (
    <Wrapper>
      <PrimereactStyle />

      <Row className="search-bar" gutter={[20, 10]} justify="space-around">
        <Col xs={24} lg={6}>
          <Space>
            <Select
              placeholder="작업목록"
              value={state.searchWorkType}
              onChange={handleChangeSearchSelect('WorkType')}
            >
              <Option value={null}>전체</Option>
              <Option value="K좋아요">K좋아요</Option>
              <Option value="K팔로워">K팔로워</Option>
              <Option value="K댓글">K댓글</Option>
              <Option value="인기게시물">인기게시물</Option>
              <Option value="최적화계정육성">최적화계정육성</Option>
            </Select>
            <Button type="primary" onClick={handleClickSearchBtn('WorkType')}>
              검색
            </Button>
          </Space>
        </Col>
        <Col xs={24} lg={6}>
          <Space>
            <Select
              placeholder="진행상황"
              value={state.searchStatus}
              onChange={handleChangeSearchSelect('Status')}
            >
              <Option value={null}>전체</Option>
              <Option value="진행모두">진행모두</Option>
              <Option value="진행중">진행중</Option>
              <Option value="완료">완료</Option>
            </Select>
            <Button type="primary" onClick={handleClickSearchBtn('Status')}>
              검색
            </Button>
          </Space>
        </Col>
        <Col xs={24} lg={6}>
          <Search placeholder="업체명" enterButton onSearch={handleSearch} />
        </Col>
        <Col xs={24} lg={4}>
          <CompanyModify refetch={handleSearch} user={user} />
        </Col>
      </Row>

      <Divider />

      <Row gutter={[10, 10]}>
        <Col xs={24} lg={4}>
          <ListBox
            value={state.selected}
            filter={false}
            options={toJS(state.options)}
            onChange={handleSelected}
            itemTemplate={(item) => (
              <div className="companies-list">
                <div className="label">
                  <span>{item.label}</span>
                  <RightOutlined />
                </div>
                {item.works && item.works.length ? (
                  <div className="work-description">
                    ({[...new Set(item.works.map((w) => w.workType))].join(',')}
                    )
                  </div>
                ) : (
                  <div className="work-description">(연동상품 없음)</div>
                )}
              </div>
            )}
            style={{ width: '100%' }}
            listStyle={{
              minHeight: '250px',
              maxHeight: state.options.length >= 8 ? '500px' : '100%',
              height: '100%',
            }}
          />
        </Col>
        <Col xs={24} lg={20}>
          {state.selectedData ? (
            <WorkDetail
              propData={toJS(state.selectedData)}
              listRefetch={handleSearch}
              user={user}
            />
          ) : (
            <Empty description="선택된 업체가 없습니다." />
          )}
        </Col>
      </Row>
    </Wrapper>
  );
});

export default WorkContainer;
