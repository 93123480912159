import gql from 'graphql-tag';
import { userFragment } from '@shared/fragments';

export const USER_ONE = gql`
  ${userFragment}
  query UserOne($findQuery: JSON) {
    userOne(findQuery: $findQuery) {
      ...UserFragment
    }
  }
`;

export const USERS = gql`
  ${userFragment}
  query Users($findQuery: JSON, $sort: JSON) {
    users(findQuery: $findQuery, sort: $sort) {
      ...UserFragment
    }
  }
`;

export const USERS_PAGINATE = gql`
  ${userFragment}
  query UsersPaginate($sort: JSON, $page: Int, $limit: Int, $findQuery: JSON) {
    usersPaginate(
      sort: $sort
      page: $page
      limit: $limit
      findQuery: $findQuery
    ) {
      docs {
        ...UserFragment
      }
      totalDocs
      limit
      page
      totalPages
      hasNextPage
      nextPage
      hasPrevPage
      prevPage
      pagingCounter
    }
  }
`;

export const CHECK_USER = gql`
  query CheckUser($findQuery: JSON) {
    checkUser(findQuery: $findQuery)
  }
`;

export const ADD_USER = gql`
  ${userFragment}
  mutation AddUser($data: InputUser) {
    addUser(data: $data) {
      ...UserFragment
    }
  }
`;

export const UPDATE_USER = gql`
  ${userFragment}
  mutation UpdateUser($id: ID!, $data: InputUser) {
    updateUser(id: $id, data: $data) {
      ...UserFragment
    }
  }
`;

export const REMOVE_USER = gql`
  mutation RemoveUser($id: ID!) {
    removeUser(id: $id)
  }
`;

export const USER_CNT = gql`
  query UserCnt($isAdmin: Boolean) {
    userCnt(isAdmin: $isAdmin)
  }
`;

export const NEW_USER = gql`
  ${userFragment}
  subscription NewUser($id: ID!) {
    newUser(id: $id) {
      ...UserFragment
    }
  }
`;
