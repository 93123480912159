/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Table, Tag, Button, Space, Grid, Row, Col, Input } from 'antd';
import { EditOutlined, MinusOutlined, EyeOutlined } from '@ant-design/icons';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import moment from 'moment';

import useStore from '@stores/useStore';

import CustomModal from '@Common/CustomModal';

const { useBreakpoint } = Grid;

const Wrapper = styled.div`
  width: 100%;

  .mobile-title {
    .ant-space-item:last-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  ${(props) => props.theme.breakpoint('xs', 'md')`
    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      width: ${props.isadmin ? '130px' : '160px'};
      white-space: nowrap;
    }
  `}
`;

const PostListTable = observer(
  ({ board, isAdmin, dataSource, handleModify, handleRemove, localStore }) => {
    const router = useHistory();
    const screen = useBreakpoint();
    const { commonStore } = useStore();
    const state = useLocalStore(() => ({
      passwordOpen: false,
      password: '',
      selectedData: null,
    }));

    const handleChangePassword = useCallback((e) => {
      state.password = e.target.value;
    }, []);

    const handleClose = useCallback(() => {
      state.password = '';
      state.selectedData = null;
      state.passwordOpen = false;
    }, []);

    const handleDetail = useCallback(
      (row) => (e) => {
        e.stopPropagation();

        if (!isAdmin && board === '고객문의') {
          state.selectedData = row;
          state.passwordOpen = true;
        } else {
          if (isAdmin) {
            let pathname;
            if (board === '공지사항') {
              pathname = '/notice';
            } else if (board === '고객문의') {
              pathname = '/contact';
            }
            router.push(`${pathname}/${row.id}`);
          } else {
            router.push(`${router.location.pathname}/${row.id}`);
          }
        }
      },
      [isAdmin, board],
    );

    const handleCheckPassword = useCallback(() => {
      if (
        state.selectedData &&
        state.selectedData.password === state.password
      ) {
        router.push(`${router.location.pathname}/${state.selectedData.id}`);
      } else {
        window.alert({ title: '비밀번호가 틀렸습니다.' });
      }
      handleClose();
    }, []);

    const columns = useMemo(() => {
      const value = [
        {
          title: '번호',
          dataIndex: 'num',
          key: 'num',
          render: (_, row, idx) =>
            row.isNotice ? <Tag color="#55a8f4">공지</Tag> : row.num,
          width: 80,
          align: 'center',
        },
        {
          title: '제목',
          dataIndex: 'title',
          key: 'title',
          render: (title, row) => (
            <Button type="text" onClick={handleDetail(row)}>
              {title}
            </Button>
          ),
          ellipsis: true,
        },
        {
          title: '작성자',
          dataIndex: 'created',
          key: 'created',
          render: (created, row) =>
            row.author ? row.author.name : row.created ? created.name : '',
          ellipsis: true,
          width: 140,
          align: 'center',
        },
        {
          title: '등록일',
          dataIndex: 'postDate',
          key: 'postDate',
          render: (postDate) => moment(postDate).format('YYYY-MM-DD'),
          width: 140,
          align: 'center',
        },
        {
          title: '조회수',
          dataIndex: 'views',
          key: 'views',
          render: (views) => (views ? views : 0),
          width: 120,
          align: 'center',
        },
      ];

      if (board === '고객문의') {
        value.push({
          title: '답변여부',
          dataIndex: 'commentCnt',
          key: 'commentCnt',
          render: (commentCnt) =>
            commentCnt ? (
              <Tag color="blue">답변완료</Tag>
            ) : (
              <Tag color="orange">답변대기</Tag>
            ),
          width: 120,
          align: 'center',
        });
      }

      if (isAdmin) {
        value.push({
          title: '',
          key: 'action',
          render: (_, row) =>
            isAdmin ||
            (commonStore.user &&
              row.created &&
              commonStore.user.id === row.created.id) ? (
              <Space size="middle">
                <Button
                  type="link"
                  onClick={handleModify(true, row)}
                  icon={<EditOutlined />}
                >
                  수정
                </Button>
                <Button
                  type="link"
                  onClick={handleRemove(row.id, row.title)}
                  danger
                  icon={<MinusOutlined />}
                >
                  삭제
                </Button>
              </Space>
            ) : null,
          width: 200,
          align: 'center',
        });
      }

      return value;
    }, [board, isAdmin]);

    const mobileColumns = useMemo(
      () => [
        {
          title: '',
          dataIndex: 'num',
          key: 'num',
          render: (_, row, idx) => {
            return (
              <Row gutter={[5, 5]}>
                <Col xs={24}>
                  <Space className="mobile-title" style={{ width: '100%' }}>
                    <div>
                      {row.isNotice ? <Tag color="#55a8f4">공지</Tag> : row.num}
                    </div>
                    <strong className="click" onClick={handleDetail(row)}>
                      {row.title}
                    </strong>
                  </Space>
                </Col>
                <Col xs={24}>
                  <Row gutter={[5, 5]} justify="space-between">
                    <Col>
                      {row.author
                        ? row.author.name
                        : row.created
                        ? row.created.name
                        : ''}
                    </Col>
                    <Col>{moment(row.postDate).format('YYYY-MM-DD')}</Col>
                  </Row>
                  <Row gutter={[5, 5]} justify="space-between">
                    <Col>
                      <Space>
                        <EyeOutlined />
                        <span>{row.views ? row.views : 0}</span>
                      </Space>
                    </Col>
                    <Col>
                      {board === '고객문의' && (
                        <>
                          {row.commentCnt ? (
                            <Tag color="blue">답변완료</Tag>
                          ) : (
                            <Tag color="orange">답변대기</Tag>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  {isAdmin ||
                  (commonStore.user &&
                    row.created &&
                    commonStore.user.id === row.created.id) ? (
                    <Row gutter={5}>
                      <Col xs={12}>
                        <Button
                          type="link"
                          onClick={handleModify(true, row)}
                          icon={<EditOutlined />}
                          size="small"
                          block
                        >
                          수정
                        </Button>
                      </Col>
                      <Col xs={12}>
                        <Button
                          type="link"
                          onClick={handleRemove(row.id, row.title)}
                          danger
                          icon={<MinusOutlined />}
                          size="small"
                          block
                        >
                          삭제
                        </Button>
                      </Col>
                    </Row>
                  ) : null}
                </Col>
              </Row>
            );
          },
          ellipsis: true,
        },
      ],
      [board],
    );

    const rowSelection = {
      type: 'checkbox',
      onChange: (selectedRowKeys, selectedRows) => {
        if (selectedRows.length) {
          localStore.selectedList = selectedRows;
          localStore.selectedRowKeys = selectedRows.map((item) => item.id);
        } else {
          localStore.selectedList = [];
          localStore.selectedRowKeys = [];
        }
      },
      selectedRowKeys: localStore.selectedRowKeys,
    };

    return (
      <Wrapper isadmin={isAdmin}>
        <Table
          dataSource={dataSource}
          columns={screen.lg ? columns : mobileColumns}
          // scroll={{ x: 992, y: 400 }}
          pagination={false}
          rowSelection={isAdmin ? rowSelection : null}
          rowKey={(row) => row.id}
        />

        <CustomModal
          title="비밀번호"
          visible={state.passwordOpen}
          onCancel={handleClose}
          onOk={handleCheckPassword}
        >
          <Input.Password
            value={state.password}
            onChange={handleChangePassword}
          />
        </CustomModal>
      </Wrapper>
    );
  },
);

export default PostListTable;
