/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect, useCallback, useMemo } from 'react';
import {
  Table,
  Space,
  Button,
  Descriptions,
  Grid,
  Row,
  Col,
  Select,
  Input,
  DatePicker,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/react-hooks';
import { toJS } from 'mobx';
import moment from 'moment';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

import useStore from '@stores/useStore';
import { RANKS, RANK_LIST } from '@shared/queries/RankQueries';
import { excelResizeWidth } from '@utils/common';

import CustomModal from '@Common/CustomModal';

const { useBreakpoint } = Grid;
const { Option } = Select;
const { Search } = Input;

const Wrapper = styled.div`
  width: 100%;
`;

const Rank = observer(({ propData = {}, modalOpen, handleModal, isAdmin }) => {
  const screen = useBreakpoint();
  const { commonStore } = useStore();

  const state = useLocalStore(() => ({
    list: [],
    paginate: {
      docs: [],
      totalDocs: 0,
      limit: 10,
      page: 1,
      totalPages: 1,
    },

    searchStartDate: moment().subtract(1, 'months'),
    searchEndDate: moment(),

    get listVal() {
      return toJS(this.list);
    },
  }));

  const client = useApolloClient();

  const handleClose = useCallback(() => {
    handleModal(false)();
  }, []);

  const fetchData = useCallback(
    async (findQuery = {}) => {
      window.maskOn();

      const result = await client.query({
        query: RANKS,
        variables: {
          page: state.paginate.page,
          limit: state.paginate.limit,
          findQuery,
        },
      });

      if (result.data && result.data.ranks) {
        state.paginate = result.data.ranks;
        state.list = result.data.ranks.docs;
      }
      window.maskOff();
    },
    [propData],
  );

  const handleChangePage = useCallback(
    (page) => {
      if (state.paginate.page !== page) {
        state.paginate.page = page;
        setTimeout(() => {
          handleSearch();
        }, 10);
      }
    },
    [propData],
  );

  const handleChangeDate = useCallback(
    (type) => (momentVal) => {
      state[`search${type}`] = momentVal;
    },
    [],
  );

  const handleSearch = useCallback(
    (isExcel) => {
      const findQuery = { work: propData.id };
      if (!state.searchStartDate) {
        return window.alert({ title: '시작날짜를 선택해 주세요' });
      }
      if (!state.searchEndDate) {
        return window.alert({ title: '종료날짜를 선택해 주세요' });
      }
      if (state.searchEndDate <= state.searchStartDate) {
        return window.alert({ title: '종료날짜가 시작날짜보다 작습니다.' });
      }
      findQuery['$and'] = [
        { createdAt: { $gte: moment(state.searchStartDate).startOf('date') } },
        { createdAt: { $lte: moment(state.searchEndDate).endOf('date') } },
      ];

      if (isExcel) {
        handleExcelDown(findQuery);
      } else {
        fetchData(findQuery);
      }
    },
    [propData],
  );

  const handleExcelDown = useCallback(
    async (findQuery = {}) => {
      window.maskOn();
      findQuery.work = propData.id;
      const res = await client.query({
        query: RANK_LIST,
        variables: {
          findQuery,
        },
      });

      if (res.data && res.data.rankList) {
        const list = res.data.rankList;
        const workbook = new Excel.Workbook();

        let worksheet = workbook.addWorksheet('순위');

        // worksheet.addRow([
        //   '아이디',
        //   '이름',
        //   '서버구분',
        //   '중분류',
        //   '소분류',
        //   '업체명 키워드',
        //   '노출키워드',
        //   // '사업자등록번호',
        //   '업체주소',
        //   '기간',
        //   '횟수',
        // ]);
        // worksheet.addRow([
        //   server.user.username,
        //   server.user.name,
        //   server.product.label,
        //   server.middleCategory ? server.middleCategory.name : '',
        //   server.lastCategory ? server.lastCategory.name : '',
        //   server.memberKeyword,
        //   server.topKeyword,
        //   server.address,
        //   server.counter,
        //   server.rollingCnt,
        // ]);
        // worksheet.addRow([]);
        if (propData.workType === '최적화계정육성') {
          worksheet.addRow(['갯수', '날짜', '게시물', '팔로워', '팔로잉']);
        } else {
          worksheet.addRow(['갯수', '날짜', '순위']);
        }
        let idx = 0;
        for (const item of list) {
          let value = [
            idx + 1,
            moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
          ];
          if (propData.workType === '최적화계정육성') {
            value = value.concat([
              item.postRank || '',
              item.followerRank || '',
              item.followingRank || '',
            ]);
          } else {
            if (item.hashtagRank[0] && item.hashtagRank[0].rank) {
              value.push(item.hashtagRank[0].rank);
            }
          }
          worksheet.addRow(value);
          idx++;
        }

        worksheet = await excelResizeWidth(worksheet);
        const xls64 = await workbook.xlsx.writeBuffer({ base64: true });
        let title = '';
        if (propData.company) {
          title += propData.company.name;
        }

        title += `-${propData.workType} 노출순위`;

        saveAs(
          new Blob([xls64], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }),
          `[${moment(state.searchStartDate).format('YYYY-MM-DD')}]_[${moment(
            state.searchEndDate,
          ).format('YYYY-MM-DD')}]`,
        );
      } else {
        window.maskOff();
        return window.alert({
          title: '엑셀로 저장할 데이터를 찾을 수 없습니다.',
        });
      }

      window.maskOff();
    },
    [propData],
  );

  useEffect(() => {
    if (propData && propData.id) {
      state.paginate.page = 1;
      handleSearch();
    }
  }, [propData]);

  const columns = useMemo(() => {
    let value = [
      {
        title: '갯수',
        dataIndex: 'idx',
        key: 'idx',
        render: (_, row, idx) => {
          let value = idx + 1;
          if (state.paginate.page > 1) {
            value = (state.paginate.page - 1) * state.paginate.limit;
            value = idx + 1 + value;
          }
          return value;
        },
        align: 'center',
        width: 80,
      },
      {
        title: '날짜',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (_, row) => moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        align: 'center',
      },
    ];
    if (propData.workType === '최적화계정육성') {
      value = value.concat([
        {
          title: '게시물',
          dataIndex: 'postRank',
          key: 'postRank',
          render: (_, row) => row.postRank,
          align: 'center',
        },
        {
          title: '팔로워',
          dataIndex: 'followerRank',
          key: 'followerRank',
          render: (_, row) => row.followerRank,
          align: 'center',
        },
        {
          title: '팔로잉',
          dataIndex: 'followingRank',
          key: 'followingRank',
          render: (_, row) => row.followingRank,
          align: 'center',
        },
      ]);
    } else {
      value.push({
        title: '순위',
        dataIndex: 'hashtagRank',
        key: 'hashtagRank',
        render: (_, row) =>
          row.hashtagRank && row.hashtagRank[0] ? row.hashtagRank[0].rank : '',
        align: 'center',
      });
    }
    return value;
  }, [propData]);

  const mobileColumns = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'idx',
        key: 'idx',
        render: (_, row, idx) => {
          return (
            <Descriptions size="small" bordered column={1}>
              <Descriptions.Item label="갯수">
                {state.paginate.page > 1
                  ? (state.paginate.page - 1) * state.paginate.limit + (idx + 1)
                  : idx + 1}
              </Descriptions.Item>
              <Descriptions.Item label="날짜">
                {moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')}
              </Descriptions.Item>
              {propData.workType === '최적화계정육성' ? (
                <>
                  <Descriptions.Item label="게시물">
                    {row.postRank}
                  </Descriptions.Item>
                  <Descriptions.Item label="팔로워">
                    {row.followerRank}
                  </Descriptions.Item>
                  <Descriptions.Item label="팔로잉">
                    {row.followingRank}
                  </Descriptions.Item>
                </>
              ) : (
                <Descriptions.Item label="순위">
                  {row.hashtagRank && row.hashtagRank[0]
                    ? row.hashtagRank[0].rank
                    : ''}
                </Descriptions.Item>
              )}
            </Descriptions>
          );
        },
      },
    ],
    [],
  );

  return (
    <CustomModal
      title={`${
        isAdmin ? propData.user.name + '(' + propData.user.username + ')' : ''
      } ${propData.company ? propData.company.name : ''} ${
        propData.workType
      } 노출순위`}
      visible={modalOpen}
      onCancel={handleClose}
      footer={null}
      minwidth={800}
      forceRender={true}
    >
      <Wrapper>
        <Row gutter={[10, 5]} justify="space-between">
          <Col xs={24} lg={12}>
            <Space style={{ marginBottom: 15 }}>
              <DatePicker
                value={state.searchStartDate}
                onChange={handleChangeDate('StartDate')}
              />
              <span>~</span>
              <DatePicker
                value={state.searchEndDate}
                onChange={handleChangeDate('EndDate')}
              />
              <Button
                type="primary"
                onClick={() => handleSearch()}
                icon={<SearchOutlined />}
              />
            </Space>
          </Col>
          <Col xs={24} lg={4}>
            <Button type="primary" block onClick={() => handleSearch(true)}>
              엑셀다운
            </Button>
          </Col>
        </Row>

        <Table
          size="small"
          dataSource={state.listVal}
          columns={screen.lg ? columns : mobileColumns}
          pagination={{
            current: state.paginate.page,
            total: state.paginate.totalDocs,
            showSizeChanger: false,
            onChange: handleChangePage,
          }}
          rowKey={(row) => row.id}
          bordered
        />
      </Wrapper>
    </CustomModal>
  );
});

export default Rank;
