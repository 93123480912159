/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Divider,
  Input,
  InputNumber,
  Space,
  Radio,
  Tabs,
  Checkbox,
  Card,
} from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { toJS } from 'mobx';
import JSZip from 'jszip';

import useStore from '@stores/useStore';
import { numberWithCommas } from '@utils/common';

import Description from '@Common/Description';
import Dropzone from '@Common/Dropzone';

const { TabPane } = Tabs;

const Wrapper = styled.div`
  width: 100%;
`;

const BuyOA = observer(
  ({ data, handleClose, handleSubmit, company, workType }) => {
    const { commonStore } = useStore();
    const state = useLocalStore(() => ({
      tab: '1',
      images: [],
      fileType: 'zip',
      handleUpload: null,
      setHandleUpload(uploadFunc) {
        this.handleUpload = uploadFunc;
      },
      setParentImages(images) {
        this.images = images;
      },
      zipFile: null,
      checked: false,

      // DB 데이터
      paymentType: '월구매',
      instaId: '',
      instaPassword: '',
      instaContents: [],
      hashtags: '1.',
      comment: '',
      buyDirectCommentCnt: 0,
      isBuyDirectComment: false,
      buyAmount: 0,
      isMonth: true,
      phone: '',
    }));

    const handleChangeTab = useCallback(
      (key) => () => {
        state.tab = key;
      },
      [],
    );

    const handleChangeFile = useCallback(async (e) => {
      if (e.target.files && e.target.files[0]) {
        window.maskOn(300000);
        const file = e.target.files[0];
        e.target.value = '';
        const lastIdx = file.name.lastIndexOf('.');
        const ext = file.name.substring(lastIdx + 1);

        if (!/zip/i.test(ext)) {
          window.maskOff();
          return window.alert({ title: 'zip 파일이 아닙니다.' });
        }

        JSZip.loadAsync(file).then(
          function (zip) {
            const images = [];
            if (zip.files && Object.keys(zip.files).length < 30) {
              state.images = [];
              window.maskOff();
              return window.alert({
                title: '이미지 30장 이상을 업로드해 주세요',
              });
            } else if (zip.files) {
              zip.forEach(function (relativePath, zipEntry) {
                images.push(zipEntry);
              });

              state.images = images;
            } else {
              window.maskOff();
              return window.alert({
                title: '압축파일 내의 목록을 읽어올 수 없습니다.',
              });
            }

            file.status = 'load';
            state.zipFile = file;
            window.maskOff();
          },
          function (e) {
            window.maskOff();
            window.alert({ title: e.message });
          },
        );
      }
    }, []);

    const calcPrice = useCallback(() => {
      let value = data.monthPrice;
      if (state.isBuyDirectComment) {
        value += state.buyDirectCommentCnt * data.directCommentPrice;
      }

      state.buyAmount = value;
      return numberWithCommas(value, true);
    }, [data]);

    const handleChangeInput = useCallback(
      (type, idx) => (e) => {
        if (type === 'instaContents') {
          const instaContents = toJS(state.instaContents);
          instaContents[idx].content = e.target.value;
          state.instaContents = instaContents;
        } else {
          state[type] = e.target.value;
        }
      },
      [],
    );

    const handleChangeInputNumber = useCallback(
      (type) => (value) => {
        state[type] = value;
      },
      [],
    );

    const handleChangeRadio = useCallback(
      (type) => (e) => {
        if (type === 'fileType') {
          state.images = [];
          state.zipFile = null;
        }

        state[type] = e.target.value;

        if (type === 'isBuyDirectComment') {
          if (e.target.value) {
            state.buyDirectCommentCnt = 1;
            state.comment = '1.';
            calcPrice();
          } else {
            state.buyDirectCommentCnt = 0;
            state.comment = '';
          }
        }
      },
      [],
    );

    const handleAddInstaContents = useCallback((e) => {
      const instaContents = toJS(state.instaContents);
      instaContents.push({ isUse: true, content: '' });
      state.instaContents = instaContents;
    }, []);

    const handleRemoveInstaContents = useCallback(
      (idx) => (e) => {
        const instaContents = toJS(state.instaContents);
        instaContents.splice(idx, 1);
        state.instaContents = instaContents;
      },
      [],
    );

    const handleCheckTextAreaRow = useCallback(
      (type) => (e) => {
        const lines = state[type].split('\n');
        let len = 0;
        if (type === 'hashtags') {
          len = 20;
        } else if (type === 'comment') {
          len = state.buyDirectCommentCnt;
        } else {
          len = lines.length;
        }

        let values = [];
        for (let i = 0; i < len; i++) {
          if (lines[i] && lines[i].length) {
            if (!/^[0-9]+\./.test(lines[i])) {
              values.push(`${i + 1}.${lines[i]}`);
            } else {
              let dotIdx = lines[i].indexOf('.');
              values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
            }
          }
        }
        state[type] = values.join('\n');
      },
      [],
    );

    const handlePressEnter = useCallback(
      (type) => (e) => {
        e.persist();
        setTimeout(() => {
          const lines = state[type].split('\n');
          let values = [];
          for (let i = 0; i < lines.length; i++) {
            if (!/^[0-9]+\./.test(lines[i])) {
              values.push(`${i + 1}.${lines[i]}`);
            } else {
              let dotIdx = lines[i].indexOf('.');
              values.push(`${i + 1}.${lines[i].substring(dotIdx + 1)}`);
            }
          }
          state[type] = values.join('\n');
        }, 10);
      },
      [],
    );

    const handleClickSubmit = useCallback(async () => {
      // if (state.images.length < 30) {
      //   return window.alert({
      //     title: '최소 30장 이상 알집파일(zip)로 묶어서 업로드 해주세요',
      //   });
      // }

      setTimeout(() => {
        const workData = {
          workType,
          paymentType: state.paymentType,
          instaId: state.instaId,
          instaPassword: state.instaPassword,
          // instaContents: toJS(state.instaContents),
          // hashtags: toJS(state.hashtags).split('\n'),
          comment: state.comment,
          buyDirectCommentCnt: state.buyDirectCommentCnt,
          isBuyDirectComment: state.isBuyDirectComment,
          status: '진행중',
          isMonth: state.isMonth,
          maxCounting: data.monthDays,
          counting: 0,
          buyAmount: state.buyAmount,
          phone: state.phone,
        };

        // handleSubmit(workData, {
        //   uploadPath: `work/oa/${company.id}`,
        //   fileType: state.fileType,
        //   images: toJS(state.images),
        //   zipFile: toJS(state.zipFile),
        //   handleUpload: toJS(state.handleUpload),
        // });
        handleSubmit(workData);
      }, 10);
    }, [data, company, workType]);

    useEffect(() => {
      if (data) {
        setTimeout(() => {
          calcPrice();
        }, 10);
      }
    }, [data]);

    return (
      <Wrapper>
        <Tabs activeKey={state.tab} tabBarStyle={{ display: 'none' }}>
          <TabPane tab="결제1" key="1">
            <h3>프로그램</h3>
            <div>
              {numberWithCommas(data.monthPrice)} 포인트 ({data.monthDays}일)
            </div>

            <Divider />

            <h3>프로그램 추가옵션</h3>
            <Row gutter={[10, 10]}>
              <Col xs={24}>
                <Radio.Group
                  value={state.isBuyDirectComment}
                  onChange={handleChangeRadio('isBuyDirectComment')}
                >
                  <Radio value={true} disabled={true}>
                    직접입력댓글 사용
                  </Radio>
                  <Radio value={false}>사용안함</Radio>
                </Radio.Group>
              </Col>
              {state.isBuyDirectComment && (
                <Col xs={24}>
                  <h4>직접입력댓글</h4>
                  <Space>
                    <InputNumber
                      min={1}
                      value={state.buyDirectCommentCnt}
                      onChange={handleChangeInputNumber('buyDirectCommentCnt')}
                    />
                    <span>개</span>
                    <span>
                      (
                      {numberWithCommas(
                        state.buyDirectCommentCnt * data.directCommentPrice,
                      )}{' '}
                      포인트)
                    </span>
                  </Space>
                </Col>
              )}
            </Row>
          </TabPane>
          <TabPane tab="결제2" key="2">
            <Row gutter={[10, 10]}>
              <Col xs={24}>
                <Space>
                  <div>
                    <h4>인스타 아이디</h4>
                    <Input
                      value={state.instaId}
                      onChange={handleChangeInput('instaId')}
                    />
                  </div>
                  <div>
                    <h4>인스타 비밀번호</h4>
                    <Input
                      value={state.instaPassword}
                      onChange={handleChangeInput('instaPassword')}
                    />
                  </div>
                </Space>
              </Col>

              <Col xs={24}>
                <h4>전화번호</h4>
                <Description text="계정 문제 발생 시 연락 가능한 연락처를 입력해주세요." />
                <Input
                  value={state.phone}
                  onChange={handleChangeInput('phone')}
                  placeholder="전화번호를 - 없이 입력하세요"
                />
              </Col>

              {/* <Col xs={24}>
                <h4>해시태그</h4>
                <Description text="해시태그 1~20개 입력(한줄에 하나씩)" />
                <Input.TextArea
                  rows={5}
                  value={state.hashtags}
                  onChange={handleChangeInput('hashtags')}
                  onBlur={handleCheckTextAreaRow('hashtags')}
                  onPressEnter={handlePressEnter('hashtags')}
                />
              </Col> */}

              {/* <Col xs={24}>
                <h4>인스타 게시물 이미지 업로드 (zip)</h4>
                <Description text="이미지는 최소 30장 이상 알집파일(zip)로 묶어서 업로드 해주세요" />

                <Row gutter={[10, 10]} style={{ marginTop: 15 }}>
                  <Col xs={24}>
                    {state.fileType === 'image' && (
                      <Dropzone
                        multiple={true}
                        uploadPath={
                          commonStore.user.isTest
                            ? 'test'
                            : `work/oa/${company ? company.id : 'no-company'}`
                        }
                        setHandleUpload={state.setHandleUpload}
                        setParentImages={state.setParentImages}
                        images={toJS(state.images)}
                        width={100000}
                        height={100000}
                        descriptions={[
                          '위 사진들을 드래그하여 보여지는 순서를 변경할 수 있습니다.',
                        ]}
                        hideDescription={true}
                      />
                    )}
                    {state.fileType === 'zip' && (
                      <>
                        <input
                          id={`oa_file`}
                          accept=".zip"
                          type="file"
                          onChange={handleChangeFile}
                          style={{ display: 'none' }}
                        />
                        <Input
                          value={
                            state.zipFile
                              ? state.zipFile.filename
                                ? state.zipFile.filename
                                : state.zipFile.name
                              : null
                          }
                          disabled
                          addonAfter={
                            <Button
                              size="small"
                              type="text"
                              onClick={() =>
                                document.querySelector(`#oa_file`).click()
                              }
                              block
                            >
                              {state.zipFile ? '수정' : '업로드'}
                            </Button>
                          }
                        />
                      </>
                    )}
                  </Col>
                </Row>
              </Col> */}

              {/* <Col xs={24}>
                <h4>인스타 게시물 내용 입력</h4>
                <Row gutter={[10, 10]} justify="center" align="middle">
                  {toJS(state.instaContents).map((item, idx) => (
                    <Col xs={24} key={`instaContents_${idx}`}>
                      <Row gutter={[10, 10]} align="middle">
                        <Col xs={22}>
                          <Input
                            value={item.content}
                            onChange={handleChangeInput('instaContents', idx)}
                          />
                        </Col>
                        <Col xs={2}>
                          <Button
                            icon={<MinusOutlined />}
                            shape="circle"
                            onClick={handleRemoveInstaContents(idx)}
                          />
                        </Col>
                      </Row>
                    </Col>
                  ))}

                  <Col>
                    <Button
                      icon={<PlusOutlined />}
                      shape="circle"
                      onClick={handleAddInstaContents}
                    />
                  </Col>
                </Row>
              </Col> */}

              {state.isBuyDirectComment && (
                <Col xs={24}>
                  <h4>인스타 댓글 입력 (추가옵션)</h4>
                  <Description text="구매 개수 만큼 일괄입력(한줄에 하나씩)" />
                  <Input.TextArea
                    rows={5}
                    value={state.comment}
                    onChange={handleChangeInput('comment')}
                    onBlur={handleCheckTextAreaRow('comment')}
                    onPressEnter={handlePressEnter('comment')}
                  />
                </Col>
              )}
            </Row>

            <Divider />

            <h3>프로그램 주의사항(필독)</h3>
            <Card size="small">
              <div dangerouslySetInnerHTML={{ __html: data.caution.content }} />
            </Card>
            <Checkbox
              checked={state.checked}
              onChange={(e) => (state.checked = e.target.checked)}
              style={{ marginTop: 10 }}
            >
              상기 주의사항을 확인하였으며, 구매진행에 동의합니다. (필수)
            </Checkbox>

            <Divider />

            <Row gutter={[10, 10]} justify="end">
              <Col>
                <Space>
                  <span>총 결제 포인트</span>
                  <span className="amount">
                    {numberWithCommas(state.buyAmount, true)}
                  </span>
                  <span>포인트</span>
                </Space>
              </Col>
            </Row>
          </TabPane>
        </Tabs>

        <Row gutter={[10, 10]} justify="end">
          <Col>
            <Button onClick={handleClose} style={{ width: 120 }}>
              취소
            </Button>
          </Col>

          {state.tab === '2' ? (
            <>
              <Col>
                <Button onClick={handleChangeTab('1')} style={{ width: 120 }}>
                  이전
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  onClick={handleClickSubmit}
                  style={{ width: 120 }}
                  // disabled={!state.checked}
                >
                  구매
                </Button>
              </Col>
            </>
          ) : (
            <Col>
              <Button
                type="primary"
                onClick={handleChangeTab('2')}
                style={{ width: 120 }}
              >
                다음
              </Button>
            </Col>
          )}
        </Row>
      </Wrapper>
    );
  },
);

export default BuyOA;
