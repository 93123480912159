/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { Form, Row, Col } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import moment from 'moment';
import styled from 'styled-components';

import { MY_POST_CNT } from '@shared/queries/PostQueries';
import { MY_COMMENT_CNT } from '@shared/queries/CommentQueries';
import useStore from '@stores/useStore';
import { validateMessages } from '@utils/customFormValidator';
import { useApolloClient } from '@apollo/react-hooks';

const Wrapper = styled.div`
  width: 100%;
`;

const ActivityInfo = observer(({ propData = {}, refetch }) => {
  const [form] = Form.useForm();

  const { commonStore } = useStore();
  const state = useLocalStore(() => ({
    postCnt: 0,
    commentCnt: 0,
    connectCnt: 0,
  }));

  const client = useApolloClient();

  const fetchData = useCallback(async () => {
    const postCntRes = await client.query({
      query: MY_POST_CNT,
      variables: { created: propData.id },
    });
    if (postCntRes.data && postCntRes.data.myPostCnt) {
      state.postCnt = postCntRes.data.myPostCnt;
    } else {
      state.postCnt = 0;
    }

    const connectCntRes = await client.query({
      query: MY_POST_CNT,
      variables: { board: '고객문의', created: propData.id },
    });
    if (connectCntRes.data && connectCntRes.data.myPostCnt) {
      state.connectCnt = postCntRes.data.myPostCnt;
    } else {
      state.connectCnt = 0;
    }

    const commentCntRes = await client.query({
      query: MY_COMMENT_CNT,
      variables: { created: propData.id },
    });
    if (commentCntRes.data && commentCntRes.data.myCommentCnt) {
      state.commentCnt = postCntRes.data.myCommentCnt;
    } else {
      state.commentCnt = 0;
    }
  }, [propData]);

  useEffect(() => {
    if (form) {
      form.resetFields();
    }
    if (propData && propData.id) {
      fetchData();
    } else {
      state.postCnt = 0;
      state.connectCnt = 0;
      state.commentCnt = 0;
    }
  }, [propData, form]);

  return (
    <Wrapper>
      <Form
        form={form}
        layout="vertical"
        name="activity_info_form"
        initialValues={{}}
        validateMessages={validateMessages}
        scrollToFirstError
      >
        <Row gutter={16}>
          <Col xs={24} lg={10}>
            <Form.Item label="가입일">
              {moment(propData.createdAt).format('YYYY-MM-DD HH:mm:ss')}
            </Form.Item>
            <Form.Item label="로그인">
              {propData.loginTime
                ? moment(propData.loginTime.date).format(
                    'YYYY-MM-DD HH:mm:ss',
                  ) + ` (${propData.loginTime.cnt}회)`
                : ''}
            </Form.Item>
            <Form.Item label="최종 로그인">
              {propData.lastLoginTime
                ? moment(propData.lastLoginTime).format('YYYY-MM-DD HH:mm:ss')
                : ''}
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item label="게시물">{state.postCnt}개</Form.Item>
            <Form.Item label="문의">{state.connectCnt}개</Form.Item>
            {/* <Form.Item label="댓글">{state.commentCnt}개</Form.Item> */}
          </Col>
          {/* <Col xs={24} lg={6}>
            <Form.Item label="추천인 코드">{propData.referralCode}</Form.Item>
            <Form.Item label="월 추천인">{propData.referralCnt}명</Form.Item>
            <Form.Item label="누적 추천인">
              {propData.referralAllCnt}명
            </Form.Item>
          </Col> */}
        </Row>
      </Form>
    </Wrapper>
  );
});

export default ActivityInfo;
