/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useEffect } from 'react';
import { Card } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { toJS } from 'mobx';
import { Swiper, SwiperSlide } from 'swiper/react';

import { META } from '@shared/queries';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 400px;

  .swiper-container {
    margin: 0;
    width: 100%;
    height: 100%;
  }
`;

const MainBanner = observer(() => {
  const state = useLocalStore(() => ({
    swiper: null,
    list: [],

    get listVal() {
      return toJS(this.list);
    },
  }));

  const metaRes = useQuery(META, { variables: { key: 'banner_main' } });

  useEffect(() => {
    if (metaRes.data && metaRes.data.meta) {
      state.swiper = null;
      state.list = metaRes.data.meta.value;

      setTimeout(() => {
        if (state.swiper) {
          state.swiper.update();
          state.swiper.slideTo(0, 0);
        }
      }, 100);
    }
  }, [metaRes.data]);

  return (
    <Wrapper>
      <Swiper
        loop={true}
        spaceBetween={0}
        slidesPerView={1}
        navigation={{
          nextEl: '.main-banner-next',
          prevEl: '.main-banner-prev',
        }}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        pagination={{ el: '.main-banner-pagination', clickable: true }}
        // onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => (state.swiper = swiper)}
      >
        {toJS(state.list).map((item, idx) => (
          <SwiperSlide key={item.uid}>
            {item.link ? (
              <a href={item.link} target="_blank">
                <img className="click" src={item.url ? item.url : ''} alt="" />
              </a>
            ) : (
              <img src={item.url ? item.url : ''} alt="" />
            )}
          </SwiperSlide>
        ))}

        <div className="swiper-button-next main-banner-next"></div>
        <div className="swiper-button-prev main-banner-prev"></div>
        <div className="swiper-pagination main-banner-pagination"></div>
      </Swiper>
    </Wrapper>
  );
});

export default MainBanner;
