/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback } from 'react';
import { Tabs, Row, Col } from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import styled from 'styled-components';

import PageTitle from '@Common/PageTitle';

import ProductConfig from './ProductConfig';
import BanWords from './BanWords';

const { TabPane } = Tabs;

const Wrapper = styled.div`
  width: 100%;
`;

const Product = observer(() => {
  const state = useLocalStore(() => ({
    tab: '1',
  }));

  const handleChangeTab = useCallback((key) => {
    state.tab = key;
  }, []);

  return (
    <Wrapper>
      <Row>
        <Col xs={24}>
          <PageTitle>상품 관리</PageTitle>
        </Col>
      </Row>

      <Tabs activeKey={state.tab} onChange={handleChangeTab}>
        <TabPane tab="K좋아요K팔로워K댓글" key="1">
          <ProductConfig
            metaKey="product_k"
            productName="K좋아요K팔로워K댓글"
            tab={state.tab}
          />
        </TabPane>
        <TabPane tab="인기게시물 상위작업" key="2">
          <ProductConfig
            metaKey="product_pp"
            productName="인기게시물"
            tab={state.tab}
          />
        </TabPane>
        <TabPane tab="인기게시물 건바이" key="3">
          <ProductConfig
            metaKey="product_pp_caseBy"
            productName="인기게시물건바이"
            tab={state.tab}
          />
        </TabPane>
        <TabPane tab="최적화계정육성" key="4">
          <ProductConfig
            metaKey="product_oa"
            productName="최적화계정육성"
            tab={state.tab}
          />
        </TabPane>
        {/* <TabPane tab="인플루언서" key="5">
          <ProductConfig
            metaKey="product_influencer"
            productName="인플루언서"
            tab={state.tab}
          />
        </TabPane> */}
        <TabPane tab="최적화테스트" key="6">
          <ProductConfig
            metaKey="product_ot"
            productName="최적화테스트"
            tab={state.tab}
          />
        </TabPane>
        <TabPane tab="금기어설정" key="7">
          <BanWords />
        </TabPane>
      </Tabs>
    </Wrapper>
  );
});

export default Product;
