/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Space,
  Avatar,
  Button,
  Divider,
  Radio,
} from 'antd';
import { observer, useLocalStore } from 'mobx-react';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import styled from 'styled-components';

import { UPDATE_USER } from '@shared/queries/UserQueries';
import useStore from '@stores/useStore';
import { validateMessages } from '@utils/customFormValidator';
import { ROLES, USER_GROUP } from '@shared/constants';

const { Option } = Select;

const Wrapper = styled.div`
  .ant-input-group-addon {
    padding: 0;
    margin: 0;
  }

  iframe {
    height: auto !important;
  }
`;

const UserInfo = observer(({ propData = {}, refetch }) => {
  const [form] = Form.useForm();

  const { commonStore } = useStore();
  const state = useLocalStore(() => ({}));

  const [update] = useMutation(UPDATE_USER);
  const client = useApolloClient();

  const handleSubmit = useCallback(
    async (values) => {
      window.maskOn();

      const data = {
        ...values,
      };

      data.updated = commonStore.user.id;
      await update({
        variables: { id: propData.id, data },
      });
      window.success({ title: `${propData.name} 회원정보가 수정되었습니다.` });
      if (refetch) {
        await refetch();
      }
      form.resetFields();
      window.maskOff();
    },
    [propData, refetch],
  );

  useEffect(() => {
    if (form) {
      form.resetFields();
    }
  }, [propData, form]);

  return (
    <Wrapper>
      <Form
        form={form}
        layout="vertical"
        name="user_info_form"
        initialValues={{
          userGroup: propData.userGroup,
          role: propData.role,
          name: propData.name,
          username: propData.username,
          phone: propData.phone,
          email: propData.email,
        }}
        validateMessages={validateMessages}
        scrollToFirstError
        onFinish={handleSubmit}
      >
        <Row gutter={32}>
          <Col xs={24} lg={12}>
            <Form.Item name="username" label="아이디">
              <Input size="large" disabled />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item name="userGroup" label="회원구분">
              <Radio.Group>
                {USER_GROUP.map((item, idx) => (
                  <Radio value={item} key={`userGroup_${idx}`}>
                    {item}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item name="role" label="권한" rules={[{ required: true }]}>
              <Select size="large" style={{ width: '100%' }}>
                {ROLES.map((item, idx) => (
                  <Option value={item.value} key={`info_role_${idx}`}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item name="name" label="이름" rules={[{ required: true }]}>
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name="phone"
              label="핸드폰번호"
              rules={[{ required: true }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item name="email" label="이메일" rules={[{ required: true }]}>
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" size="large" htmlType="submit" block>
            저장
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  );
});

export default UserInfo;
